import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import BankItem from '../components/bank/BankItem';
import AuthMoldel from '../models/AuthMoldel';
import AppNotification from '../helpers/AppNotification';
import TransactionModel from '../models/TransactionModel';
import Paginate from '../components/paginate/Paginate';

function TransactionHistoryPage(props) {
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState({});
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [keyword, setKeyword] = useState({
        start_date: '',
        end_date: '',
    });
    const [filter, setFilter] = useState({
        search: '',
        perPage: perPage,
        page: page,
    });

    const changePage = (page) => {
        setPage(page);
        setFilter({ search: keyword });
    }
    useEffect(() => {
        TransactionModel.history({
            search: filter.search,
            page: page,
            perPage: perPage
        })
            .then(res => {
                setItems(res.data);
                setMeta(res.meta);
            }).catch(err => {
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, [page, filter]);
    
    const handleChange = (event) => {
        setKeyword({
            ...keyword,
            [event.target.name]: event.target.value
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setPage(1);
        // Xử lý tìm kiếm dữ liệu dựa trên keyword
        setFilter({ search: keyword });
        // Reset trường input sau khi submit
    };

    const handleSetPage = (number) => {
        setPage(page + number);
        setFilter({ search: keyword });
    }
    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Lịch Sử Giao Dịch" />
            <div className='row'>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Lịch sử giao dịch</h4>
                        </div>
                        <div className="card-body">
                            <div className='row d-flex justify-content-end'>
                                <div className='col-lg-6 d-flex justify-content-end'>
                                    <form onSubmit={handleSubmit}>
                                        <div className='input-group mb-3'>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <label className=''>Từ ngày : </label>
                                                    <input
                                                        type='date'
                                                        name='start_date'
                                                        className='form-control ms-2'
                                                        value={keyword.start_date}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-4'>
                                                    <label className='ms-2'>Đến ngày : </label>
                                                    <input
                                                        type='date'
                                                        name='end_date'
                                                        className='form-control ms-2'
                                                        value={keyword.end_date}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-4 d-flex flex-column justify-content-end'>
                                                    <div className='input-group-append'>
                                                        <button className='btn light btn-light ms-2' type='submit'>
                                                            Tìm kiếm
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <table className="table header-border">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Thể Loại</th>
                                            <th>Số tiền</th>
                                            <th>Ghi chú</th>
                                            <th>Thời gian</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            items.map((item, key) => (
                                                <tr key={key}>
                                                    <td><a href="">{item.id}</a>
                                                    </td>
                                                    {item.type == 'DEPOSITAPP' ?? 'Nạp tiền vào App'}
                                                    <td><span className="badge badge-danger">
                                                        {item.type === 'DEPOSITAPP' && 'Nạp tiền vào App'}
                                                        {item.type === 'RECHARGE' && 'Nạp tiền vào web'}
                                                        {item.type === 'EARNMONEY' && 'rút tiền'}
                                                        {item.type === 'PAYMONEY' && 'Chuyển tiền qua tài khoản chính'}
                                                        {item.type === 'CHECK' && 'Kiểm tra tài khoản ở App'}
                                                        {item.type === 'FEETRANSFER' && 'Phí chuyển tiền trong app'}
                                                        {item.type === 'BUYPACKAGE' && 'Mua gói vip'}
                                                        {item.type === 'EDITACCOUTNAPP' && 'Sửa số tài khoản app'}
                                                        {item.type === 'CREATEACCOUTNAPP' && 'Tạo mới tài khoản app'}
                                                        {item.type === 'DEPOSITFROMADMIN' && 'Nạp tiền từ quản trị viên'}
                                                        {item.type === 'DOWLOADBILL' && 'Phí tạo bill app'}
                                                        {item.type === 'BUYPACKAGEBILL' && 'Mua gói bill'}
                                                        {item.type === 'DOWLOADNOTI' && 'Tạo Fake thông báo'}
                                                        {item.type === 'RECHARGECODE' && 'Nạp tiền từ mã CODE'}
                                                    </span>
                                                    </td>
                                                    <td><span className="badge badge-success">{item.amount_fm} VNĐ</span>
                                                    </td>
                                                    <td>{item.note}</td>
                                                    <td><span className="text-muted">{item.create_at_fm}</span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <Paginate meta={meta} changePage={changePage} page={page} handleSetPage={handleSetPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MasterLayout>
    );
}

export default TransactionHistoryPage;
