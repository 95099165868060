import React, { useState } from 'react';

function InstructBank(props) {
    const [isShow, setIsShow] = useState(0);
    const handleShow = () => {
        setIsShow(1);
    }
    const handleClose = () => {
        setIsShow(0);
    }
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <p><span className="text-warning">* Mỗi ngân hàng sẽ có 2 sự lựa chọn là "Tải App" và "Mở Ngân Hàng" :</span></p><br/>
                    <p><span className='text-primary'>- Nếu chọn "Tải App" :</span> thì sẽ mở ra một màn hình bên phải cho chúng ta lựa chọn tải App cho Android hoặc IOS</p>
                    <p><span className='text-primary'>- Nếu chọn "Mở Ngân Hàng" :</span> thì sẽ mở ra một màn hình bên phải gồm những thông tin ngân hàng và danh sách tài khoản App</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <p><span className="text-warning">* Phần danh sách tài khoản App :</span></p><br/>
                    <p><span className='text-primary'>- Nếu chưa có tài khoản thì bấm vào thêm tài khoản :</span> Nhập các thông tin cần thiết, lưu ý stk ngân hàng phải là số tài khoản đăng ký ở ngân hàng đó.</p>
                    <p><span className='text-primary'></span>- Sau khi có tài khoản thì dùng thông tin đó để đăng nhập vào app...</p>
                </div>
            </div>
            {
                isShow == 1 ?
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <p><span className='text-primary'>- Nhấn vào biểu tượng hình mắt :</span> sẽ hiển thị thông tin số dư, mật khẩu và Mã OTP </p>
                                <p><span className='text-primary'>- Nhấn vào biểu tượng dấu cộng :</span> sẽ hiển thị chức năng nạp tiền vào app,sửa tài khoản và xóa tài khoản </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <button onClick={handleClose} className='btn btn-primary btn-sm'>Đóng</button>
                            </div>
                        </div>
                    </div>
                    :
                    ''
            }
            {
               isShow == 0 ? 
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <button onClick={handleShow} className='btn btn-primary btn-sm'>Xem thêm</button>
                        </div>
                    </div>
                : ''
            }
        </>

    );
}

export default InstructBank;