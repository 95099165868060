import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import UserBankAcountModel from '../../models/UserBankAcountModel';
import AppNotification from '../../helpers/AppNotification';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../../redux/action';


const validationSchema = Yup.object().shape({
    accountNumber: Yup.number()
        .typeError('Vui lòng chỉ nhập số')
        .required('Vui lòng nhập số tài khoản')
        .test('is-number', 'Số tài khoản phải là chữ số', value => {
            return !isNaN(value); // Kiểm tra xem giá trị có phải là số hay không
        }),
    phone: Yup.string()
    .required('Vui lòng nhập số điện thoại')
    .test('phone-length', 'Số điện thoại phải có 10 chữ số', (value) => {
        if (value) {
        return value.length === 10;
        }
        return true;
    }),
    password_level_two: Yup.string()
        .required('Vui lòng nhập OTP')
        .test('password_level_two-length', 'Mã OTP phải có 6 chữ số', (value) => {
            if (value) {
                return String(value).length === 6;
            }
            return true;
        }),
    password: Yup.string()
        .min(6, 'Độ dài tối thiểu 6 ký tự')
        .max(32, 'Độ dài tối đa 32 ký tự')
        .required('Vui lòng nhập mật khẩu!'),
});

function ModalCreateAcount(props) {

    
    const dispatch = useDispatch();

    const initialValues = {
        accountNumber: '',
        phone: '',
        password: '',
        password_level_two: '',
        bin: props.bin,
        type: props.type,
        bank_name: props.bankName,
    };
    const handleSubmit = (values) => {
        dispatch(setShowPreloader(true));
        UserBankAcountModel.createUserBankAcount(values).then(function (res) {
            dispatch(setShowPreloader(false));
            if (res.success === true) {
                Swal.fire({
                    icon: "success",
                    title: "Thêm tài khoản thành công!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                setTimeout(() => {
                    props.reloadPage(props.id,props.type);
                    props.onHide();
                }, 2000);
            } else {
                Swal.fire({
                    icon: "error",
                    title: res.data,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        }).catch(function (err) {
            dispatch(setShowPreloader(false));
            Swal.fire({
                icon: "error",
                title: 'Thêm tài khoản thất bại!',
                showConfirmButton: false,
                timer: 2000,
            });
            console.log(err);
        })
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Thêm Tài khoản {props.bankName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className='card-body'>
                                
                            </div>
                            <div className="mb-3">
                                <label htmlFor="accountNumber" className="form-label">
                                    <span className='text-success'>Nhập <span className='text-warning'>số tài khoản ngân hàng thật</span> {props.bankName}</span> (*)
                                </label>
                                <Field
                                    type="text"
                                    id="accountNumber"
                                    name="accountNumber"
                                    className={`form-control ${errors.accountNumber && touched.accountNumber ? 'is-invalid' : ''
                                        }`}
                                    autoFocus
                                />
                                {errors.accountNumber && touched.accountNumber && (
                                    <div className="invalid-feedback">{errors.accountNumber}</div>
                                )}
                                <label htmlFor="phone" className="form-label">
                                    <span className='text-success'>Nhập số điện thoại đăng ký làm tài khoản đăng nhập App</span> (*)
                                </label>
                                <Field
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    className={`form-control ${errors.phone && touched.phone ? 'is-invalid' : ''
                                        }`}
                                    autoFocus
                                />
                                {errors.phone && touched.phone && (
                                    <div className="invalid-feedback">{errors.phone}</div>
                                )}
                                <label htmlFor="password_level_two" className="form-label">
                                    <span className='text-success'>Đặt OTP 6 số làm OTP chuyển khoản</span> (*)
                                </label>
                                <Field
                                    type="text"
                                    id="password_level_two"
                                    name="password_level_two"
                                    className={`form-control ${errors.password_level_two && touched.password_level_two ? 'is-invalid' : ''
                                        }`}
                                    autoFocus
                                />
                                {errors.password_level_two && touched.password_level_two && (
                                    <div className="invalid-feedback">{errors.password_level_two}</div>
                                )}
                                <label htmlFor="password_level_two" className="form-label">
                                    <span className='text-success'>Đặt mật khẩu đăng nhập App</span> (*)
                                </label>
                                <Field
                                    type="text"
                                    id="password"
                                    name="password"
                                    className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''
                                        }`}
                                    autoFocus
                                />
                                {errors.password && touched.password && (
                                    <div className="invalid-feedback">{errors.password}</div>
                                )}
                                <p>Lưu ý:</p>
                                <ul>
                                    <li>
                                        - Số tài khoản<span className="text-warning">phải trùng với số tài khoản ngân hàng thật.</span>
                                    </li>
                                    <li>
                                        - Bạn <span className="text-warning">chỉ cần nhập Số tài khoản ngân hàng</span>, hệ thống sẽ tự động tìm tên Chủ tài khoản.
                                    </li>
                                    <li>
                                        - Việc kiểm tra tên tự động sẽ <span className="text-warning">mất một khoản phí là 2.000 đ.</span>
                                    </li>
                                    <li>
                                        - Hệ thống <span className="text-warning">không hoàn tiền khi quý khách nhập sai</span> STK.
                                    </li>
                                </ul>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={props.onHide}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-success">
                                    Xác nhận
                                </button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default ModalCreateAcount;