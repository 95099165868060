import React, { useEffect, useRef, useState } from 'react';
import ThemeBreadcrumb from '../themes/ThemeBreadcrumb';
import MasterLayout from '../../layouts/MasterLayout';
import ImageBill from './ImageBill';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CurrencyInput from '../global/CurrencyInput';
import AppNotification from '../../helpers/AppNotification';
import { useDispatch } from 'react-redux';
import VietQrModel from '../../models/VietQrModel';
import { setDownloadBillData, setShowPreloader } from '../../redux/action';
import UserModel from '../../models/UserModel';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MaGiaoDich from '../../models/MaGiaoDich';
import FormCreateBillSchema from '../../models/FormCreateBillSchema';
import BillGenerate from '../../models/BillGenerate';
import Select from 'react-select';

function FormCreateBill(props) {
    const { type } = useParams();
    const [dataValidate, setDataValidate] = useState({}); // Initialize with an empty object
    const hours = Array.from({ length: 24 }, (_, index) => index);
    const minutes = Array.from({ length: 59 }, (_, index) => index);
    useEffect(() => {
        let schema = FormCreateBillSchema.generateSchema(type);
        setDataValidate(schema);
    }, [type]);

    const validationSchema = dataValidate;
    // Sinh tự động mã giao dịch
    var tranansaction_code_random = MaGiaoDich.tranansactionCodeRandom(type);

    const [initialValues, setInitialValues] = useState({
        type: type,
        amount: '',
        hour: '',
        minute: '',
        day: '',
        receiver_name: '',
        receiver_number: '',
        receiver_bank_name: '',
        transaction_code: tranansaction_code_random ? tranansaction_code_random : '',
        operatingSystem: 'ios',
        decreption: '',
        sender_name: '',
        sender_number: '',
        logo: '',
        shortname: '',
        fullname: '',
        fullname_fm: '',
        power: 10,
    });

    useEffect(() => {
        const currentDate = new Date();
        const currentHour = currentDate.getHours().toString().padStart(2, "0"); // Lấy giờ hiện tại và định dạng thành chuỗi 2 chữ số
        const currentDay = currentDate.toISOString().split("T")[0]; // Lấy ngày hiện tại và định dạng thành chuỗi YYYY-MM-DD
        const currentMinute = currentDate.getMinutes().toString().padStart(2, "0");
        setInitialValues({
            ...initialValues,
            hour: currentHour,
            minute: currentMinute,
            day: currentDay,
            operatingSystem: "ios",
        });
    }, []);

    const navigate = useNavigate();
    // const { typeBank, bankName } = location.state;
    // //console.log(typeBank);
    const dispatch = useDispatch();
    const [flagShowImage, setFlagShowImage] = useState(false);

    const [imageTemplate, setImageTemplate] = useState('/bills/' + type + '/ios/base.png');
    const [exportBill, setExportBill] = useState(false);

    const [operating, setOperating] = useState();
    const [bankList, setBankList] = useState([]);
    useEffect(() => {
        setOperating(0); // Set lại giá trị của showFormCreateBill thành 0 mỗi khi component được tải lại
    }, []);
    const handleChangeOperating = (event) => {
        setOperating(event.target.value);
    };

    const [vietQrBanks, setVietQrBanks] = useState([]);

    useEffect(() => {
        dispatch(setShowPreloader(true));
        VietQrModel.getAllVietQR({
            type: type
        })
            .then(res => {
                dispatch(setShowPreloader(false));
                setVietQrBanks(res.data);

                let newBankList = []
                for (var i in res.data) {
                    let bankObj = res.data[i];
                    let bill_logo = bankObj.logo;
                    bill_logo = bill_logo.replace('http://127.0.0.1:8000/images','');
                    bill_logo = bill_logo.replace('https://okbill.net/images','');
                    newBankList[bankObj.shortName] = {
                        type : bankObj.type,
                        logo : bill_logo,
                        name : bankObj.name, 
                        name_fm : bankObj.name_fm, 
                        shortName : bankObj.shortName, 
                    };
                }
                setBankList(newBankList)
            }).catch(err => {
                dispatch(setShowPreloader(false));
                //console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, []);

    const options = vietQrBanks.map(item => ({
        value: item.name_fm,
        label: item.shortName
    }));
    const handleSubmit = (values) => {
        //console.log(values);
        setExportBill(false);
        setInitialValues({
            ...values,
            type: type
        });
        setFlagShowImage(true);

    };

    const handleExportBill = (imagePreview) => {
        const userAgent = navigator.userAgent;
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

        dispatch(setShowPreloader(true));
        UserModel.feeDowloadBill(imagePreview)
            .then(res => {
                dispatch(setShowPreloader(false));
                if (res.success === true) {
                    if (isMobile) {
                        window.location.href = res.imageUrl;
                    } else {
                        var link = document.createElement('a');
                        link.href = res.imageUrl;
                        link.download = 'bill.png';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                    // setDownloadBill(res.imageUrl)

                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.messange,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }).catch(err => {
                dispatch(setShowPreloader(false));
                //console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    };

    const [infoBankBill, setInfoBankBill] = useState({});

    useEffect(() => {
        let foundElement = null;
        vietQrBanks.forEach(element => {
            if (element.code === type) {
                foundElement = element;
            }
        });

        if (foundElement) {
            setInfoBankBill(foundElement);
        }
    }, [type, vietQrBanks]);

    const handleSenderName = (event) => {
        setInitialValues({
            ...initialValues,
            sender_name: event.target.value,
            decreption: event.target.value + " chuyen khoan",
        });
    }

    const handleChangeAllFeildBank = (event) => {
        const { label, value } = event;
        console.log('label',label);
        console.log('value',value);
        var selectedOption = label;
        var dataLogo = bankList[selectedOption].logo;
        var dataShortName = bankList[selectedOption].shortName;
        var dataFullName = bankList[selectedOption].name_fm;
        var dataFullNameFm = bankList[selectedOption].name;
        setInitialValues((prevValues) => ({
            ...prevValues,
            ['receiver_bank_name']: value,
            logo: dataLogo,
            shortname: dataShortName,
            fullname: dataFullName,
            fullname_fm: dataFullNameFm
        }));
    }
    const handleChangeAllFeil = (event) => {
        const { name, value } = event.target || {};
        setInitialValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };


    const handleChangeAmount = (event, value) => {
        //console.log(initialValues);
        setInitialValues((prevValues) => ({
            ...prevValues,
            ['amount']: value,
        }));
    };

    // xử lý tạo bill
    const [imagePreview, setImagePreview] = useState('');
    useEffect(() => {
        //console.log('props', props);
        // Load fonts
        const fonts = [
            { name: 'SFProDisplay', url: 'url(/fonts/SF-Pro-Display/SFProDisplay-Regular.woff2)', weight: 'normal' },
            { name: 'SFProDisplay', url: 'url(/fonts/SF-Pro-Display/SFProDisplay-Medium.woff2)', weight: '500' },
            { name: 'SFProDisplay', url: 'url(/fonts/SF-Pro-Display/SFProDisplay-Bold.woff2)', weight: 'bold' },
            { name: 'Inter', url: 'url(/fonts/Inter/Inter-Regular.woff2)', weight: 'normal' },
            { name: 'Inter', url: 'url(/fonts/Inter/Inter-Medium.woff2)', weight: '500' },
            { name: 'Inter', url: 'url(/fonts/Inter/Inter-Bold.woff2)', weight: 'bold' },
            { name: 'Be Vietnam Pro', url: 'url(/fonts/BeVietnamPro/BeVietnamPro-Regular.woff2)', weight: 'normal' },
            { name: 'Be Vietnam Pro', url: 'url(/fonts/BeVietnamPro/BeVietnamPro-Medium.woff2)', weight: '500' },
            { name: 'Be Vietnam Pro', url: 'url(/fonts/BeVietnamPro/BeVietnamPro-Bold.woff2)', weight: 'bold' },
        ];

        const fontPromises = fonts.map(({ name, url, weight }) => {
            const font = new FontFace(name, url, { weight: weight });
            return font.load().then((loadedFont) => {
                document.fonts.add(loadedFont);
            });
        });

        Promise.all(fontPromises)
            .then((data) => {
                //console.log(123);
                if (initialValues.amount) {
                    var platform = initialValues.operatingSystem;
                    let power = initialValues.power;
                    if (!platform) {
                        platform = 'android';
                    }
                    var type = initialValues.type;
                    var canvas = document.createElement('canvas');
                    canvas.width = 636;
                    canvas.height = 1377;
                    var ctx = canvas.getContext('2d');
                    var image = new Image();
                    image.src = '/bills/' + type + '/' + platform + '/' + power + '.png';
                    // image.src = '/bills/' + type + '/' + platform + '/base.png';
                    //console.log(image.src);
                    image.onload = () => {
                        var imageWidth = image.width;
                        var imageHeight = image.height;

                        // Tính tỷ lệ giữa kích thước hình ảnh và canvas
                        var aspectRatio = imageWidth / imageHeight;
                        // Tính toán kích thước mới cho hình ảnh
                        var newWidth, newHeight;
                        if (aspectRatio > 1) {
                            newWidth = canvas.width;
                            newHeight = canvas.width / aspectRatio;
                        } else {
                            newWidth = canvas.height * aspectRatio;
                            newHeight = canvas.height;
                        }
                        // Set lại canvas
                        canvas.width = newWidth;
                        canvas.height = newHeight;
                        // Tính toán vị trí chèn hình ảnh vào canvas
                        var x = (canvas.width - newWidth) / 2;
                        var y = (canvas.height - newHeight) / 2;
                        ctx.drawImage(image, x, y, newWidth, newHeight);
                        ctx = BillGenerate.generateBill(type, initialValues, ctx, canvas);

                        // Render image
                        var imageURL = canvas.toDataURL('image/png', 1);
                        setImagePreview(imageURL);

                        if (exportBill) {
                            var link = document.createElement("a");
                            link.href = canvas.toDataURL('image/png', 1);
                            link.download = type + ' - ' + Math.floor(Date.now() / 1000) + ".png";
                            link.click();
                            Swal.fire({
                                icon: "success",
                                title: "Tải bill thành công!",
                                showConfirmButton: false,
                                timer: 2000,
                            });
                        }
                    }
                }
            });

    }, [initialValues])
    return (
        <MasterLayout>
            <ThemeBreadcrumb title={`Tạo bill / Fake Bill Chuyển Khoản ${infoBankBill.shortName}`} />
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className="col-xl-8 col-lg-8">
                            <div className="row">
                                <div className="col-xl-12 col-xxl-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Danh Sách Ngân Hàng Fake Bill Chuyển Khoản {infoBankBill.shortName}</h4>
                                        </div>
                                        <div className="card-body">
                                            <Formik
                                                initialValues={initialValues}
                                                validationSchema={validationSchema}
                                                onSubmit={handleSubmit}
                                                enableReinitialize={true}
                                            >
                                                {({ errors, touched, setFieldValue }) => (

                                                    <Form>
                                                        <div id="smartwizard" className="form-wizard order-create sw sw-theme-default sw-justified">
                                                            <div className="tab-content" style={{ height: 'auto' }}>
                                                                <div
                                                                    id="wizard_Service"
                                                                    className="tab-pane"
                                                                    role="tabpanel"
                                                                    style={{ display: 'block' }}
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Số tiền*</label>
                                                                                <CurrencyInput
                                                                                    type="text"
                                                                                    className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                                                                        }`}
                                                                                    autoFocus
                                                                                    onChange={e => {
                                                                                        let value = e.target.value || "";
                                                                                        value = value.replaceAll(',', '');
                                                                                        setFieldValue("amount", value);
                                                                                        handleChangeAmount(e, value)
                                                                                    }}
                                                                                />
                                                                                <ErrorMessage name="amount" component="div" className="error-message text-danger" />
                                                                                <Field
                                                                                    type="hidden"
                                                                                    name="amount"
                                                                                    className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                                                                        }`}
                                                                                    autoFocus
                                                                                    placeholder=""
                                                                                    required=""
                                                                                    fdprocessedid="y2s2mp"

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Ngày*</label>
                                                                                <Field
                                                                                    type="date"
                                                                                    name="day"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    required=""
                                                                                    fdprocessedid="4aq0kr"
                                                                                    onChange={handleChangeAllFeil}
                                                                                />
                                                                                <ErrorMessage name="day" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Thời gian*</label>
                                                                                <div className='row'>
                                                                                    <div className='col'>
                                                                                        <Field
                                                                                            as="select"
                                                                                            name="hour"
                                                                                            className="form-control form-control-new"
                                                                                            required=""
                                                                                            onChange={handleChangeAllFeil}
                                                                                        >
                                                                                            {hours.map((hour) => (
                                                                                                <option value={hour < 10 ? '0' + hour : hour} key={hour}>{hour < 10 ? '0' + hour : hour}</option>
                                                                                            ))}
                                                                                        </Field>
                                                                                    </div>
                                                                                    <div className='col'>
                                                                                        <Field
                                                                                            as="select"
                                                                                            name="minute"
                                                                                            className="form-control form-control-new"
                                                                                            placeholder=""
                                                                                            onChange={handleChangeAllFeil}
                                                                                        >
                                                                                            {minutes.map((minute) => (
                                                                                                <option value={minute < 10 ? '0' + minute : minute} key={minute}>{minute < 10 ? '0' + minute : minute}</option>
                                                                                            ))}
                                                                                        </Field>
                                                                                    </div>
                                                                                </div>

                                                                                <ErrorMessage name="hour" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        {
                                                                            (type == 'MB' ||
                                                                                type == 'ACB' ||
                                                                                type == 'ICB' ||
                                                                                type == 'STB' ||
                                                                                type == 'TPB')
                                                                                ?
                                                                                (
                                                                                    <>
                                                                                        <div className="col-lg-6 mb-2">
                                                                                            <div className="mb-3">
                                                                                                <label className="text-label form-label">Số tài khoản người gửi*</label>
                                                                                                <Field
                                                                                                    type="text"
                                                                                                    name="sender_number"
                                                                                                    className="form-control"
                                                                                                    placeholder=""
                                                                                                    required=""
                                                                                                    fdprocessedid="4aq0kr"
                                                                                                    onChange={handleChangeAllFeil}
                                                                                                />
                                                                                                <ErrorMessage name="sender_number" component="div" className="error-message text-danger" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                                :
                                                                                ''
                                                                        }
                                                                        {
                                                                            (type == 'MB' ||
                                                                                type == 'ACB' ||
                                                                                type == 'ICB' ||
                                                                                type == 'TPB' ||
                                                                                type == 'MSB')
                                                                                ?
                                                                                (
                                                                                    <>
                                                                                        <div className="col-lg-6 mb-2">
                                                                                            <div className="mb-3">
                                                                                                <label className="text-label form-label">Tên người gửi*</label>
                                                                                                <Field
                                                                                                    type="text"
                                                                                                    name="sender_name"
                                                                                                    className="form-control"
                                                                                                    placeholder=""
                                                                                                    required=""
                                                                                                    fdprocessedid="4aq0kr"
                                                                                                    onChange={handleSenderName}

                                                                                                />
                                                                                                <ErrorMessage name="sender_name" component="div" className="error-message text-danger" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                                :
                                                                                ''
                                                                        }
                                                                        <div className="col-lg-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Tên người nhận*</label>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="receiver_name"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    required=""
                                                                                    fdprocessedid="4aq0kr"
                                                                                    onChange={handleChangeAllFeil}
                                                                                />
                                                                                <ErrorMessage name="receiver_name" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Số tài khoản người nhận*</label>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="receiver_number"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    required=""
                                                                                    fdprocessedid="4aq0kr"
                                                                                    onChange={handleChangeAllFeil}
                                                                                />
                                                                                <ErrorMessage name="receiver_number" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="col-lg-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Ngân hàng thụ hưởng*</label>
                                                                                <Field
                                                                                    as="select"
                                                                                    type="text"
                                                                                    name="receiver_bank_name"
                                                                                    className="form-control form-control-new select2"
                                                                                    placeholder=""
                                                                                    required=""
                                                                                    fdprocessedid="4aq0kr"
                                                                                    onChange={handleChangeAllFeil}
                                                                                >
                                                                                    <option value="">- -Chọn Ngân hàng- -</option>
                                                                                    {
                                                                                        vietQrBanks.map((item, key) => (
                                                                                            <option
                                                                                                data-logo={item.logo}
                                                                                                data-shortname={item.shortName}
                                                                                                value={`${item.name_fm}`}>{item.shortName}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </Field>
                                                                                <ErrorMessage name="receiver_bank_name" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div> */}

                                                                        <div className="col-lg-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Ngân hàng thụ hưởng*</label>
                                                                                <div className="select-wrapper"> {/* Apply custom styling for select */}
                                                                                    <Select
                                                                                        className="react-select-container"
                                                                                        classNamePrefix="react-select"
                                                                                        // components={{
                                                                                        //     Control: ({ children, ...props }) => (
                                                                                        //         <div className="css-13cymwt-control form-control form-control-new" {...props}>
                                                                                        //             {children}
                                                                                        //         </div>
                                                                                        //     )
                                                                                        // }}
                                                                                        name="receiver_bank_name"
                                                                                        onChange={handleChangeAllFeildBank}
                                                                                        options={options}
                                                                                        styles={{
                                                                                            option: (provided, state) => ({
                                                                                                ...provided,
                                                                                                color: 'white',
                                                                                            }),
                                                                                            singleValue: (provided, state) => ({
                                                                                                ...provided,
                                                                                                color: 'black',
                                                                                            }),
                                                                                            input: (provided, state) => ({
                                                                                                ...provided,
                                                                                                color: 'black',
                                                                                            }),
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <ErrorMessage name="receiver_bank_name" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Mã giao dịch*</label>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="transaction_code"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    required=""
                                                                                    fdprocessedid="4aq0kr"
                                                                                    value={initialValues.transaction_code}
                                                                                />
                                                                                <ErrorMessage name="transaction_code" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Hệ điều hành*</label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="operatingSystem"
                                                                                    className="form-control form-control-new"
                                                                                    required=""
                                                                                    fdprocessedid="4aq0kr"
                                                                                    onChange={handleChangeAllFeil}
                                                                                >
                                                                                    <option value="ios">IOS</option>
                                                                                    <option value="android">Android</option>
                                                                                </Field>
                                                                                <ErrorMessage name="operatingSystem" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                       
                                                                        <div className="col-lg-6 col-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Tình trạng pin*</label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="power"
                                                                                    className="form-control form-control-new"
                                                                                    required=""
                                                                                    fdprocessedid="4aq0kr"
                                                                                    onChange={handleChangeAllFeil}
                                                                                >
                                                                                    <option value="10">10%</option>
                                                                                    <option value="20">20%</option>
                                                                                    <option value="30">30%</option>
                                                                                    <option value="40">40%</option>
                                                                                    <option value="50">50%</option>
                                                                                    <option value="60">60%</option>
                                                                                    <option value="70">70%</option>
                                                                                    <option value="80">80%</option>
                                                                                    <option value="90">90%</option>
                                                                                    <option value="100">100%</option>
                                                                                </Field>
                                                                                <ErrorMessage name="power" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Nội dung giao dịch*</label>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="decreption"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    required=""
                                                                                    fdprocessedid="4aq0kr"
                                                                                    onChange={handleChangeAllFeil}
                                                                                />
                                                                                <ErrorMessage name="decreption" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        {/* Thêm các trường khác tương tự */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="toolbar toolbar-bottom d-flex justify-content-between"
                                                                role="toolbar"
                                                                style={{ textAlign: 'right' }}
                                                            >
                                                                <Link
                                                                    className="btn btn-primary sw-btn-prev"
                                                                    to="/bill"
                                                                >
                                                                    Quay lại
                                                                </Link>
                                                                <button onClick={() => handleExportBill(imagePreview)} type="button" className="btn btn-primary ms-2 sw-btn-prev">
                                                                    Tạo và Tải bill
                                                                    <svg className='ms-2 bi bi-cloud-download' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                                        <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                                                                        <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
                                                                    </svg>
                                                                </button>
                                                                {/* <button type="submit" className="btn btn-primary ms-2 sw-btn-prev">
                                                                    Tạo hóa đơn
                                                                </button> */}
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}

                                            </Formik>
                                            <div className='row pt-3'>
                                                <div className='col'>
                                                    <strong className='text-warning'>*Lưu ý :</strong>
                                                    <p className='text-primary'>- Phí tạo bill là : 19.000 VNĐ</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <ImageBill exportBill={exportBill} imageBill={imageTemplate} initialValues={initialValues} flagShowImage={flagShowImage} /> */}
                        <ImageBill imageBill={imagePreview} imageTemplate={imageTemplate} />
                        <img id="billSign" src={'/bills/vnd.png'} style={{ display:'none', zIndex: -2 }} />
                        <img id="billLogo" src={initialValues.logo} style={{ display:'none', zIndex: -1 }} />
                    </div>
                </div>
            </div>
        </MasterLayout>
    );
}

export default FormCreateBill;