import axiosClient from './axiosClient';
import AppCookie from "../helpers/AppCookie";

class DeviceModel {
    constructor(){
        this.api_url = "/api/device-show";
    }
    async getDevice(credentials) {
        const res = await axiosClient.post(this.api_url, credentials);
        return res;
    }

}

export default new DeviceModel;