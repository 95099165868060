import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import VerifyCode from '../../models/VerifyCode';
import AppNotification from '../../helpers/AppNotification';
import UserModel from '../../models/UserModel';
import PaymentModel from '../../models/PaymentModel';
import ModalOwnerBank from './ModalOwnerBank';
import Swal from "sweetalert2";
import CurrencyInput from '../global/CurrencyInput';
import { useNavigate } from 'react-router-dom';
const validationSchema = Yup.object().shape({
    amount: Yup.number()
        .required('Vui lòng nhập số tiền')
        // .min(10000, 'Số tiền phải lớn hơn hoặc bằng 10.000đ'),
});

function ModalDeposit(props) {
    const navigate = useNavigate();
    const ownerBank = props.bank;
    const [amount, setAmount] = useState();
    const [ depositIdcheck, setDepositIdcheck ] = useState(null);
    const [ dataQR, setDataQR ] = useState();
    const [flag,setFlag] = useState(false);

    const [modalShow, setModalShow] = React.useState(false);

    const handleCloseModal = () => {
        setModalShow(false);
    };

    const initialValues = {
        amount: '',
        ownerbank_id : props.ownerid,
    };

    const [ownerBankSD, setOwnerBankSD] = useState({});
    const [checkoutUrl, setCheckoutUrl] = useState('');

    useEffect(() => {
        if(flag == true) {
            localStorage.setItem('depositIdcheck', JSON.stringify(depositIdcheck));
            localStorage.setItem('ownerBankSD', JSON.stringify(ownerBankSD));
            localStorage.setItem('checkoutUrl', JSON.stringify(checkoutUrl));
            navigate('/deposit/app');
        }
    }, [ownerBankSD,checkoutUrl,depositIdcheck,flag]);

    const handleSubmit = (values) => {
        PaymentModel.deposit(values).then(function (res) {
            setOwnerBankSD(ownerBank);
            if( res.success ){
                setFlag(true);
                setCheckoutUrl(res.redirect);
                props.onHide()
                // Thiết lập state để Gọi api check tài khoản
                setDepositIdcheck(res.id);
                localStorage.setItem('depositIdcheck', JSON.stringify(depositIdcheck));

                // setModalShow(true);
            }
        }).catch(function (err) {
            Swal.fire({
                icon: "error",
                title: "Nạp tiền thất bại!",
                showConfirmButton: false,
                timer: 2000,
            });
            AppNotification.showError('Nạp tiền thất bại!!');
        })
    };
    
    // Tự động kiểm tra giao dịch
    useEffect(() => {
        const interval = setInterval(() => {
            // Kiểm tra có ID mới bắt đầu call api
            if(depositIdcheck){
                PaymentModel.depositDetail(depositIdcheck).then(function (res) {
                    AppNotification.showSuccess("lấy chi tiết nạp tiền thành công!");
                    if(res.data.status == 1){
                        // Đóng modal iframe và thiết lập CheckoutUrl rỗng
                        setCheckoutUrl('');
                        setModalShow(false);
    
                        // Cập nhật số tiền trong tài khoản user
                        UserModel.updateBlance({
                            amount: res.data.amount
                        }).then(function (res) {
                            Swal.fire({
                                icon: "success",
                                title: "Nạp tiền thành công!",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                            clearInterval(interval); 
                            navigate('/payment/deposit');
                        }).catch(function (err) {
                            AppNotification.showError('Cập nhật số dư thất bại!!');
                        })
                    }
                    if(res.data.status == -1){
                        // Đóng modal iframe và thiết lập CheckoutUrl rỗng
                        setCheckoutUrl('');
                        setModalShow(false);

                        Swal.fire({
                            icon: "error",
                            title: "Nạp tiền thất bại!",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                        clearInterval(interval); 
                    }
                }).catch(function (err) {
                    console.log(err);
                    AppNotification.showError('Lấy chi tiết thất bại!!');
                })
            }
        }, 5000); 
        return () => {
          clearInterval(interval); 
        };
      }, [depositIdcheck]); 
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {ownerBank && `Nạp tiền vào tài khoản ${ownerBank.name}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched, setFieldValue }) => (
                            <Form>
                                <div className="mb-3">
                                    <label htmlFor="amount" className="form-label">
                                        Số tiền (*)
                                    </label>
                                    <CurrencyInput
                                        type="text"
                                        className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                            }`}
                                        autoFocus
                                        onChange={e => {
                                            let value = e.target.value || "";
                                            value = value.replaceAll(',','');
                                            setFieldValue("amount", value);
                                          }}
                                    />
                                    <Field
                                        type="hidden"
                                        id="amount"
                                        name="amount"
                                        className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                            }`}
                                        autoFocus
                                    />
                                    {errors.amount && touched.amount && (
                                        <div className="invalid-feedback">{errors.amount}</div>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={props.onHide}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-success">
                                        Xác nhận
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <ModalOwnerBank
                show={modalShow}
                onHide={handleCloseModal}
                ownerbank={ownerBankSD}
                // amount={amount}
                // dataQR={dataQR}
                checkout_url={checkoutUrl}
            />
        </>


    );
}

export default ModalDeposit;