import React, { useState } from 'react';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import MasterLayout from '../layouts/MasterLayout';
import ModalChangePassWord from '../components/changepassword/ModalChangePassWord';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import UserModel from '../models/UserModel';
import AppNotification from '../helpers/AppNotification';
import Swal from "sweetalert2";

const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string().required('Vui lòng nhập mật khẩu cũ'),
    new_password: Yup.string()
        .min(6, 'Độ dài tối thiểu 6 ký tự')
        .max(32, 'Độ dài tối đa 32 ký tự')
        .matches(/[A-Z]/, 'Phải có ít nhất một chữ in hoa')
        .required('Vui lòng nhập mật khẩu mới'),
    repeat_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Mật khẩu không khớp')
        .required('Vui lòng nhập lại mật khẩu'),
});

const changeSecondPasswordSchema = Yup.object().shape({
    old_password: Yup.string().required('Vui lòng nhập mật khẩu cũ'),
    new_password: Yup.string()
        .min(6, 'Độ dài tối thiểu 6 ký tự')
        .max(32, 'Độ dài tối đa 32 ký tự')
        .matches(/^[0-9]{6}$/, 'Mật khẩu phải có 6 ký tự số')
        .required('Vui lòng nhập mật khẩu mới'),
    repeat_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Mật khẩu không khớp')
        .required('Vui lòng nhập lại mật khẩu'),
});

function UserChangePasswordPage(props) {
    const [modalShow, setModalShow] = React.useState(false);
    const [restoreTpye, setRestoreTpye] = useState("");
    const [formPassword] = useState({
        old_password: "",
        new_password: "",
        repeat_password: "",
        type: "PASSWORD",
    })

    const [formSecondpass] = useState({
        old_password: "",
        new_password: "",
        repeat_password: "",
        type: "SECONDPASS",
    })
    const handleShowModal = (type) => {
        setModalShow(true)
        setRestoreTpye(type);
    }
    const handleCloseModal = () => {
        setModalShow(false);
    };

    const handleSubmit = (values) => {
        if (values.type == "PASSWORD") {
            UserModel.changePassword(values).then(function (res) {
                if (res.success == true) {
                    Swal.fire({
                        icon: "success",
                        title: "Đổi mật khẩu thành công!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    AppNotification.showSuccess('Cập Nhật Mật Khẩu Thành Công!');
                }else{
                    Swal.fire({
                        icon: "error",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }).catch(function (err) {
                console.log(err);
                Swal.fire({
                    icon: "error",
                    title: "Đổi mật khẩu Thất bại!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                AppNotification.showError('Cập Nhật Mật Khẩu Thất Bại!');
            })
        }
        if (values.type == "SECONDPASS") {
            UserModel.changePassword(values).then(function (res) {
                if (res.success == true) {
                    Swal.fire({
                        icon: "success",
                        title: "Đổi mật khẩu Cấp 2 Thành Công!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    AppNotification.showSuccess('Cập Nhật Mật Khẩu Cấp 2 Thành Công!');
                }else {
                    Swal.fire({
                        icon: "error",
                        title: res.data,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }).catch(function (err) {
                Swal.fire({
                    icon: "error",
                    title: "Đổi mật khẩu Cấp 2 Thất bại!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                console.log(err);
                AppNotification.showError('Cập Nhật Mật Khẩu Thất Bại!');
            })
        }
        
    }
    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Đổi mật khẩu" />
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className='col-xl-6'>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Đổi mật khẩu</h4>
                                </div>
                                <div className="card-body p-3">
                                    <Formik
                                        initialValues={formPassword}
                                        validationSchema={changePasswordSchema}
                                        onSubmit={(values) => handleSubmit(values)}
                                    >
                                        {({ errors, touched }) => (
                                            <Form>
                                                <div className="mb-3 row">
                                                    <label className="col-sm-3 col-form-label col-form-label-sm text-primary">
                                                        Mật khẩu cũ:
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <Field
                                                            type="password"
                                                            className="form-control form-control-sm"
                                                            name="old_password"
                                                            placeholder="Mật khẩu cũ"
                                                        />
                                                        <ErrorMessage
                                                            component="div"
                                                            name="old_password"
                                                            className="text-danger"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label className="col-sm-3 col-form-label col-form-label-sm text-primary">
                                                        Mật khẩu mới:
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <Field
                                                            type="password"
                                                            className="form-control form-control-sm"
                                                            name="new_password"
                                                            placeholder="Mật khẩu mới"
                                                        />
                                                        <ErrorMessage
                                                            component="div"
                                                            name="new_password"
                                                            className="text-danger"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label className="col-sm-3 col-form-label col-form-label-sm text-primary">
                                                        Nhập lại mật khẩu:
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <Field
                                                            type="password"
                                                            className="form-control form-control-sm"
                                                            name="repeat_password"
                                                            placeholder="Nhập lại mật khẩu"
                                                        />
                                                        <ErrorMessage
                                                            component="div"
                                                            name="repeat_password"
                                                            className="text-danger"
                                                        />
                                                        <input
                                                            type="hidden"
                                                            className="form-control form-control-sm"
                                                            name="type"
                                                            value="PASSWORD"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-12 col-xxl-12">
                                                        <div className="mb-2">
                                                            <h4 className="card-title card-intro-title text-warning">
                                                                Yêu cầu mật khẩu
                                                            </h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <ul className="text-success">
                                                                <li>Độ dài tối thiểu 6 ký tự tối đa 32 kí tự</li>
                                                                <li>Có ít nhất một chữ viết hoa</li>
                                                                <li>Dùng để đăng nhập vào hệ thống quản lý và ngân hàng</li>
                                                            </ul>
                                                        </div>
                                                        <button type="submit" className="btn btn-success btn-sm mt-3">
                                                            Cập Nhật
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-info btn-sm mt-3 ms-2"
                                                            onClick={(type) => handleShowModal("PASSWORD")}
                                                        >
                                                            Khôi Phục
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div className='row'>
                                <div className='col-xl-12'>
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Đổi mật khẩu cấp 2</h4>
                                        </div>
                                        <div className="card-body p-3">
                                            <Formik
                                                initialValues={formSecondpass}
                                                validationSchema={changeSecondPasswordSchema}
                                                onSubmit={(values) => handleSubmit(values)}
                                            >
                                                {({ errors, touched }) => (
                                                    <Form>
                                                        <div className="mb-3 row">
                                                            <label className="col-sm-3 col-form-label col-form-label-sm text-primary">
                                                                Mật khẩu cũ:
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <Field
                                                                    type="password"
                                                                    className="form-control form-control-sm"
                                                                    name="old_password"
                                                                    placeholder="Mật khẩu cũ"
                                                                />
                                                                <ErrorMessage
                                                                    component="div"
                                                                    name="old_password"
                                                                    className="text-danger"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 row">
                                                            <label className="col-sm-3 col-form-label col-form-label-sm text-primary">
                                                                Mật khẩu mới:
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <Field
                                                                    type="password"
                                                                    className="form-control form-control-sm"
                                                                    name="new_password"
                                                                    placeholder="Mật khẩu mới"
                                                                />
                                                                <ErrorMessage
                                                                    component="div"
                                                                    name="new_password"
                                                                    className="text-danger"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 row">
                                                            <label className="col-sm-3 col-form-label col-form-label-sm text-primary">
                                                                Nhập lại mật khẩu:
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <Field
                                                                    type="password"
                                                                    className="form-control form-control-sm"
                                                                    name="repeat_password"
                                                                    placeholder="Nhập lại mật khẩu"
                                                                />
                                                                <ErrorMessage
                                                                    component="div"
                                                                    name="repeat_password"
                                                                    className="text-danger"
                                                                />
                                                                <input
                                                                    type="hidden"
                                                                    className="form-control form-control-sm"
                                                                    name="type"
                                                                    value="PASSWORD"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-12 col-xxl-6">
                                                                <div className="mb-2">
                                                                    <h4 className="card-title card-intro-title text-warning">
                                                                        Yêu cầu mật khẩu
                                                                    </h4>
                                                                </div>
                                                                <div className="card-content">
                                                                    <ul className="text-success">
                                                                        <li>Mật khẩu dài 6 kí tự</li>
                                                                        <li>Phải bắt buộc là số</li>
                                                                        <li>Dùng để xác minh chuyển khoản</li>
                                                                    </ul>
                                                                </div>
                                                                <button type="submit" className="btn btn-success btn-sm mt-3">
                                                                    Cập Nhật
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-info btn-sm mt-3 ms-2"
                                                                    onClick={(type) => handleShowModal("SECONDPASS")}
                                                                >
                                                                    Khôi Phục
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalChangePassWord
                show={modalShow}
                onHide={handleCloseModal}
                type={restoreTpye}
            />
        </MasterLayout>
    );
}

export default UserChangePasswordPage;