import React, { useEffect, useState } from 'react';
import MasterLayout from '../../layouts/MasterLayout';
import ThemeBreadcrumb from '../themes/ThemeBreadcrumb';
import PayosIframe from './PayosIframe';
import AppNotification from '../../helpers/AppNotification';
import { useNavigate } from 'react-router-dom';
import PaymentModel from '../../models/PaymentModel';
import UserModel from '../../models/UserModel';
import Swal from "sweetalert2";

function LoadingDeposit(props) {
    const navigate = useNavigate();

    // const ownerBank = JSON.parse(localStorage.getItem('ownerBankSD'));
    // const amount = JSON.parse(localStorage.getItem('checkoutUrl'));
    const checkoutUrl = JSON.parse(localStorage.getItem('checkoutUrl'));
    const depositIdcheck = JSON.parse(localStorage.getItem('depositIdcheck'));

     // Tự động kiểm tra giao dịch
     useEffect(() => {
        const interval = setInterval(() => {
            // Kiểm tra có ID mới bắt đầu call api
            if(depositIdcheck){
                PaymentModel.depositDetail(depositIdcheck).then(function (res) {
                    AppNotification.showSuccess("lấy chi tiết nạp tiền thành công!");
                    if(res.data.status == 1){
                        // Đóng modal iframe và thiết lập CheckoutUrl rỗng
                        // setCheckoutUrl('');
                        // setModalShow(false);
    
                        // Cập nhật số tiền trong tài khoản user
                        UserModel.updateBlance({
                            amount: res.data.amount
                        }).then(function (res) {
                            Swal.fire({
                                icon: "success",
                                title: "Nạp tiền thành công!",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                            clearInterval(interval); 
                            navigate('/payment/deposit');
                        }).catch(function (err) {
                            AppNotification.showError('Cập nhật số dư thất bại!!');
                        })
                    }
                    if(res.data.status == -1){
                        // Đóng modal iframe và thiết lập CheckoutUrl rỗng
                        // setCheckoutUrl('');
                        // setModalShow(false);

                        Swal.fire({
                            icon: "error",
                            title: "Nạp tiền thất bại!",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                        clearInterval(interval); 
                    }
                }).catch(function (err) {
                    console.log(err);
                    AppNotification.showError('Lấy chi tiết thất bại!!');
                })
            }
        }, 5000); 
        return () => {
          clearInterval(interval); 
        };
      }, [depositIdcheck]); 
    return (
        <>
            <MasterLayout>
                <ThemeBreadcrumb title="Nạp tiền" />
                <div className='row'>
                    <div className='col-xl-12'>
                        <div className='row'>
                            <div className='col-xl-12'>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Nạp Tiền Tự Động</h4>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className='row'>
                                            <div id='modal-owner-bank' className="">
                                                {
                                                    checkoutUrl ? <PayosIframe src={checkoutUrl} /> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MasterLayout>
        </>
    );
}

export default LoadingDeposit;