import axiosClient from "./axiosClient";
import AppCookie from '../helpers/AppCookie';

class OwnerBankModel {
    constructor(){
        this.api_url = "/api/owner_banks";
    }

    async getOwnerBank() {
        const res = await axiosClient.get(this.api_url);
        return res;
    }
    

}

export default new OwnerBankModel;