import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import NewsModel from '../../models/NewsModel';
import { Editor } from '@tinymce/tinymce-react';

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Vui lòng nhập tiêu đề'),
    content: Yup.string().required('Vui lòng nhập nội dung'),
});

function ModalCreateNews(props) {
    const editorRef = useRef(null);
    const initialValues = {
        title: '',
        content: '',
    };



    const onSubmit = (values) => {
        NewsModel.create(values).then((res) => {
            // Notification
            Swal.fire({
                icon: "success",
                title: "Thêm mới thành công!",
                showConfirmButton: false,
                timer: 2000,
            });
            setTimeout(() => {
                reloadPage();
            }, 2000);
        }).catch((err) => {
            Swal.fire({
                icon: "error",
                title: "Thêm mới thất bại!",
                showConfirmButton: false,
                timer: 2000,
            });
        })
    };

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Đăng tin mới
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched,setFieldValue }) => (
                        <Form>
                            <div>
                                <label htmlFor="title">Tiêu đề:</label>
                                <Field className="form-control" type="text" id="title" name="title" />
                                <ErrorMessage name="title" component="div" className="error-message text-danger" />
                            </div>

                            <div className='mt-3'>
                                <label htmlFor="content">Nội Dung:</label>
                                <Editor
                                    apiKey='c6pz05fi3jcutst1cxch5twc4zvtw8ivxee1j55i8zm4pzdb'
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    initialValue=""
                                    onEditorChange={ (e) => {
                                        setFieldValue("content", e);
                                    }}
                                />
                                <Field type="hidden" className="form-control" id="content" name="content" />
                                <ErrorMessage name="content" component="div" className="error-message text-danger" />
                            </div>
                            


                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={props.onHide}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-success">
                                    Xác nhận
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default ModalCreateNews;