import axiosClient from './axiosClient';
class HomeModel {
    constructor(){
        this.api_url = "/api/home";
    }
    async getDashboard() {
        const res = await axiosClient.get(this.api_url);
        return res;
    }

    async getDashboardAdmin() {
        const res = await axiosClient.get(this.api_url + '/admin');
        return res;
    }

    async getInfoHeader() {
        const res = await axiosClient.get(this.api_url + "/headers");
        return res;
    }

}

export default new HomeModel;