import React, { useEffect } from 'react';
import InformationAndroidBankComponent from './InformationAndroidBankComponent';
import FakeNotiModel from '../../models/FakeNotiModel';
import Battery from '../global/Battery';


function ImageHTMLAndroid(props) {

    const {
        network, date,networkDark,
        logo1, titleMessGlobal1, contentMessGlobal1, date1, minute1, second1, hour1, account1, transaction1, balance1, note1, amount1, timeWord1, accountGuest1,
        logo2, titleMessGlobal2, contentMessGlobal2, date2, minute2, second2, hour2, account2, transaction2, balance2, note2, amount2, timeWord2, accountGuest2,
        logo3, titleMessGlobal3, contentMessGlobal3, date3, minute3, second3, hour3, account3, transaction3, balance3, note3, amount3, timeWord3, accountGuest3,
        showFormNotiTwo, showFormNotiThree, driverNetwork, background, hideMark, divRef } = props;
    let { pin } = props;
    let { hour, minute, second } = props;
    hour = hour < 10 ? '0' + hour : hour;
    minute = minute < 10 ? '0' + minute : minute;
    let power = (20 / 100) * pin
    let color = pin < 21 ? 'red' : 'white';
    const containerScreenStyle = {
        backgroundImage: `${background}`,
    };

    let newdate = FakeNotiModel.formatDate(date);
    return (
            <>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Android</title>
                <link rel="stylesheet" href="/asset-noti-custom/css/android.css" />
                <main ref={props.divRef} style={{ width: '400px', height: '812px', position: 'relative' }}>
                    {
                        !hideMark && (
                            <img src='/bills/mark.png' style={{ position: 'absolute', objectFit: 'cover', width: '100%', height: '100%', top: 0, left: 0, zIndex:100 }} alt='mark' />
                        )
                    }
                    <div style={{ backgroundImage: `${background}`,backgroundSize: 'cover' }} className="containerBackground" id="background_image_div">
                        <div className="container-android">
                            <div className="containerHeader">
                                <p className="time">{hour}:{minute}</p>
                                <div className="blockInfoNetwork">
                                    <img className="network" src={`/image-electromagnetics/network-${networkDark}-light.png`} alt="network" />
                                    <img id="wifi" className="wifi" src={driverNetwork} alt="wifi" />
                                    <Battery pin ={pin} color = {color} isWhiteBorder = {true} />

                                    {/* <div className="battery">
                                        <div className="icon-container">
                                            <div className="outer-rect" />
                                            <div
                                                className="inner-rect"
                                                id="innerRect"
                                                style={{ width: `${power}px`, backgroundColor: `${color}` }}
                                            />
                                        </div>
                                        <div className="semi-circle" id="semiCircle" style={{ left: "23.5px" }} />
                                    </div> */}
                                </div>
                            </div>

                            <p className="date">{newdate}</p>
                            <img
                                className="boltIcon"
                                src="/asset-noti-custom/image/android/bolt.png"
                                alt="bolt-icon"
                            />
                            {
                                driverNetwork == '/asset-noti-custom/image/wifi.png' ? (
                                    <img
                                        className="androidNotification"
                                        src="/asset-noti-custom/image/android/androidnotification.png"
                                        alt="android-notification-icon"
                                    />
                                )
                                :
                                (
                                    <img
                                        className="androidNotification"
                                        src="/asset-noti-custom/image/android/AndroidNotification-4g.png"
                                        alt="android-notification-icon"
                                    />
                                )
                            }
                            <p className="notification">Thông báo cảnh báo</p>
                            <InformationAndroidBankComponent
                                network={network} pin={pin} date={date} hour={hour} minute={minute}
                                logo1={logo1} titleMessGlobal1={titleMessGlobal1} contentMessGlobal1={contentMessGlobal1} minute1={minute1} second1={second1} accountGuest1={accountGuest1} date1={date1} hour1={hour1} account1={account1} transaction1={transaction1} balance1={balance1} note1={note1} amount1={amount1} timeWord1={timeWord1}
                                logo2={logo2} titleMessGlobal2={titleMessGlobal2} contentMessGlobal2={contentMessGlobal2} minute2={minute2} second2={second2} accountGuest2={accountGuest2} date2={date2} hour2={hour2} account2={account2} transaction2={transaction2} balance2={balance2} note2={note2} amount2={amount2} timeWord2={timeWord2}
                                logo3={logo3} titleMessGlobal3={titleMessGlobal3} contentMessGlobal3={contentMessGlobal3} minute3={minute3} second3={second3} accountGuest3={accountGuest3} date3={date3} hour3={hour3} account3={account3} transaction3={transaction3} balance3={balance3} note3={note3} amount3={amount3} timeWord3={timeWord3}
                                showFormNotiTwo={showFormNotiTwo}
                                showFormNotiThree={showFormNotiThree}
                                driverNetwork={driverNetwork}
                                background={background}
                                divRef={divRef}
                                hideMark={hideMark}
                            />
                        </div>
                    </div>
                </main>
            </>
    );
}

export default ImageHTMLAndroid;