import axiosClient from './axiosClient';

class CodeMoneyModel {
  constructor() {
    this.api_url = "/api/";
  }
  // async getCode(credentials) {
  //   const res = await axiosClient.get(this.api_url + "history", {
  //     params: {
  //       search: credentials.search,
  //       page: credentials.page,
  //       perPage: credentials.perPage
  //     },
  //   });
  //   return res;
  // }
  async getCode(credentials) {
    const res = await axiosClient.get(this.api_url + "get-code-money", {
      params: {
        search: credentials.search,
        page: credentials.page,
        perPage: credentials.perPage
      },
    });
    return res;
  }

  async createCode(credentials) {
    const res = await axiosClient.post(this.api_url + "create-code-money", credentials);
    return res;
  }

  async depositCode(credentials) {
    const res = await axiosClient.post(this.api_url + "deposit-code", credentials);
    return res;
  }
  
}

export default new CodeMoneyModel;