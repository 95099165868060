import React from 'react';
import '../global/battery/battery.css'
function Battery(props) {

    const {pin, color, isWhiteBorder} = props

    const styleBatteryBlack =
    {
        border: '1px solid rgba(0, 0, 0, 0.4)'
    };

    const styleBatteryWhite =
    {
        border: '1px solid rgba(255, 255, 255)',
    };

    return (
        <div className="battery-na">
            <div className="icon-container-na">
                <div className="outer-rect-na" style={isWhiteBorder?styleBatteryWhite :styleBatteryBlack}>
                    <div className="inner-rect-na" id="innerRect" style={{ width: `${pin}%`, backgroundColor: `${color}` }} />
                </div>
            </div>
            <div className="semi-circle-na" id="semiCircle" style={isWhiteBorder?styleBatteryWhite :styleBatteryBlack}/>
        </div>
    );
}

export default Battery;