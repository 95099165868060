import React, { useEffect, useState } from 'react';
import MasterLayout from '../../layouts/MasterLayout';
import ThemeBreadcrumb from '../../components/themes/ThemeBreadcrumb';
import AppNotification from '../../helpers/AppNotification';
import Paginate from '../../components/paginate/Paginate';
import Swal from 'sweetalert2';
import CodeMoneyModel from '../../models/CodeMoneyModel';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../../redux/action';

function CreateCodeMoney(props) {
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState({});
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [keyword, setKeyword] = useState({
        denominations: '',
        quantity: '',
    });
    const [keywordSearch, setKeywordSearch] = useState({
        denominationsSearch: '',
        statusSearch: '',
    });

    const [filter, setFilter] = useState({
        search: '',
        perPage: perPage,
        page: page,
    });

    const changePage = (page) => {
        setPage(page);
        setFilter({ search: keywordSearch });
    };
    useEffect(() => {
        CodeMoneyModel.getCode({
            search: filter.search,
            page: page,
            perPage: perPage
        })
            .then(res => {
                setItems(res.data);
                setMeta(res.meta);
            }).catch(err => {
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, [page, filter]);
    const handleChange = (event) => {
        setKeyword({
            ...keyword,
            [event.target.name]: event.target.value
        });
    };
    const handleSubmit = () => {
        dispatch(setShowPreloader(true));
        CodeMoneyModel.createCode({
            quantity: keyword.quantity,
            denominations: keyword.denominations,
        })
            .then((res) => {
                if (res.success === true) {
                    dispatch(setShowPreloader(false));
                    Swal.fire({
                        icon: "success",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    setTimeout(() => {
                        reloadPage();
                    }, 2000);
                } else {
                    dispatch(setShowPreloader(false));
                    Swal.fire({
                        icon: "error",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }).catch(err => {
                dispatch(setShowPreloader(false));
                console.log(err);
            })
    };
    const handleChangeSearch = (event) => {
        setKeywordSearch({
            ...keywordSearch,
            [event.target.name]: event.target.value
        });
        setPage(1);
        // Xử lý tìm kiếm dữ liệu dựa trên keyword
        setFilter({ search: keywordSearch });
        // Reset trường input sau khi submit
    };
    const handleSetPage = (number) => {
        setPage(page + number);
        setFilter({ search: keywordSearch });
    };
    const reloadPage = () => {
        window.location.reload();
    };
    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Tạo Mã Code" />
            <div className='row'>
                <div className="col-lg-4 col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title"> Tạo Code</h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className='input-group mb-3'>
                                    <div className='row'>
                                        <div className='col-12 mb-2'>
                                            <label className='text-warning'>Nhập mệnh giá code: </label>
                                            <input
                                                type='text'
                                                name='denominations'
                                                className='form-control ms-2'
                                                placeholder='Nhập mệnh giá...'
                                                value={keyword.denominations}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className='col-12 mb-2'>
                                            <label className='text-warning'>Số lượng: </label>
                                            <input
                                                type='text'
                                                name='quantity'
                                                className='form-control ms-2'
                                                placeholder='Nhập số lượng...'
                                                value={keyword.quantity}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className='col-12 d-flex justify-content-center'>
                                            <div className='input-group-append'>
                                                <button className='btn light btn-primary ms-2' type='button' onClick={handleSubmit}>
                                                    Tạo Code
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title"> Mã Code Đã Tạo</h4>
                        </div>
                        <div className="card-body">
                            <div className='row d-flex justify-content-end'>
                                <div className='col-lg-12 d-flex'>
                                    <form >
                                        <div className='input-group mb-3'>
                                            <input
                                                type='text'
                                                name='denominationsSearch'
                                                className='form-control ms-2'
                                                placeholder='Tìm Mệnh giá...'
                                                value={keywordSearch.denominationsSearch}
                                                onChange={handleChangeSearch}
                                            />
                                            {/* <div className='col-6'>
                                                    <label className=''>Tìm với trạng thái : </label>
                                                    <select
                                                        name='statusSearch'
                                                        className='form-control'
                                                        value={keywordSearch.statusSearch}
                                                        onChange={handleChangeSearch}
                                                    >
                                                        <option className='form-control' style={{color:'black'}} value="1">Còn Hạn</option>
                                                        <option className='form-control' style={{color:'black'}}  value="2">Hết Hạn</option>
                                                    </select>
                                                </div> */}
                                            {/* <div className='col-3 d-flex flex-column justify-content-end'>
                                                    <div className='input-group-append'>
                                                        <button className='btn light btn-light ms-2' type='submit'>
                                                            Tìm kiếm
                                                        </button>
                                                    </div>
                                                </div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <table className="table header-border table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Code</th>
                                            <th>Mệnh giá</th>
                                            <th>Trạng thái</th>
                                            {/* <th>Người Nạp</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            items.map((item, key) => (
                                                <tr key={key}>
                                                    <td><a href="">{item.id}</a>
                                                    </td>
                                                    {/* <td><span className="badge badge-danger">Rút tiền giới thiệu</span>
                                                    </td> */}
                                                    <td>{item.code}</td>
                                                    <td>{item.denominations_fm} VND</td>
                                                    <td>{item.quantity > 0 && <span className="badge badge-success">Còn Hạn <span className='text-warning'>(còn {item.quantity})</span></span>}
                                                        {/* {item.status === 0 && <span className="badge badge-warning">Đã dùng</span>} */}
                                                        {item.quantity == 0 && <span className="badge badge-danger">Hết Hạn</span>}
                                                    </td>
                                                    {/* <td><span className="text-muted">{item.user_name}</span>
                                                    </td> */}
                                                    {/* <td>{item.status === 0 ? <button className='btn-sm btn-primary' onClick={() => HandleShowModel(item.id)} >Cập Nhập</button> : ''}</td> */}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <Paginate meta={meta} changePage={changePage} page={page} handleSetPage={handleSetPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MasterLayout>
    );
}

export default CreateCodeMoney;
