import { Field, Form, Formik } from 'formik';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import TransactionModel from '../../models/TransactionModel';
import Swal from 'sweetalert2';

const validationSchema = Yup.object().shape({
    status: Yup.number()
        .required('Vui lòng chọn trạng thái')
});

function ModalStatus(props) {
    const initialValues = props.element;
    const handleSubmit = (values) => {
        TransactionModel.changeStatus(values).then(function (res) {
            if (res.success === true) {
                Swal.fire({
                    icon: "success",
                    title: "Xác thực thành công!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                props.reloadPage();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Xác thực thất bại!",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        })
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Xác thực giao dịch
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched }) => (
                        <Form >
                            <div className='card-body'>
                                <div className="row pt-3">
                                    <div className="col">
                                        <strong>Tên người dùng</strong>
                                    </div>
                                    <div className="col">
                                        <input className="form-control" value={initialValues.bank_user} readOnly />
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col">
                                        <strong>Tài khoản</strong>
                                    </div>
                                    <div className="col">
                                        <input className="form-control" value={initialValues.bank_number} readOnly />
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col">
                                        <strong>Ngân hàng</strong>
                                    </div>
                                    <div className="col">
                                        <input className="form-control" value={initialValues.bank_name} readOnly />
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col">
                                        <strong>Loại tiền</strong>
                                    </div>
                                    <div className="col">
                                        <input className="form-control" value={initialValues.type_money} readOnly />
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col">
                                        <strong>Số lượng</strong>
                                    </div>
                                    <div className="col">
                                        <input className="form-control" value={initialValues.amount.toLocaleString()} readOnly />
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col">
                                        <strong>Mục đích</strong>
                                    </div>
                                    <div className="col">
                                        <input className="form-control" value={initialValues.type} readOnly />
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col">
                                        <strong>Tình trạng:</strong>
                                    </div>
                                    <div className="col">
                                        <Field
                                            as="select"
                                            id="type"
                                            name="status"
                                            className={`form-control`}
                                            autoFocus
                                        >
                                            <option value="" className="text-black" checked>Chọn tình trạng</option>
                                            <option value="1" className="text-black">Đã chuyển khoản</option>
                                            <option value="0" className="text-black">Đang chờ</option>
                                            <option value="-1" className="text-black">Từ chối</option>
                                        </Field>
                                        {errors.status && touched.status && (
                                            <div className="text-red mt-3 mr-5">{errors.status}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={props.onHide}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-success">
                                    Xác nhận
                                </button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default ModalStatus;