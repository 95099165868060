import React from 'react';
import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import admins from './AdminRoutes';
import users from './UserRoutes';
import publics from './PublicRoutes';
import AuthMoldel from '../models/AuthMoldel';
const ProtectedRoute = ({ redirectPath = '/landing' }) => {
    let isLoggedin = AuthMoldel.checkAuth()
    if (!isLoggedin) {
        window.location.href = redirectPath
        return false;
    }
    return <Outlet />;
};
const ProtectedAdminRoute = ({ redirectPath = '/' }) => {
    let isAdmin = AuthMoldel.isAdmin()
    if (!isAdmin) {
        return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
};

function AppRoutes(props) {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<ProtectedAdminRoute/>}>
                    {admins.map((page) => (
                        <Route key={page.path} {...page} />
                    ))}
                </Route>
                <Route element={<ProtectedRoute/>}>
                    {users.map((page) => (
                        <Route key={page.path} {...page} />
                    ))}
                </Route>
                {publics.map((page) => (
                    <Route key={page.path} {...page} />
                ))}
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;