import React from 'react';

function NotFoundPage(props) {
    return (
        <div>
            404
        </div>
    );
}

export default NotFoundPage;