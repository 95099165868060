import AppCookie from '../helpers/AppCookie';
import axiosClient from './axiosClient';

class AuthModel {
  constructor() {
    this.api_url = "/api/auth/";
  }
  async login(credentials) {
    const res = await axiosClient.post(this.api_url + "login", credentials);
    return res;
  }

  async register(credentials) {
    console.log(credentials);
    const res = await axiosClient.post(this.api_url + "register", credentials);
    return res;
  }

  async fogotpassword(email) {
    const res = await axiosClient.post(this.api_url + "forgot-password", email );
    return res;
  }

  async logout(credentials) {
    try {
      localStorage.removeItem('activeMenu');
      await axiosClient.post(this.api_url + "logout",credentials);
      return true;
    } catch (err) {
      return false;
    }
  }

  checkAuth(){
    let token = AppCookie.getCookie('token')
    if(token){
        return true;
    }
    return false;
  }
  isAdmin(){
    const isLoggedin = this.checkAuth();
    let user = AppCookie.getCookie('user') ?? '{}';
    if(!user) return false;

    user = JSON.parse(user)
    if(user.role == 0){
        return true;
    }
    return false;
  }
}

export default new AuthModel();