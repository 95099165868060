import React, { useEffect, useRef } from "react";
import AsideMenu from "./AsideMenu";
import { useDispatch } from "react-redux";

function Sidebar(props) {
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();

  const handleCLickOutside = (e) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(e.target) &&
      window.screen.width <= 768 &&
      !e.target.classList.contains('toggle-btn-trigger')
    ) {
      dispatch({
        type: "SHOW_SIDEBAR",
        payload: false,
      });
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleCLickOutside);
    return () => {
      document.removeEventListener("mousedown", handleCLickOutside);
    };
  });
  return (
    <div
      className="dlabnav"
      style={{
        backgroundImage: "images/sidebar-img/3.jpg",
      }}
      ref={sidebarRef}
    >
      <div className="dlabnav-scroll">
        <AsideMenu />
        {/* <div className="copyright">
                    <p className="fs-14">
                        <strong>doLab Personal Banking Admin</strong> © 2022 All Rights Reserved
                    </p>
                    <p className="fs-14">
                        Made with <span className="heart" /> by DexignZone
                    </p>
                </div> */}
      </div>
    </div>
  );
}

export default Sidebar;
