import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

function InformationAndroidBank(props) {
    const { logo, account, transaction, balance, key, note, amount, timeWord, date, accountGuest, contentMessGlobal, titleMessGlobal,networkDark } = props;
    console.log('logo1: ', logo);
    console.log('date: ', date);
    let { hour, minute, second } = props;
    hour = hour < 10 ? '0' + hour : hour;
    minute = minute < 10 ? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;
    hour = hour + ":" + minute;


    const [newAccountMB, setNewAccountMB] = useState('');
    const [newAccountVCB, setNewAccountVCB] = useState('');
    const [newAccount, setNewAccount] = useState('');


    useEffect(() => {
        setNewAccount("xxxx" + account.slice(4));
    }, [logo == 'TPB'])
    useEffect(() => {
        setNewAccountMB(account.slice(0, 2) + "xxx" + account.slice(5));
    }, [logo == 'MB'])
    useEffect(() => {
        if (account.length <= 7) {
            setNewAccountVCB(account);
        } else {
            setNewAccountVCB(account.slice(0, 7) + "...");
        }
    }, [logo == 'VCB'])


    let formattedDate = '';
    if (date.indexOf('/') !== -1) {
        const [day, month, year] = date.split('/');
        if (logo === "MB") {
            formattedDate = format(new Date(year, month - 1, day), 'dd/MM/yy');
        } else if (logo === 'MSB') {
            formattedDate = format(new Date(year, month - 1, day), 'dd/MM');
        } else {
            formattedDate = format(new Date(year, month - 1, day), 'dd/MM/yyyy');
        }
    } else {
        if (logo === "MB") {
            formattedDate = format(new Date(date), 'dd/MM/yy');
        } else if (logo === 'MSB') {
            formattedDate = format(new Date(date), 'dd/MM');
        } else {
            formattedDate = format(new Date(date), 'dd/MM/yyyy');
        }
    }


    return (
        <div key={key}>
            <hr style={{ color: "black" }}></hr>
            <div className="componentContent d-flex justify-content-between">
                <div className='d-flex'>
                    {
                        (logo != 'ANDROID_SMS' && logo != 'ANDROID_MESS' && logo != 'ZALO') ? (
                            <img
                                className="logoVietin1"
                                src={`/asset-noti-custom/image/smallLogo/${logo}.png`}
                                alt="vietin-logo"
                            />
                        ) :
                            (
                                <img
                                    className="logoVietin1"
                                    src={`/asset-noti-custom/image/${logo}.png`}
                                    alt="vietin-logo"
                                />
                            )
                    }
                    <p className="content1">
                        {logo === 'ACB' && 'ACB ONE •'}
                        {logo === 'VCB' && (`VCB Digibank  • Số dư TK ${newAccountVCB} `)}
                        {logo === 'TCB' && (`Techcombank  •`)}
                        {logo === 'BIDV' && (`SmartBangking • ${hour} ${formattedDate}...`)}
                        {logo === 'ICB' && (`VietinBank iP... • Thời gian ${formattedDate.substring(0, 5)}... •`)}
                        {logo === 'VBA' && (`Agribank Plus •`)}
                        {logo === 'TPB' && (`TPBank Mobile •`)}
                        {logo === 'VPB' && (`VPBank NEO •`)}
                        {logo === 'MSB' && (`MSB mbank •`)}
                        {logo === 'MB' && (`MB Bank •`)}
                        {logo === 'SCB' && (`Sacombank Pay •`)}
                        {logo === 'ANDROID_SMS' && (`Tin nhắn •`)}
                        {logo === 'ANDROID_MESS' && (`Messenger •`)}
                        {logo === 'ZALO' && (`Zalo •`)}
                    </p>
                    {
                        (logo != 'ZALO' && logo != 'ANDROID_MESS' && logo != 'ANDROID_SMS' && logo != 'SCB' && logo != 'MB' && logo != 'TCB' && logo != 'MSB' && logo != 'VPB' && logo != 'ACB' && logo != 'ICB' && logo != 'VBA' && logo != 'TPB') && (
                            <>
                                <img
                                    className="ellipse"
                                    src="/asset-noti-custom/image/android/ellipse.png"
                                    alt="ellipse-icon"
                                />
                                <img
                                    className="ellipse"
                                    src="/asset-noti-custom/image/android/ellipse.png"
                                    alt="ellipse-icon"
                                />
                            </>
                        )
                    }
                    <p className="content1">{timeWord}</p>
                </div>
                <img
                    className="chevron"
                    src="/asset-noti-custom/image/android/chevron.png"
                    alt="chevron-icon"
                />
            </div>
            <div className="componentContent3">
                <div className="componentContent2">
                    {/* title */}
                    <p className="balance">
                        {logo === 'VCB' && 'Thông báo VCB'}
                        {logo === 'TCB' && (<>
                            <p className='title-tcb'><span id='title-tcb'>+</span> VND {amount}</p>
                        </>)}
                        {logo === 'VBA' && `Thông báo Agribank`}
                        {logo === 'ACB' && (
                            <>
                                <p className='title-acb'>Thong bao thay doi so du tai k...</p>
                            </>
                        )}
                        {logo === 'BIDV' && `Thông báo BIDV`}
                        {logo === 'MB' && `Thông báo biến động số dư`}
                        {logo === 'MSB' && `Thông báo biến động số dư`}
                        {logo === 'SCB' && (
                            <p className='title-scb'>Sacombank Pay: Thông báo gi...</p>
                        )}
                        {logo === 'VPB' && `Thông báo VPBank`}
                        {logo === 'TPB' && `TPBank Mobile`}
                        {logo === 'ICB' && `Biến động số dư`}
                        {logo === 'ANDROID_SMS' && `${titleMessGlobal}`}
                        {logo === 'ANDROID_MESS' && `${titleMessGlobal}`}
                        {logo === 'ZALO' && `${titleMessGlobal}`}
                    </p>
                    {/* content */}
                    {
                        logo == 'ACB' && (
                            <div>
                                <p id="account" className="contentName" >
                                    {logo}: TK {account}(VND) +  {amount} luc {hour} {formattedDate}. So du {balance}. GD {note} {transaction} GD {accountGuest}-{account} {hour}:{second}
                                </p>
                            </div>
                        )
                    }
                    {/* VCB */}
                    {
                        logo == 'VCB' && (
                            <>
                                <p id="account" className="contentName" > Số dư TK VCB {account} +{amount} VND lúc {date.split("/").join("-")} {hour}:{second}. Số dư {balance} VND. Ref {transaction}. {note}</p>
                            </>
                        )
                    }
                    {/* TCB */}
                    {
                        logo == 'TCB' && (
                            <>
                                <p id="account" className="contentName" > Tài khoản: {account} <br />Số dư: {balance} VND <br />{note}</p>
                            </>
                        )
                    }
                    {/* TPB */}
                    {
                        logo == 'TPB' && (
                            <>
                                <p id="account" className="contentName" > (TPBank): {date};{hour}<br />TK: {newAccount}<br />PS: +{amount}VND<br />SD: {balance}VND...</p>
                            </>
                        )
                    }
                    {/* ICB */}
                    {
                        logo == 'ICB' && (
                            <>
                                <p id="account" className="contentName" >Thời gian: {date} {hour}<br />Tài khoản: {account}<br />Giao dịch: +{amount} VND<br />Số dư hiện tại: {balance} VND ...</p>
                            </>
                        )
                    }
                    {/* VPB */}
                    {
                        logo == 'VPB' && (
                            <>
                                <p id="account" className="contentName" >Quý khách có thông báo biến động số dư mới</p>
                            </>
                        )
                    }
                    {/* SCB */}
                    {
                        logo == 'SCB' && (
                            <>
                                <p id="account" className="contentName" >Ngày {date} {hour} TK: {account}. PS: +{amount} VND. Số dư khả dụng: {balance} VND. {note} {transaction}</p>
                            </>
                        )
                    }
                    {/* {
                    logo == 'ACB' && (
                        <>
                            <p id="account" className="contentName" > ACB: TK {account + "(VND)"} + {amount} <br/> luc {hour} {formattedDate}. So du {balance}. GD </p>
                            <p id="account" className="contentName" >{note} {transaction} </p>
                        </>
                    )
                } */}
                    {
                        logo == 'VBA' && (
                            <>
                                <p id="account" className="contentName" > Agribank: {hour} {formattedDate} Tài khoản {account}: +{amount} VND. Nội dung giao dịch: {note} {transaction} </p>
                            </>
                        )
                    }
                    {
                        logo == 'BIDV' && (
                            <>
                                <p id="account" className="contentName" >{hour} {formattedDate}  Tài khoản thanh toán <br /> {account}. Số tiền: +{amount} VND. Số dư cuối {balance} VND Nội dung giao dịch: TKThe: {accountGuest} tại... </p>
                            </>
                        )
                    }
                    {
                        logo == 'MB' && (
                            <>
                                <p id="account" className="contentName" >TK: {newAccountMB}|GD:+{amount}VND {formattedDate} {hour} |SD: {balance}VND|TU:{note} - {transaction}</p>
                            </>
                        )
                    }
                    {
                        logo == 'MSB' && (
                            <>
                                <p id="account" className="contentName" >TK: {formattedDate} {hour} TK: {account} VND (+) {amount} (GD:10,000,Thue/Phi:0) ND: {accountGuest} - {transaction} - {note} </p>
                            </>
                        )
                    }
                    {
                        logo == 'ANDROID_MESS' && (
                            <>
                                <p id="account" className="contentName" >{contentMessGlobal}</p>
                            </>
                        )
                    }
                    {
                        logo == 'ANDROID_SMS' && (
                            <>
                                <p id="account" className="contentName" >{contentMessGlobal}</p>
                            </>
                        )
                    }
                    {
                        logo == 'ZALO' && (
                            <>
                                <p id="account" className="contentName" >{contentMessGlobal}</p>
                            </>
                        )
                    }
                </div>
                <div className="containerVietin">
                    {/* logo image right */}
                    <img className="imgVietin" src={`/asset-noti-custom/image/${logo}.png`} />
                </div>
            </div>

        </div>
    );
}

export default InformationAndroidBank;