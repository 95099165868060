import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import BankItemBill from '../components/bill/BankItemBill';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../redux/action';
import BankInfoBill from '../models/BankInfoBill';
import AppNotification from '../helpers/AppNotification';
import QuestionAcordition from '../components/bill/QuestionAcordition';
import FormCreateBill from '../components/bill/FormCreateBill';
import { Tooltip } from 'react-tooltip';



function BillPage(props) {
    const [bankInfoBill, setBankInfoBill] = useState([]);
    const [packageBill, setPackageBill] = useState({});
    const [userPackageBill, setUserPackageBill] = useState({});
    const dispatch = useDispatch();
    const [showFormCreateBill, setShowFormCreateBill] = useState();
    const [bankName, setBankName] = useState();
    const [imageBillIOS, setImageBillIOS] = useState();
    const [imageBillAndroid, setImageBillAndroid] = useState();
    const [typeBank, setTypeBank] = useState();
    useEffect(() => {
        setShowFormCreateBill(0); // Set lại giá trị của showFormCreateBill thành 0 mỗi khi component được tải lại
        setBankName('');
        setImageBillIOS('');
        setImageBillAndroid('');
        setTypeBank('');
    }, []);
    const handleShowForm = (bankName, imageBillIOS, imageBillAndroid, typeBank) => {
        setBankName(bankName);
        setImageBillIOS(imageBillIOS);
        setImageBillAndroid(imageBillAndroid);
        setTypeBank(typeBank);
        setShowFormCreateBill(1);
    }
    const handleCloseForm = () => {
        setShowFormCreateBill(0);
    }
    useEffect(() => {
        localStorage.setItem('reloaded', 0);
        dispatch(setShowPreloader(true));
        BankInfoBill.all({})
            .then(res => {
                dispatch(setShowPreloader(false));
                setBankInfoBill(res.data.data);
                setPackageBill(res.data.package_bill);
                setUserPackageBill(res.data.is_package_bill);
            }).catch(err => {
                dispatch(setShowPreloader(false));
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, []);
    return (
        <MasterLayout>
            <>
                <ThemeBreadcrumb title="Tạo bill" />
                <div className='row'>
                    <div className='col-xl-12'>
                        <div className='row'>
                            <div className="col-xl-8 col-lg-8">
                                <div className="card">
                                    <div className="card-header" style={{display: 'flex', justifyContent:'space-between', alignItems: 'start'}}>
                                        <h4 className="card-title">Danh Sách Ngân Hàng Fake Bill Chuyển Khoản</h4>
                                        <div className='custom-vip-mobile'>
                                            {userPackageBill && (
                                                <>
                                                    <a data-tooltip-id={'detail-vip-bill'} className="badge badge-xl badge-warning">
                                                    {packageBill.name}
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye ms-2" viewBox="0 0 16 16">
                                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                        </svg>
                                                    </a>
                                                    <Tooltip
                                                        
                                                        id={'detail-vip-bill'}
                                                        html={`Tải bill miễn phí tối đa: ${userPackageBill.type == 'vip3' ? 'Không giới hạn' : userPackageBill.max_create_bill + ' / ' + packageBill.max_download_bill + ' bill / ngày'} <br /><br />
                                                        Đăng nhập tối đa: ${userPackageBill.max_login_device} / ${packageBill.max_device_login} Thiết bị<br /><br />
                                                        Thời hạn vip:  ${userPackageBill.type == 'vip3' ? 'Vĩnh viễn' : 'còn ' + userPackageBill.duration_vip_bill + ' ngày'} <br /><br />`}
                                                    />
                                                    {userPackageBill.type === 'vip1' && <img src="/images/vip1.png" width={40} height={40} />}
                                                    {userPackageBill.type === 'vip2' && <img src="/images/vip 2.png" width={40} height={40} />}
                                                    {userPackageBill.type === 'vip3' && <img src="/images/vip3.png" width={40} height={40} />}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            {
                                                bankInfoBill.map((item, key) => (
                                                    <div className='col-xl-4 col-xxl-4 col-lg-4 col-sm-12'>
                                                        <BankItemBill handleShowForm={() => handleShowForm(item.name, item.image_bill_fake_ios, item.image_bill_fake_android, item.type)} item={item} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Câu Hỏi Thường Gặp</h4>
                                            </div>
                                            <div className="card-body">
                                                <QuestionAcordition />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </MasterLayout>
    );
}

export default BillPage;