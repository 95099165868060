import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Acordition from './Acordition';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../../redux/action';
import BuildAppModel from '../../models/BuildAppModel';
import AppNotification from '../../helpers/AppNotification';
import Swal from 'sweetalert2';


function InfoBankDowload(props) {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowIOS, setModalShowIOS] = React.useState(false);
    const bankInfo = props.bankInfo;
    const dispatch = useDispatch();
    const [check,setCheck] = useState('');


    const [workflowId, SetWorkflowId] = useState('');
    useEffect(() => {
        if (bankInfo.type == 'VCB') {
            SetWorkflowId('build-vc');
        } else if (bankInfo.type == 'ICB') {
            SetWorkflowId('build-vt');
        } else if (bankInfo.type == 'BIDV') {
            SetWorkflowId('build-b');
        } else if (bankInfo.type == 'MB') {
            SetWorkflowId('build-m');
        } else if (bankInfo.type == 'TCB') {
            SetWorkflowId('build-tc');
        }
    }, [bankInfo])
    const [uuid, SetUuid] = useState('');
    const onChangeUuid = (e) => {
        SetUuid(e.target.value)
    }

    const handleStartBuild = () => {
        dispatch(setShowPreloader(true));
        BuildAppModel.startBuild({
            uuid: uuid,
            workflowId: workflowId,
            type:bankInfo.type
        })
            .then(res => {
                dispatch(setShowPreloader(false));
                if (res.success == true) {
                    Swal.fire({
                        icon: 'success',
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    let number  = Math.floor(Math.random() * 900) + 100;
                    setCheck(number)
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    }); 
                }
                // setTimeout(() => {
                //     reloadPage();
                // }, 2000);
                // console.log(res.data);
            }).catch(res => {
                dispatch(setShowPreloader(false));
                Swal.fire({
                    icon: 'error',
                    title: res.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
                AppNotification.showError('Lấy dữ liệu thất bại!');
            });
    }
    const [items, setItems] = useState([]);
    useEffect(() => {
        BuildAppModel.getAllBuild({
            type:bankInfo.type
        })
            .then(res => {
                setItems(res.data);
                // console.log(res.data);
            }).catch(res => {
                AppNotification.showError('Lấy dữ liệu thất bại!');
            });
    }, [check,bankInfo.type])

    const handleCheckStatusBuild = (id) => {
        dispatch(setShowPreloader(true));
        BuildAppModel.getStatusBuild({
            buildId: id,
        })
            .then(res => {
                dispatch(setShowPreloader(false));
                if (res.success == true) {
                    Swal.fire({
                        icon: 'success',
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    let number  = Math.floor(Math.random() * 900) + 100;
                    setCheck(number)
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    }); 
                }

                // setTimeout(() => {
                //     reloadPage();
                // }, 2000);
                // console.log(res.data);
            }).catch(res => {
                dispatch(setShowPreloader(false));
                Swal.fire({
                    icon: 'error',
                    title: res.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
                AppNotification.showError('Lấy dữ liệu thất bại!');
            });
    }

    const handleDelete = (id) => {
        dispatch(setShowPreloader(true));
        BuildAppModel.delete({
            id: id,
        })
            .then(res => {
                dispatch(setShowPreloader(false));
                if (res.success == true) {
                    Swal.fire({
                        icon: 'success',
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    let number  = Math.floor(Math.random() * 900) + 100;
                    setCheck(number)
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    }); 
                }

                // setTimeout(() => {
                //     reloadPage();
                // }, 2000);
                // console.log(res.data);
            }).catch(res => {
                dispatch(setShowPreloader(false));
                Swal.fire({
                    icon: 'error',
                    title: res.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
                AppNotification.showError('Lấy dữ liệu thất bại!');
            });
    }

    const reloadPage = () => {
        window.location.reload();
    };


    return (
        <>
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h4 className="card-title">Thông tin ngân hàng {bankInfo.name}</h4>
                        </div>
                        <div className='card-body'>
                            <div id="example_wrapper" className="dataTables_wrapper">
                                <div id="example" className="display dataTable mb-3" role="grid" aria-describedby="example_info">
                                    <div className="row odd" role="row">
                                        <div className="col-lg-3 sorting_1">Ngân Hàng</div>
                                        <div className="col-lg-9">{bankInfo.name}</div>
                                    </div>
                                    <div className="row even pt-3" role="row">
                                        <div className="col-lg-3 sorting_1">Tình trạng</div>
                                        <div className="col-lg-9">
                                            {bankInfo.status === 1 ? (
                                                <span className="badge badge-success">Hoạt động</span>
                                            ) : (
                                                <span className="badge badge-danger">Đang Tắt</span>
                                            )}
                                            {bankInfo.user_status === 1 ? (
                                                <span className="badge badge-success ms-2">Bật</span>
                                            ) : (
                                                <span className="badge badge-danger ms-2">Tắt</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row even mt-3" role="row">
                                        <div className="col-lg-3 sorting_1">Phiên bản Android</div>
                                        <div className="col-lg-9">
                                            <span className="badge light badge-warning">{bankInfo.android_version}</span>
                                        </div>
                                    </div>
                                    <div className="row even pt-3" role="row">
                                        <div className="col-lg-3 sorting_1">Phiên bản IOS</div>
                                        <div className="col-lg-9">
                                            <span className="badge light badge-warning">{bankInfo.ios_version}</span>
                                        </div>
                                    </div>
                                </div>
                                <Acordition type={bankInfo.type} IOS_qr={bankInfo.ios_download_qr} IOS_link={bankInfo.ios_download_link} Android_qr={bankInfo.android_download_qr} Android_link={bankInfo.android_download_link} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InfoBankDowload;