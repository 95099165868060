import React, { useEffect } from 'react';
import Battery from '../global/Battery';
function ImageHTMLBILLIOSVCB(props) {
    const {
        type, amount, receiverName, shortNameBank, receiverNumber, optionValue, transactionCode, decreption, driverNetwork, networkDark, hideMark, optionValueCode
    } = props;

    function formatDate(dateString) {
        // Tách chuỗi ngày thành mảng
        const [day, month, year] = dateString.split('/');

        // Tạo đối tượng Date từ các thành phần ngày, tháng, năm
        const date = new Date(`${year}-${month}-${day}`);

        // Lấy tên ngày trong tuần
        const daysOfWeek = ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'];
        const dayName = daysOfWeek[date.getDay()];

        // Ghép chuỗi định dạng ngày
        return `${dayName} ${day}/${month}/${year}`;
    }

    let { hour, minute, second } = props;
    hour = hour < 10 ? '0' + hour : hour;
    minute = minute < 10 ? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;

    var { date } = props;
    date = formatDate(date);

    let { pin } = props;
    let power = (20 / 100) * pin
    let color = pin < 21 ? 'red' : 'black';

    return (
        <>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>IOS</title>
            <link rel="stylesheet" href="/asset-bill-transfer/VCB/css/ios.css" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
            <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
                rel="stylesheet"
            />
            <main ref={props.divRef} style={{ width: '375px', height: '812px', position: 'relative' }}>
                {
                    !hideMark && (
                        <img src='/bills/mark.png' style={{ position: 'absolute', objectFit: 'cover', width: '100%', height: '100%', top: 0, left: 0, zIndex: 100 }} alt='mark' />
                    )
                }
                <img className="container-vcb" src="/asset-bill-transfer/VCB/image/default.png"> 
                    </img>
                    <div className="containerHeader">
                        <div>
                            <p className="time-ios">{hour}:{minute}</p>
                        </div>
                        <div className="blockInfoNetwork">
                            <img
                                className="network"
                                src={`/image-electromagnetics/network-${networkDark}-dark.png`}
                                alt="network"
                            />
                            {
                                driverNetwork === 'wifi' ?
                                    <img id="wifi" className="wifi" src="/asset-bill-transfer/VCB/image/wifi.png" alt="wifi" />
                                    :
                                    <img id="wifi" className="wifi" src="/asset-bill-transfer/VCB/image/4G.png" alt="wifi" />
                            }

                            <Battery pin ={pin} color = {color} isWhiteBorder = {false} />
                            {/* <div className="battery">
                                <div className="icon-container">
                                    <div className="outer-rect" >
                                        <div className="inner-rect" id="innerRect" style={{ width: `${pin}%`, backgroundColor: `${color}` }} />
                                    </div>
                                </div>
                                <div className="semi-circle" id="semiCircle" />
                            </div> */}
                        </div>
                    </div>
                    <div className="components">
                        <div className="div-amount">
                            <div className="amount">
                                {amount} <span>VND</span>
                            </div>
                        </div>
                        <div className="div-fixed-datein">
                            <div className="datetime">
                                <div className="date-input">{hour}:{minute} {date}</div>
                            </div>
                        </div>
                        <div className="div-user-name">
                            <div className="user-name">{receiverName}</div>
                        </div>
                        <div className="div-account">
                            <div className="account">{receiverNumber}</div>
                        </div>
                        <div className="div-content">
                            <div className="content">{decreption}</div>
                        </div>
                        <div className="div-bank">
                            <div className="bank">
                                <img src={`/banklogo/${optionValueCode}.png`} alt="" />
                                {shortNameBank}
                            </div>
                        </div>
                        <div className="div-bank-full">
                            <div className="bank-full">{optionValue}</div>
                        </div>
                        <div className="div-transaction-id">
                            <div className="transaction-id">{transactionCode}</div>
                        </div>
                    </div>
            </main>
        </>
    );
}

export default ImageHTMLBILLIOSVCB;