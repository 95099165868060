import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PayosIframe from './PayosIframe';

function ModalOwnerBank(props) {

    const ownerBank = props.ownerbank;
    const amount = props.amount;
    const checkoutUrl = props.checkout_url;
    return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Nạp tiền vào tài khoản {ownerBank.name}:
                        <p>Vui lòng không đóng cửa sổ cho đến khi tiến trình kết thúc !</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    'padding': 0,
                    'height': '85vh'
                }}>
                    <div id='modal-owner-bank' className="mb-3">
                        {
                            checkoutUrl ? <PayosIframe src={checkoutUrl}/> : null
                        }
                    </div>
                    <div className="modal-footer" style={{
                        display:'none'
                    }}>
                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={props.onHide}>
                            Đóng
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

    );
}

export default ModalOwnerBank;