    import React, { useState } from 'react';
    import Modal from 'react-bootstrap/Modal';
    import { Formik, Field, Form, ErrorMessage } from 'formik';
    import * as Yup from 'yup';
    import VerifyCode from '../../models/VerifyCode';
    import AppNotification from '../../helpers/AppNotification';
    import UserModel from '../../models/UserModel';
    import PaymentModel from '../../models/PaymentModel';
    import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../../redux/action';
import CurrencyInput from '../global/CurrencyInput';

    const validationSchema = Yup.object().shape({
        bank_name: Yup.string().required("Vui lòng chọn ngân hàng"),
        bank_number: Yup.string().required("Vui lòng nhập số tài khoản"),
        bank_user: Yup.string().required("Vui lòng nhập chủ tài khoản"),
        amount: Yup.number()
            .required('Vui lòng nhập số tiền')
            .min(100000, 'Số tiền phải lớn hơn hoặc bằng 100.000đ'),
        verify_code: Yup.string()
            .required('Vui lòng nhập mã xác nhận')
            .matches(/^\d{6}$/, 'Mã xác nhận phải đúng 6 chữ số')
    });

    function ModalWithdraw(props) {
        const dispatch = useDispatch();

        const [form, setForm] = useState({
            bank_name: "",
            bank_number: "",
            bank_user: "",
            amount: 0,
            verify_code: ""
        });

        const handleSubmit = (values) => {
            PaymentModel.withdraw(values).then(function (res) {
                AppNotification.showSuccess(res.data);
                console.log(res.data);
                if (res.success == true) {
                    Swal.fire({
                        icon: "success",
                        title: "Yêu cầu rút tiền được gửi đi thành công thành công!",
                        showConfirmButton: false,
                        timer: 2000,
                      });
                      props.onHide();
                      props.reloadPage();
                }else {
                    Swal.fire({
                        icon: "error",
                        title: res.data,
                        showConfirmButton: false,
                        timer: 2000,
                      });
                }
            }).catch(function (err) {
                console.log(err);
                AppNotification.showError('Cập Nhật Mật Khẩu Thất Bại!');
            })

        };

        const sendOTP = () => {
            dispatch(setShowPreloader(true));
            VerifyCode.getOTP({
                type: "EARNMONEY"
            }).then(function (res) {
                dispatch(setShowPreloader(false));
                Swal.fire({
                    icon: "success",
                    title: "Mã OTP đã được gửi vào email của bạn!",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                AppNotification.showSuccess('Mã OTP đã được gửi vào email của bạn!');
            }).catch(function (err) {
                Swal.fire({
                    icon: "error",
                    title: "Lấy Mã OTP Thất Bại!",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                AppNotification.showError('Lấy Mã OTP Thất Bại!');
            })
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Rút tiền về ngân hàng
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={form}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched, setFieldValue }) => (
                            <Form>
                                {/* <div style={{ marginBottom: "20px" }}>
                                    <strong style={{ fontSize: "1.2em" }}>
                                        Số tiền có thể rút: 0đ
                                    </strong>
                                </div> */}

                                <div className="mb-3">
                                    <label htmlFor="bank_name" className="form-label">
                                        Ngân hàng (*)
                                    </label>
                                    <Field
                                        as="select"
                                        id="bank_name"
                                        name="bank_name"
                                        className={`form-select ${errors.bank_name && touched.bank_name ? "is-invalid" : ""
                                            }`}
                                        style={{
                                            backgroundColor: "#000936",
                                            border: "0.0625rem solid rgba(255, 255, 255, 0.25)"
                                        }}
                                    >
                                        <option value="">Chọn ngân hàng</option>
                                        <option value="vietcombank">Vietcombank</option>
                                        <option value="acb">ACB</option>
                                        <option value="techcombank">Techcombank</option>
                                    </Field>
                                    {errors.bank && touched.bank && (
                                        <div className="invalid-feedback">{errors.bank}</div>
                                    )}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="bank_number" className="form-label">
                                        Số tài khoản (*)
                                    </label>
                                    <Field
                                        type="text"
                                        id="bank_number"
                                        name="bank_number"
                                        className={`form-control ${errors.bank_number && touched.bank_number ? "is-invalid" : ""
                                            }`}
                                        autoFocus
                                    />
                                    {errors.bank_number && touched.bank_number && (
                                        <div className="invalid-feedback">{errors.bank_number}</div>
                                    )}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="accountHolder" className="form-label">
                                        Chủ tài khoản (*)
                                    </label>
                                    <Field
                                        type="text"
                                        id="bank_user"
                                        name="bank_user"
                                        className={`form-control ${errors.bank_user && touched.bank_user ? "is-invalid" : ""
                                            }`}
                                        autoFocus
                                    />
                                    {errors.bank_user && touched.bank_user && (
                                        <div className="invalid-feedback">{errors.bank_user}</div>
                                    )}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="amount" className="form-label">
                                        Số tiền (*)
                                    </label>
                                    <CurrencyInput
                                        type="text"
                                        className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                            }`}
                                        autoFocus
                                        onChange={e => {
                                            let value = e.target.value || "";
                                            value = value.replaceAll(',','');
                                            setFieldValue("amount", value);
                                            }}
                                    />
                                    <Field
                                        type="hidden"
                                        id="amount"
                                        name="amount"
                                        className={`form-control ${errors.amount && touched.amount ? "is-invalid" : ""
                                            }`}
                                        autoFocus
                                    />
                                    {errors.amount && touched.amount && (
                                        <div className="invalid-feedback">{errors.amount}</div>
                                    )}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="verify_code" className="form-label">
                                        Xác nhận (*)
                                    </label>
                                    <div className="input-group">
                                        <Field
                                            type="text"
                                            id="verify_code"
                                            name="verify_code"
                                            className={`form-control ${errors.verify_code && touched.verify_code ? "is-invalid" : ""
                                                }`}
                                            autoFocus
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-warning"
                                            onClick={sendOTP}
                                        >
                                            Lấy mã
                                        </button>
                                        {errors.verify_code && touched.verify_code && (
                                            <div className="invalid-feedback">{errors.verify_code}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button className="btn btn-danger" type="button" onClick={props.onHide}>
                                        Close
                                    </button>
                                    <button className="btn btn-success" type="submit">
                                        Xác nhận
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    {/* <p>Lưu ý:</p>
                    <ul>
                        <li>
                            Nhấn <span className="text-warning">lấy OTP</span>, một mã xác minh được gửi vào email bạn đăng ký
                        </li>
                        <li>
                            Lấy mã rồi nhập mã vào <span className="text-warning">Nhập mã xác thực...</span>
                        </li>
                        <li>Nhấn Khôi phục để hoàn tất!</li>
                        <li className="text-success">Nếu không thấy mail OTP, vui lòng đọc trong tin nhắn SPAM!</li>
                    </ul> */}
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={props.onHide}>Đóng</Button>
                </Modal.Footer> */}
            </Modal>
        );
    }

    export default ModalWithdraw;