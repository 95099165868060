import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MasterLayout from '../../layouts/MasterLayout';
import ThemeBreadcrumb from '../../components/themes/ThemeBreadcrumb';
import AppNotification from '../../helpers/AppNotification';
import Paginate from '../../components/paginate/Paginate';
import UserModel from '../../models/UserModel';
import ModalDetailUser from '../../components/users/ModalDetailUser';
import Swal from "sweetalert2";
import NewsModel from '../../models/NewsModel';
import ModalCreateNews from '../../components/news/ModalCreateNews';
import ModalUpdateNews from '../../components/news/ModalUpdateNews';

function NewsPage(props) {
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState({});
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [keyword, setKeyword] = useState('');
    const [user, setUser] = useState({});
    const [news, setNews] = useState({});
    const [flag, setFlag] = useState(false);
    const [filter, setFilter] = useState({
        search: '',
        perPage: perPage,
        page: page,
    });
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowUpdate, setModalShowUpdate] = React.useState(false);

    const changePage = (page) => {
        setPage(page);
        setFilter({ search: keyword });
    }
    useEffect(() => {
        NewsModel.getAllPaginate({
            search: filter.search,
            page: page,
            perPage: perPage
        })
            .then(res => {
                setItems(res.data);
                setMeta(res.meta);
            }).catch(err => {
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, [page, filter]);
    const handleChange = (event) => {
        setKeyword(event.target.value);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setPage(1);
        // Xử lý tìm kiếm dữ liệu dựa trên keyword
        setFilter({ search: keyword });
        // Reset trường input sau khi submit
    };

    const handleSetPage = (number) => {
        setPage(page + number);
        setFilter({ search: keyword });
    }

    const handleShowModal = () => {
        setModalShow(true);
    }

    useEffect(() => {
        if (flag == true) {
            setModalShowUpdate(true);
            setFlag(false);
        }
    }, [news, flag]);
    
    const handleShowModalUpdate = (item) => {
        setNews(item);
        setFlag(true);
    }

    const handleCloseModalUpdate = () => {
        setNews({});
        setModalShowUpdate(false)
    }

    const handleDelete = (id) => {
        const shouldDelete = window.confirm('Bạn có chắc chắn muốn xóa không?');
        if (shouldDelete) {
            NewsModel.delete(id)
                .then(res => {
                    Swal.fire({
                        icon: "success",
                        title: "Xóa thành công!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    setTimeout(() => {
                        reloadPage();
                    }, 2000);
                }).catch(err => {
                    Swal.fire({
                        icon: "error",
                        title: "Xóa thất bại!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    console.log(err);
                    AppNotification.showError('Xóa thất bại!');
                })
        }
    }

    const reloadPage = () => {
        window.location.reload();
    }
    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Quản lí tin đăng" />
            <div className='row'>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <div className='row'>
                                <h4 className="card-title">Quản lí tin đăng</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-4'>
                                    <button type='button' onClick={(e) => handleShowModal()} className='btn btn-primary ms-2 btn-sm'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                    </svg> Đăng tin</button>
                                </div>
                                <div className='col-4'>
                                    <form onSubmit={handleSubmit}>
                                        <div className='input-group mb-3'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Nhập từ khóa...'
                                                value={keyword}
                                                onChange={handleChange}
                                            />
                                            <div className='input-group-append'>
                                                <button className='btn light btn-light' type='submit'>
                                                    Tìm kiếm
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <table className="table header-border table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Tiêu đề</th>
                                            <th>Nội dung</th>
                                            <th>Thao tác</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            items.map((item, key) => (
                                                <tr key={key}>
                                                    <td><span className='badge badge-warning'>{item.id}</span>
                                                    </td>
                                                    <td>{item.title}
                                                    </td>
                                                    <td><div className="menu-icon" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                                    </td>
                                                    <td>
                                                        <button type='button' className='btn btn-warning ms-2 btn-sm' onClick={(e) => handleShowModalUpdate(item)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                                        </svg></button>
                                                        <button className='btn btn-danger ms-2 btn-sm' onClick={(e) => handleDelete(item.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                                        </svg></button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <Paginate meta={meta} changePage={changePage} page={page} handleSetPage={handleSetPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalCreateNews
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <ModalUpdateNews
                show={modalShowUpdate}
                onHide={() => handleCloseModalUpdate()}
                news={news}
            />


        </MasterLayout>
    );
}

export default NewsPage;
