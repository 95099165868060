import React, { useState } from 'react';

function Paginate(props) {
    const meta = props.meta;
    const count = [];
    for (var i = 1; i <= meta.last_page; i++) {
        count.push(i);
    }

    let style = {}; // Giá trị mặc định của biến Style

    const changePage = (pageNumber) => {
        props.changePage(pageNumber);
    }

    const handleback = (number) => {
        props.handleSetPage(number);
    }

    return (
        <div className='row'>
            <div className='col-12 d-flex justify-content-center'>
                <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        {
                            props.page === 1 ? null : <li className="page-item"><a className="page-link" onClick={() => handleback(-1)}>Trước</a></li>
                        }
                        {count.map((pageNumber) => (
                            props.page === pageNumber ? (
                                <li className="page-item" key={pageNumber}>
                                    <a className="page-link" style={{ backgroundColor: 'aqua', color: 'blueviolet' }} onClick={() => changePage(pageNumber)}>
                                        {pageNumber}
                                    </a>
                                </li>
                            ) : (
                                <li className="page-item" key={pageNumber}>
                                    <a className="page-link" onClick={() => changePage(pageNumber)}>
                                        {pageNumber}
                                    </a>
                                </li>
                            )
                        ))}
                        {
                            props.page === meta.last_page ? null : <li className="page-item"><a className="page-link" onClick={() => handleback(1)}>Sau</a></li>
                        }
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Paginate;