import React, { useEffect, useState } from 'react';
import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import UserModel from '../models/UserModel';
import AppNotification from '../helpers/AppNotification';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import ModalChangePassWord from '../components/changepassword/ModalChangePassWord';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../redux/action';
import ModalUpdateEmail from '../components/users/ModalUpdateEmail';


const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string().required('Vui lòng nhập mật khẩu cũ'),
    new_password: Yup.string()
        .min(6, 'Độ dài tối thiểu 6 ký tự')
        .max(32, 'Độ dài tối đa 32 ký tự')
        .matches(/[A-Z]/, 'Phải có ít nhất một chữ in hoa')
        .required('Vui lòng nhập mật khẩu mới'),
    repeat_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Mật khẩu không khớp')
        .required('Vui lòng nhập lại mật khẩu'),
});

const updateSchema = Yup.object().shape({
    user_name: Yup.string()
        .max(255, "Tối đa 255 kí tự ")
        .required("Trường bắt buộc"),
    name: Yup.string()
        .max(255, "Tối đa 255 kí tự ")
        .required("Trường bắt buộc"),
});

function UserProfilePage(props) {

    // xử lý sửa email
    const [modalShowUdateEmail,setModalShowUdateEmail] = useState(false);
    const handleCloseModalUpdateEmail = () => {
        setModalShowUdateEmail(false);
    }
    const handleShowModalUpdateEmail = () => {
        setModalShowUdateEmail(true);
    }  


    // xử lí pass
    const [modalShow, setModalShow] = React.useState(false);
    const [restoreTpye, setRestoreTpye] = useState("");
    const dispatch = useDispatch();


    const [formPassword] = useState({
        old_password: "",
        new_password: "",
        repeat_password: "",
        type: "PASSWORD",
    })

    const handleShowModal = (type) => {
        setModalShow(true)
        setRestoreTpye(type);
    }
    const handleCloseModal = () => {
        setModalShow(false);
    };

    const handleSubmitPass = (values) => {
        if (values.type == "PASSWORD") {
            UserModel.changePassword(values).then(function (res) {
                if (res.success == true) {
                    Swal.fire({
                        icon: "success",
                        title: "Đổi mật khẩu thành công!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    AppNotification.showSuccess('Cập Nhật Mật Khẩu Thành Công!');
                }else{
                    Swal.fire({
                        icon: "error",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }).catch(function (err) {
                console.log(err);
                Swal.fire({
                    icon: "error",
                    title: "Đổi mật khẩu Thất bại!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                AppNotification.showError('Cập Nhật Mật Khẩu Thất Bại!');
            })
        }
    }


    // xử lý profile
    const [form, setForm] = useState({
        name: '',
        email: '',
        user_name: ''
    })

    useEffect(() => {
        dispatch(setShowPreloader(true));
        UserModel.find().then(function (res) {
            let profileData = {
                name: res.data.name,
                user_name: res.data.user_name,
                email: res.data.email
            }
            dispatch(setShowPreloader(false));
            setForm(profileData);
        }).catch(function (error) {

        })
    }, [])

    const handleSubmit = (values) => {
        UserModel.update(values).then(function (res) {
            Swal.fire({
                icon: "success",
                title: "Cập Nhật Profile thành công!",
                showConfirmButton: false,
                timer: 2000,
            });
            setTimeout(() => {
                reloadPage();
            }, 2000);
            AppNotification.showSuccess('Cập Nhật Profile thành công!');
        }).catch(function (err) {
            Swal.fire({
                icon: "error",
                title: "Cập Nhật Thất Bại!",
                showConfirmButton: false,
                timer: 2000,
            });
            console.log(err);
            AppNotification.showError('Cập Nhật Thất Bại!');
        })
    }

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Thông tin cá nhân" />
            <div className="row">
                <div className="col-lg-12">
                    <div className="profile card card-body px-3 pt-3 pb-0">
                        <div className="profile-head">
                            {/* <div className="photo-content">
                                <div className="cover-photo rounded" />
                            </div> */}
                            <div className="profile-info">
                                <div className="profile-photo mt-2 mb-2">
                                    <img
                                        src={form.image != null ? form.image : "/images/profile/profile.png"}
                                        className="img-fluid rounded-circle"
                                        alt=""
                                    />
                                </div>
                                <div className="profile-details">
                                    <div className="profile-name px-3 pt-2 mt-3 mb-3">
                                        <h4 className="text-white mb-0">{form.name}</h4>
                                        <h4 className="mb-0">{form.email}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Thông tin tài khoản</h4>
                        </div>
                        <div className="card-body">
                            <div className="form-validation">
                                <Formik
                                    initialValues={form}
                                    enableReinitialize={true}
                                    validationSchema={updateSchema}
                                    onSubmit={(values) => handleSubmit(values)}
                                >
                                    {({ errors, touched }) => (
                                        <Form className="needs-validation" noValidate>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="mb-3 row">
                                                        <label className="col-lg-4 col-form-label" htmlFor="id">
                                                            Tên
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <div className="col-lg-6">
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                id="name"
                                                                name="name"
                                                                placeholder="65cd8e9826f768edd202f12d"
                                                                readOnly={true}
                                                            />
                                                            {errors.name && touched.name ? (
                                                                <div style={{ color: 'red' }}>{errors.name}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <label className="col-lg-4 col-form-label" htmlFor="verificationAccount">
                                                            Tài khoản xác minh:
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <div className="col-lg-6">
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                id="verificationAccount"
                                                                name="verificationAccount"
                                                                placeholder="65cd8e9826f768edd202f12d"
                                                                value={form.status === 0 ? "Chưa xác minh" : "Đã xác minh"}
                                                                readOnly={true}
                                                            />
                                                            <ErrorMessage
                                                                name="verificationAccount"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <label className="col-lg-4 col-form-label" htmlFor="account">
                                                            Tài khoản
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <div className="col-lg-6">
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                id="user_name"
                                                                name="user_name"
                                                                placeholder="65cd8e9826f768edd202f12d"
                                                                readOnly={true}
                                                            />
                                                            {errors.user_name && touched.user_name ? (
                                                                <div style={{ color: 'red' }}>{errors.user_name}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-lg-12">
                                                        {/* <button type="submit" className="btn btn-primary">Cập nhập</button> */}
                                                        <button type="button" onClick={() => handleShowModalUpdateEmail()} className="btn btn-default m-3">Thay đổi Email</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xl-6'>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Đổi mật khẩu</h4>
                        </div>
                        <div className="card-body p-3">
                            <Formik
                                initialValues={formPassword}
                                validationSchema={changePasswordSchema}
                                onSubmit={(values) => handleSubmitPass(values)}
                            >
                                {({ errors, touched }) => (
                                    <Form>
                                        <div className="mb-3 row">
                                            <label style={{ fontSize: 'inherit' }} className="col-sm-3 col-form-label col-form-label-sm text-primary">
                                                Mật khẩu cũ:
                                            </label>
                                            <div className="col-sm-9">
                                                <Field
                                                    type="password"
                                                    className="form-control form-control-sm"
                                                    name="old_password"
                                                    placeholder="Mật khẩu cũ"
                                                />
                                                <ErrorMessage
                                                    component="div"
                                                    name="old_password"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label style={{ fontSize: 'inherit' }} className="col-sm-3 col-form-label col-form-label-sm text-primary">
                                                Mật khẩu mới:
                                            </label>
                                            <div className="col-sm-9">
                                                <Field
                                                    type="password"
                                                    className="form-control form-control-sm"
                                                    name="new_password"
                                                    placeholder="Mật khẩu mới"
                                                />
                                                <ErrorMessage
                                                    component="div"
                                                    name="new_password"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label style={{ fontSize: 'inherit' }} className="col-sm-3 col-form-label col-form-label-sm text-primary">
                                                Nhập lại mật khẩu:
                                            </label>
                                            <div className="col-sm-9">
                                                <Field
                                                    type="password"
                                                    className="form-control form-control-sm"
                                                    name="repeat_password"
                                                    placeholder="Nhập lại mật khẩu"
                                                />
                                                <ErrorMessage
                                                    component="div"
                                                    name="repeat_password"
                                                    className="text-danger"
                                                />
                                                <input
                                                    type="hidden"
                                                    className="form-control form-control-sm"
                                                    name="type"
                                                    value="PASSWORD"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12 col-xxl-12">
                                                <div className="mb-2">
                                                    <h4 className="card-title card-intro-title text-warning">
                                                        Yêu cầu mật khẩu
                                                    </h4>
                                                </div>
                                                <div className="card-content">
                                                    <ul className="text-success">
                                                        <li>Độ dài tối thiểu 6 ký tự tối đa 32 kí tự</li>
                                                        <li>Có ít nhất một chữ viết hoa</li>
                                                        <li>Dùng để đăng nhập vào hệ thống quản lý và ngân hàng</li>
                                                    </ul>
                                                </div>
                                                <button type="submit" className="btn btn-success btn-sm mt-3">
                                                    Cập Nhật
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-info btn-sm mt-3 ms-2"
                                                    onClick={(type) => handleShowModal("PASSWORD")}
                                                >
                                                    Khôi Phục
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <ModalChangePassWord
                show={modalShow}
                onHide={handleCloseModal}
                type={restoreTpye}
            />
            <ModalUpdateEmail
                show={modalShowUdateEmail}
                onHide={handleCloseModalUpdateEmail}
            />
        </MasterLayout>
    );
}

export default UserProfilePage;