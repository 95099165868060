import React, { useEffect, useState } from 'react';
import MasterLayout from '../../layouts/MasterLayout';
import ThemeBreadcrumb from '../../components/themes/ThemeBreadcrumb';
import Swal from "sweetalert2";
import SettingModel from '../../models/SettingModel';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import AppNotification from '../../helpers/AppNotification';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../../redux/action';

// Validate của cấu hình hỗ trợ
const validationSchema_hotro = Yup.object().shape({
    nameTele_hotro: Yup.string().required('Vui lòng nhập tên Telegram'),
    hotline: Yup.string().required('Vui lòng nhập hotline'),
    pageName: Yup.string().required('Vui lòng nhập tên fanpage'),
    pagePath: Yup.string().required('Vui lòng nhập đường dẫn fanpage'),
    groupName: Yup.string().required('Vui lòng nhập tên group'),
    groupPath: Yup.string().required('Vui lòng nhập đường dẫn group'),
});

// Validate của cấu hình nạp tiền
const validationSchema_naptien = Yup.object().shape({
    // imageVIB: Yup.mixed().required('Vui lòng hình ảnh VIB'),
    // imageTCB: Yup.mixed().required('Vui lòng hình ảnh TCB'),
});

// Validate của cấu hình nạp tiền
const validationSchema_lienhe = Yup.object().shape({
    address: Yup.string().required('Vui lòng nhập địa chỉ'),
    phone: Yup.string().required('Vui lòng nhập số điện thoại'),
    email: Yup.string().required('Vui lòng nhập email'),
    nameTele_lienhe: Yup.string().required('Vui lòng nhập tên Telegram'),
    pathTele_lienhe: Yup.string().required('Vui lòng nhập đường dẫn Telegram'),
});

function SettingPage(props) {

    const dispatch = useDispatch();

    const [imageTCBpath, setImageTCBpath] = useState(null);
    const [imageVIBpath, setImageVIBpath] = useState(null);

    const [initialValues_hotro, setInitialValues_hotro] = useState({
        nameTele_hotro: '',
        hotline: '',
        pageName: '',
        pagePath: '',
        groupName: '',
        groupPath: '',
        type: 'hotro',
    });
    const [initialValues_naptien, setInitialValues_naptien] = useState({
        imageVIB: '',
        imageTCB: '',
        bankCodeTCB: '',
        bankNameVIB: '',
        bankNameTCB: '',
        bankCodeVIB: '',
        type: 'naptien',
    });
    const [initialValues_lienhe, setInitialValues_lienhe] = useState({
        address: '',
        phone: '',
        email: '',
        nameTele_lienhe: '',
        pathTele_lienhe: '',
        type: 'lienhe',
    });


    useEffect(() => {
        dispatch(setShowPreloader(true));
        SettingModel.getAll({})
            .then(res => {
                let hotroPrev = {
                    nameTele_hotro: res.data.find(item => item.key === 'nameTele_hotro')?.value,
                    hotline: res.data.find(item => item.key === 'hotline')?.value,
                    pageName: res.data.find(item => item.key === 'pageName')?.value,
                    pagePath: res.data.find(item => item.key === 'pagePath')?.value,
                    groupName: res.data.find(item => item.key === 'groupName')?.value,
                    groupPath: res.data.find(item => item.key === 'groupPath')?.value,
                };

                let lienhePrev = {
                    address: res.data.find(item => item.key === 'address')?.value,
                    phone: res.data.find(item => item.key === 'phone')?.value,
                    email: res.data.find(item => item.key === 'email')?.value,
                    nameTele_lienhe: res.data.find(item => item.key === 'nameTele_lienhe')?.value,
                    pathTele_lienhe: res.data.find(item => item.key === 'pathTele_lienhe')?.value,
                };

                let vibPath = res.data.find(item => item.key === 'imageVIB')?.value;
                let tcbPath = res.data.find(item => item.key === 'imageTCB')?.value;

                let naptienPrev = {
                    imageVIB: '',
                    imageTCB: '',
                    bankCodeTCB: res.data.find(item => item.key === 'bankCodeTCB')?.value,
                    bankCodeVIB: res.data.find(item => item.key === 'bankCodeVIB')?.value,
                    bankNameVIB: res.data.find(item => item.key === 'bankNameVIB')?.value,
                    bankNameTCB: res.data.find(item => item.key === 'bankNameTCB')?.value,
                };

                setInitialValues_hotro(hotroPrev);
                setInitialValues_lienhe(lienhePrev);
                setInitialValues_naptien(naptienPrev);

                setImageTCBpath(vibPath);
                setImageVIBpath(tcbPath);

                dispatch(setShowPreloader(false));
            }).catch(err => {
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, []);
    const onSubmit = (values) => {
        dispatch(setShowPreloader(true));
        SettingModel.create(values).then((res) => {
            if (res.success) {
                console.log(res);
                dispatch(setShowPreloader(false));
                Swal.fire({
                    icon: "success",
                    title: "Lưu thành công!",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        }).catch((err) => {
            dispatch(setShowPreloader(false));
            Swal.fire({
                icon: "error",
                title: "Lưu thất bại!",
                showConfirmButton: false,
                timer: 2000,
            });
        })
    };

    const onSubmitImage = (values) => {

        dispatch(setShowPreloader(true));

        const formData = new FormData();
        if (values.imageTCB) {
            formData.append('imageTCB', values.imageTCB);
        }
        if (values.imageVIB) {
            formData.append('imageVIB', values.imageVIB);
        }

        formData.append('bankCodeVIB', values.bankCodeVIB);
        formData.append('bankNameVIB', values.bankNameVIB);
        formData.append('bankCodeTCB', values.bankCodeTCB);
        formData.append('bankNameTCB', values.bankNameTCB);

        SettingModel.createImage(formData).then((res) => {
            if (res.success) {
                dispatch(setShowPreloader(false));

                let vibPath = res.data.imageVIB;
                let tcbPath = res.data.imageTCB;

                if(vibPath){
                    setImageTCBpath(vibPath);
                }
                if(tcbPath){
                    setImageVIBpath(tcbPath);
                }

                Swal.fire({
                    icon: "success",
                    title: "Lưu thành công!",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        }).catch((err) => {
            dispatch(setShowPreloader(false));
            Swal.fire({
                icon: "error",
                title: "Lưu thất bại!",
                showConfirmButton: false,
                timer: 2000,
            });
        })
    };

    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Quản lí cấu hình" />
            {/* Cấu hình hỗ trợ */}
            <div className='row'>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <div className='row'>
                                <h4 className="card-title">Cấu hình hỗ trợ</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className='table-responsive'>
                                <Formik
                                    initialValues={initialValues_hotro}
                                    validationSchema={validationSchema_hotro}
                                    enableReinitialize={true}
                                    onSubmit={(values) => onSubmit(values)}
                                >
                                    {({ errors, touched, setFieldValue }) => (
                                        <Form>
                                            <table className="table header-border table-responsive-sm">
                                                <thead>
                                                    <tr>
                                                        <th className="align-top">Telegram</th>
                                                        <td>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Nhập đường dẫn telegram...'
                                                                id="nameTele_hotro"
                                                                name="nameTele_hotro"
                                                            />
                                                            <ErrorMessage name="nameTele_hotro" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="align-top">Hotline</th>
                                                        <td>
                                                            <Field
                                                                type='number'
                                                                className='form-control'
                                                                placeholder='Nhập số hotline...'
                                                                id="hotline"
                                                                name="hotline"
                                                            />
                                                            <ErrorMessage name="hotline" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="align-top">Fanpage</th>
                                                        <td>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Nhập tên hiển thị fanpage...'
                                                                id="pageName"
                                                                name="pageName"
                                                            />
                                                            <ErrorMessage name="pageName" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                            <Field
                                                                type='text'
                                                                className='form-control mt-3'
                                                                placeholder='Nhập đường dẫn liên kết fanpage...'
                                                                id="pagePath"
                                                                name="pagePath"
                                                            />
                                                            <ErrorMessage name="pagePath" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="align-top">Group</th>
                                                        <td>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Nhập tên hiển thị group...'
                                                                id="groupName"
                                                                name="groupName"
                                                            />
                                                            <ErrorMessage name="groupName" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                            <Field
                                                                type='text'
                                                                className='form-control mt-3'
                                                                placeholder='Nhập đường dẫn liên kết group...'
                                                                id="groupPath"
                                                                name="groupPath"
                                                            />
                                                            <ErrorMessage name="groupPath" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>
                                            <button type="submit" className="btn btn-success" style={{ float: 'right' }}>
                                                Xác nhận
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Nạp tiền tự động */}
            <div className='row'>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <div className='row'>
                                <h4 className="card-title">Nạp tiền tự động</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className='table-responsive'>
                                <Formik
                                    initialValues={initialValues_naptien}
                                    validationSchema={validationSchema_naptien}
                                    enableReinitialize={true}
                                    onSubmit={(values) => onSubmitImage(values)}
                                >
                                    {({ errors, touched, setFieldValue, values }) => (
                                        <Form>
                                            <table className="table header-border table-responsive-sm">
                                                <thead>
                                                    <tr>
                                                        <td style={{ borderBottom: "none" }} className="align-middle">Ảnh QR 1</td>
                                                        <td style={{ borderBottom: "none" }} className="col-lg-4">
                                                            <Field name="imageTCB">
                                                                {({ field }) => (
                                                                    <>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control"
                                                                            id="imageTCB"
                                                                            name="imageTCB"
                                                                            onChange={(event) => {
                                                                                setFieldValue("imageTCB", event.currentTarget.files[0]);
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Field>
                                                            <ErrorMessage name="imageVIB" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                        <td rowSpan="3">
                                                            {imageVIBpath && (
                                                                <td className="col-lg-12">
                                                                    <img className="img-fluid" height={250} width={250} src={imageVIBpath} alt="QR VIB" />
                                                                </td>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ borderBottom: "none" }} className="align-middle">Số tài khoản 1</td>
                                                        <td style={{ borderBottom: "none" }}>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Số tài khoản VIB'
                                                                id="bankCodeVIB"
                                                                name="bankCodeVIB"
                                                            />
                                                            <ErrorMessage name="bankCodeVIB" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-middle">Tên tài khoản 1</td>
                                                        <td>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Tên tài khoản VIB'
                                                                id="bankNameVIB"
                                                                name="bankNameVIB"
                                                            />
                                                            <ErrorMessage name="bankNameVIB" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ borderBottom: "none" }} className="align-middle">Ảnh QR 2</td>
                                                        <td style={{ borderBottom: "none" }}>
                                                            <Field name="imageVIB">
                                                                {({ field }) => (
                                                                    <>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control"
                                                                            id="imageVIB"
                                                                            name="imageVIB"
                                                                            onChange={(event) => {
                                                                                setFieldValue("imageVIB", event.currentTarget.files[0]);
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Field>
                                                            <ErrorMessage name="imageTCB" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                        <td rowSpan="3">
                                                            {imageTCBpath && (
                                                                <td>
                                                                    <img className="img-fluid" height={250} width={250} src={imageTCBpath} alt="QR VIB" />
                                                                </td>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ borderBottom: "none" }} className="align-middle">Số tài khoản 2</td>
                                                        <td style={{ borderBottom: "none" }}>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Số tài khoản TCB'
                                                                id="bankCodeTCB"
                                                                name="bankCodeTCB"
                                                            />
                                                            <ErrorMessage name="bankCodeTCB" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-middle">Tên tài khoản 2</td>
                                                        <td>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Tên tài khoản TCB'
                                                                id="bankNameTCB"
                                                                name="bankNameTCB"
                                                            />
                                                            <ErrorMessage name="bankNameTCB" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>
                                            <button type="submit" className="btn btn-success" style={{ float: 'right' }}>
                                                Xác nhận
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Cấu hình liên hệ */}
            <div className='row'>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <div className='row'>
                                <h4 className="card-title">Cấu hình liên hệ</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className='table-responsive'>
                                <Formik
                                    initialValues={initialValues_lienhe}
                                    validationSchema={validationSchema_lienhe}
                                    enableReinitialize={true}
                                    onSubmit={(values) => onSubmit(values)}
                                >
                                    {({ errors, touched, setFieldValue }) => (
                                        <Form>
                                            <table className="table header-border table-responsive-sm">
                                                <thead>
                                                    <tr>
                                                        <th className="align-top">Địa chỉ</th>
                                                        <td>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Nhập địa chỉ...'
                                                                id="address"
                                                                name="address"
                                                            />
                                                            <ErrorMessage name="address" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="align-top">Số điện thoại</th>
                                                        <td>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Nhập số số điện thoại...'
                                                                id="phone"
                                                                name="phone"
                                                            />
                                                            <ErrorMessage name="phone" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="align-top">Email</th>
                                                        <td>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Nhập email...'
                                                                id="email"
                                                                name="email"
                                                            />
                                                            <ErrorMessage name="email" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="align-top">Telegram</th>
                                                        <td>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Nhập tên hiển thị telegram...'
                                                                id="nameTele_lienhe"
                                                                name="nameTele_lienhe"
                                                            />
                                                            <ErrorMessage name="nameTele_lienhe" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                            <Field
                                                                type='text'
                                                                className='form-control mt-3'
                                                                placeholder='Nhập đường dẫn liên kết telegram...'
                                                                id="pathTele_lienhe"
                                                                name="pathTele_lienhe"
                                                            />
                                                            <ErrorMessage name="pathTele_lienhe" component="div" className="ml-2 mt-2 error-message text-danger" />
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>
                                            <button type="submit" className="btn btn-success" style={{ float: 'right' }}>
                                                Xác nhận
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MasterLayout>
    );
}

export default SettingPage;
