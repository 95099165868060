import axiosClient from "./axiosClient";
import AppCookie from '../helpers/AppCookie';


class PackageModel {
    constructor(){
        this.api_url = "/api/package";
    }

    async all(credentials) {
        const res = await axiosClient.get(this.api_url, {
            params: {
              bank_code: credentials.bank_code,
            },
          });
        return res;
    }
    
    async buyPackage(credentials) {
        const res = await axiosClient.post(this.api_url + "/buy", credentials );
        return res;
    }

    async getCurrentPackage() {
        const res = await axiosClient.get(this.api_url + "/get-current-package");
        return res;
    }

    async deletePackageApp(credentials) {
        const res = await axiosClient.post(this.api_url + "/delete-app", credentials );
        return res;
    }

}

export default new PackageModel;