import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

function InformationVCBank(props) {
    const { logo, account, transaction, balance, key, note, amount, timeWord, date, accountGuest, titleMessGlobal,contentMessGlobal} = props;
    let { hour, minute, second } = props;
    hour = hour < 10 ? '0' + hour : hour;
    minute = minute < 10 ? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;
    hour = hour + ":" + minute;
    const [newAccount, setNewAccount] = useState('');
    const [newAccountMB, setNewAccountMB] = useState('');
    useEffect(() => {
        setNewAccount("xxxx" + account.slice(4));
    }, [logo == 'TPB'])
    useEffect(() => {
        setNewAccountMB(account.slice(0, 2) + "xxx" + account.slice(5));
    }, [logo == 'MB'])
    let formattedDate = '';
    if (date.indexOf('/') !== -1) {
        const [day, month, year] = date.split('/');
        if (logo === "MB") {
            formattedDate = format(new Date(year, month - 1, day), 'dd/MM/yy');
        } else if (logo === 'MSB') {
            formattedDate = format(new Date(year, month - 1, day), 'dd/MM');
        } else {

            formattedDate = format(new Date(year, month - 1, day), 'dd/MM/yyyy');
        }
    } else {
        if (logo === "MB") {
            formattedDate = format(new Date(date), 'dd/MM/yy');
        } else if (logo === 'MSB') {
            formattedDate = format(new Date(date), 'dd/MM');
        } else {

            formattedDate = format(new Date(date), 'dd/MM/yyyy');
        }
    }

    return (
        <div className="componentInfo" key={key}>
            <div className="">
                {
                    (logo == 'MESS' || logo == 'SMS')  ? (
                        <img className="imgVietin" src={`/asset-noti-custom/image/IOS_${logo}.png`} />
                    ):
                    (
                        <img className="imgVietin" src={`/asset-noti-custom/image/${logo}.png`} />
                    )
                }
            </div>
            <div className="content">
                <div className="contentHeader">
                    <div className="contentTitle">
                        {logo === 'VCB' && 'Thông báo VCB'}
                        {logo === 'TCB' && (<>
                            <p className='title-tcb'><span id='title-tcb'>+</span> VND {amount}</p>
                        </>)}
                        {logo === 'VBA' && `Thông báo Agribank`}
                        {logo === 'ACB' && (
                            <>
                            <p className='title-acb'>Thong bao thay doi so du tai k...</p>
                            </>
                        )}
                        {logo === 'BIDV' && `Thông báo BIDV`}
                        {logo === 'MB' && `Thông báo biến động số dư`}
                        {logo === 'MSB' && `Thông báo biến động số dư`}
                        {logo === 'SCB' && (
                            <p className='title-scb'>Sacombank Pay: Thông báo gi...</p>
                        )}
                        {logo === 'VPB' && `Thông báo VPBank`}
                        {logo === 'TPB' && `TPBank Mobile`}
                        {logo === 'ICB' && `Biến động số dư`}
                        {logo === 'IOS_SMS' && `${titleMessGlobal}`}
                        {logo === 'IOS_MESS' && `${titleMessGlobal}`}
                        {logo === 'ZALO' && `${titleMessGlobal}`}
                    </div>
                    <div className="contentNow" >{timeWord}</div>
                </div>
                {/* VCB */}
                {
                    logo == 'VCB' && (
                        <>
                            <p id="account" className="contentName" > Số dư TK VCB {account} +{amount} VND lúc {date.split("/").join("-")} {hour}:{second}. Số dư {balance} VND. Ref {transaction}. {note}</p>
                        </>
                    )
                }
                {/* TCB */}
                {
                    logo == 'TCB' && (
                        <>
                            <p id="account" className="contentName" > Tài khoản: {account} <br />Số dư: {balance} VND <br />{note}</p>
                        </>
                    )
                }
                {/* TPB */}
                {
                    logo == 'TPB' && (
                        <>
                            <p id="account" className="contentName" > (TPBank): {date};{hour}<br />TK: {newAccount}<br />PS: +{amount}VND<br />SD: {balance}VND...</p>
                        </>
                    )
                }
                {/* ICB */}
                {
                    logo == 'ICB' && (
                        <>
                            <p id="account" className="contentName" >Thời gian: {date} {hour}<br />Tài khoản: {account}<br />Giao dịch: +{amount} VND<br />Số dư hiện tại: {balance} VND ...</p>
                        </>
                    )
                }
                {/* VPB */}
                {
                    logo == 'VPB' && (
                        <>
                            <p id="account" className="contentName" >Quý khách có thông báo biến động số dư mới</p>
                        </>
                    )
                }
                {/* SCB */}
                {
                    logo == 'SCB' && (
                        <>
                            <p id="account" className="contentName" >Ngày {date} {hour} TK:<br />{account}. PS: +{amount} VND.<br />Số dư khả dụng: {balance} VND.<br />{note} {transaction}</p>
                        </>
                    )
                }
                {
                    logo == 'ACB' && (
                        <>
                            <p id="account" className="contentName" > ACB: TK {account + "(VND)"} + {amount} <br/> luc {hour} {formattedDate}. So du {balance}. GD </p>
                            <p id="account" className="contentName" >{note} {transaction} </p>
                        </>
                    )
                }
                {
                    logo == 'VBA' && (
                        <>
                            <p id="account" className="contentName" > Agribank: {hour} {formattedDate} Tài khoản {account}: +{amount} VND. Nội dung giao dịch: {note} {transaction} </p>
                        </>
                    )
                }
                {
                    logo == 'BIDV' && (
                        <>
                            <p id="account" className="contentName" >{hour} {formattedDate}  Tài khoản thanh toán <br/> {account}. Số tiền: +{amount} VND. Số dư cuối {balance} VND Nội dung giao dịch: TKThe: {accountGuest} tại... </p>
                        </>
                    )
                }
                {
                    logo == 'MB' && (
                        <>
                            <p id="account" className="contentName" >TK: {newAccountMB}|GD:+{amount}VND {formattedDate} {hour} |SD: {balance}VND|TU:{note} - {transaction}</p>
                        </>
                    )
                }
                {
                    logo == 'MSB' && (
                        <>
                            <p id="account" className="contentName" >TK: {formattedDate} {hour} TK: {account} VND (+) {amount} (GD:10,000,Thue/Phi:0) ND: {accountGuest} - {transaction} - {note} </p>
                        </>
                    )
                }
                {
                    logo == 'IOS_MESS' && (
                        <>
                            <p id="account" className="contentName" >{contentMessGlobal}</p>
                        </>
                    )
                }
                {
                    logo == 'IOS_SMS' && (
                        <>
                            <p id="account" className="contentName" >{contentMessGlobal}</p>
                        </>
                    )
                }
                {
                    logo == 'ZALO' && (
                        <>
                            <p id="account" className="contentName" >{contentMessGlobal}</p>
                        </>
                    )
                }
            </div>
        </div>
    );
}

export default InformationVCBank;