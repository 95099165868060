import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const BillResult = (props) => {
    const { img } = useParams();
    const baseUrl = process.env.REACT_LINK_IMG;
  return (
    <div style={{ display:"flex", justifyContent:'center', width: "100%", height: "100vh" }}>
      <img
        width={375}
        height={812}
        src={`https://okbill.net/storage/billdownloaded/${img}.png`}
        alt="Base64"
        style={{
          imageRendering: "optimizequality",
        }}
      />
    </div>
  );
};

export default BillResult;