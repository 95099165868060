import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import BankItem from '../components/bank/BankItem';
import BankVideo from '../components/bank/BankVideo';
import BankNotication from '../components/bank/BankNotication';
import InfoBankDowload from '../components/dowload/InfoBankDowload';
import BankModel from '../models/BankModel';
import AppNotification from '../helpers/AppNotification';
import { setShowPreloader } from '../redux/action';
import { useDispatch } from 'react-redux';

function BankDownloadPage(props) {
    const [banks, setBanks] = useState([]);
    const [bank, setBank] = useState(null);
    const dispatch = useDispatch();
    const [urlVideo,setUrlVieo] = useState('');
    useEffect(() => {
        dispatch(setShowPreloader(true));
        BankModel.all({})
            .then(res => {
                dispatch(setShowPreloader(false));
                setBanks(res.data);
                setUrlVieo(res.data[0]);
            }).catch(err => {
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    },[]);
    const handleClick = (id) => {
        dispatch(setShowPreloader(true));
        BankModel.find(id)
            .then(res => {
                dispatch(setShowPreloader(false));
                setBank(res.data);
                console.log(res.data);
            }).catch(err => {
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            });
    }
    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Tải App" />
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className='col-xl-6'>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Tải App Ngân Hàng</h4>
                                </div>
                                <div className="card-body p-3">
                                    <div className='row'>
                                        {
                                            banks.map((item, key) => (
                                                <div className='col-xl-6' key={key}>
                                                    <a onClick={() =>handleClick(item.id)}><BankItem image={item.image} name={item.name} status={item.status} user_status={item.user_status} /></a>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div className='col-xl-12'>
                                {!bank ? (
                                    <div className='row'>
                                        <BankNotication />
                                    </div>
                                ) : (
                                    <InfoBankDowload bankInfo={bank}/>
                                )}
                            </div>
                            <div className='row'>
                                <div className='col-xl-12'>
                                    <BankVideo urlVideo={urlVideo.url_video_setting_bank} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MasterLayout>
    );
}

export default BankDownloadPage;