import React, { useEffect, useRef, useState } from 'react';
import ThemeBreadcrumb from '../themes/ThemeBreadcrumb';
import MasterLayout from '../../layouts/MasterLayout';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CurrencyInput from '../global/CurrencyInput';
import AppNotification from '../../helpers/AppNotification';
import { useDispatch } from 'react-redux';
import VietQrModel from '../../models/VietQrModel';
import { setDownloadBillData, setShowPreloader } from '../../redux/action';
import UserModel from '../../models/UserModel';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MaGiaoDich from '../../models/MaGiaoDich';
import FormCreateBillSchema from '../../models/FormCreateBillSchema';
import BillGenerate from '../../models/BillGenerate';
import Select from 'react-select';
import ImageHTMLBIDV from './ImageHTMLBIDV';
import { toPng } from 'html-to-image';
import download from 'downloadjs';
import ImageHTMLTCB from './ImageHTMLTCB';
import ImageHTMLMB from './ImageHTMLMB';
import ImageHTMLVBA from './ImageHTMLVBA';
import ImageHTMLVPB from './ImageHTMLVPB';
import CurrencyInputSpace from '../global/CurrencyInputSpace';
import ImageHTMLICB from './ImageHTMLICB';
import ImageHTMLACB from './ImageHTMLACB';
import ImageHTMLSCB from './ImageHTMLSCB';
import ImageHTMLTPB from './ImageHTMLTPB';
import ImageHTMLVCB from './ImageHTMLVCB';
import BankInfoBill from '../../models/BankInfoBill';
import { Tooltip } from 'react-tooltip';
import html2canvas from 'html2canvas';


function FormCreateBillBlance(props) {


    const [bankInfoBill, setBankInfoBill] = useState([]);
    const [packageBill, setPackageBill] = useState({});
    const [userPackageBill, setUserPackageBill] = useState({});



    useEffect(() => {
        localStorage.setItem('reloaded', 0);
        dispatch(setShowPreloader(true));
        BankInfoBill.all({})
            .then(res => {
                dispatch(setShowPreloader(false));
                setBankInfoBill(res.data.data);
                setPackageBill(res.data.package_bill);
                setUserPackageBill(res.data.is_package_bill);
            }).catch(err => {
                dispatch(setShowPreloader(false));
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, []);


    // const { type } = useParams();
    const [type, setType] = useState('VCB');

    const divRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dataValidate, setDataValidate] = useState({}); // Initialize with an empty object
    useEffect(() => {
        let schema = FormCreateBillSchema.generateSchema(type);
        setDataValidate(schema);
    }, [type]);
    const validationSchema = dataValidate;
    const [initialValues, setInitialValues] = useState({
        type: type,
        // amount: '',
    });


    const hours = Array.from({ length: 24 }, (_, index) => index);
    const minutes = Array.from({ length: 59 }, (_, index) => index);
    const [hideMark, setHideMark] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const [operaSystem, setOperaSystem] = useState('ios');
    const [network, setNetwork] = useState('Viettel');
    const [pin, setPin] = useState('50');
    const [date, setDate] = useState('11/06/2024');
    const [hour, setHour] = useState('1');
    const [minute, setMinute] = useState('2');
    const [driverNetwork, setDriverNetwork] = useState('wifi');
    const [background, setBackground] = useState('');
    const [balance, setBalance] = useState('50,000');
    const [account, setAccount] = useState('1234567890');
    const [networkDark, setNetworkDark] = useState('3');
    const [name, setName] = useState('NGUYEN VAN A');
    useEffect(() => {
        if (type === 'VPB') {
            setBackground('/banklogo/VPB.png');
            setBalance('50 000');
        }else if(type === 'MB'){
            setBackground('/banklogo/MB.png');
        }else if(type === 'ICB'){
            setBackground('/banklogo/ICB.png');
        }else if(type === 'ACB'){
            setBackground('/banklogo/ACB.png');
        }else if(type === 'TPB'){
            setBackground('/banklogo/TPB.png');
        }else if(type === 'VCB'){
            setBackground('/banklogo/VCB.png');
        }else{
            setBalance('50,000');
        }
    }, [type]);



    const onChangeOperaSystem = (e) => {
        setOperaSystem(e.target.value)
    }
    const onChangeNetwork = (e) => {
        setNetwork(e.target.value)
    }
    const onChangePin = (e) => {
        setPin(e.target.value)
    }
    const onChangeDate = (e) => {
        setDate(e.target.value)
    }
    const onChangeHour = (e) => {
        setHour(e.target.value)
    }
    const onChangeMinute = (e) => {
        setMinute(e.target.value)
    }
    const onChangeDriverNetwork = (e) => {
        setDriverNetwork(e.target.value)
    }
    const onChangeBalance = (e) => {
        setBalance(e.target.value)
    }
    const onChangeAccount = (e) => {
        setAccount(e.target.value)
    }
    const onChangeType = (e) => {
        setType(e.target.value)
    }
    const onChangeNetworkDark = (e) => {
        setNetworkDark(e.target.value)
    }
    const onChangeName = (e) => {
        setName(e.target.value)
    }
    const onChangeBackground = (e) => {
        if (e.target.files && e.target.files[0]) {
            setBackground(URL.createObjectURL(e.target.files[0]));
            const backgroundDiv = document.getElementById('background_image_div');
            backgroundDiv.style.backgroundImage = `url(${URL.createObjectURL(e.target.files[0])})`;
        }
    }


    const handleSubmit = (values) => {
        console.log(values);
    };
    useEffect(() => {
        if (hideMark) {
            handleDownloadImage();
        }
    }, [hideMark])
    const handleClickDowNoti = () => {
        setHideMark(true);
    }

    const handleDownloadImage = async () => {
        try {
            dispatch(setShowPreloader(true));
            const userAgent = navigator.userAgent;
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
            if (!divRef.current) {
                dispatch(setShowPreloader(false));
                return;
            }

            if (isMobile) {
                const scale = 4; // Tăng giá trị này để tăng độ phân giải
                const width = 375;
                const height = 812;

                const canvas = await html2canvas(divRef.current, {
                    width,
                    height,
                    scale,
                    useCORS: true,
                    allowTaint: true,
                    backgroundColor: null,
                    imageTimeout: 15000,
                });
                const dataUrl = canvas.toDataURL('image/png');
                const res = await UserModel.feeDowloadBill(dataUrl);
                if (res.success) {
                    dispatch(setShowPreloader(false));
                    setHideMark(false);

                    if (isMobile) {
                        window.location.href = res.imageUrl;
                    }
                } else {
                    dispatch(setShowPreloader(false));
                    setHideMark(false);
                    Swal.fire({
                        icon: 'error',
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            } else {
                
                const scale = 2; // Tăng giá trị này để tăng độ phân giải
                const width = divRef.current.offsetWidth * scale;
                const height = divRef.current.offsetHeight * scale;

                const dataUrlPng = await toPng(divRef.current, {
                    width,
                    height,
                    style: {
                        transform: `scale(${scale})`,
                        transformOrigin: 'top left',
                    },
                });
                const res = await UserModel.feeDowloadBill(dataUrlPng);
                if (res.success) {
                    dispatch(setShowPreloader(false));
                    setHideMark(false);

                    if (!isMobile) {
                        const link = document.createElement('a');
                        link.href = res.imageUrl;
                        link.download = 'bill.png';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                } else {
                    dispatch(setShowPreloader(false));
                    setHideMark(false);
                    Swal.fire({
                        icon: 'error',
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }


        } catch (err) {
            dispatch(setShowPreloader(false));
            AppNotification.showError('Lấy dữ liệu thất bại!');
        }
    };

    // const handleDownloadImage = async () => {
    //     try {
    //         dispatch(setShowPreloader(true));
    //         const userAgent = navigator.userAgent;
    //         const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    //         if (divRef.current === null) {
    //             return;
    //         }
    //         const scale = 2; // Tăng giá trị này để tăng độ phân giải
    //         const width = divRef.current.offsetWidth * scale;
    //         const height = divRef.current.offsetHeight * scale;

    //         const dataUrl = await toPng(divRef.current, {
    //             width,
    //             height,
    //             style: {
    //                 transform: `scale(${scale})`,
    //                 transformOrigin: 'top left',
    //             },
    //         });

    //         const res = await UserModel.feeDowloadBill(dataUrl);
    //         if (res.success === true) {
    //             dispatch(setShowPreloader(false));
    //             if (isMobile) {
    //                 console.log(res.imageUrl);
    //                 setHideMark(false);
    //                 var segments = res.imageUrl.split('/');
    //                 var lastSegment = segments.pop();
    //                 var desiredString = lastSegment.split('.')[0];
    //                 navigate('/result-img/'+desiredString);
    //                 // window.location.href = res.imageUrl;
    //             } else {
    //                 setHideMark(false);
    //                 var link = document.createElement('a');
    //                 link.href = res.imageUrl;
    //                 link.download = 'bill.png';
    //                 document.body.appendChild(link);
    //                 link.click();
    //                 document.body.removeChild(link);
    //             }
    //         } else {
    //             dispatch(setShowPreloader(false));
    //             setHideMark(false);
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: res.message,
    //                 showConfirmButton: false,
    //                 timer: 2000,
    //             });
    //         }
    //     } catch (err) {
    //         dispatch(setShowPreloader(false));
    //         AppNotification.showError('Lấy dữ liệu thất bại!');
    //     }
    // };
    const reloadPage = () => {
        window.location.reload();
    };


    return (
        <MasterLayout>
            <ThemeBreadcrumb title={`Tạo bill / Fake Bill Số Dư`} />
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className="col-xl-8 col-lg-8">
                            <div className="row">
                                <div className="col-xl-12 col-xxl-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Danh Sách Ngân Hàng Fake Bill Số Dư </h4>
                                            <div>
                                                {userPackageBill && (
                                                    <>
                                                        <a data-tooltip-id={'detail-vip-bill'} className="badge badge-xl badge-warning">
                                                            {packageBill.name}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye ms-2" viewBox="0 0 16 16">
                                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                            </svg>
                                                        </a>
                                                        <Tooltip

                                                            id={'detail-vip-bill'}
                                                            html={`Tải bill miễn phí tối đa: ${userPackageBill.type == 'vip3' ? 'Không giới hạn' : userPackageBill.max_create_bill + ' / ' + packageBill.max_download_bill + ' bill / ngày'} <br /><br />
                                                        Đăng nhập tối đa: ${userPackageBill.max_login_device} / ${packageBill.max_device_login} Thiết bị<br /><br />
                                                        Thời hạn vip:  ${userPackageBill.type == 'vip3' ? 'Vĩnh viễn' : 'còn ' + userPackageBill.duration_vip_bill + ' ngày'} <br /><br />`}
                                                        />
                                                        {userPackageBill.type === 'vip1' && <img src="/images/vip1.png" width={40} height={40} />}
                                                        {userPackageBill.type === 'vip2' && <img src="/images/vip 2.png" width={40} height={40} />}
                                                        {userPackageBill.type === 'vip3' && <img src="/images/vip3.png" width={40} height={40} />}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Formik
                                                initialValues={initialValues}
                                                // validationSchema={validationSchema}
                                                onSubmit={handleSubmit}
                                                enableReinitialize={true}
                                            >
                                                {({ errors, touched, setFieldValue }) => (

                                                    <Form>
                                                        <div id="smartwizard" className="form-wizard order-create sw sw-theme-default sw-justified">
                                                            <div className="tab-content" style={{ height: 'auto' }}>
                                                                <div
                                                                    id="wizard_Service"
                                                                    className="tab-pane"
                                                                    role="tabpanel"
                                                                    style={{ display: 'block' }}
                                                                >
                                                                    <div className="row">
                                                                        {/* <div className="col-lg-4 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Nhà mạng*</label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="network"
                                                                                    className="form-control form-control-new"
                                                                                    required=""
                                                                                    value={network}
                                                                                    onChange={onChangeNetwork}
                                                                                    fdprocessedid="4aq0kr"
                                                                                >
                                                                                    <option value="Viettel">Viettel</option>
                                                                                    <option value="Vinaphone">Vinaphone</option>
                                                                                    <option value="mobifone">Mobifone</option>
                                                                                </Field>
                                                                                <ErrorMessage name="network" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="col-lg-4 col-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Chọn Ngân Hàng*</label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="type"
                                                                                    className="form-control form-control-new"
                                                                                    required=""
                                                                                    value={type}
                                                                                    onChange={onChangeType}
                                                                                    fdprocessedid="4aq0kr"
                                                                                >
                                                                                    <option value="VCB">VCB</option>
                                                                                    <option value="TCB">TCB</option>
                                                                                    <option value="ACB">ACB</option>
                                                                                    <option value="BIDV">BIDV</option>
                                                                                    <option value="ICB">Viettin</option>
                                                                                    <option value="VBA">Agribank</option>
                                                                                    <option value="TPB">TPB</option>
                                                                                    <option value="VPB">VPB</option>
                                                                                    {/* <option value="MSB">MSB</option> */}
                                                                                    <option value="MB">MB</option>
                                                                                    <option value="SCB">SCB</option>
                                                                                </Field>

                                                                                <ErrorMessage name="type" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Chọn Mức sóng*</label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="type"
                                                                                    className="form-control form-control-new"
                                                                                    required=""
                                                                                    value={networkDark}
                                                                                    onChange={onChangeNetworkDark}
                                                                                    fdprocessedid="4aq0kr"
                                                                                >
                                                                                    <option value="1">1 vạch sóng</option>
                                                                                    <option value="2">2 vạch sóng</option>
                                                                                    <option value="3">3 vạch sóng</option>
                                                                                    <option value="4">4 vạch sóng</option>
                                                                                </Field>

                                                                                <ErrorMessage name="type" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Wifi*</label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="operatingSystem"
                                                                                    className="form-control form-control-new"
                                                                                    required=""
                                                                                    fdprocessedid="4aq0kr"
                                                                                    value={driverNetwork}
                                                                                    onChange={onChangeDriverNetwork}
                                                                                >
                                                                                    <option value="wifi">Wifi</option>
                                                                                    <option value="4g">4G</option>
                                                                                </Field>
                                                                                <ErrorMessage name="operatingSystem" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Pin*</label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="power"
                                                                                    className="form-control form-control-new"
                                                                                    required=""
                                                                                    value={pin}
                                                                                    onChange={onChangePin}
                                                                                    fdprocessedid="4aq0kr"
                                                                                >
                                                                                    {Array.from({ length: 100 }, (_, i) => (
                                                                                        <option key={i + 1} value={i + 1}>
                                                                                            {i + 1}%
                                                                                        </option>
                                                                                    ))}
                                                                                </Field>
                                                                                <ErrorMessage name="power" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="col-lg-4 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Ngày Tháng*</label>
                                                                                <Field
                                                                                    type="date"
                                                                                    name="day"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    value={date}
                                                                                    onChange={onChangeDate}
                                                                                    required=""
                                                                                    fdprocessedid="4aq0kr"
                                                                                />
                                                                                <ErrorMessage name="day" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="col-lg-2 col-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">giờ*</label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="hour"
                                                                                    className="form-control form-control-new"
                                                                                    required=""
                                                                                    value={hour}
                                                                                    onChange={onChangeHour}
                                                                                    fdprocessedid="4aq0kr"
                                                                                >
                                                                                    {[...Array(24)].map((_, index) => (
                                                                                        <option key={index + 1} value={index + 1}>
                                                                                            {index + 1}
                                                                                        </option>
                                                                                    ))}
                                                                                </Field>
                                                                                <ErrorMessage name="hour" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2 col-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Phút*</label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="minute"
                                                                                    className="form-control form-control-new"
                                                                                    required=""
                                                                                    value={minute}
                                                                                    onChange={onChangeMinute}
                                                                                    fdprocessedid="4aq0kr"
                                                                                >
                                                                                    {[...Array(60)].map((_, index) => (
                                                                                        <option key={index + 1} value={index + 1}>
                                                                                            {index + 1}
                                                                                        </option>
                                                                                    ))}
                                                                                </Field>
                                                                                <ErrorMessage name="minute" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            type === 'VPB' ?
                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                    <div className="mb-3">
                                                                                        <label className="text-label form-label">Số dư*</label>
                                                                                        <CurrencyInputSpace
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={balance}
                                                                                            onChange={e => {
                                                                                                let value = e.target.value || "";
                                                                                                value = value.replaceAll(',', '');
                                                                                                onChangeBalance(e)
                                                                                            }}
                                                                                        />
                                                                                        <ErrorMessage name="amount_blance3" component="div" className="error-message text-danger" />
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                    <div className="mb-3">
                                                                                        <label className="text-label form-label">Số dư*</label>
                                                                                        <CurrencyInput
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={balance}
                                                                                            onChange={e => {
                                                                                                let value = e.target.value || "";
                                                                                                value = value.replaceAll(',', '');
                                                                                                onChangeBalance(e)
                                                                                            }}
                                                                                        />
                                                                                        <ErrorMessage name="amount_blance3" component="div" className="error-message text-danger" />
                                                                                    </div>
                                                                                </div>
                                                                        }

                                                                        <div className="col-lg-4 col-6 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Hình Đại Diện*</label>
                                                                                <input
                                                                                    type="file"
                                                                                    name="operatingSystem"
                                                                                    className="form-control form-control-new"
                                                                                    required=""
                                                                                    onChange={onChangeBackground}
                                                                                />
                                                                                <ErrorMessage name="operatingSystem" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-4 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Số tài khoản*</label>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="bank_number"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    required=""
                                                                                    value={account}
                                                                                    onChange={onChangeAccount}
                                                                                    fdprocessedid="4aq0kr"
                                                                                />
                                                                                <ErrorMessage name="bank_number" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-4 mb-2">
                                                                            <div className="mb-3">
                                                                                <label className="text-label form-label">Chủ Tài Khoản*</label>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="name"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    required=""
                                                                                    value={name}
                                                                                    onChange={onChangeName}
                                                                                    fdprocessedid="4aq0kr"
                                                                                />
                                                                                <ErrorMessage name="name" component="div" className="error-message text-danger" />
                                                                            </div>
                                                                        </div>

                                                                        {/* Thêm các trường khác tương tự */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="toolbar toolbar-bottom d-flex justify-content-between"
                                                                role="toolbar"
                                                                style={{ textAlign: 'right' }}
                                                            >
                                                                {/* <Link
                                                                    className="btn btn-primary sw-btn-prev"
                                                                    to="/fake-blance"
                                                                >
                                                                    Quay lại
                                                                </Link> */}
                                                                <button type="button" onClick={handleClickDowNoti} className="btn btn-primary ms-2 sw-btn-prev">
                                                                    Tạo và Tải bill
                                                                    <svg className='ms-2 bi bi-cloud-download' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                                        <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                                                                        <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
                                                                    </svg>
                                                                </button>
                                                                {/* <button type="submit" className="btn btn-primary ms-2 sw-btn-prev">
                                                                    Tạo hóa đơn
                                                                </button> */}
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}

                                            </Formik>
                                            <div className='row pt-3'>
                                                <div className='col'>
                                                    <strong className='text-warning'>*Lưu ý :</strong>
                                                    <p className='text-primary'>- Phí tạo bill là : 19.000 VNĐ</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-bill-wrapper custom-center-bill-ver2">
                            {
                                type === 'BIDV' &&
                                <ImageHTMLBIDV
                                    network={network} pin={pin} date={date} hour={hour} minute={minute}
                                    driverNetwork={driverNetwork}
                                    divRef={divRef}
                                    hideMark={hideMark}
                                    balance={balance}
                                    account={account}
                                    networkDark={networkDark}
                                />
                            }
                            {
                                type === 'TCB' &&
                                <ImageHTMLTCB
                                    network={network} pin={pin} date={date} hour={hour} minute={minute}
                                    driverNetwork={driverNetwork}
                                    divRef={divRef}
                                    hideMark={hideMark}
                                    balance={balance}
                                    account={account}
                                    networkDark={networkDark}
                                />
                            }
                            {
                                type === 'MB' &&
                                <ImageHTMLMB
                                    network={network} pin={pin} date={date} hour={hour} minute={minute}
                                    driverNetwork={driverNetwork}
                                    divRef={divRef}
                                    hideMark={hideMark}
                                    balance={balance}
                                    account={account}
                                    networkDark={networkDark}
                                    background={background}
                                />
                            }
                            {
                                type === 'VBA' &&
                                <ImageHTMLVBA
                                    network={network} pin={pin} date={date} hour={hour} minute={minute}
                                    driverNetwork={driverNetwork}
                                    divRef={divRef}
                                    hideMark={hideMark}
                                    balance={balance}
                                    account={account}
                                    networkDark={networkDark}
                                />
                            }
                            {
                                type === 'VPB' &&
                                <ImageHTMLVPB
                                    network={network} pin={pin} date={date} hour={hour} minute={minute}
                                    driverNetwork={driverNetwork}
                                    divRef={divRef}
                                    hideMark={hideMark}
                                    balance={balance}
                                    account={account}
                                    background={background}
                                    networkDark={networkDark}
                                />
                            }
                            {
                                type === 'ICB' &&
                                <ImageHTMLICB
                                    network={network} pin={pin} date={date} hour={hour} minute={minute}
                                    driverNetwork={driverNetwork}
                                    divRef={divRef}
                                    hideMark={hideMark}
                                    balance={balance}
                                    account={account}
                                    background={background}
                                    networkDark={networkDark}
                                    name={name}
                                />
                            }
                            {
                                type === 'ACB' &&
                                <ImageHTMLACB
                                    network={network} pin={pin} date={date} hour={hour} minute={minute}
                                    driverNetwork={driverNetwork}
                                    divRef={divRef}
                                    hideMark={hideMark}
                                    balance={balance}
                                    account={account}
                                    background={background}
                                    networkDark={networkDark}
                                    name={name}
                                />
                            }
                            {
                                type === 'SCB' &&
                                <ImageHTMLSCB
                                    network={network} pin={pin} date={date} hour={hour} minute={minute}
                                    driverNetwork={driverNetwork}
                                    divRef={divRef}
                                    hideMark={hideMark}
                                    balance={balance}
                                    account={account}
                                    background={background}
                                    networkDark={networkDark}
                                    name={name}
                                />
                            }
                            {
                                type === 'TPB' &&
                                <ImageHTMLTPB
                                    network={network} pin={pin} date={date} hour={hour} minute={minute}
                                    driverNetwork={driverNetwork}
                                    divRef={divRef}
                                    hideMark={hideMark}
                                    balance={balance}
                                    account={account}
                                    background={background}
                                    networkDark={networkDark}
                                    name={name}
                                />
                            }
                            {
                                type === 'VCB' &&
                                <ImageHTMLVCB
                                    network={network} pin={pin} date={date} hour={hour} minute={minute}
                                    driverNetwork={driverNetwork}
                                    divRef={divRef}
                                    hideMark={hideMark}
                                    balance={balance}
                                    account={account}
                                    background={background}
                                    networkDark={networkDark}
                                    name={name}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </MasterLayout>
    );
}

export default FormCreateBillBlance;