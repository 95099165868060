import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UserBankAcountModel from '../../models/UserBankAcountModel';
import AppNotification from '../../helpers/AppNotification';

function ModalAcountApp(props) {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        UserBankAcountModel.getAllUserBankAcountForAdmin({
            user_id: props.userID,
        })
        .then((res) => {
            setUsers(res.data);
            console.log(res.data);
        })
        .catch(err => {
            console.log(err);
            AppNotification.showError('Lấy dữ liệu thất bại!');
        });
    }, [props.userID]);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Danh sách tài khoản App
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-body">
                    <div className="table-responsive">
                        <div id="example2_wrapper" className="dataTables_wrapper">
                            <div className="dataTables_scroll">
                                <div
                                    className="dataTables_scrollHead"
                                    style={{
                                        overflow: "hidden",
                                        position: "relative",
                                        border: 0,
                                        width: "100%"
                                    }}
                                >

                                </div>
                                <div
                                    className="dataTables_scrollBody"
                                    style={{
                                        position: "relative",
                                        overflow: "auto",
                                        maxHeight: "42vh",
                                        width: "100%"
                                    }}
                                >
                                    <table
                                        id="example2"
                                        className="display dataTable"
                                        style={{ width: "100%" }}
                                        role="grid"
                                        aria-describedby="example2_info"
                                    >
                                        <thead>
                                            <tr>
                                                <th className='text-warning'>ID</th>
                                                <th className='text-warning'>Tên</th>
                                                <th className='text-warning'>Số tài khoản</th>
                                                <th className='text-warning'>Ngân hàng</th>
                                                <th className='text-warning'>Số dư</th>
                                                <th className='text-warning'>Mã OTP</th>
                                                <th className='text-warning'>Mật khẩu</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                users.map((item, key) => (
                                                    <tr key={key} className="odd" role="row">
                                                        <td className="sorting_1">{item.id}</td>
                                                        <td>{item.bank_username}</td>
                                                        <td>{item.bank_number}</td>
                                                        <td>{item.type}</td>
                                                        <td>{item.account_balance_fm} VNĐ</td>
                                                        <td>{item.password_level_two}</td>
                                                        <td>{item.password_decryption}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div
                                    className="dataTables_scrollFoot"
                                    style={{ overflow: "hidden", border: 0, width: "100%" }}
                                >
                                    <div
                                        className="dataTables_scrollFootInner"
                                        style={{ width: "1467.62px", paddingRight: 17 }}
                                    >
                                        <table
                                            className="display dataTable"
                                            style={{ width: "1467.62px", marginLeft: 0 }}
                                            role="grid"
                                        >
                                            <tfoot>
                                                <tr>
                                                    <th rowSpan={1} colSpan={1} style={{ width: 0 }}>
                                                        Name
                                                    </th>
                                                    <th rowSpan={1} colSpan={1} style={{ width: 0 }}>
                                                        Position
                                                    </th>
                                                    <th rowSpan={1} colSpan={1} style={{ width: 0 }}>
                                                        Office
                                                    </th>
                                                    <th rowSpan={1} colSpan={1} style={{ width: 0 }}>
                                                        Age
                                                    </th>
                                                    <th rowSpan={1} colSpan={1} style={{ width: 0 }}>
                                                        Start date
                                                    </th>
                                                    <th rowSpan={1} colSpan={1} style={{ width: 0 }}>
                                                        Salary
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={props.onHide}>
                        Close
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalAcountApp;