import React, { useEffect, useState } from 'react';
import UserBankModel from '../../models/UserBankModel';
import Swal from "sweetalert2";
import AppNotification from '../../helpers/AppNotification';
import ModalCreateAcount from './ModalCreateAcount';
import UserBankAcountModel from '../../models/UserBankAcountModel';
import ModalDepositApp from './ModalDepositApp';
import Dropdown from 'react-bootstrap/Dropdown';
import ModalUpdateAcount from './ModalUpdateAcount';
import { Tooltip } from 'react-tooltip';

function BankInfo(props) {

    // xử lý update acount
    const [showUpdateAccount, setShowUpdateAccount] = useState(false);
    const [dataAcountUpdate, setDataAcountUpdate] = useState({});
    const [flagUpdateAcount, setFlagUpdateAcount] = useState(false);
    const handleCloseModalUpdateAcount = () => {
        setDataAcountUpdate({});
        setShowUpdateAccount(false);
    }
    useEffect(() => {
        if (flagUpdateAcount == true) {
            setShowUpdateAccount(true);
            setFlagUpdateAcount(false);
        }
    }, [dataAcountUpdate, flagUpdateAcount]);
    const handleShowModalUpdateAcount = (item) => {
        setDataAcountUpdate(item);
        setFlagUpdateAcount(true);
    }
    // show modal
    const [modalShow, setModalShow] = React.useState(false);
    const [modalDepositApp, setModalDepositApp] = React.useState(false);
    const [idBankAccount, setIdBankAccount] = useState();
    const [flag, setFlag] = useState(false);
    // const [listUserBankAcount, setListUserBankAcount] = useState(props.listUserBankAcount);
    const toggleList = () => {
        console.log('toggleList');
        var list = document.getElementById("myList");
        list.style.display = (list.style.display === "none") ? "block" : "none";
    }
    var bankInfo = props.bankInfo;
    const [userBank, setUserbank] = useState({
        user_status: '',
        bank_id: '',
    });
    const [checkClick, setCheckClick] = useState(false);
    const changUserBankStatus = (number) => {
        setUserbank({
            user_status: number,
            bank_id: bankInfo.id,
        });
        setCheckClick(true);
    }
    useEffect(() => {
        if (flag === true) {
            setModalDepositApp(true);
            setFlag(false);
        }
    }, [idBankAccount, flag]);
    const handleDepositApp = (id) => {
        setIdBankAccount(id);
        setFlag(true);
    }
    const handleCloseDepositApp = (id) => {
        setModalDepositApp(false);
        setIdBankAccount('');
    }
    useEffect(() => {
        if (checkClick) {
            UserBankModel.updateStatus(userBank)
                .then((res) => {
                    // Notification
                    AppNotification.showSuccess('Cập nhật thành công!');
                    Swal.fire({
                        icon: "success",
                        title: "Cập nhật thành công!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    setTimeout(() => {
                        props.reloadPage();
                    }, 2000);
                })
                .catch((err) => {
                    console.log(err);
                    AppNotification.showError('Cập nhật thất bại!');
                    Swal.fire({
                        icon: "error",
                        title: "Cập nhật thất bại!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                });
        }
    }, [userBank, checkClick]);

    const handleDelete = (id) => {
        const shouldDelete = window.confirm('Bạn có chắc chắn muốn xóa không?');
        if (shouldDelete) {
            UserBankAcountModel.delete(id)
                .then(res => {
                    Swal.fire({
                        icon: "success",
                        title: "Xóa thành công!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    props.reloadPage(bankInfo.id, bankInfo.type);
                }).catch(err => {
                    Swal.fire({
                        icon: "error",
                        title: "Xóa thất bại!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    console.log(err);
                    AppNotification.showError('Xóa thất bại!');
                })
        }
    }

    const reloadPage = () => {
        window.location.reload();
    };

    function getTooltipHtml(item) {
        return (
            <>
                Số dư: {item.account_balance} VNĐ<br />
                Mã OTP: {item.password_level_two}<br />
                Mật khẩu: {item.password_decryption}<br />
            </>
        );
    }
    return (
        <>
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h4 className="card-title">Thông tin ngân hàng {bankInfo.name}</h4>
                        </div>
                        <div className='card-body'>
                            <div className="row pt-3">
                                <div className="col-lg-4">
                                    <strong>Ngân Hàng:</strong>
                                </div>
                                <div className="col-lg-8">
                                    {bankInfo.name}
                                </div>
                            </div>

                            <div className="row pt-3">
                                <div className="col-lg-4">
                                    <strong>Tình trạng:</strong>
                                </div>
                                <div className="col-lg-8">
                                    {bankInfo.status === 1 ? (
                                        <span className="badge badge-success">Hoạt động</span>
                                    ) : (
                                        <span className="badge badge-danger">Đang Tắt</span>
                                    )}
                                </div>
                            </div>

                            <div className="row pt-3">
                                <div className="col-lg-4">
                                    <strong>Giá mở ngân hàng:</strong>
                                </div>
                                <div className="col-lg-8">
                                    {bankInfo.opening_fee} đ (Vĩnh Viễn)
                                </div>
                            </div>

                            <div className="row pt-3">
                                <div className="col-lg-4">
                                    <strong>Giá cập nhât tài khoản (sửa,xóa):</strong>
                                </div>
                                <div className="col-lg-8">
                                    100.000 đ
                                </div>
                            </div>

                            <div className="row pt-3">
                                <div className="col-lg-4">
                                    <strong className='text-info'>Giá nạp tiền vào TK APP:</strong>
                                </div>
                                <div className="col-lg-8 text-info">
                                    1% tổng bill/lượt
                                </div>
                            </div>

                            <div className="row pt-3">
                                <div className="col-lg-4">
                                    <strong className='text-info'>Giá chuyển tiền trong TK APP:</strong>
                                </div>
                                <div className="col-lg-8 text-info">
                                    55.000 đ
                                </div>
                            </div>

                            <div className="row pt-3">
                                <div className="col-lg-4">
                                    <strong>Giá kiểm tra số tài khoản tự động:</strong>
                                </div>
                                <div className="col-lg-8">
                                    2.000 đ / lượt
                                </div>
                            </div>
                            {/* {
                                bankInfo.is_UserPackage != null &&
                                (
                                    <>
                                        <div className="row pt-3">
                                            <div className="col-lg-4">
                                                <strong className='text-success'>Quý khách đang sử dụng gói :</strong>
                                            </div>
                                            <div className="col-lg-8 text-success">
                                                {bankInfo.package.name}
                                            </div>
                                        </div>

                                        <div className="row pt-3">
                                            <div className="col-lg-4">
                                                <strong className='text-success'>Mở tối đa tài khoản :</strong>
                                            </div>
                                            <div className="col-lg-8 text-success">
                                                {bankInfo.is_UserPackage.total_create_account} / {bankInfo.package.max_create_account} tài khoản
                                            </div>
                                        </div>

                                        <div className="row pt-3">
                                            <div className="col-lg-4">
                                                <strong className='text-success'>Sửa số tài khoản miễn phí:</strong>
                                            </div>
                                            <div className="col-lg-8 text-success">
                                                {bankInfo.is_UserPackage.total_edit_account} / {bankInfo.package.max_edit_account} lần
                                            </div>
                                        </div>

                                        <div className="row pt-3">
                                            <div className="col-lg-4">
                                                <strong className='text-success'>kiểm tra số tài khoản</strong>
                                            </div>
                                            <div className="col-lg-8 text-success">
                                                không giới hạn
                                            </div>
                                        </div>

                                        <div className="row pt-3">
                                            <div className="col-lg-4">
                                                <strong className='text-success'>Chuyển khoản miễn phí ở App:</strong>
                                            </div>
                                            <div className="col-lg-8 text-success">
                                                {bankInfo.is_UserPackage.total_transfer_app} / {bankInfo.package.max_transfer_free} lần
                                            </div>
                                        </div>

                                        <div className="row pt-3">
                                            <div className="col-lg-4">
                                                <strong className='text-success'>Nạp tối đa vào app App:</strong>
                                            </div>
                                            <div className="col-lg-8 text-success">
                                                {bankInfo.is_UserPackage.total_deposit_app_fm} / {bankInfo.package.max_deposit_app_fm} VNĐ
                                            </div>
                                        </div>
                                    </>
                                )

                            } */}

                            <div className="row pt-3">
                                <div className="col-lg-4">
                                    <strong className="text-warning">Lưu ý quan trọng:</strong>
                                </div>
                                <div className="col-lg-8">
                                    <span className="text-warning">Dùng số số điện thoại đăng ký ngân hàng để đăng nhập vào App.</span> <br />
                                    <span className="text-warning">Khi nạp tiền vào App, Tổng nhận ở App = số tiền nạp x 100</span> <br />
                                    <span className="text-warning">Ví dụ: Nạp 10.000đ, Tổng nhập ở App = 10.000đ x 100 = 1.000.000đ</span>
                                </div>
                            </div>
                            <div className='p-3'>
                                <div
                                    className="toolbar toolbar-bottom"
                                    role="toolbar"
                                    style={{ textAlign: "right" }}
                                >
                                </div>
                            </div>
                            {
                                bankInfo.is_UserPackage != null &&
                                (
                                    <div className="row pt-3 d-flex justify-content-center"

                                    >
                                        <div className="col-lg-12 d-flex justify-content-center">
                                            <strong className='text-success'>Quý khách đang sử dụng gói :</strong>
                                        </div>
                                        <div className="col-lg-12 text-success d-flex justify-content-center">
                                            {bankInfo.package.type === 'week' ? 'Tuần' : bankInfo.package.name}
                                        </div>
                                        <div className="col-lg-12 text-success d-flex justify-content-center">
                                            {bankInfo.package.type == 'vip1' && <img src="/images/vip1.png" width={100} height={100} />}
                                            {bankInfo.package.type == 'vip2' && <img src="/images/vip 2.png" width={100} height={100} />}
                                            {bankInfo.package.type == 'vip3' && <img src="/images/vip3.png" width={100} height={100} />}
                                            {bankInfo.package.type == 'week' && <img src="/images/week.png" width={100} height={100} />}
                                        </div>
                                        <div className="col-lg-12 text-success d-flex justify-content-center">
                                            {
                                                bankInfo.package.type == 'vip3' ?
                                                (<>
                                                    <a data-tooltip-id={'detail-vip'} className="badge badge-xl badge-warning">
                                                    chi tiết 
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye ms-2" viewBox="0 0 16 16">
                                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                        </svg>
                                                    </a>
                                                    <Tooltip
                                                        
                                                        id={'detail-vip'}
                                                        html={`Mở tối đa tài khoản:  ${bankInfo.is_UserPackage.total_create_account} / ${bankInfo.package.max_create_account} tài khoản<br /><br />
                                                        Sửa số tài khoản miễn phí:  ${bankInfo.is_UserPackage.total_edit_account} / ${bankInfo.package.max_edit_account} lần<br /><br />
                                                        Kiểm tra số tài khoản:  không giới hạn<br /><br />
                                                        Chuyển khoản miễn phí ở App:  không giới hạn<br /><br />
                                                        Nạp tối đa vào app App:  không giới hạn<br /><br />
                                                        Thời hạn còn lại:  ${bankInfo.is_UserPackage.duration_vip} ngày <br /><br />`}
                                                    />
                                                </>)
                                                :
                                                (<>
                                                    <a data-tooltip-id={'detail-vip'} className="badge badge-xl badge-warning">
                                                    chi tiết 
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye ms-2" viewBox="0 0 16 16">
                                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                        </svg>
                                                    </a>
                                                    <Tooltip
                                                        
                                                        id={'detail-vip'}
                                                        html={`Mở tối đa tài khoản: ${bankInfo.is_UserPackage.total_create_account} / ${bankInfo.package.max_create_account} tài khoản<br /><br />
                                                        Sửa số tài khoản miễn phí: ${bankInfo.is_UserPackage.total_edit_account} / ${bankInfo.package.max_edit_account} lần<br /><br />
                                                        Kiểm tra số tài khoản: không giới hạn<br /><br />
                                                        Chuyển khoản miễn phí ở App: ${bankInfo.is_UserPackage.total_transfer_app} / ${bankInfo.package.max_transfer_free} lần / ngày <br /><br />
                                                        Nạp tối đa vào app App:  không giới hạn<br /><br />
                                                        Thời hạn còn lại:  ${bankInfo.is_UserPackage.duration_vip} ngày <br /><br />`}
                                                    />
                                                </>)
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h4 className="card-title">Danh sách tài khoản</h4>
                        </div>
                        <div className='card-body'>
                            <div id="example_wrapper" className="dataTables_wrapper">
                                <div className='row'>
                                    <div className='col-6'>

                                    </div>
                                    <div className='col-6 pb-3'>
                                        <div
                                            className="toolbar toolbar-bottom"
                                            role="toolbar"
                                            style={{ textAlign: "right" }}
                                        >
                                            {
                                                (bankInfo.is_UserPackage && (bankInfo.is_UserPackage.total_create_account < bankInfo.package.max_create_account) && props.countAccount < bankInfo.package.max_create_account &&
                                                    <button className="btn btn-primary sw-btn-next btn-sm" type="button" onClick={() => setModalShow(true)}>
                                                        Thêm Tài Khoản
                                                    </button>
                                                )
                                                ||
                                                (
                                                    props.listUserBankAcount[0] == null &&
                                                    <button className="btn btn-primary sw-btn-next btn-sm" type="button" onClick={() => setModalShow(true)}>
                                                        Thêm Tài Khoản
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='table-responsive set-height'>
                                    <table className="table header-border table-responsive-sm">
                                        <thead>
                                            <tr>
                                                <th className='text-warning'>Số tài khoản</th>
                                                <th className='text-warning'>Chủ tài khoản</th>
                                                <th className='text-warning'>Số điện thoại</th>
                                                {/* <th className='text-warning'>Tình Trạng</th> */}
                                                <th className='text-warning'>Chi tiết</th>
                                                <th className='text-warning'>Nạp tiền</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                props.listUserBankAcount[0] == null
                                                    ?
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td><div className='d-flex justify-content-center'><p>(Tài khoản trống)</p></div></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    :
                                                    props.listUserBankAcount.map((item, key) => (
                                                        <tr key={key}>
                                                            <td className='text-primary'>{item.bank_number}</td>
                                                            <td className='text-primary'>{item.bank_username}</td>
                                                            <td className='text-primary'>{item.phone}</td>
                                                            {/* <td>
                                                                {item.status === 1 ? (
                                                                    <span className="badge badge-success">Hoạt động</span>
                                                                ) : (
                                                                    <span className="badge badge-danger">Đang Tắt</span>
                                                                )}
                                                            </td> */}
                                                            <td>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <a data-tooltip-id={'my-tooltip-html-prop-' + key} className="badge badge-xl badge-warning">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                                            </svg>
                                                                        </a>
                                                                        <Tooltip
                                                                            key={key}
                                                                            id={'my-tooltip-html-prop-' + key}
                                                                            html={`Số dư: ${item.account_balance_fm} VNĐ<br />Mã OTP: ${item.password_level_two}<br />Mật khẩu: ${item.password_decryption}<br />`}
                                                                        />
                                                                    </div>
                                                                    {/* <div className='col-12'>
                                                                    <div className='row'> */}
                                                                    {/* <div className='col-6'>
                                                                            <span href="" className="badge badge-xl badge-warning">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                                                </svg>
                                                                            </span>
                                                                        </div> */}
                                                                    {/* <div className='col-4'>
                                                                            <Dropdown className=''>
                                                                                <Dropdown.Toggle className='btn btn-sm ms-2' variant="success" id="dropdown-basic">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                                                    </svg>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item href="#">Số dư : {item.account_balance_fm} VNĐ </Dropdown.Item>
                                                                                    <Dropdown.Item href="#">Mã OTP : {item.password_level_two}</Dropdown.Item>
                                                                                    <Dropdown.Item href="#">Mật khẩu : {item.password_decryption}</Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div> */}

                                                                    {/* </div>
                                                                </div> */}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle className='btn btn-sm' variant="primary" id="dropdown-basic">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                                                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                                                                </svg>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item className='text-primary' onClick={() => handleDepositApp(item.id)}>Nạp tiền vào App</Dropdown.Item>
                                                                                <Dropdown.Item className='text-warning' onClick={() => handleShowModalUpdateAcount(item)}>Sửa tài khoản</Dropdown.Item>
                                                                                <Dropdown.Item className='text-danger' onClick={() => handleDelete(item.id)}>Xóa tài khoản</Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalCreateAcount
                show={modalShow}
                onHide={() => setModalShow(false)}
                bankName={bankInfo.name}
                BankStatus={bankInfo.status}
                UserBankStatus={bankInfo.user_status}
                bankInfo={bankInfo}
                bin={bankInfo.bin}
                id={bankInfo.id}
                type={bankInfo.type}
                reloadPage={props.reloadPage}
            />
            <ModalDepositApp
                show={modalDepositApp}
                onHide={() => setModalDepositApp(false)}
                bankName={bankInfo.name}
                BankStatus={bankInfo.status}
                UserBankStatus={bankInfo.user_status}
                bin={bankInfo.bin}
                type={bankInfo.type}
                user_bank_account_id={idBankAccount}
                reloadPage={props.reloadPage}
                PackAge={bankInfo.package}
                is_UserPackage={bankInfo.is_UserPackage}
                id={bankInfo.id}
            />
            <ModalUpdateAcount
                show={showUpdateAccount}
                onHide={() => handleCloseModalUpdateAcount()}
                bin={bankInfo.bin}
                type={bankInfo.type}
                bankName={bankInfo.name}
                data={dataAcountUpdate}
                new={1}
                PackAge={bankInfo.package}
                is_UserPackage={bankInfo.is_UserPackage}
                reloadPage={props.reloadPage}
                id={bankInfo.id}
            />

        </>
    );
}

export default BankInfo;

