import React, { useState } from 'react';

function InstructDeposut(props) {
    const [isShow, setIsShow] = useState(0);
    const handleShow = () => {
        setIsShow(1);
    }
    const handleClose = () => {
        setIsShow(0);
    }
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <p><span className="text-warning">* Chọn ngân hàng để nạp tiền :</span></p><br />
                    <p><span className='text-primary'>- Sau khi chọn ngân hàng :</span> sẽ có một hộp thoại mở ra -> nhập số tiền muốn nạp và nhấn nút "Xác nhận".</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <p><span className="text-warning">* Sau khi nhấn nút xác nhận : </span></p><br />
                    <p><span className='text-primary'>- Sẽ có hộp thoại thanh toán mở ra.</span></p>
                    <p><span className='text-primary'>- Hộp thoại :</span> gồm có mã QR để thanh toán, chúng ta có thể quét mã QR để thanh toán.</p>
                    <p><span className='text-primary'></span>- Hoặc có thể nhập số tài khoản và ngân hàng hiển thị bên dưới mã QR...</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <p><span className='text-primary'>- Sau khi thanh toán thành công :</span> hệ thống sẽ tự động trở về trang nạp tiền. </p>
                    <p><span className='text-primary'>- Nếu số dư chưa cập nhật :</span> vui lòng tải lại trang hoặc liên hệ quản trị viên. </p>
                </div>
            </div>
            {/* {
                isShow == 1 ?
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <p><span className='text-primary'>- Sau khi thanh toán thành công :</span> hệ thống sẽ tự động trở về trang nạp tiền </p>
                                <p><span className='text-primary'>- Nếu số dư chưa cập nhật :</span> vui lòng load lại trang hoặc liên hệ admin </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <button onClick={handleClose} className='btn btn-primary btn-sm'>Đóng</button>
                            </div>
                        </div>
                    </div>
                    :
                    ''
            }
            {
               isShow == 0 ? 
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <button onClick={handleShow} className='btn btn-primary btn-sm'>Xem thêm</button>
                        </div>
                    </div>
                : ''
            } */}
        </>

    );
}

export default InstructDeposut;