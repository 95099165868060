import axiosClient from "./axiosClient";
import AppCookie from '../helpers/AppCookie';


class PackageBillModel {
    constructor(){
        this.api_url = "/api/package-bill";
    }

    async all() {
        const res = await axiosClient.get(this.api_url);
        return res;
    }
    
    async buyPackageBill(credentials) {
        const res = await axiosClient.post(this.api_url + "/buy", credentials );
        return res;
    }

    async deletePackageBill() {
        const res = await axiosClient.post(this.api_url + "/delete");
        return res;
    }

}

export default new PackageBillModel;