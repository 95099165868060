// Import action
// import { DEPOSIT, WITHDRAW } from "./action";
// Khai bao giá trị khởi tạo của state
const initialState = {
    showPreloader: false,
    showSidebar: window.innerWidth >= 768,
    system_setting: {},
};

// Khai báo reducer
const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_PRELOADER':
            return { ...state,showPreloader:action.payload  }
            break;
        case 'SHOW_SIDEBAR':
            return { ...state,showSidebar:action.payload  }
            break;
        case 'SET_DOWNLOAD_BILL_DATA':
            return { ...state, downloadBillData: action.payload };
            break;
        case 'SET_SYSTEM_SETTING':
            return { ...state, system_setting: action.payload };
            break;
        default:
            return state; 
            break;
    }
}

export default rootReducer;