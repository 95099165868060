import React, { useEffect, useState } from 'react';
import HomeModel from '../../models/HomeModel';
import AppNotification from '../../helpers/AppNotification';
import { Link } from 'react-router-dom';
import AuthMoldel from '../../models/AuthMoldel';

function Header(props) {
    const [data, setData] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const isAdmin = AuthMoldel.isAdmin()
    const handleClick = () => {
        setIsShow(!isShow)
    }

    useEffect(() => {
        HomeModel.getInfoHeader().then((res) => {
            setData(res.data);
        }).catch((err) => {
        })
    }, [])

    const handleClickRemoveLocalStorage = () => {
        localStorage.removeItem("imageQR");
    }
    return (
        <div className="header mobile-header">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
                        </div>
                        <div className="dropdown header-profile2 ">
                            <a className="nav-link user-profile me-sm-4 mobile-header mobile-element"  >
                                <div className="header-info2 d-flex align-items-center">
                                    <label className="nav-link nav-link-monile" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet" viewBox="0 0 16 16">
                                            <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1" />
                                        </svg>
                                    </label>
                                    <div className="d-flex align-items-center sidebar-info">
                                        <div className="user-info">
                                            <span className="font-w500 d-block  fs-5 text-white">
                                                Số dư
                                            </span>
                                            <small className="text-end font-w400">{data.account_balance ? data.account_balance.toLocaleString('en-US') : data.account_balance} VNĐ</small>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a className="nav-link user-profile me-sm-4 mobile-header mobile-element" >
                                <div className="header-info2 d-flex align-items-center">
                                    <label className="nav-link" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet" viewBox="0 0 16 16">
                                            <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1" />
                                        </svg>
                                    </label>
                                    <div className="d-flex align-items-center sidebar-info">
                                        <div className="user-info">
                                            <span className="font-w500 d-block  fs-5 text-white">
                                                Tài khoản giới thiệu
                                            </span>
                                            <small className="text-end font-w400">{data.referral_account_balance ? data.referral_account_balance.toLocaleString('en-US') : data.referral_account_balance} VNĐ</small>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a style={{ 'cursor': 'pointer' }} className="nav-link user-profile mobile-header" onClick={handleClick}>
                                <div className="header-info2 d-flex align-items-center">
                                    <img src="/images/placeholder.jpg" alt="" />
                                    <div className="d-flex align-items-center sidebar-info">
                                        <div className="user-info">
                                            <span className="font-w500 d-block  fs-5 text-white">
                                                {data.name}{data.type_package != null && <small className="text-end font-w400 ms-2 text-success">({data.type_package})</small>}
                                            </span>
                                            {
                                                data.role == 0 ?
                                                    <small className="text-end font-w400">Admin</small>
                                                    :
                                                    <small className="text-end font-w400">Người dùng</small>
                                            }
                                        </div>
                                        <svg
                                            width={14}
                                            height={8}
                                            viewBox="0 0 14 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12.8334 1.08331L7.00002 6.91665L1.16669 1.08331"
                                                stroke="#FFFF"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </a>
                            <div className={`dropdown-menu profile dropdown-menu-end ${isShow ? 'show' : ''}`}
                                data-bs-popper="none"
                            >
                                {
                                    isAdmin && (
                                        <Link to={'/admin'} className='dropdown-item ai-icon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bank text-success" viewBox="0 0 16 16">
                                                <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z" />
                                            </svg>
                                            <span className="ms-2">Trang quản trị </span>
                                        </Link>
                                    )
                                }
                                <Link to={'/user/profile'} className='dropdown-item ai-icon'>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="text-primary"
                                        width={18}
                                        height={18}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                        <circle cx={12} cy={7} r={4} />
                                    </svg>
                                    <span className="ms-2">Trang cá nhân </span>
                                </Link>
                                <Link to={'/transaction/history'} className='dropdown-item ai-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock-history" viewBox="0 0 16 16">
                                        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
                                        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                                        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                    <span className="ms-2">Lịch sử GD </span>
                                </Link>
                                <Link to={'/logout'} onClick={() => handleClickRemoveLocalStorage()} className='dropdown-item ai-icon'>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="text-danger"
                                        width={18}
                                        height={18}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                                        <polyline points="16 17 21 12 16 7" />
                                        <line x1={21} y1={12} x2={9} y2={12} />
                                    </svg>
                                    <span className="ms-2">Đăng xuất </span>
                                </Link>
                            </div>
                        </div>

                    </div>
                </nav>
            </div>
        </div>

    );
}

export default Header;