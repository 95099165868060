import React from 'react';

function CardNews(props) {
    return (
        <>
            <div className="timeline-badge info"></div>
            <a className="timeline-panel " href="#">
                {/* <span>20 minutes ago</span> */}
                <h6 className="mb-0 text-warning">
                    {props.title}
                </h6>
                <p className="mb-0">
                    <div className="menu-icon" dangerouslySetInnerHTML={{ __html: props.content }}></div>
                </p>
            </a>
        </>
    );
}

export default CardNews;