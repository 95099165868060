import React from 'react';
import ThemePreloader from '../components/themes/ThemePreloader';
import { showPreloader } from '../redux/action';
import { useSelector } from 'react-redux';

function AuthLayout({children}) {
    const showPreloader = useSelector(state => state.showPreloader);
    return (
        <>
            {showPreloader ? <ThemePreloader/> : null }
            <div className="vh-100">
                <div className="authincation h-100">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AuthLayout;