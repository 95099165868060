import React, { useEffect, useState } from 'react';
import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../redux/action';
import AppNotification from '../helpers/AppNotification';
import BillPackageItem from '../components/package/BillPackageItem';
import PackageBillModel from '../models/PackageBillModel';

function PackageBill(props) {
    const [packAges, setPackAges] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setShowPreloader(true));
        PackageBillModel.all()
            .then(res => {
                dispatch(setShowPreloader(false));
                setPackAges(res.data);
            }).catch(err => {
                dispatch(setShowPreloader(false));
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, []);
    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Mua gói bill" />
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        {
                            packAges.map((item, key) => (
                                <BillPackageItem package={item} />
                             ))
                        }
                    </div>
                </div>
            </div>
        </MasterLayout>
    );
}

export default PackageBill;