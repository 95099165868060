import React from 'react';

function BankItem(props) {
    return (
        <div className="card overflow-hidden">
            <div className="card-body">
                <div className="text-center">
                    <div className="profile-photo">
                        <img
                            src={props.image}
                            style={{
                                width: '200px',
                                height: '100px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                            }}
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <p className="mt-2 mb-1 text-white">{props.name}</p>
                </div>
            </div>
            <div className="card-footer pt-0 pb-0 text-center">
                <div className="row">
                    <div className="col-5 pt-2 pb-2 border-start">
                        <button className='btn btn-primary' onClick={() => props.handleClick(props.id,1)}>
                            Tải App
                            {/* <label className="badge badge-xl light badge-success">Tải App</label> */}
                        </button>
                    </div>
                    <div className="col-7 pt-2 pb-2 border-end">
                        <button className='btn btn-primary' onClick={() => props.handleClick(props.id,2)}>
                            Mở Ngân Hàng
                            {/* {props.user_status === 1 ? (<label className="badge badge-xl light badge-success">Đang bật</label>) : (<label className="badge badge-xl light badge-danger">Đang tắt</label>)} */}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BankItem;