import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import NewsModel from '../../models/NewsModel';
import { Editor } from '@tinymce/tinymce-react';

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Vui lòng nhập tiêu đề'),
    content: Yup.string().required('Vui lòng nhập nội dung'),
});

function ModalUpdateNews(props) {
    const editorRef = useRef(null);

    const [initialValues, setInitialValues] = useState({
        title: '',
        content: '',
    });

    useEffect(() => {
        if (props.news) {
            setInitialValues({
                title: props.news.title,
                content: props.news.content,
            });
        }
    }, [props.news]);

    const onSubmit = (values) => {
        NewsModel.update({
            id : props.news.id,
            title : values.title,
            content : values.content,
        }).then((res) => {
            // Notification
            Swal.fire({
                icon: "success",
                title: "Cập nhật thành công!",
                showConfirmButton: false,
                timer: 2000,
            });
            setTimeout(() => {
                reloadPage();
            }, 2000);
        }).catch((err) => {
            Swal.fire({
                icon: "error",
                title: "Cập nhật thất bại!",
                showConfirmButton: false,
                timer: 2000,
            });
        })
    };

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cập nhật tin đăng
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched,setFieldValue }) => (
                        <Form>
                            <div>
                                <label htmlFor="title">Tiêu đề:</label>
                                <Field className="form-control" type="text" id="title" name="title" />
                                {errors.title && touched.title ? (
                                    <div style={{ color: 'red' }}>{errors.title}</div>
                                ) : null}
                            </div>

                            <div className='mt-3'>
                                <label htmlFor="content">Nội Dung:</label>
                                <Editor
                                    apiKey='c6pz05fi3jcutst1cxch5twc4zvtw8ivxee1j55i8zm4pzdb'
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    initialValue={props.news.content}
                                    onEditorChange={ (e) => {
                                        setFieldValue("content", e);
                                    }}
                                />
                                <Field type="hidden" className="form-control" id="content" name="content" />
                                {errors.content && touched.content ? (
                                    <div style={{ color: 'red' }}>{errors.content}</div>
                                ) : null}
                            </div>


                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={props.onHide}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-success">
                                    Xác nhận
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default ModalUpdateNews;