import React from 'react';

function OwnerBankItem(props) {
    const bank = props.bank;
    return (
        <div className="card overflow-hidden">
            <div className="card-body">
                <div className="text-center">
                    <div className="profile-photo">
                    <img
                            src={bank.image}
                            style={{
                                width: '200px',
                                height: '100px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                            }}
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <p className="mt-2 mb-1 text-white">{bank.name}</p>
                </div>
            </div>
        </div>
    );
}

export default OwnerBankItem;