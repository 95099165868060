import React from 'react';

function HomeAdminCardMoney(props) {
    return (
        <div className="card  wallet blue">
            <div className="boxs">
                <span className="box one" />
                <span className="box two" />
                <span className="box three" />
                <span className="box four" />
            </div>
            <div className="card-header border-0">
                <div className="wallet-info">
                    <span className="font-w400 d-block text-primary">{props.title}</span>
                    <div className='row'>
                        <div className='col-12'>
                            <h4 className="fs-24 font-w600 mb-0 d-inline-flex me-2 text-warning">{props.value}</h4>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="wallet-icon">
                    <svg
                        width={62}
                        height={39}
                        viewBox="0 0 62 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="42.7722"
                            cy="19.2278"
                            r="19.2278"
                            fill="white"
                            fillOpacity="0.2"
                        />
                        <circle
                            cx="19.2278"
                            cy="19.2278"
                            r="19.2278"
                            fill="white"
                            fillOpacity="0.2"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default HomeAdminCardMoney;