import React, { useState } from 'react';
import {  useSelector } from 'react-redux';

const ButtonSuppot = () => {

  const [chatBoxVisible, setChatBoxVisible] = useState(false);
  const sytemSettings = useSelector((state) => state.system_setting);

  const openTelegramChat = () => {
    const url = sytemSettings?.nameTele_hotro;
    const width = 500;
    const height = 800;
    const leftPosition = (window.innerWidth - width) / 2;
    const topPosition = (window.innerHeight - height) / 2;
    const options = `width=${width},height=${height},top=${topPosition},left=${leftPosition}`;
    window.open(url, "_blank", options);
  };
  const openTelegramGroup = () => {
    const url = sytemSettings?.groupPath;
    const width = 500;
    const height = 800;
    const leftPosition = (window.innerWidth - width) / 2;
    const topPosition = (window.innerHeight - height) / 2;
    const options = `width=${width},height=${height},top=${topPosition},left=${leftPosition}`;
    window.open(url, "_blank", options);
  };

  const handleIconClick = (e) => {
    e.preventDefault();
    setChatBoxVisible(true);
  };

  const handleCloseClick = () => {
    setChatBoxVisible(false);
  };

  return (
    <div>
      <div className="telegram-icon" onClick={handleIconClick}>
        <a href="#" className="telegram-link">
          <img src="https://autopaypm.com/static/home/img/chat/telegram_icon.png" alt="Telegram" />
        </a>
      </div>
      {chatBoxVisible && (
        <div className="chat-box">
          <div className="chat-header">
            <img src="https://autopaypm.com/static/home/img/chat/supporter.png" alt="Logo" />
            <div className="support-info">
              <h3 style={{ fontSize: '16px', fontWeight: 'bold' }}>ATPM</h3>
              <p>Bộ phận hỗ trợ 247!</p>
            </div>
            <a href="#" className="close-btn" onClick={handleCloseClick}>×</a>
          </div>
          <div className="chat-content">
            <p>Xin chào! Bạn cần hỗ trợ vấn đề gì ạ?</p>
            <a href="#" className="telegram-chat-link" onClick={(e) => { e.preventDefault(); openTelegramChat(); }}>
              <img src="https://image.similarpng.com/very-thumbnail/2021/10/Telegram-icon-on-transparent-background-PNG.png" alt="Telegram" />
              Chat qua Telegram (24/24)
            </a>
            <a href="#" className="telegram-chat-link" onClick={(e) => { e.preventDefault(); openTelegramGroup(); }}>
              <img src="https://image.similarpng.com/very-thumbnail/2021/10/Telegram-icon-on-transparent-background-PNG.png" alt="Telegram" />
              Nhóm thảo luận { sytemSettings?.groupName }
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ButtonSuppot;
