import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import NewsModel from '../../models/NewsModel';
import UserBankAcountModel from '../../models/UserBankAcountModel';

const validationSchema = Yup.object().shape({
    accountNumber: Yup.string().required('Không được để trống!'),
    phone: Yup.string().required('Không được để trống!'),
    password_level_two: Yup.string().required('Không được để trống!'),
    password: Yup.string()
        .min(6, 'Độ dài tối thiểu 6 ký tự')
        .max(32, 'Độ dài tối đa 32 ký tự')
        .required('Vui lòng nhập mật khẩu!'),
});

function ModalUpdateAcount(props) {
    const [initialValues, setInitialValues] = useState({
        user_bank_id: '',
        accountNumber: '',
        phone: '',
        password_level_two: '',
        bin: '',
        type: '',
        bank_name: '',
        password: '',
    });

    useEffect(() => {
        if (props.data) {
            setInitialValues({
                user_bank_id: props.data.id,
                accountNumber: props.data.bank_number,
                phone: props.data.phone,
                password_level_two: props.data.password_level_two,
                bin: props.bin,
                type: props.type,
                bank_name: props.bankName,
                password: props.data.password_decryption,

            });
        }
    }, [props.data]);

    const onSubmit = (values) => {
        UserBankAcountModel.updateUserBankAcount(values).then((res) => {
            // Notification
            if (res.success == true) {
                Swal.fire({
                    icon: "success",
                    title: "Cập nhật thành công!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                setTimeout(() => {
                    props.reloadPage(props.id,props.type);
                    props.onHide();
                }, 2000);
            } else {
                Swal.fire({
                    icon: "error",
                    title: res.data,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        }).catch((err) => {
            Swal.fire({
                icon: "error",
                title: "Cập nhật thất bại!",
                showConfirmButton: false,
                timer: 2000,
            });
        })
    };

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cập nhật Tài khoản
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div>
                                <label htmlFor="accountNumber">Số Tài Khoản:</label>
                                <Field className="form-control" type="text" id="accountNumber" name="accountNumber" />
                                {errors.accountNumber && touched.accountNumber ? (
                                    <div style={{ color: 'red' }}>{errors.accountNumber}</div>
                                ) : null}
                                {(props.is_UserPackage && props.PackAge && (props.is_UserPackage.total_edit_account >= props.PackAge.max_edit_account) &&
                                    <div className='pt-2'>
                                        <span className='text-warning'>Lưu ý: Khi sửa số tài khoản sẽ mất Phí 100.000đ</span>
                                    </div>)

                                    ||

                                    (props.is_UserPackage == null &&
                                        <div className='pt-2'>
                                            <span className='text-warning'>Lưu ý: Khi sửa số tài khoản sẽ mất Phí 100.000đ và 2.000đ kiểm tra tài khoản</span>
                                        </div>
                                    )
                                }
                            </div>
                            <div>
                                <label htmlFor="phone">Số Điện Thoại:</label>
                                <Field className="form-control" type="text" id="phone" name="phone" />
                                {errors.phone && touched.phone ? (
                                    <div style={{ color: 'red' }}>{errors.phone}</div>
                                ) : null}
                            </div>
                            <div>
                                <label htmlFor="password_level_two">Mã OTP:</label>
                                <Field className="form-control" type="text" id="password_level_two" name="password_level_two" />
                                {errors.password_level_two && touched.password_level_two ? (
                                    <div style={{ color: 'red' }}>{errors.password_level_two}</div>
                                ) : null}
                            </div>
                            <div>
                                <label htmlFor="password">Mật Khẩu:</label>
                                <Field className="form-control" type="text" id="password" name="password" />
                                {errors.password && touched.password ? (
                                    <div style={{ color: 'red' }}>{errors.password}</div>
                                ) : null}
                            </div>


                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={props.onHide}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-success">
                                    Xác nhận
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default ModalUpdateAcount;