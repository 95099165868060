import React, { useEffect, useState } from 'react';
import MasterLayout from '../../layouts/MasterLayout';
import ThemeBreadcrumb from '../../components/themes/ThemeBreadcrumb';
import AppNotification from '../../helpers/AppNotification';
import TransactionModel from '../../models/TransactionModel';
import Paginate from '../../components/paginate/Paginate';
import ModalStatus from '../../components/transaction/ModalStatus';
import Swal from 'sweetalert2';

function TransactionHistoryPage(props) {
    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState({});
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [keyword, setKeyword] = useState({
        start_date: '',
        end_date: '',
        name: '',
    });
    const [modalShowStatus, setModalShowStatus] = React.useState(false);
    const [element, setElement] = useState({});
    const [filter, setFilter] = useState({
        search: '',
        perPage: perPage,
        page: page,
    });

    const changePage = (page) => {
        setPage(page);
        setFilter({ search: keyword });
    };
    useEffect(() => {
        TransactionModel.historyAdmin({
            search: filter.search,
            page: page,
            perPage: perPage
        })
            .then(res => {
                setItems(res.data);
                setMeta(res.meta);
            }).catch(err => {
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, [page, filter]);
    const handleChange = (event) => {
        setKeyword({
            ...keyword,
            [event.target.name]: event.target.value
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setPage(1);
        // Xử lý tìm kiếm dữ liệu dựa trên keyword
        setFilter({ search: keyword });
        // Reset trường input sau khi submit
    };
    const handleSetPage = (number) => {
        setPage(page + number);
        setFilter({ search: keyword });
    };
    const handleShowModalStatus = (data) => {
        setElement(data);
        setModalShowStatus(true);
    };
    const handleCloseModalStatus = () => {
        setModalShowStatus(false);
    };
    const reloadPage = () => {
        window.location.reload();
    }
    const HandleShowModel = (id) => {
        TransactionModel.find(id).then(function (res) {
            if (res.success === true) {
                handleShowModalStatus(res.data);
            } else {
                Swal.fire({
                    icon: "error",
                    title: res.data,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        });
    }
    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Lịch Sử Rút Tiền Giới Thiệu" />
            <div className='row'>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Lịch sử Rút Tiền Giới Thiệu</h4>
                        </div>
                        <div className="card-body">
                            <div className='row d-flex justify-content-end'>
                                <div className='col-lg-6 d-flex justify-content-end'>
                                    <form onSubmit={handleSubmit}>
                                        <div className='input-group mb-3'>
                                            <div className='row'>
                                            <div className='col-3'>
                                                    <label className=''>Tìm với tên : </label>
                                                    <input
                                                        type='text'
                                                        name='name'
                                                        className='form-control ms-2'
                                                        placeholder='Nhập tên...'
                                                        value={keyword.name}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-3'>
                                                    <label className=''>Từ ngày : </label>
                                                    <input
                                                        type='date'
                                                        name='start_date'
                                                        className='form-control ms-2'
                                                        value={keyword.start_date}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-3'>
                                                    <label className='ms-2'>Đến ngày : </label>
                                                    <input
                                                        type='date'
                                                        name='end_date'
                                                        className='form-control ms-2'
                                                        value={keyword.end_date}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-3 d-flex flex-column justify-content-end'>
                                                    <div className='input-group-append'>
                                                        <button className='btn light btn-light ms-2' type='submit'>
                                                            Tìm kiếm
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <table className="table header-border table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Thể Loại</th>
                                            <th>Số tiền</th>
                                            <th>Người rút</th>
                                            <th>Trạng thái</th>
                                            <th>Thời gian</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            items.map((item, key) => (
                                                <tr key={key}>
                                                    <td><a href="">{item.id}</a>
                                                    </td>
                                                    <td><span className="badge badge-danger">Rút tiền giới thiệu</span>
                                                    </td>
                                                    <td>{item.amount_fm} VNĐ</td>
                                                    <td>{item.name_user}</td>
                                                    <td>{item.status === 1 && <span className="badge badge-success">Thành công</span>}
                                                        {item.status === 0 && <span className="badge badge-warning">Chờ duyệt</span>}
                                                        {item.status === -1 && <span className="badge badge-danger">Từ chối</span>}
                                                    </td>
                                                    <td><span className="text-muted">{item.create_at_fm}</span>
                                                    </td>
                                                    <td>{item.status === 0 ? <button className='btn-sm btn-primary' onClick={() => HandleShowModel(item.id)} >Cập Nhập</button> : ''}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <Paginate meta={meta} changePage={changePage} page={page} handleSetPage={handleSetPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalStatus
                show={modalShowStatus}
                onHide={handleCloseModalStatus}
                reloadPage={reloadPage}
                element={element}
            />
        </MasterLayout>
    );
}

export default TransactionHistoryPage;
