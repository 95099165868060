import React from 'react';
import Battery from '../global/Battery';

function ImageHTMLBILLIOSMB(props) {

    const {
        type, amount, date, receiverName, receiverNumber, optionValue, transactionCode, decreption, driverNetwork, networkDark, hideMark, senderName, senderNumber
    } = props;


    function formatDate(dateString) {
        // Tạo đối tượng Date từ chuỗi ngày
        const dateObj = new Date(dateString);

        // Lấy thông tin về ngày trong tuần
        const daysOfWeek = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'];
        const dayOfWeek = daysOfWeek[dateObj.getDay()];

        // Lấy ngày, tháng, năm
        const day = dateObj.getDate();
        const month = dateObj.getMonth() + 1; // Tháng bắt đầu từ 0
        const year = dateObj.getFullYear();

        // Định dạng chuỗi ngày
        return `${dayOfWeek} ${day}/${month}/${year}`;
    }



    let { hour, minute, second } = props;
    hour = hour < 10 ? '0' + hour : hour;
    minute = minute < 10 ? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;




    let { pin } = props;
    let power = (20 / 100) * pin
    let color = pin < 21 ? 'red' : 'black';


    return (
        <>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>IOS</title>
            <link rel="stylesheet" href="/asset-bill-transfer/MB/css/ios.css" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
            <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
                rel="stylesheet"
            />
            {/* <main className='custom-center-bill'> */}
                <main ref={props.divRef} style={{ width: '375px', height: '812px', position: 'relative' }}>
                    {
                        !hideMark && (
                            <img src='/bills/mark.png' style={{ position: 'absolute', objectFit: 'cover', width: '100%', height: '100%', top: 0, left: 0, zIndex: 100 }} alt='mark' />
                        )
                    }
                    <img src="/asset-bill-transfer/MB/image/default.png" className="container-mb">
                    </img>
                        <div className="containerHeader">
                            <div>
                                <p className="time-ios">{hour}:{minute}</p>
                            </div>
                            <div className="blockInfoNetwork">
                                <img
                                    className="network"
                                    src={`/image-electromagnetics/network-${networkDark}-dark.png`}
                                    alt="network"
                                />
                                {
                                    driverNetwork === 'wifi' ?
                                        <img id="wifi" className="wifi" src="/asset-bill-transfer/MB/image/wifi.png" alt="wifi" />
                                        :
                                        <img id="wifi" className="wifi" src="/asset-bill-transfer/MB/image/4G.png" alt="wifi" />

                                }
                                <Battery pin ={pin} color = {color} isWhiteBorder = {false} />

                                {/* <div className="battery">
                                    <div className="icon-container">
                                        <div className="outer-rect">
                                            <div className="inner-rect" id="innerRect" style={{ width: `${pin}%`, backgroundColor: `${color}`}}/>
                                        </div>
                                    </div>
                                    <div className="semi-circle" id="semiCircle" />
                                </div> */}
                            </div>
                        </div>
                        <div className="components">
                            <div className="div-amount">
                                <div className="amount">{amount} VND</div>
                            </div>
                            <div className="div-fixed-datein">
                                <div className="datetime">
                                    <div className="date-input">{hour}:{minute} - {date}</div>
                                </div>
                            </div>
                            <div className="div-user-name">
                                <div className="user-name">{receiverName}</div>
                            </div>
                            <div className="div-detail">
                                <div className="detail">
                                    <img src="/asset-bill-transfer/MB/image/mbIcon.png" alt="" />
                                    MBBank (MB) - {senderName}
                                </div>
                            </div>
                            <div className="div-content">
                                <div className="content">{decreption}</div>
                            </div>
                            <div className="div-account">
                                <div className="account">{senderName}</div>
                            </div>
                            <div className="div-transaction-id">
                                <div className="transaction-id">{transactionCode}</div>
                            </div>
                        </div>
                </main>
            {/* </main> */}
        </>

    );
}

export default ImageHTMLBILLIOSMB;