import React, { useEffect, useRef, useState } from 'react';
import { toPng } from 'html-to-image';
import download from 'downloadjs';
import { format } from 'date-fns';
import { vi } from 'date-fns/locale';
import InformationVCBank from './InformationVCBank';
import Battery from '../global/Battery';

function ImageHTMLIOS(props) {
    const {
        network, date, hour, minute,networkDark,
        logo1, contentMessGlobal1, titleMessGlobal1, date1, minute1, second1, hour1, account1, transaction1, balance1, note1, amount1, timeWord1, accountGuest1,
        logo2, contentMessGlobal2, titleMessGlobal2, date2, minute2, second2, hour2, account2, transaction2, balance2, note2, amount2, timeWord2, accountGuest2,
        logo3, contentMessGlobal3, titleMessGlobal3, date3, minute3, second3, hour3, account3, transaction3, balance3, note3, amount3, timeWord3, accountGuest3,
        showFormNotiTwo, showFormNotiThree, driverNetwork, hideMark, background } = props;

    let { pin } = props;
    let power = (19.5 / 100) * pin
    let color = pin < 21 ? 'red' : 'white';
    
    const containerScreenStyle = {
        backgroundImage: `${background}`,
    };

    return (
        <>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>IOS</title>
            {/* <link
                href="https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&family=Nunito:wght@400;600;700&display=swap"
                rel="stylesheet"
            /> */}
            <link rel='stylesheet' href='/asset-noti-custom/fonts/font.css' />
            <link rel="stylesheet" href="/asset-noti-custom/css/ios.css" />
            <script rel="stylesheet" href="/asset-noti-custom/js/iosScript.js" />
            <main ref={props.divRef} style={{ width: '400px', height: '812px', position: 'relative' }} >
                {
                    !hideMark && (
                        <img src='/bills/mark.png' style={{ position: 'absolute', objectFit: 'cover', width: '100%', height: '100%', top: 0, left: 0 }} alt='mark' />
                    )
                }
                <div style={{ backgroundImage: `${background}`, backgroundSize: 'cover' }} className="containerBill" id="background_image_div" >
                    <div className="containerHeader">
                        <div>
                            <p className="networkName">{network}</p>
                        </div>
                        <div className='d-flex'>
                            <img
                                className="network"
                                src={`/image-electromagnetics/network-${networkDark}-light.png`}
                                alt="network"
                            />
                            <img id="wifi" className="wifi" src={driverNetwork} alt="wifi" />
                            {/* <img
                                id="battery"
                                className="batteryIndicator"
                                src="/asset-noti-custom/image/batteryIndicator.png"
                                alt="batteryIndicator"
                            /> */}
                            <Battery pin ={pin} color = {color} isWhiteBorder = {true} />

                            {/* <div className="battery">
                                <div className="icon-container">
                                    <div className="outer-rect" />
                                    <div
                                        className="inner-rect"
                                        id="innerRect"
                                        style={{
                                            width: `${power}px`,
                                            backgroundColor: `${color}`
                                        }}
                                    />
                                </div>
                                <div className="semi-circle" id="semiCircle" style={{ left: "23.5px" }} />
                            </div> */}

                        </div>
                    </div>
                    <img className="lockIcon" src="/asset-noti-custom/image/lock.png" alt="lock-icon" />
                    <p className="date">{format(date, "EEEE, dd 'tháng' MM", { locale: vi })}</p>
                    <p id="countTime" className="time">
                        {hour < 10 ? '0' + hour : hour}:{minute < 10 ? '0' + minute : minute}
                    </p>
                    <div className="components">
                        {/* eventDetails */}
                        {showFormNotiThree ? <InformationVCBank contentMessGlobal={contentMessGlobal3} titleMessGlobal={titleMessGlobal3} accountGuest={accountGuest3} logo={logo3} date={date3} minute={minute3} second={second3} hour={hour3} account={account3} transaction={transaction3} balance={balance3} note={note3} amount={amount3} timeWord={timeWord3} /> : ''}
                        {showFormNotiTwo ? <InformationVCBank contentMessGlobal={contentMessGlobal2} titleMessGlobal={titleMessGlobal2} accountGuest={accountGuest2} logo={logo2} date={date2} minute={minute2} second={second2} hour={hour2} account={account2} transaction={transaction2} balance={balance2} note={note2} amount={amount2} timeWord={timeWord2} /> : ''}
                        <InformationVCBank contentMessGlobal={contentMessGlobal1} titleMessGlobal={titleMessGlobal1} accountGuest={accountGuest1} logo={logo1} date={date1} hour={hour1} minute={minute1} second={second1} account={account1} transaction={transaction1} balance={balance1} note={note1} amount={amount1} timeWord={timeWord1} />
                        <div className="controlIcon">
                            <div className="containerLightIcon">
                                <img
                                    className="lightIcon"
                                    src="/asset-noti-custom/image/light.png"
                                    alt="light-icon"
                                />
                            </div>
                            <div className="containerCameraIcon">
                                <img className="cameraIcon" src="/asset-noti-custom/image/camera.png" alt="camera" />
                            </div>
                        </div>
                        <img className="baseIcon" src="/asset-noti-custom/image/base.png" alt="base-icon" />
                    </div>
                </div>
            </main>
            {/* <footer></footer> */}
        </>
    );
}

export default ImageHTMLIOS;