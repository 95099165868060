import React from 'react';

function ThemePreloader(props) {
    return (
        <div id="preloader">
            <div className="inner">
                <span>Loading </span>
                <div className="loading">
                </div>
            </div>
        </div>
    );
}

export default ThemePreloader;