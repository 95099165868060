import React, {useEffect, useState } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import ThemePreloader from "../components/themes/ThemePreloader";
import ThemeAnimation from "../components/themes/ThemeAnimation";
import Logo from "./includes/Logo";
import usePreloader from "../hook/usePreloader";
import SettingModel from '../models/SettingModel';
import { getCookie } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { setSystemSettings } from '../redux/action';


function MasterLayout({ children }) {
  const isLoading = usePreloader();
  const showSidebar = useSelector((state) => state.showSidebar);
  const showPreloader = useSelector((state) => state.showPreloader);
  const sytemSettings = useSelector((state) => state.system_setting);
  const dispatch = useDispatch();

  useEffect(() => {
    if( Object.keys(sytemSettings).length === 0 ){
      SettingModel.getAll({})
      .then(res => {
        dispatch(setSystemSettings(res.data_setting));
      })
    }
  }, [])

  return (
    <>
      {isLoading ? <ThemePreloader /> : null}
      {showPreloader ? <ThemePreloader /> : null}
      <div
        id="main-wrapper"
        className={`show ${!showSidebar ? "menu-toggle" : ""}`}
      >
        <ThemeAnimation />
          <Logo />
          <Sidebar />
        <Header />
        <div className="content-body">
          <div className="container-fluid">{children}</div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default MasterLayout;
