import axiosClient from "./axiosClient";
import AppCookie from '../helpers/AppCookie';

class VerifyCode {
    constructor(){
        this.api_url = "/api/verify-code/send-otp";
    }

    async getOTP(credentials) {
        const res = await axiosClient.post(this.api_url , credentials);
        return res;
    }

}

export default new VerifyCode;