import React from 'react';

function ThemeBreadcrumb({title}) {
    return (
        <div className="row page-titles">
            <ol className="breadcrumb">
                <li className="breadcrumb-item active"><a href="#">Trang chủ</a></li>
                <li className="breadcrumb-item">{title}</li>
            </ol>
        </div>
    );
}

export default ThemeBreadcrumb;