import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import UserBankAcountModel from '../../models/UserBankAcountModel';
import AppNotification from '../../helpers/AppNotification';
import BankModel from '../../models/BankModel';
import CurrencyInput from '../global/CurrencyInput';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../../redux/action';

const validationSchema = Yup.object().shape({
    amount: Yup.number()
        .required('Vui lòng nhập số tiền')
});

function ModalDepositApp(props) {
    const dispatch = useDispatch();

    // xử lí scrip công thức
    const [amountMessage, setAmountMessage] = useState({
        total: '',
        fee: '',
        total_order: '',
    });

    //
    const initialValues = {
        amount: '',
        user_bank_account_id: props.user_bank_account_id,
    };
    const handCloseModal = () => {
        setAmountMessage({
            total: '',
            fee: '',
        })
        props.onHide();
    }
    const handleSubmit = (values) => {
        dispatch(setShowPreloader(true));
        BankModel.depositApp(values).then(function (res) {
            dispatch(setShowPreloader(false));
            if (res.success === true) {
                Swal.fire({
                    icon: "success",
                    title: "Nạp tiền vào tài khoản thành công!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                setTimeout(() => {
                    props.reloadPage(props.id,props.type);
                    handCloseModal();
                }, 2000);
            } else {
                Swal.fire({
                    icon: "error",
                    title: res.data,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        }).catch(function (err) {
            dispatch(setShowPreloader(false));
            Swal.fire({
                icon: "error",
                title: 'Nạp tiền vào tài khoản thất bại!',
                showConfirmButton: false,
                timer: 2000,
            });
            console.log(err);
        })
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Nạp Tiền vào Tài khoản {props.bankName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched, setFieldValue }) => (
                        <Form>
                            <div className='card-body'>
                                <div className="row pt-3">
                                    <div className="col">
                                        <strong>Tình trạng:</strong>
                                    </div>
                                    <div className="col">
                                        {props.BankStatus === 1 ? (
                                            <span className="badge badge-success">Hoạt động</span>
                                        ) : (
                                            <span className="badge badge-danger">Đang Tắt</span>
                                        )}
                                    </div>
                                </div>

                            </div>
                            <div className="mb-3">
                                <label htmlFor="amount" className="form-label">
                                    <span className='text-success'>Nhập số tiền nạp vào App</span> (*)
                                </label>
                                {
                                    (props.PackAge && props.PackAge.max_deposit_app == -1) &&
                                        <CurrencyInput
                                            type="text"
                                            className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                                }`}
                                            autoFocus
                                            onChange={e => {
                                                let value = e.target.value || "";
                                                value = value.replaceAll(',', '');
                                                setFieldValue("amount", value);
                                                setAmountMessage({
                                                    // total: 'Số tiền cần thanh toán = ' + new Intl.NumberFormat().format(value) + ' / 100 = ' + new Intl.NumberFormat().format(value / 100) + ' VNĐ',
                                                    // fee: 'Phí nạp = ' + new Intl.NumberFormat().format(value / 100) + ' x 1% = ' + new Intl.NumberFormat().format(Math.ceil(((value / 10000 * 1)))) + ' VNĐ',
                                                })
                                            }}
                                        />
                                    
                                }
                                {(props.is_UserPackage && props.PackAge && (props.is_UserPackage.total_deposit_app < props.PackAge.max_deposit_app) &&
                                    <CurrencyInput
                                        type="text"
                                        className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                            }`}
                                        autoFocus
                                        onChange={e => {
                                            let value = e.target.value || "";
                                            value = value.replaceAll(',', '');
                                            setFieldValue("amount", value);
                                            setAmountMessage({
                                                // total: 'Số tiền cần thanh toán = ' + new Intl.NumberFormat().format((value / 10000)*20) + ' VNĐ',
                                                // fee: 'Vì đã quá giới hạn nạp của gói vip nên phí nạp = ' + new Intl.NumberFormat().format(value / 100) + ' x 1% = ' + new Intl.NumberFormat().format(Math.ceil(((value / 10000 * 1)))) + ' VNĐ',
                                            })
                                        }}
                                    />)

                                    ||

                                    (props.is_UserPackage && props.PackAge && (props.is_UserPackage.total_deposit_app >= props.PackAge.max_deposit_app) && (props.PackAge.max_deposit_app != -1) &&
                                        <CurrencyInput
                                            type="text"
                                            className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                                }`}
                                            autoFocus
                                            onChange={e => {
                                                let value = e.target.value || "";
                                                value = value.replaceAll(',', '');
                                                setFieldValue("amount", value);
                                                setAmountMessage({
                                                    total: 'Số tiền cần thanh toán = ' + new Intl.NumberFormat().format((value / 10000)*20) + ' VNĐ',
                                                    // fee: 'Vì đã quá giới hạn nạp của gói vip nên phí nạp = ' + new Intl.NumberFormat().format((value / 10000)*20) + ' x 1% = ' + new Intl.NumberFormat().format(Math.ceil((((value / 10000)*20)/100*1))) + ' VNĐ',
                                                })
                                            }}
                                        />)

                                    ||

                                    (props.is_UserPackage == null &&
                                        <CurrencyInput
                                            type="text"
                                            className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                                }`}
                                            autoFocus
                                            onChange={e => {
                                                let value = e.target.value || "";
                                                value = value.replaceAll(',', '');
                                                setFieldValue("amount", value);
                                                setAmountMessage({
                                                    total: 'Số tiền cần thanh toán = ' + new Intl.NumberFormat().format(value) + ' / 100 = ' + new Intl.NumberFormat().format(value / 100) + ' VNĐ',
                                                    // fee: 'Phí nạp = ' + new Intl.NumberFormat().format(value / 100) + ' x 1% = ' + new Intl.NumberFormat().format(Math.ceil(((value / 10000 * 1)))) + ' VNĐ',
                                                })
                                            }}
                                        />
                                    )
                                }
                                <Field
                                    type="hidden"
                                    id="amount"
                                    name="amount"
                                    className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                        }`}
                                    autoFocus
                                />
                                {errors.amount && touched.amount && (
                                    <div className="invalid-feedback">{errors.amount}</div>
                                )}
                                <div className='text-warning pt-2'>
                                    {amountMessage.total}
                                </div>
                                <div className='text-warning pt-2'>
                                    {amountMessage.fee}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={handCloseModal}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-success">
                                    Xác nhận
                                </button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default ModalDepositApp;