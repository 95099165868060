import axiosClient from './axiosClient';
import AppCookie from "../helpers/AppCookie";

class BankModel {
    constructor(){
        this.api_url = "/api/banks";
    }
    async all() {
        const res = await axiosClient.get(this.api_url);
        return res;
    }

    async find(id) {
        const res = await axiosClient.get(this.api_url + '/' + id);
        return res;
    }

    async depositApp(credentials) {
        const res = await axiosClient.post("/api/tranctions-app/deposit", credentials );
        return res;
    }

}

export default new BankModel;