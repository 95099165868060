import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import { useEffect, useState } from 'react';
import React, { useRef } from 'react';
import ModalTransfer from '../components/paymentEarnmoney/ModalTransfer';
import ModalWithdraw from '../components/paymentEarnmoney/ModalWithdraw';
import PaymentModel from '../models/PaymentModel';
import AppNotification from '../helpers/AppNotification';
import Paginate from '../components/paginate/Paginate';
import InstructEarnmonye from '../components/instructs/InstructEarnmonye';



function PaymentEarnMoneyPage(props) {
    // paginate
    const [meta, setMeta] = useState({});
    const [perPage, setPerPage] = useState(5);
    const [page, setPage] = useState(1);
    const changePage = (page) => {
        setPage(page);
        setFilter({ search: keyword });
    }
    const handleSetPage = (number) => {
        setPage(page + number);
        setFilter({ search: keyword });
    }

    // search
    const [keyword, setKeyword] = useState({
        start_date: '',
        end_date: '',
    });
    const [filter, setFilter] = useState({
        search: '',
        perPage: perPage,
        page: page,
    });
    const handleChange = (event) => {
        setKeyword({
            ...keyword,
            [event.target.name]: event.target.value
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setPage(1);
        // Xử lý tìm kiếm dữ liệu dựa trên keyword
        setFilter({ search: keyword });
        // Reset trường input sau khi submit
    }


    // xử lý khác
    const [modalShow, setModalShow] = React.useState(false);

    const handleShowModal = (type) => {
        setModalShow(true)
    }
    const handleCloseModal = () => {
        setModalShow(false);
    };

    const [modalShowTransfer, setModalShowTransfer] = React.useState(false);
    const handleShowModalTransfer = (type) => {
        setModalShowTransfer(true)
    }
    const handleCloseModalTransfer = () => {
        setModalShowTransfer(false);
    };

    const [info, setInfo] = useState({});
    const url_ref = 'https://okbill.net/register?ref=' + info.user_name;

    const [withdrawHistory, setWithdrawHistory] = useState([]);

    useEffect(() => {
        PaymentModel.getWithdrawHistory({
            search: filter.search,
            page: page,
            perPage: perPage
        }).then((res) => {
            setMeta(res.meta);
            setWithdrawHistory(res.data);
        }).catch((err) => {
            console.log(err);
            AppNotification.showError('Lấy tin tức thất bại!');
        })
    }, [page, filter])

    const inputRef = useRef(null);
    const copyToClipboard = () => {
        inputRef.current.select();
        document.execCommand('copy');
        inputRef.current.blur();
    };

    useEffect(() => {
        PaymentModel.getInfo().then((res) => {
            setInfo(res.data);
        }).catch((err) => {
            console.log(err);
            AppNotification.showError('Lấy thông tin thất bại!');
        })
    }, [])

    const reloadPage = () => {
        window.location.reload();
    }

    console.log(info);
    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Kiếm tiền" />
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className="col-xl-6 col-lg-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Giới Thiệu Kiếm tiền Triệu</h4>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <p>Link giới thiệu của bạn:</p>
                                        <div className="input-group mb-3">
                                            <input ref={inputRef} type="text" className="form-control" value={url_ref} />
                                            <button className="btn btn-primary" type="button" onClick={copyToClipboard}> Sao chép </button>
                                        </div>
                                        <div className='row pt-2'>
                                            <div className='col-lg-3'>
                                                <strong className="text-primary">Số tiền có thể rút:</strong>
                                            </div>
                                            <div className='col-lg-6'>
                                                {info.can_earn_money}đ [Trực tiếp]
                                            </div>
                                        </div>
                                        <div className='row pt-2'>
                                            <div className='col-lg-3'>
                                                <strong className="text-primary">Số tài khoản giới thiệu:</strong>
                                            </div>
                                            <div className='col-lg-6'>
                                                {info.count_account_chilrent} tài khoản
                                            </div>
                                        </div>
                                        <div className='row pt-2'>
                                            <div className='col-lg-3'>
                                                <strong className="text-primary">Tổng số khách nạp:</strong>
                                            </div>
                                            <div className='col-lg-6'>
                                                {info.count_people} tài khoản
                                            </div>
                                        </div>
                                        <div className='row pt-2'>
                                            <div className='col-lg-3'>
                                                <strong className="text-primary">Số tiền kiếm được:</strong>
                                            </div>
                                            <div className='col-lg-6'>
                                                {info.total_earn_money} đ
                                            </div>
                                        </div>
                                        <div className='row pt-2'>
                                            <div className='col-lg-3'>
                                                <strong className="text-primary">Hoa hồng trực tiếp:</strong>
                                            </div>
                                            <div className='col-lg-6'>
                                                {info.total_profit}% / đơn nạp thành công
                                            </div>
                                        </div>
                                        <div className='row pt-2'>
                                            <div className='col-lg-3'>
                                                <strong className="text-primary">Số lần đã rút tiền:</strong>
                                            </div>
                                            <div className='col-lg-6'>
                                                {info.count_withdraw_money} lần rút
                                            </div>
                                        </div>
                                        <div className='row pt-2'>
                                            <div className='col-lg-3'>
                                                <strong className="text-primary">Tổng số tiền đã rút:</strong>
                                            </div>
                                            <div className='col-lg-6'>
                                                {info.total_withdraw_money}đ
                                            </div>
                                        </div>

                                        <div style={{ marginTop: '10px' }}>
                                            <button onClick={handleShowModal} className="btn btn-success">Rút tiền</button>
                                            <ModalWithdraw
                                                show={modalShow}
                                                onHide={handleCloseModal}
                                                reloadPage={reloadPage}
                                            />
                                            <button onClick={handleShowModalTransfer} className="btn btn-outline-light" style={{ marginLeft: '10px' }}>Chuyển tiền</button>
                                            <ModalTransfer
                                                show={modalShowTransfer}
                                                onHide={handleCloseModalTransfer}
                                                reloadPage={reloadPage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-xl-6'>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Hướng dẫn sử dụng</h4>
                                </div>
                                <div className="card-body p-3">
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <InstructEarnmonye/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Lịch sử rút tiền và chuyển tiền</h4>
                        </div>
                        <div className="card-body">
                        <div className='row d-flex justify-content-end'>
                                <div className='col-lg-6 d-flex justify-content-end'>
                                    <form onSubmit={handleSubmit}>
                                        <div className='input-group mb-3'>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <label className=''>Từ ngày : </label>
                                                    <input
                                                        type='date'
                                                        name='start_date'
                                                        className='form-control ms-2'
                                                        placeholder='Nhập từ khóa...'
                                                        value={keyword.start_date}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-4'>
                                                    <label className='ms-2'>Đến ngày : </label>
                                                    <input
                                                        type='date'
                                                        name='end_date'
                                                        className='form-control ms-2'
                                                        placeholder='Nhập từ khóa...'
                                                        value={keyword.end_date}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-4 d-flex flex-column justify-content-end'>
                                                    <div className='input-group-append'>
                                                        <button className='btn light btn-light ms-2' type='submit'>
                                                            Tìm kiếm
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <table className="table header-border table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Thể loại</th>
                                            <th>Số tiền</th>
                                            <th>Thực nhận</th>
                                            <th>Ghi chú</th>
                                            <th>Tình trạng</th>
                                            <th>Thời gian</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            withdrawHistory.map((item, key) => (
                                                <tr key={key}>
                                                    <td>{item.id}</td>
                                                    <td><span style={{ color: 'green' }} className="badge badge-warning">
                                                        {item.type === 'DEPOSITAPP' && 'Nạp tiền vào App'}
                                                        {item.type === 'RECHARGE' && 'Nạp tiền vào web'}
                                                        {item.type === 'EARNMONEY' && 'rút tiền'}
                                                        {item.type === 'PAYMONEY' && 'Chuyển tiền qua tài khoản chính'}
                                                        </span></td>
                                                    <td>{item.amount_fm} đ</td>
                                                    <td>{item.received_fm} đ</td>
                                                    <td>{item.note}</td>
                                                    <td>
                                                        {item.status === 1 && <span className="badge badge-success">Thành công</span>}
                                                        {item.status === 0 && <span className="badge badge-warning">Chờ duyệt</span>}
                                                        {item.status === -1 && <span className="badge badge-danger">Từ chối</span>}
                                                    </td>
                                                    <td><span className="text-muted">{item.create_at_fm}</span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <Paginate meta={meta} changePage={changePage} page={page} handleSetPage={handleSetPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MasterLayout>
    );
}

export default PaymentEarnMoneyPage;