import axiosClient from "./axiosClient";
import AppCookie from '../helpers/AppCookie';

class UserModel {
    constructor(){
        this.api_url = "/api/users";
    }

    async getAllUser(credentials) {
        const res = await axiosClient.get(this.api_url, {
          params: {
            search: credentials.search,
            page: credentials.page,
            perPage: credentials.perPage
          },
        });
        return res;
    }

    async show(credentials) {
        const res = await axiosClient.get(this.api_url + "/" + credentials);
        return res;
    }

    async find() {
        const res = await axiosClient.get(this.api_url + "/profile" );
        return res;
    }

    async update(credentials) {
        const res = await axiosClient.put(this.api_url + "/profile", credentials);
        return res;
    }

    async updateStatus(credentials) {
        const res = await axiosClient.post(this.api_url + "/update-status", credentials);
        return res;
    }

    async updateEmail(credentials) {
        const res = await axiosClient.post(this.api_url + "/update-email", credentials);
        return res;
    }

    async updateRole(credentials) {
        const res = await axiosClient.post(this.api_url + "/update-role", credentials);
        return res;
    }

    async delete(credentials) {
        const res = await axiosClient.delete(this.api_url + "/" + credentials);
        return res;
    }

    async changePassword(credentials) {
        const res = await axiosClient.post(this.api_url + "/change-password", credentials);
        return res;
    }

    async restore(credentials) {
        const res = await axiosClient.post(this.api_url + "/restore-password", credentials);
        return res;
    }

    async updateBlance(credentials) {
        const res = await axiosClient.post(this.api_url + "/update-balance", credentials);
        return res;
    }

    async depositHandmade(credentials) {
        const res = await axiosClient.post(this.api_url + "/deposit/handmade", credentials);
        return res;
    }

    async adminUpdate(credentials) {
        const res = await axiosClient.post(this.api_url + "/admin-update", credentials);
        return res;
    }

    async feeDowloadBill(imagePreview) {
        const res = await axiosClient.post(this.api_url + "/fee-dowload-bill",{imagePreview:imagePreview});
        return res;
    }

    async feeDowloadNotification() {
        const res = await axiosClient.post(this.api_url + "/dowload-notification");
        return res;
    }
}

export default new UserModel;