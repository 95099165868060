import React from 'react';
import ModalBuyPackage from './ModalBuyPackage';

function PackageItem(props) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <div className="col-xl-3 col-xxl-6 col-lg-6">
                <div className="card">
                    <div className="card-header  border-0 pb-0">
                        <div className='row d-flex justify-content-center'>
                            <div className='col-12 d-flex justify-content-center'>
                                {
                                    props.package.type == 'week' ?
                                        <h4 className={props.package.type == 'vip3' ? "text-success" : "text-warning"}>
                                            {(props.type == 'VCB' && "Gói " + "tuần" + " Ngân Hàng Vietcombank") || (props.type == 'TCB' && "Gói " + "tuần" + " Ngân Hàng Techcombank") || (props.type == 'MB' && "Gói " + "tuần" + " Ngân Hàng MB-Bank") || (props.type == 'BIDV' && "Gói " + "tuần" + " Ngân Hàng BIDV") || (props.type == 'ICB' && "Gói " + "tuần" + " Ngân Hàng Viettinbank") || (props.type == 'ALL' && "Gói " + props.package.name + " ĐẶC BIỆT")}
                                        </h4>
                                        :
                                        <h4 className={props.package.type == 'vip3' ? "text-success" : "text-warning"}>
                                            {(props.type == 'VCB' && "Gói " + props.package.name + " Ngân Hàng Vietcombank") || (props.type == 'TCB' && "Gói " + props.package.name + " Ngân Hàng Techcombank") || (props.type == 'MB' && "Gói " + props.package.name + " Ngân Hàng MB-Bank") || (props.type == 'BIDV' && "Gói " + props.package.name + " Ngân Hàng BIDV") || (props.type == 'ICB' && "Gói " + props.package.name + " Ngân Hàng Viettinbank") || (props.type == 'ALL' && "Gói " + props.package.name + " ĐẶC BIỆT")}
                                        </h4>
                                }
                            </div>
                            {
                                props.type == 'ALL' ? (
                                    <div className='col-12 d-flex justify-content-center'>
                                        <span className='text-primary'>
                                            (Áp dụng cho tất cả App Ngân Hàng)
                                        </span>
                                    </div>
                                ) : ''
                            }
                            <div className='col-12 d-flex justify-content-center'>
                                {props.package.type == 'vip1' && <img src="/images/vip1.png" width={100} height={100} />}
                                {props.package.type == 'vip2' && <img src="/images/vip 2.png" width={100} height={100} />}
                                {props.package.type == 'vip3' && <img src="/images/vip3.png" width={100} height={100} />}
                                {props.package.type == 'week' && <img src="/images/week.png" width={100} height={100} />}
                            </div>
                            <div className='col-12 d-flex justify-content-center'>
                                <h1 className="card-title text-primary">{props.package.price_fm} VNĐ / {props.package.type == 'week' ? `tuần` : `tháng`}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div
                            id="DZ_W_Todo1"
                            className="widget-media dz-scroll ps ps--active-y"
                        >
                            <div className='row'>
                                <div className='col-12 pb-4'><strong className='text-warning'>Quyền lợi:</strong></div>
                            </div>
                            <div className='row pb-3'>
                                <div className='col-12'>
                                    <strong className=''>- Mở tối đa {props.package.max_create_account} tài khoản</strong>
                                </div>
                            </div>
                            <div className='row pb-3'>
                                <div className='col-12'>
                                    <strong className=''>- {props.package.max_edit_account} lần sửa thay đổi số tài khoản</strong>
                                </div>
                            </div>
                            <div className='row pb-3'>
                                <div className='col-12'>
                                    <strong className='text-primary'>- Check tài khoản không giới hạn</strong>
                                </div>
                            </div>
                            <div className='row pb-3'>
                                <div className='col-12'>
                                    {props.package.max_transfer_free == -1 ? <strong className='text-primary'>- Chuyển khoản không giới hạn</strong> : <strong className=''>- Chuyển khoản {props.package.max_transfer_free} lần / ngày</strong>}

                                </div>
                            </div>
                            <div className='row pb-3'>
                                <div className='col-12'>
                                    {props.package.max_deposit_app == -1 ? <strong className='text-primary'>- Nạp vào app không giới hạn </strong> : <strong className=''>- Nạp tối đa {props.package.max_deposit_app_fm} VNĐ vào app</strong>}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <button className="btn btn-primary sw-btn-next" onClick={() => setModalShow(true)} type="button">
                                    Đăng ký gói
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalBuyPackage
                show={modalShow}
                onHide={() => setModalShow(false)}
                packageName={props.package.name}
                packagePrice={props.package.price_fm}
                type={props.type}
                typePackage={props.package.type}
                bankCode={props.package.bank_code}
            />
        </>
    );
}

export default PackageItem;