import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetisMenu from "@metismenu/react";
import AuthMoldel from "../../models/AuthMoldel";
import adminMenus from "../../route/adminMenu";
import menus from "../../route/menu";
import HomeModel from "../../models/HomeModel";
import { useDispatch } from "react-redux";

function AsideMenu(props) {
//   const isAdmin = AuthMoldel.isAdmin();
  const dispatch = useDispatch();

  const isAdminPage = window.location.pathname.includes("admin");
  const [data, setData] = useState([]);
  const [showPackage, setShowPackage] = useState(0);
  const [activeMenu, setActiveMenu] = useState(() => {
    const storedActiveMenu = localStorage.getItem("activeMenu");
    return storedActiveMenu || "dashboard";
  });

  const handleLinkClick = (menuId) => {
    if (window.screen.width <= 768) {
      dispatch({
        type: "SHOW_SIDEBAR",
        payload: false,
      });
    }
    setActiveMenu(menuId);
    localStorage.setItem("activeMenu", menuId);
  };
  const handleClick = () => {
    if (showPackage == 0) {
      setShowPackage(1);
    } else {
      setShowPackage(0);
    }
  };

  useEffect(() => {
    HomeModel.getInfoHeader()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <MetisMenu className="metismenu" id="menu">
      <li className="info-user-sidebar ms-5">
        <span className="nav-text text-fm-sd">
          Số dư :{" "}
          {data.account_balance
            ? data.account_balance.toLocaleString("en-US")
            : data.account_balance}{" "}
          VNĐ
        </span>{" "}
        <br />
        <span className="nav-text text-fm-sd">
          Tài khoản giới thiệu :{" "}
          {data.referral_account_balance
            ? data.referral_account_balance.toLocaleString("en-US")
            : data.referral_account_balance}{" "}
          VNĐ
        </span>
      </li>
      {!isAdminPage && (
        <div className="custom-menu-title ms-4">
          <span className="nav-text custom-color">Menu Chức Năng</span>
        </div>
      )}
      {!isAdminPage
        ? menus.map((menu, key) => (
            <li
              className={`p-0 m-0 ${
                activeMenu === menu.id ? "active-menu" : "no-active-menu"
              }`}
              key={key}
            >
              {(menu.id == "dashboard" ||
                menu.id == "package" ||
                menu.id == "package-bill" ||
                menu.id == "deposit" ||
                menu.id == "earn-money") && (
                <>
                  <Link
                    to={menu.path}
                    className="custom-link"
                    onClick={() => handleLinkClick(menu.id)}
                  >
                    <div
                      className="menu-icon custom-color-icon"
                      dangerouslySetInnerHTML={{ __html: menu.icon }}
                    ></div>
                    <span className="nav-text custom-color-icon">
                      {menu.text}
                    </span>
                  </Link>
                </>
              )}
            </li>
          ))
        : null}

      {!isAdminPage && (
        <div className="custom-menu-title ms-4">
          <span className="nav-text custom-color">Menu App</span>
        </div>
      )}
      {!isAdminPage
        ? menus.map((menu, key) => (
            <li
              className={`p-0 m-0 ${
                activeMenu === menu.id ? "active-menu" : "no-active-menu"
              }`}
              key={key}
            >
              {menu.id == "bank" && (
                <>
                  <Link
                    to={menu.path}
                    className="custom-link"
                    onClick={() => handleLinkClick(menu.id)}
                  >
                    <div
                      className="menu-icon custom-color-icon"
                      dangerouslySetInnerHTML={{ __html: menu.icon }}
                    ></div>
                    <span className="nav-text custom-color-icon">
                      {menu.text}
                    </span>
                  </Link>
                </>
              )}
            </li>
          ))
        : null}

      {!isAdminPage && (
        <div className="custom-menu-title ms-4">
          <span className="nav-text custom-color">Menu Tạo Bill</span>
        </div>
      )}
      {!isAdminPage
        ? menus.map((menu, key) => (
            <li
              className={`p-0 m-0 ${
                activeMenu === menu.id ? "active-menu" : "no-active-menu"
              }`}
              key={key}
            >
              {(
                menu.id == "fake-notication" ||
                menu.id == "fake-blance" ||
                menu.id == "bill-new") && (
                <>
                  <Link
                    to={menu.path}
                    className="custom-link"
                    onClick={() => handleLinkClick(menu.id)}
                  >
                    <div
                      className="menu-icon custom-color-icon"
                      dangerouslySetInnerHTML={{ __html: menu.icon }}
                    ></div>
                    <span className="nav-text custom-color-icon">
                      {menu.text}
                    </span>
                  </Link>
                </>
              )}
            </li>
          ))
        : null}
      {!isAdminPage && (
        <div className="custom-menu-title ms-4">
          <span className="nav-text custom-color">Liên hệ</span>
        </div>
      )}
      {!isAdminPage
        ? menus.map((menu, key) => (
            <li
              className={`p-0 m-0 ${
                activeMenu === menu.id ? "active-menu" : "no-active-menu"
              }`}
              key={key}
            >
              {menu.id == "contact" && (
                <>
                  <Link
                    to={menu.path}
                    className="custom-link"
                    onClick={() => handleLinkClick(menu.id)}
                  >
                    <div
                      className="menu-icon custom-color-icon"
                      dangerouslySetInnerHTML={{ __html: menu.icon }}
                    ></div>
                    <span className="nav-text custom-color-icon">
                      {menu.text}
                    </span>
                  </Link>
                </>
              )}
            </li>
          ))
        : null}

      {/* {
                !isAdminPage ? menus.map((menu, key) => (
                    <li key={key}>
                        {menu.id == 'package' ?
                            (
                                <>
                                    <Link to="/package/VCB">
                                        <div className="menu-icon" dangerouslySetInnerHTML={{ __html: menu.icon }}></div>
                                        <span className="nav-text">{menu.text}</span>
                                    </Link>
                                </>
                            ):
                            (
                                <>
                                    <Link to={menu.path}>
                                        <div className="menu-icon" dangerouslySetInnerHTML={{ __html: menu.icon }}></div>
                                        <span className="nav-text">{menu.text}</span>
                                    </Link>
                                </>
                            )
                        }
                    </li>
                )) : null
            } */}
      {isAdminPage
        ? adminMenus.map((menu, key) => (
            <li key={key}>
              <Link to={menu.path}>
                <div
                  className="menu-icon"
                  dangerouslySetInnerHTML={{ __html: menu.icon }}
                ></div>
                <span className="nav-text">{menu.text}</span>
              </Link>
            </li>
          ))
        : null}
    </MetisMenu>
  );
}

export default AsideMenu;
