import React from 'react';
import TransactionPage from '../pages/admin/TransactionPage';
import AdminHomePage from '../pages/admin/AdminHomePage';
import NewsPage from '../pages/admin/NewsPage';
import CreateCodeMoney from '../pages/admin/CreateCodeMoney';
import SettingPage from '../pages/admin/SettingPage';

const admins = [
	{ path: '/admin', element: <AdminHomePage /> },
	{ path: '/admin/transactions', element: <TransactionPage /> },
	{ path: '/admin/create-code-money', element: <CreateCodeMoney /> },
	{ path: '/admin/setting', element: <SettingPage /> },
	{ path: '/admin/news', element: <NewsPage /> },
];

export default admins;