import axiosClient from './axiosClient';
class BuildAppModel {
    constructor(){
        this.api_url = "/api";
    }
    async startBuild(credentials) {
        const res = await axiosClient.post(this.api_url+ '/save-uuid',credentials);
        return res;
    }
    async delete(credentials) {
        const res = await axiosClient.post(this.api_url+ '/uuid-delete',credentials);
        return res;
    }

    async getStatusBuild(credentials) {
        const res = await axiosClient.get(this.api_url + '/get-status-build', {
            params: {
                buildId: credentials.buildId,
            },
          });
        return res;
    }

    async getAllBuild(credentials) {
        const res = await axiosClient.get(this.api_url + '/get-all-build', {
            params: {
                type: credentials.type,
            },
          });
        return res;
    }


}

export default new BuildAppModel;