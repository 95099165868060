import React from 'react';
import { Link } from 'react-router-dom';

function BankItemBill(props) {
    const handleButtonClick = () => {
        window.location.href = '/bill/create/' + props.item.type; // Thay đổi URL theo nhu cầu của bạn
      };
    return (
        <div className="card overflow-hidden">
            <div className="card-body">
                <div className="text-center">
                    <div className="profile-photo">
                        <img
                            src={props.item.logo}
                            style={{
                                width: '200px',
                                height: '100px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                            }}
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <p className="mt-2 mb-1 text-white">Fake Bill Chuyển Khoản {props.item.name}</p>
                </div>
            </div>
            <div className="card-footer pt-0 pb-0 text-center">
                <div className="row">
                    {/* <div className="col-5 pt-2 pb-2 border-start">
                        <button className='btn btn-primary'>
                            Tải App
                        </button>
                    </div> */}
                    <div className="col-12 pt-2 pb-2">
                        {/* <Link to={`/bill/${props.item.type}`} onClick={props.handleShowForm} className='btn btn-primary' >
                            Tạo bill
                        </Link> */}
                        {/* <Link
                            to={`/bill/create/${props.item.type}`}
                            className='btn btn-primary'
                        >
                            Tạo bill
                        </Link> */}
                        <button
                            onClick={handleButtonClick}
                            className='btn btn-primary'
                        >
                            Tạo bill
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BankItemBill;