import axiosClient from './axiosClient';
import AppCookie from "../helpers/AppCookie";


class SettingModel {
    constructor(){
        this.api_url = "/api/settings";
    }
    async getNews() {
        const res = await axiosClient.get(this.api_url);
        return res;
    }

    async getAll(credentials) {
        const res = await axiosClient.get(this.api_url + '/getall');
        return res;
    }

    async show() {
        const res = await axiosClient.get(this.api_url + '/show');
        return res;
    }

    async create(credentials) {
        const res = await axiosClient.post(this.api_url + "/create", credentials);
        return res;
    }

    async createImage(credentials) {
        const res = await axiosClient.post(this.api_url + "/createImage", credentials,{
            headers : {
                'Content-Type' : 'multipart/form-data'
            }
        });
        return res;
    }

    async update(credentials) {
        const res = await axiosClient.post(this.api_url, credentials);
        return res;
    }

    async delete(credentials) {
        console.log(credentials);
        const res = await axiosClient.delete(this.api_url + '/' + credentials);
        return res;
    }

    handleStoreCookie(res){
        let nameTele_hotro = res.data.find(item => item.key === 'nameTele_hotro')?.value;
        let groupName = res.data.find(item => item.key === 'groupName')?.value;
        let groupPath = res.data.find(item => item.key === 'groupPath')?.value;
        let address = res.data.find(item => item.key === 'address')?.value;
        let phone = res.data.find(item => item.key === 'phone')?.value;
        let email = res.data.find(item => item.key === 'email')?.value;
        let nameTele_lienhe = res.data.find(item => item.key === 'nameTele_lienhe')?.value;
        let pathTele_lienhe = res.data.find(item => item.key === 'pathTele_lienhe')?.value;
        let imageVIB = res.data.find(item => item.key === 'imageVIB')?.value;
        let imageTCB = res.data.find(item => item.key === 'imageTCB')?.value;

        AppCookie.setCookie('setting_nameTele_hotro', nameTele_hotro);
        AppCookie.setCookie('setting_groupName', groupName);
        AppCookie.setCookie('setting_groupPath', groupPath);
        AppCookie.setCookie('setting_address', address);
        AppCookie.setCookie('setting_phone', phone);
        AppCookie.setCookie('setting_email', email);
        AppCookie.setCookie('setting_nameTele_lienhe', nameTele_lienhe);
        AppCookie.setCookie('setting_pathTele_lienhe', pathTele_lienhe);
        AppCookie.setCookie('setting_imageVIB', imageVIB);
        AppCookie.setCookie('setting_imageTCB', imageTCB);
        AppCookie.setCookie('setting_load_setting',1);
    }
}

export default new SettingModel;