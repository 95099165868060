import * as Yup from 'yup';
class FormCreateBillSchema {
    constructor() {
        this.type = '';
    }

    generateSchema(type) {
        let schema;
        if (type === "MB" || type === "ACB" || type === "ICB" || type === "TPB") {
            schema = Yup.object().shape({
                amount: Yup.string().required('Số tiền không được để trống'),
                hour: Yup.string().required('Giờ không được để trống'),
                day: Yup.string().required('Ngày không được để trống'),
                receiver_name: Yup.string().required('Tên người nhận không được để trống').matches(/^[A-Z\s]+$/, 'Tên người nhận chỉ được chứa ký tự viết hoa và khoảng trắng'),
                receiver_number: Yup.string().required('Số tài khoản người nhận không được để trống').matches(/^[0-9]+$/, 'Số tài khoản người nhận chỉ được chứa các ký tự từ 0 đến 9'),
                receiver_bank_name: Yup.string().required('Tên ngân hàng người nhận không được để trống'),
                transaction_code: Yup.string().required('Mã giao dịch không được để trống'),
                operatingSystem: Yup.string().required('Hệ điều hành không được để trống'),
                decreption: Yup.string()
                    .required('Mô tả không được để trống')
                    .max(150, 'Mô tả không được vượt quá 150 ký tự')
                    .matches(/^[\w\s]*$/, 'Mô tả không được chứa ký tự đặc biệt, chỉ được chứa chữ, số và khoảng trắng'),
                sender_name: Yup.string().required('Số tiền không được để trống').matches(/^[A-Z\s]+$/, 'Tên người nhận chỉ được chứa ký tự viết hoa và khoảng trắng'),
                sender_number: Yup.string().required('không được để trống').matches(/^[0-9]+$/, 'Số tài khoản người nhận chỉ được chứa các ký tự từ 0 đến 9'),
            });
        } else if (type === "MSB") {
            schema = Yup.object().shape({
                amount: Yup.string().required('Số tiền không được để trống'),
                hour: Yup.string().required('Giờ không được để trống'),
                day: Yup.string().required('Ngày không được để trống'),
                receiver_name: Yup.string().required('Tên người nhận không được để trống').matches(/^[A-Z\s]+$/, 'Tên người nhận chỉ được chứa ký tự viết hoa và khoảng trắng'),
                receiver_number: Yup.string().required('Số tài khoản người nhận không được để trống').matches(/^[0-9]+$/, 'Số tài khoản người nhận chỉ được chứa các ký tự từ 0 đến 9'),
                receiver_bank_name: Yup.string().required('Tên ngân hàng người nhận không được để trống'),
                transaction_code: Yup.string().required('Mã giao dịch không được để trống'),
                operatingSystem: Yup.string().required('Hệ điều hành không được để trống'),
                decreption: Yup.string()
                    .required('Mô tả không được để trống')
                    .max(150, 'Mô tả không được vượt quá 150 ký tự')
                    .matches(/^[\w\s]*$/, 'Mô tả không được chứa ký tự đặc biệt, chỉ được chứa chữ, số và khoảng trắng'),
                sender_name: Yup.string().required('Số tiền không được để trống')
                    .matches(/^[A-Z\s]+$/, 'Tên người nhận chỉ được chứa ký tự viết hoa và khoảng trắng'),
            });
        } else {
            schema = Yup.object().shape({
                amount: Yup.string().required('Số tiền không được để trống'),
                hour: Yup.string().required('Giờ không được để trống'),
                day: Yup.string().required('Ngày không được để trống'),
                receiver_name: Yup.string()
                    .required('Tên người nhận không được để trống')
                    .matches(/^[A-Z\s]+$/, 'Tên người nhận chỉ được chứa ký tự viết hoa và khoảng trắng'),
                receiver_number: Yup.string().required('Số tài khoản người nhận không được để trống').matches(/^[0-9]+$/, 'Số tài khoản người nhận chỉ được chứa các ký tự từ 0 đến 9'),
                receiver_bank_name: Yup.string().required('Tên ngân hàng người nhận không được để trống'),
                transaction_code: Yup.string().required('Mã giao dịch không được để trống'),
                operatingSystem: Yup.string().required('Hệ điều hành không được để trống'),
                decreption: Yup.string()
                    .required('Mô tả không được để trống')
                    .max(150, 'Mô tả không được vượt quá 150 ký tự')
                    .matches(/^[\w\s]*$/, 'Mô tả không được chứa ký tự đặc biệt, chỉ được chứa chữ, số và khoảng trắng'),
            });
        }
        return schema
    }
}
export default new FormCreateBillSchema;