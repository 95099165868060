import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import VerifyCode from '../../models/VerifyCode';
import AppNotification from '../../helpers/AppNotification';
import UserModel from '../../models/UserModel';
import Swal from "sweetalert2";
import ModalNoticationPass from './ModalNoticationPass';

const validationSchema = Yup.object().shape({
  verify_code: Yup.string()
    .required('Vui lòng nhập mã xác thực')
    .matches(/^\d{6}$/, 'Mã xác thực phải có đúng 6 chữ số')
});

function ModalChangePassWord(props) {
  const [modalShowNoti, setModalShowNoti] = React.useState(false);
  const [notication,setNotication] = useState();
  const handleCloseModalNoti = () => {
      setModalShowNoti(false);
  };
  const handleShowModalNoti = () => {
    setModalShowNoti(true);
  };
  const [formPass, setFormPass] = useState({
    verify_code: "",
    type: "PASSWORD",
  });
  const [formSecondpass, setFormSecondpass] = useState({
    verify_code: "",
    type: "SECONDPASS",
  });
  const handleSubmit = (values) => {
      console.log(values);
      UserModel.restore(values).then(function (res) {
          if (res.success == true) {
            setNotication(res.data);
            props.onHide();
            Swal.fire({
              icon: "success",
              title: "mật khẩu được khôi phục!",
              showConfirmButton: false,
              timer: 2000,
            });
            setModalShowNoti(true);
          }else {
            Swal.fire({
              icon: "error",
              title: res.data,
              showConfirmButton: false,
              timer: 2000,
            });
          }
          AppNotification.showSuccess(res.data);
      }).catch(function (err) {
          console.log(err);
          AppNotification.showError('Khôi Phục Mật Khẩu Thất Bại!');
      })
  };

  const sendOTP = () => {
      VerifyCode.getOTP({
        type: props.type
      }).then(function (res) {
        Swal.fire({
          icon: "success",
          title: "Mã OTP đã được gửi vào email của bạn!",
          showConfirmButton: false,
          timer: 2000,
        });
          AppNotification.showSuccess('Mã OTP đã được gửi vào email của bạn!');
      }).catch(function (err) {
        Swal.fire({
          icon: "error",
          title: "Không gửi được mã OTP!",
          showConfirmButton: false,
          timer: 2000,
        });
          console.log(err);
          AppNotification.showError('Lấy Mã OTP Thất Bại!');
      })
  }

  const initialValues = props.type === "PASSWORD" ? formPass : formSecondpass;
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.type === 'PASSWORD' ? 'Xác minh Đổi mật khẩu' : 'Xác minh Đổi mật khẩu Cấp 2'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Nhập mã xác thực để đổi mật khẩu.</h4>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="input-group mb-2">
                <Field type="text" className="form-control" name="verify_code" />
                <button className="btn btn-warning" type="button" onClick={sendOTP}>
                  Lấy mã
                </button>
                <button className="btn btn-primary" type="submit">
                  Khôi phục
                </button>
              </div>
              <ErrorMessage
                component="div"
                name="verify_code"
                className="text-danger"
              />
            </Form>
          </Formik>
          <p>Lưu ý:</p>
          <ul>
            <li>
              Nhấn <span className="text-warning">lấy OTP</span>, một mã xác minh được gửi vào email bạn đăng ký
            </li>
            <li>
              Lấy mã rồi nhập mã vào <span className="text-warning">Nhập mã xác thực...</span>
            </li>
            <li>Nhấn Khôi phục để hoàn tất!</li>
            <li className="text-success">Nếu không thấy mail OTP, vui lòng đọc trong tin nhắn SPAM!</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Đóng</Button>
        </Modal.Footer>
      </Modal>
      <ModalNoticationPass show={modalShowNoti} onHide={handleCloseModalNoti} notication={notication}/>
    </>
  );
}

export default ModalChangePassWord;