import axiosClient from "./axiosClient";
import AppCookie from '../helpers/AppCookie';

class UserBankAcountModel {
    constructor(){
        this.api_url = "/api/app/user-bank-account";
    }

    async getAllUserBankAcountForAdmin(credentials) {
      const res = await axiosClient.get(this.api_url + '/get-admin' , {
          params: {
            user_id: credentials.user_id,
          },
        });
      return res;
  }

    async getAllUserBankAcount(credentials) {
        const res = await axiosClient.get(this.api_url + '/all' , {
            params: {
              search: credentials.search,
              page: credentials.page,
              perPage: credentials.perPage
            },
          });
        return res;
    }

    async getUserBankAcount(credentials) {
        const res = await axiosClient.get("/api/app/user-bank-accounts" , {
            params: {
              type: credentials.type,
            },
          });
        return res;
    }

    async createUserBankAcount(credentials) {
        const res = await axiosClient.post(this.api_url, credentials);
        return res;
    }

  async updateUserBankAcount(credentials) {
      const res = await axiosClient.put(this.api_url, credentials);
      return res;
  }

  async delete(credentials) {
    const res = await axiosClient.delete(this.api_url + "/" + credentials);
    return res;
  }

}
export default new UserBankAcountModel;