import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import VerifyCode from '../../models/VerifyCode';
import AppNotification from '../../helpers/AppNotification';
import UserModel from '../../models/UserModel';
import PaymentModel from '../../models/PaymentModel';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../../redux/action';
import CurrencyInput from '../global/CurrencyInput';


const validationSchema = Yup.object().shape({
    amount: Yup.number()
        .required('Vui lòng nhập số tiền')
        .min(10000, 'Số tiền phải lớn hơn hoặc bằng 10.000đ'),
    verify_code: Yup.string()
        .required('Vui lòng nhập mã xác nhận')
        .matches(/^\d{6}$/, 'Mã xác nhận phải đúng 6 chữ số')
});

function ModalTransfer(props) {
    const dispatch = useDispatch();

    const initialValues = {
        account_source_id: 3,
        account_target_id: 1,
        amount: '',
        verify_code: ''
    };


    const handleSubmit = (values) => {
        PaymentModel.transfer(values).then(function (res) {
            if (res.success === true) {
                Swal.fire({
                    icon: "success",
                    title: "Yêu cầu rút tiền được gửi đi thành công thành công!",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                AppNotification.showSuccess(res.data);
                props.reloadPage();
            }else {
                Swal.fire({
                    icon: "error",
                    title: res.data,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        }).catch(function (err) {
            Swal.fire({
                icon: "error",
                title: 'Yêu cầu chuyển tiền Thất Bại!',
                showConfirmButton: false,
                timer: 2000,
              });
            console.log(err);
            AppNotification.showError('Yêu cầu chuyển tiền Thất Bại!');
        })
    };

    const sendOTP = () => {
        dispatch(setShowPreloader(true));
        VerifyCode.getOTP({
            type: "PAYMONEY"
        }).then(function (res) {
            dispatch(setShowPreloader(false));
            Swal.fire({
                icon: "success",
                title: "Mã OTP đã được gửi vào email của bạn!",
                showConfirmButton: false,
                timer: 2000,
              });
            AppNotification.showSuccess('Mã OTP đã được gửi vào email của bạn!');
        }).catch(function (err) {
            dispatch(setShowPreloader(false));
            Swal.fire({
                icon: "error",
                title: "Lấy Mã OTP Thất Bại!",
                showConfirmButton: false,
                timer: 2000,
              });
            AppNotification.showError('Lấy Mã OTP Thất Bại!');
        })
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Rút tiền về ngân hàng
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched, setFieldValue }) => (
                        <Form>
                            <div className="mb-3">
                                <label htmlFor="account_source_id" className="form-label">
                                    Tiền nguồn (*)
                                </label>
                                <Field
                                    as="select"
                                    id="account_source_id"
                                    name="account_source_id"
                                    className={`form-select ${errors.account_source_id && touched.account_source_id ? 'is-invalid' : ''
                                        }`}
                                    style={{
                                        backgroundColor: '#000936',
                                        border: '0.0625rem solid rgba(255, 255, 255, 0.25)'
                                    }}
                                >
                                    <option value="3">[REF] Tài khoản giới thiệu</option>
                                </Field>
                                {errors.account_source_id && touched.account_source_id && (
                                    <div className="invalid-feedback">{errors.account_source_id}</div>
                                )}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="account_target_id" className="form-label">
                                    Tiền đích (*)
                                </label>
                                <Field
                                    as="select"
                                    id="account_target_id"
                                    name="account_target_id"
                                    className={`form-select ${errors.account_target_id && touched.account_target_id ? 'is-invalid' : ''
                                        }`}
                                    style={{
                                        backgroundColor: '#000936',
                                        border: '0.0625rem solid rgba(255, 255, 255, 0.25)'
                                    }}
                                >
                                    <option value="1">[MAIN] Tài khoản chính</option>
                                </Field>
                                {errors.account_target_id && touched.account_target_id && (
                                    <div className="invalid-feedback">{errors.account_target_id}</div>
                                )}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="amount" className="form-label">
                                    Số tiền (*)
                                </label>
                                <CurrencyInput
                                    type="text"
                                    className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                        }`}
                                    autoFocus
                                    onChange={e => {
                                        let value = e.target.value || "";
                                        value = value.replaceAll(',','');
                                        setFieldValue("amount", value);
                                        }}
                                />
                                <Field
                                    type="hidden"
                                    id="amount"
                                    name="amount"
                                    className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                        }`}
                                    autoFocus
                                />
                                {errors.amount && touched.amount && (
                                    <div className="invalid-feedback">{errors.amount}</div>
                                )}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="verify_code" className="form-label">
                                    Xác nhận (*)
                                </label>
                                <div className="input-group">
                                    <Field
                                        type="text"
                                        id="verify_code"
                                        name="verify_code"
                                        className={`form-control ${errors.verify_code && touched.verify_code ? 'is-invalid' : ''
                                            }`}
                                        autoFocus
                                    />
                                    <button type="button" className="btn btn-outline-warning" onClick={sendOTP}>
                                        Lấy mã
                                    </button>
                                </div>
                                {errors.verify_code && touched.verify_code && (
                                    <div className="invalid-feedback">{errors.verify_code}</div>
                                )}
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={props.onHide}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-success">
                                    Xác nhận
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default ModalTransfer;