class BillGenerate {
    constructor() {
        this.platform = '';
        this.type = '';
    }

    getLogoBank(type) {
        return "https://api.vietqr.io/img/" + type + ".png"
    }

    generateBill(type, data, ctx, canvas) {
        data.amount = this.number_format(data.amount)
        console.log(data);
        switch (type) {
            case 'ACB':
                ctx = this.generateBillACB(data, ctx, canvas);
                break;
            case 'BIDV':
                ctx = this.generateBillBIDV(data, ctx, canvas);
                break;
            case 'ICB':
                ctx = this.generateBillICB(data, ctx, canvas);
                break;
            case 'MB':
                ctx = this.generateBillMB(data, ctx, canvas);
                break;
            case 'MSB':
                ctx = this.generateBillMSB(data, ctx, canvas);
                break;
            case 'STB':
                ctx = this.generateBillSTB(data, ctx, canvas);
                break;
            case 'TCB':
                ctx = this.generateBillTCB(data, ctx, canvas);
                break;
            case 'TPB':
                ctx = this.generateBillTPB(data, ctx, canvas);
                break;
            case 'VBA':
                ctx = this.generateBillVBA(data, ctx, canvas);
                break;
            case 'VCB':
                ctx = this.generateBillVCB(data, ctx, canvas);
                break;
            case 'VPB':
                ctx = this.generateBillVP(data, ctx, canvas);
                break;
            default:
                break;
        }
    }

    // Bank functions
    // ACB
    generateBillACB(data, ctx, canvas) {
        let top = 15
        let mobileHour = -10;
        if (data.operatingSystem == 'ios') {
            top = 50
            mobileHour = 5
        }

        // Giờ điện thoại
        ctx.font = '500 22px SFProDisplay';
        ctx.fillStyle = '#FFFFFF';
        ctx.fillText(data.hour + ':' + data.minute, 45, 40 + mobileHour);

        //Số tiền
        ctx.font = '700 35px Inter';
        ctx.fillStyle = '#1774C4';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.amount + ' VND', canvas.width / 2, 260 + top);

        //Số tiền bằng chữ
        ctx.font = '400 23px Inter';
        let amount = data.amount.replaceAll(',', '');
        let text_money = this.readMoneyNumber(amount)
        text_money = this.ucfirst(text_money) + ' đồng';
        var text_moneys = this.arrayChunk(text_money.split(' '), 9);

        ctx.fillStyle = '#000000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        for (let i = 0; i < text_moneys.length; i++) {
            let newLine = (i * 30) + top;
            ctx.fillText(text_moneys[i].join(' '), canvas.width / 2, 315 + newLine);
        }

        //Tên người gửi
        ctx.font = '700 28px Inter'; // Set the font style and size
        ctx.fillStyle = '#345DA4'; // Set the fill color
        ctx.textAlign = 'left'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        // ctx.fillText(data.sender_name, canvas.width - 430, 478 + top);
        let ten_nguoi_gui = data.sender_name;
        var ten_nguoi_guis = this.arrayChunk(ten_nguoi_gui.split(' '), 3);
        for (let i = 0; i < ten_nguoi_guis.length; i++) {
            let newLine = (i * 35) + top;
            ctx.fillText(ten_nguoi_guis[i].join(' '), 78, 780 + newLine);
        }
        var baseIndex = 0;
        if (this.countWords(ten_nguoi_gui) <= 3) {
            baseIndex = -30;
        }

        //Ngân hàng gửi tiền
        ctx.font = '400 28px Inter';
        ctx.fillStyle = '#345DA4';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        let ten_ngan_hang_gui = 'ACB - NH TMCP A CHAU';
        ctx.fillText(ten_ngan_hang_gui, 78, 850 + baseIndex + top);

        //Tài khoản người gửi tiền
        ctx.font = '400 28px Inter';
        ctx.fillStyle = '#345DA4';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.sender_number, 78, 890 + baseIndex + top);


        //Tên người thụ hưởng
        ctx.font = '500 26px Inter'; // Set the font style and size
        ctx.fillStyle = '#001D37'; // Set the fill color
        ctx.textAlign = 'right'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        // ctx.fillText(data.receiver_name, canvas.width - 430, 600 + top);
        let ten_nguoi_nhan = data.sender_name;
        var ten_nguoi_nhans = this.arrayChunk(ten_nguoi_nhan.split(' '), 3);
        for (let i = 0; i < ten_nguoi_nhans.length; i++) {
            let newLine = (i * 35) + top;
            ctx.fillText(ten_nguoi_nhans[i].join(' '), canvas.width - 48, 1001 + newLine);
        }

        //Ngân hàng thụ hưởng
        var baseIndex = 0;
        if (this.countWords(ten_nguoi_nhan) <= 3) {
            baseIndex = -30;
        }
        ctx.font = '500 26px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        let ten_ngan_hang = data.receiver_bank_name;
        var ten_ngan_hangs = this.arrayChunk(ten_ngan_hang.split(' '), 3);
        for (let i = 0; i < ten_ngan_hangs.length; i++) {
            let newLine = (i * 30) + top;
            ctx.fillText(ten_ngan_hangs[i].join(' '), canvas.width - 48, 1090 + baseIndex + newLine);
        }

        //Tài khoản thụ hưởng
        ctx.font = '400 25px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.receiver_number, canvas.width - 48, 1230 + baseIndex + top);

        // Ngày nhập lệnh
        ctx.font = '500 26px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(this.formatDate(data.day) + ', ' + data.hour + ':' + data.minute + ':14 ', canvas.width - 45, 506 + top);

        // Ngày hiệu lực
        ctx.font = '500 26px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(this.formatDate(data.day), canvas.width - 48, 565 + top);


        return ctx;
    }
    // BIDV
    generateBillBIDV(data, ctx, canvas) {
        console.log(data);
        let top = 90
        let mobileHour = -10;
        let trading = 100
        if (data.operatingSystem == 'ios') {
            top = 130
            mobileHour = 10
            trading = 130
        }

        // Giờ điện thoại
        ctx.font = '500 22px SFProDisplay';
        ctx.fillStyle = '#000000';
        ctx.fillText(data.hour + ':' + data.minute, 45, 40 + mobileHour);

        // Ma giao dich
        ctx.font = '400 23px Be Vietnam Pro';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('Số tham chiếu ' + data.transaction_code, canvas.width / 2, 910 + trading);

        //text chuyển khoản
        ctx.font = '500 25px Be Vietnam Pro';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#000000';
        ctx.textBaseline = 'middle';
        let text = 'Quý khách đã chuyển thành công số tiền' + data.amount + ' VND' + ' đến số tài khoản ' + data.receiver_number + '/ ' + data.receiver_name + '/ ' + data.receiver_bank_name + ' ' + 'vào lúc ' + this.formatDate(data.day) + ' ' + data.hour + ':' + data.minute + ':02 ' + '.Nội dung: ' + data.decreption;
        var texts = this.arrayChunk(text.split(' '), 6);
        for (let i = 0; i < texts.length; i++) {
            let newLine = (i * 40) + top;
            ctx.fillText(texts[i].join(' '), canvas.width / 2, 510 + newLine);
        }

        return ctx;
    }
    // ICB
    generateBillICB(data, ctx, canvas) {
        console.log(data);
        let top = 125
        let mobileHour = -10;
        if (data.operatingSystem == 'ios') {
            top = 125
            mobileHour = -3
        }

        // Giờ điện thoại
        ctx.font = '600 20px SFProDisplay';
        ctx.fillStyle = '#FFFFFF';
        ctx.fillText(data.hour + ':' + data.minute, 45, 40 + mobileHour);

        //Số tiền
        ctx.font = '700 23px Be Vietnam Pro';
        ctx.fillStyle = '#005B9B';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.amount + ' VND', canvas.width - 38, 765 + top);

        //Số tiền bằng chữ
        ctx.font = '700 23px Be Vietnam Pro';
        let amount = data.amount.replaceAll(',', '');
        let text_money = this.readMoneyNumber(amount)
        text_money = this.ucfirst(text_money) + ' đồng';

        // ctx.fillStyle = '#005B9B';
        // ctx.textAlign = 'right';
        // ctx.textBaseline = 'middle';
        // ctx.fillText(text_money, canvas.width - 38, 795 + top);
        
        var text_moneys = this.arrayChunk(text_money.split(' '), 9);
        ctx.fillStyle = '#005B9B';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        for (let i = 0; i < text_moneys.length; i++) {
            let newLine = (i * 25) + top;
            ctx.fillText(text_moneys[i].join(' '), canvas.width - 38, 790 + newLine);
        }
        


        //Tài khoản thụ hưởng
        ctx.font = '600 23px Be Vietnam Pro';
        ctx.fillStyle = '#09283D';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.receiver_number, canvas.width - 38, 575 + top);

        //Tài khoản người gửi tiền       
        const lastFourDigits = data.sender_number.slice(-4);
        const asterisksLength = Math.max(data.sender_number.length - 4, 0); // Đảm bảo không nhỏ hơn 0
        const asterisks = '*'.repeat(asterisksLength);
        const maskedNumber = asterisks + lastFourDigits;
        ctx.font = '400 22px Inter';
        ctx.fillStyle = '#09283D';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(maskedNumber, canvas.width - 38, 460 + top);



        //Tên người thụ hưởng
        ctx.font = '600 23px Be Vietnam Pro'; // Set the font style and size
        ctx.fillStyle = '#09283D'; // Set the fill color
        ctx.textAlign = 'right'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        ctx.fillText(data.receiver_name, canvas.width - 38, 605 + top);

        //Tên người gửi
        ctx.font = '400 22px Be Vietnam Pro'; // Set the font style and size
        ctx.fillStyle = '#09283D'; // Set the fill color
        ctx.textAlign = 'right'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        ctx.fillText(data.sender_name, canvas.width - 38, 488 + top);


        //Tài khoản thụ hưởng
        ctx.font = '400 23px Be Vietnam Pro';
        ctx.fillStyle = '#09283D';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        let ten_ngan_hang = data.receiver_bank_name;
        var ten_ngan_hangs = this.arrayChunk(ten_ngan_hang.split(' '), 5);
        for (let i = 0; i < ten_ngan_hangs.length; i++) {
            let newLine = (i * 25) + top;
            ctx.fillText(ten_ngan_hangs[i].join(' '), canvas.width - 38, 685 + newLine);
        }

        // Nội dung
        ctx.font = '400 22px Be Vietnam Pro';
        ctx.fillStyle = '#09283D';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        let noi_dung = data.decreption;
        var noi_dungs = this.arrayChunk(noi_dung.split(' '), 3);
        for (let i = 0; i < noi_dungs.length; i++) {
            let newLine = (i * 25) + top;
            ctx.fillText(noi_dungs[i].join(' '), canvas.width - 38, 945 + newLine);
        }
        // Thời gian chuyển
        ctx.font = '400 17px Be Vietnam Pro';
        ctx.fillStyle = '#8D979F';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(this.formatDate(data.day) + ' ' + data.hour + ':' + data.minute, canvas.width - 38, 110 + top);

        // Ma giao dich
        ctx.font = '400 17px Be Vietnam Pro';
        ctx.fillStyle = '#8D979F';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.transaction_code, canvas.width - 38, 130 + top);

        return ctx;
    }
    // MB
    generateBillMB(data, ctx, canvas) {
        console.log(data);
        let top = 0
        let mobileHour = -15;
        if (data.operatingSystem == 'ios') {
            top = 0
            mobileHour = 2
        }

        // Giờ điện thoại
        ctx.font = '600 22px SFProDisplay';
        ctx.fillStyle = '#000000';
        ctx.fillText(data.hour + ':' + data.minute, 45, 46 + mobileHour);

        //Số tiền
        ctx.font = '600 60px Be Vietnam Pro';
        ctx.fillStyle = '#141FD3';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.amount + ' VND', canvas.width / 2, 234 + top);

        // Chen logo
        var billLogo = document.getElementById("billLogo");
        if (billLogo) {
            ctx.drawImage(billLogo, 34, 450 + top, 47, 47);
        }

        //Tài khoản thụ hưởng
        ctx.font = '400 20px Be Vietnam Pro';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.receiver_number, 105, 476 + top);

        //Tài khoản người gửi tiền
        ctx.font = '400 20px Be Vietnam Pro';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.sender_number, canvas.width - 43, 608 + top);

        //Tên người thụ hưởng
        ctx.font = '600 25px Be Vietnam Pro'; // Set the font style and size
        ctx.fillStyle = '#000000'; // Set the fill color
        ctx.textAlign = 'left'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        ctx.fillText(data.receiver_name, 105, 447 + top);

        //Tên người gửi
        ctx.font = '600 22px Be Vietnam Pro'; // Set the font style and size
        ctx.fillStyle = '#000000'; // Set the fill color
        ctx.textAlign = 'right'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        ctx.fillText(data.sender_name, canvas.width - 43, 640 + top);


        //Tài khoản thụ hưởng
        ctx.font = '400 20px Be Vietnam Pro';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        let ten_ngan_hang = data.receiver_bank_name;
        var ten_ngan_hangs = this.arrayChunk(ten_ngan_hang.split(' '), 9);
        for (let i = 0; i < ten_ngan_hangs.length; i++) {
            let newLine = (i * 25) + top;
            ctx.fillText(ten_ngan_hangs[i].join(' '), 105, 502 + newLine);
        }

        // Nội dung
        ctx.font = '400 20px Be Vietnam Pro';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        let noi_dung = data.decreption;
        var noi_dungs = this.arrayChunk(noi_dung.split(' '), 3);
        for (let i = 0; i < noi_dungs.length; i++) {
            let newLine = (i * 20) + top;
            ctx.fillText(noi_dungs[i].join(' '), canvas.width - 43, 703 + newLine);
        }
        // Thời gian chuyển
        ctx.font = '400 20px Be Vietnam Pro';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.hour + ':' + data.minute + ':08,' + ' ' + this.formatDate(data.day), canvas.width - 43, 769 + top);

        // Ma giao dich
        ctx.font = '400 20px Be Vietnam Pro';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.transaction_code, canvas.width - 43, 894 + top);

        return ctx;
    }
    // MSB
    generateBillMSB(data, ctx, canvas) {
        console.log(data);
        let top = 3
        let mobileHour = -15;
        if (data.operatingSystem == 'ios') {
            top = 3
            mobileHour = 0;
        }
        // Code here
        // Giờ điện thoại
        ctx.font = '600 22px SFProDisplay';
        ctx.fillStyle = '#000000';
        ctx.fillText(data.hour + ':' + data.minute, 45, 45 + top + mobileHour);

        //Tên người gửi
        ctx.font = '600 20px Inter'; // Set the font style and size
        ctx.fillStyle = '#000000'; // Set the fill color
        ctx.textAlign = 'right'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        ctx.fillText(data.sender_name, canvas.width - 45, 550 + top);

        //Số tiền
        ctx.font = '600 20px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.amount + ' VND', canvas.width - 45, 622 + top);

        // Thời gian chuyển
        ctx.font = '600 20px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.hour + ':' + data.minute + ' ' + this.formatDate(data.day), canvas.width - 45, 930 + top);

        //Tên người thụ hưởng
        ctx.font = '600 20px Inter'; // Set the font style and size
        ctx.fillStyle = '#000000'; // Set the fill color
        ctx.textAlign = 'right'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        ctx.fillText(data.receiver_name, canvas.width - 45, 745 + top);

        //Tài khoản thụ hưởng
        ctx.font = '600 20px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.receiver_number, canvas.width - 45, 683 + top);

        // Nội dung
        ctx.font = '600 20px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        let noi_dung = data.decreption;
        var noi_dungs = this.arrayChunk(noi_dung.split(' '), 3);
        for (let i = 0; i < noi_dungs.length; i++) {
            let newLine = (i * 25) + top;
            ctx.fillText(noi_dungs[i].join(' '), canvas.width - 45, 840 + newLine);
        }

        return ctx;
    }
    // STB
    generateBillSTB(data, ctx, canvas) {
        console.log(data);
        let mobileHour = -15;
        let top = -40
        if (data.operatingSystem == 'ios') {
            top = -5
            mobileHour = 4;
        }
        // Code here
        // Giờ điện thoại
        ctx.font = '600 22px SFProDisplay';
        ctx.fillStyle = '#FFFFFF';
        ctx.fillText(data.hour + ':' + data.minute, 43, 45 + mobileHour);

        //Số tiền
        ctx.font = '700 27px Inter';
        ctx.fillStyle = '#023766';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('-' + data.amount + 'đ', canvas.width / 2, 540 + top);

        // Ma giao dich
        ctx.font = '500 26px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.transaction_code, canvas.width - 28, 671 + top);

        //Tài khoản thụ hưởng
        ctx.font = '400 28px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.receiver_number + ' (' + data.receiver_name + ')', canvas.width / 2, 495 + top);

        // Thời gian chuyển
        ctx.font = '300 28px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.hour + ':' + data.minute + ':25' + ' ' + this.formatDate(data.day), canvas.width / 2, 585 + top);

        //Tài khoản thụ hưởng
        ctx.font = '500 26px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        let loai_giao_dich = 'Chuyển tiền từ TK Sacombank đến TK NH nội địa';
        var loai_giao_dichs = this.arrayChunk(loai_giao_dich.split(' '), 5);
        for (let i = 0; i < loai_giao_dichs.length; i++) {
            let newLine = (i * 35) + top;
            ctx.fillText(loai_giao_dichs[i].join(' '), canvas.width - 28, 732 + newLine);
        }
        //Tài khoản người chyển tiền
        ctx.font = '500 26px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.sender_number, canvas.width - 28, 832 + top);

        //Tài khoản người nhận
        ctx.font = '500 26px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.receiver_number, canvas.width - 28, 895 + top);

        //Tên ngân hàng nhận
        ctx.font = '500 26px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.receiver_bank_name, canvas.width - 28, 956 + top);

        // Nội dung
        ctx.font = '500 26px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        let noi_dung = data.decreption;
        var noi_dungs = this.arrayChunk(noi_dung.split(' '), 5);
        for (let i = 0; i < noi_dungs.length; i++) {
            let newLine = (i * 25) + top;
            ctx.fillText(noi_dungs[i].join(' '), canvas.width - 28, 1012 + newLine);
        }

        //Số tiền
        ctx.font = '500 26px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.amount, canvas.width - 28, 1081 + top);

        //Phí giao dịch
        ctx.font = '500 26px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText('0', canvas.width - 28, 1146 + top);

        //Tổng số tiền
        ctx.font = '700 26px Inter';
        ctx.fillStyle = '#001D37';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.amount, canvas.width - 28, 1206 + top);

        return ctx;
    }
    // TCB
    generateBillTCB(data, ctx, canvas) {
        console.log(data);
        let top = 78
        let mobileHour = -6;
        if (data.operatingSystem == 'ios') {
            top = 115
            mobileHour = 8
        }
        // Code here
        // Giờ điện thoại
        ctx.font = '500 22px SFProDisplay';
        ctx.fillStyle = '#000000';
        ctx.fillText(data.hour + ':' + data.minute, 46, 40 + mobileHour);

        //Tên người thụ hưởng
        ctx.font = '600 45px Inter'; // Set the font style and size
        ctx.fillStyle = '#000000'; // Set the fill color
        ctx.textAlign = 'left'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        ctx.fillText(data.receiver_name, 93, 410 + top);

        //Số tiền
        ctx.font = '600 40px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.amount, 120, 460 + top);

        //Tài khoản thụ hưởng
        ctx.font = '600 27px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        let ten_ngan_hang = data.receiver_bank_name;
        var ten_ngan_hangs = this.arrayChunk(ten_ngan_hang.split(' '), 6);
        let addLine = 0;
        for (let i = 0; i < ten_ngan_hangs.length; i++) {
            let newLine = (i * 30) + top;
            addLine = i * 27;
            ctx.fillText(ten_ngan_hangs[i].join(' '), 30, 570 + newLine);
        }

        //Tài khoản thụ hưởng
        ctx.font = '600 29px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.receiver_number, 30, 605 + top + addLine);

        // Nội dung
        ctx.font = '600 29px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        let noi_dung = data.decreption;
        var noi_dungs = this.arrayChunk(noi_dung.split(' '), 6);
        for (let i = 0; i < noi_dungs.length; i++) {
            let newLine = (i * 35) + top;
            ctx.fillText(noi_dungs[i].join(' '), canvas.width - 608, 705 + newLine);
        }

        // Thời gian chuyển
        ctx.font = '600 29px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        const date = new Date(data.day);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        ctx.fillText(day + ' tháng ' + month + ', ' + year + ' lúc ' + data.hour + ':' + data.minute, canvas.width - 608, 814 + top);

        // Ma giao dich
        ctx.font = '600 28px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.transaction_code, canvas.width - 608, 920 + top);
        return ctx;
    }
    // TPB
    // TPB
    generateBillTPB(data, ctx, canvas) {
        console.log(data);
        let top = -10
        let mobileHour = -5
        if (data.operatingSystem == 'ios') {
            top = 23
            mobileHour = 10
        }
        // Code here
        // Giờ điện thoại
        ctx.font = '500 22px SFProDisplay';
        ctx.fillStyle = '#FFFFFF';
        ctx.fillText(data.hour + ':' + data.minute, 45, 35 + mobileHour);

        //Tên người gửi
        ctx.font = '500 20px Inter'; // Set the font style and size
        ctx.fillStyle = '#000000'; // Set the fill color
        ctx.textAlign = 'left'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        ctx.fillText(data.sender_name, 125, 513 + top);

        //Tài khoản người gửi tiền
        ctx.font = '400 18px Inter';
        ctx.fillStyle = '#797C97';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.sender_number, 125, 546 + top);

        //Số tiền
        ctx.font = '300 50px Inter';
        ctx.fillStyle = '#2B1F48';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.amount + ' VND', canvas.width / 2, 373 + top);

        // Ma giao dich
        ctx.font = '500 20px Inter';
        ctx.fillStyle = '#2C2048';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.transaction_code, canvas.width - 47, 720 + top);


        // Nội dung
        ctx.font = '500 20px Inter';
        ctx.fillStyle = '#2C2048';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        let noi_dung = data.decreption;
        var noi_dungs = this.arrayChunk(noi_dung.split(' '), 6);
        for (let i = 0; i < noi_dungs.length; i++) {
            let newLine = (i * 35) + top;
            ctx.fillText(noi_dungs[i].join(' '), canvas.width - 47, 795 + newLine);
        }

        // Thời gian chuyển
        ctx.font = '500 20px Inter';
        ctx.fillStyle = '#2C2048';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.hour + ':' + data.minute + ':13' + ', ' + 'Ngày' + ' ' + this.formatDate(data.day), canvas.width - 47, 872 + top);

        //Tên người thụ hưởng
        ctx.font = '500 20px Inter'; // Set the font style and size
        ctx.fillStyle = '#000000'; // Set the fill color
        ctx.textAlign = 'left'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        ctx.fillText(data.receiver_name, 125, 618 + top);

        // Logo người nhận
        var billLogo = document.getElementById("billLogo");
        if (billLogo) {
            ctx.drawImage(billLogo, 49, 597 + top, 60, 60);
        }

        //Tài khoản thụ hưởng
        ctx.font = '400 18px Inter';
        ctx.fillStyle = '#797C97';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.receiver_number + ' | ' + data.shortname, 125, 650 + top);
        console.log(canvas.width);
        // let ten_ngan_hang = data.receiver_bank_name;
        // var ten_ngan_hangs = this.arrayChunk(ten_ngan_hang.split(' '), 6);
        // for (let i = 0; i < ten_ngan_hangs.length; i++) {
        //     let newLine = (i * 25) + top;
        //     ctx.fillText(data.receiver_number + ' | ' + ten_ngan_hangs[i].join(' '), 115, 650 + newLine);
        // }
        return ctx;
    }
    // VBA
    generateBillVBA(data, ctx, canvas) {
        console.log(data);
        let top = 80
        let mobileHour = -15;
        let bankName = 10;
        let timeBank = 15;
        let tradingCode = 5;
        let transferContent = 4;
        if (data.operatingSystem == 'ios') {
            top = 120
            mobileHour = 7
            bankName = 2;
            timeBank = 0;
            tradingCode = 0;
            transferContent = 0;
        }

        // Giờ điện thoại
        ctx.font = '500 20px SFProDisplay';
        ctx.fillStyle = '#000000';
        ctx.fillText(data.hour + ':' + data.minute, 45, 40 + mobileHour);

        //Số tiền
        ctx.font = '800 35px Inter';
        ctx.fillStyle = '#FFFFFF';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.amount + ' VND', 61, 265 + top);

        // Thời gian chuyển
        ctx.font = '400 28px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.hour + ':' + data.minute + ' ' + this.formatDate(data.day), 55, 1220 + top + timeBank);

        //Tên người thụ hưởng
        ctx.font = '500 28px Inter'; // Set the font style and size
        ctx.fillStyle = '#E26F12'; // Set the fill color
        ctx.textAlign = 'left'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        ctx.fillText(data.receiver_name, 55, 530 + top);

        //Tài khoản thụ hưởng
        ctx.font = '400 28px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.receiver_number, 55, 400 + top);

        //Tài khoản thụ hưởng
        ctx.font = '400 28px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        let ten_ngan_hang = data.receiver_bank_name;
        var ten_ngan_hangs = this.arrayChunk(ten_ngan_hang.split(' '), 6);
        for (let i = 0; i < ten_ngan_hangs.length; i++) {
            let newLine = (i * 25) + top;
            ctx.fillText(ten_ngan_hangs[i].join(' '), 55, 800 + newLine + bankName);
        }

        // Ma giao dich
        ctx.font = '400 28px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.transaction_code, 55, 665 + top + tradingCode);

        // Nội dung
        ctx.font = '400 28px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        let noi_dung = data.decreption;
        var noi_dungs = this.arrayChunk(noi_dung.split(' '), 5);
        for (let i = 0; i < noi_dungs.length; i++) {
            let newLine = (i * 35) + top;
            ctx.fillText(noi_dungs[i].join(' '), 55, 950 + newLine + transferContent);
        }
        return ctx;
    }
    // VCB
    generateBillVCB(data, ctx, canvas) {
        console.log(data);
        let top = -31
        let mobileHour = -10;
        if (data.operatingSystem == 'ios') {
            top = 1
            mobileHour = 1
        }

        // Giờ điện thoại
        ctx.font = '500 22px SFProDisplay';
        ctx.fillStyle = '#FFFFFF';
        ctx.fillText(data.hour + ':' + data.minute, 30, 40 + mobileHour);

        //Số tiền
        ctx.font = '500 40px SFProDisplay';
        ctx.fillStyle = '#72BF00';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.amount + ' VND', canvas.width / 2, 394 + top);

        // Thời gian chuyển
        ctx.font = '500 19px SFProDisplay';
        ctx.fillStyle = '#858E92';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.hour + ':' + data.minute + ' ' + this.formatThu(data.day) + ' ' + this.formatDate(data.day), canvas.width / 2, 445 + top);

        //Tên người thụ hưởng
        ctx.font = '500 22px SFProDisplay'; // Set the font style and size
        ctx.fillStyle = '#FFFFFF'; // Set the fill color
        ctx.textAlign = 'right'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        ctx.fillText(data.receiver_name, canvas.width - 33, 525 + top);

        //Tài khoản thụ hưởng
        ctx.font = '450 22px SFProDisplay';
        ctx.fillStyle = '#FFFFFF';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.receiver_number, canvas.width - 33, 607 + top);

        //Tài khoản thụ hưởng
        ctx.font = '400 22px SFProDisplay';
        ctx.fillStyle = '#FFFFFF';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        let ten_ngan_hang = data.receiver_bank_name;
        var ten_ngan_hangs = this.arrayChunk(ten_ngan_hang.split(' '), 6);
        for (let i = 0; i < ten_ngan_hangs.length; i++) {
            let newLine = (i * 35) + top;
            ctx.fillText(ten_ngan_hangs[i].join(' '), canvas.width - 33, 692 + newLine);
        }

        // Ma giao dich
        ctx.font = '450 22px SFProDisplay';
        ctx.fillStyle = '#FFFFFF';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.transaction_code, canvas.width - 33, 803 + top);

        // Nội dung
        ctx.font = '450 22px SFProDisplay';
        ctx.fillStyle = '#FFFFFF';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        let noi_dung = data.decreption;
        var noi_dungs = this.arrayChunk(noi_dung.split(' '), 6);
        for (let i = 0; i < noi_dungs.length; i++) {
            let newLine = (i * 30) + top;
            ctx.fillText(noi_dungs[i].join(' '), canvas.width - 33, 880 + newLine);
        }

        return ctx;
    }
    // VP
    generateBillVP(data, ctx, canvas) {
        console.log(data);
        let top = -50
        let mobileHour = -12;
        var color_hour = "#FFFFFF"
        if (data.operatingSystem == 'ios') {
            top = -5
            mobileHour = -3
            color_hour = "#FFFFFF"
        }
        if (data.operatingSystem == 'android') {
            mobileHour = -12
            color_hour = "#000000"
        }
        // Code here
        // Giờ điện thoại
        ctx.font = '600 22px SFProDisplay';
        ctx.fillStyle = color_hour;
        ctx.fillText(data.hour + ':' + data.minute, 30, 50 + mobileHour);

        //Số tiền
        ctx.font = '600 38px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        data.amount = data.amount.replaceAll(',', ' ');
        ctx.fillText(data.amount, 125, 363 + top);

        let soTienWidth = this.getTextWidth(data.amount, ctx.font);
        // Chèn kí hiệu VND
        let billSign = document.getElementById('billSign');
        if(billSign){
            ctx.drawImage(billSign, 140 + soTienWidth, 343 + top, 27, 35);
        }


        // Thời gian chuyển
        ctx.font = '600 22px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(this.formatDate(data.day), canvas.width - 45, 655 + top);

        //Tên người thụ hưởng
        ctx.font = '500 22px Inter'; // Set the font style and size
        ctx.fillStyle = '#000000'; // Set the fill color
        ctx.textAlign = 'left'; // Set the text alignment
        ctx.textBaseline = 'middle'; // Set the vertical alignment
        ctx.fillText(data.receiver_name, 117, 520 + top);

        //Tài khoản thụ hưởng
        ctx.font = '400 22px Inter';
        ctx.fillStyle = '#909493';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(data.receiver_number, 117, 555 + top);

        // Logo người nhận
        var billLogo = document.getElementById("billLogo");
        var toplogo = 503;
        if (data.operatingSystem == 'android') {
           toplogo = 458
        }
        if(billLogo){
            console.log(billLogo);
            ctx.drawImage(billLogo, 46, toplogo, 60, 60);
        }

        //Tài khoản thụ hưởng
        ctx.font = '400 28px SFProDisplay';
        ctx.fillStyle = '#FFFFFF';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        let ten_ngan_hang = data.receiver_bank_name;
        var ten_ngan_hangs = this.arrayChunk(ten_ngan_hang.split(' '), 3);
        for (let i = 0; i < ten_ngan_hangs.length; i++) {
            let newLine = (i * 35) + top;
            ctx.fillText(ten_ngan_hangs[i].join(' '), canvas.width - 20, 720 + newLine);
        }

        // Ma giao dich
        ctx.font = '600 22px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        if (data.operatingSystem == 'ios') {
            ctx.fillText(data.transaction_code, canvas.width - 45, 918 + top);
        } else {
            ctx.fillText(data.transaction_code, canvas.width - 45, 920 + top);
        }

        // Nội dung
        ctx.font = '600 20px Inter';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        let noi_dung = data.decreption;
        var noi_dungs = this.arrayChunk(noi_dung.split(' '), 4);
        for (let i = 0; i < noi_dungs.length; i++) {
            let newLine = (i * 25) + top;
            ctx.fillText(noi_dungs[i].join(' '), canvas.width - 45, 735 + newLine);
        }

        return ctx;
    }

    // Helper functions
    formatThu(dateString) {
        const daysOfWeek = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'];
        const date = new Date(dateString);
        const dayOfWeek = date.getDay();
        return daysOfWeek[dayOfWeek];
    }
    formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
        return formattedDate;
    }
    arrayChunk(array, size) {
        var chunks = [];
        for (var i = 0; i < array.length; i += size) {
            var chunk = array.slice(i, i + size);
            chunks.push(chunk);
        }
        return chunks;
    }
    number_format(number, decimals, dec_point, thousands_sep) {
        // Strip all characters but numerical ones.
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    // Đọc số thành chữ
    readMoneyNumber(number) {
        var hyphen = ' ';
        var conjunction = '  ';
        var separator = ' ';
        var negative = 'âm ';
        var decimal = ' phẩy ';
        var dictionary = {
            0: 'không',
            1: 'Một',
            2: 'Hai',
            3: 'Ba',
            4: 'Bốn',
            5: 'Năm',
            6: 'Sáu',
            7: 'Bảy',
            8: 'Tám',
            9: 'Chín',
            10: 'Mười',
            11: 'Mười một',
            12: 'Mười hai',
            13: 'Mười ba',
            14: 'Mười bốn',
            15: 'Mười năm',
            16: 'Mười sáu',
            17: 'Mười bảy',
            18: 'Mười tám',
            19: 'Mười chín',
            20: 'Hai mươi',
            30: 'Ba mươi',
            40: 'Bốn mươi',
            50: 'Năm mươi',
            60: 'Sáu mươi',
            70: 'Bảy mươi',
            80: 'Tám mươi',
            90: 'Chín mươi',
            100: 'trăm',
            1000: 'nghìn',
            1000000: 'triệu',
            1000000000: 'tỷ',
            1000000000000: 'nghìn tỷ',
            1000000000000000: 'nghìn triệu triệu',
            1000000000000000000: 'tỷ tỷ'
        };

        if (isNaN(number) || !isFinite(number)) {
            return false;
        }

        if (number < 0) {
            return negative + this.readMoneyNumber(Math.abs(number));
        }
        var string = '';
        var fraction = null;
        if (number % 1 !== 0) {
            var numberParts = number.toString().split('.');
            number = parseInt(numberParts[0]);
            fraction = parseInt(numberParts[1]);
        }
        if (number < 21) {
            string = dictionary[number];
        } else if (number < 100) {
            var tens = Math.floor(number / 10) * 10;
            var units = number % 10;
            string = dictionary[tens];
            if (units) {
                string += hyphen + dictionary[units];
            }
        } else if (number < 1000) {
            var hundreds = Math.floor(number / 100);
            var remainder = number % 100;
            string = dictionary[hundreds] + ' ' + dictionary[100];
            if (remainder) {
                string += conjunction + this.readMoneyNumber(remainder);
            }
        } else {
            var baseUnit = Math.pow(1000, Math.floor(Math.log(number) / Math.log(1000)));
            var numBaseUnits = Math.floor(number / baseUnit);
            var remainder = number % baseUnit;
            string = this.readMoneyNumber(numBaseUnits) + ' ' + dictionary[baseUnit];
            if (remainder) {
                string += remainder < 100 ? conjunction : separator;
                string += this.readMoneyNumber(remainder);
            }
        }
        if (fraction !== null && !isNaN(fraction)) {
            string += decimal;
            var words = [];
            fraction.toString().split('').forEach(function (digit) {
                words.push(dictionary[parseInt(digit)]);
            });
            string += words.join(' ');
        }

        return string.replace(/\s{2,}/g, ' ');
    }
    // Viết hoa chữ cái đầu
    ucfirst(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    countWords(text) {
        text = text.trim();
        let words = text.split(/\s+/);
        return words.length;
    }
    getTextWidth(text, font) {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        context.font = font;
        var metrics = context.measureText(text);
        return metrics.width;
    }


}

export default new BillGenerate;