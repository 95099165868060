import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import VerifyCode from '../../models/VerifyCode';
import AppNotification from '../../helpers/AppNotification';
import UserModel from '../../models/UserModel';
import Swal from "sweetalert2";

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Không được để trống!').email('Email phải đúng định dạng!'),
    verify_code: Yup.string()
        .matches(/^\d{6}$/, 'Mã xác thực phải có đúng 6 chữ số')
});

function ModalUpdateEmail(props) {
    const [initialValues, setInitialValues] = useState({
        email: '',
        verify_code: '',
    });

    const handleChange = (event) => {
        setInitialValues({
            ...initialValues,
            [event.target.name]: event.target.value
        });
    };

    const [modalShowNoti, setModalShowNoti] = React.useState(false);
    const [notication, setNotication] = useState();
    const handleCloseModalNoti = () => {
        setModalShowNoti(false);
    };
    const handleShowModalNoti = () => {
        setModalShowNoti(true);
    };

    const handleSubmit = (values) => {
        UserModel.updateEmail(values).then(function (res) {
            if (res.success == true) {
                Swal.fire({
                    icon: "success",
                    title: "Email đã được cập nhật!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                setTimeout(() => {
                    reloadPage();
                }, 2000);
            } else {
                Swal.fire({
                    icon: "error",
                    title: res.data,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        }).catch(function (err) {
            Swal.fire({
                icon: "error",
                title: 'Email cập nhật thất bại!',
                showConfirmButton: false,
                timer: 2000,
            });
            console.log(err);
            AppNotification.showError('Khôi Phục Mật Khẩu Thất Bại!');
        })
    };

    const sendOTP = (email) => {
        VerifyCode.getOTP({
            type: "CHANGEMAIL",
            email: email,
          }).then(function (res) {
            Swal.fire({
                icon: "success",
                title: "Mã OTP đã được gửi vào email của bạn!",
                showConfirmButton: false,
                timer: 2000,
            });
            AppNotification.showSuccess('Mã OTP đã được gửi vào email của bạn!');
        }).catch(function (err) {
            Swal.fire({
                icon: "error",
                title: "Không gửi được mã OTP!",
                showConfirmButton: false,
                timer: 2000,
            });
            console.log(err);
            AppNotification.showError('Lấy Mã OTP Thất Bại!');
        })
    }

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Thay đổi Email
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        <Form>
                            <div className='row'>
                                <div className='col-12'>
                                    <h4 className='text-warning'>Bước 1 : Nhập email mới</h4>
                                    <div className="input-group mb-2">
                                        <Field type="text" onChange={handleChange} placeholder="Nhập email mới..." className="form-control" name="email" />
                                        <button className="btn btn-warning" type="button" onClick={() => sendOTP(initialValues.email)}>
                                            Lấy mã
                                        </button>
                                    </div>
                                    <ErrorMessage
                                        component="div"
                                        name="email"
                                        className="text-danger"
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <h4 className='text-warning'>Bước 2 : Nhập mã xác minh</h4>
                                    <div className="input-group mb-2">
                                        <Field type="text" placeholder="Nhập mã xác minh..." className="form-control" name="verify_code" />
                                        <button className="btn btn-primary" type="submit">
                                            Cập Nhật
                                        </button>
                                    </div>
                                    <ErrorMessage
                                        component="div"
                                        name="verify_code"
                                        className="text-danger"
                                    />
                                </div>
                            </div>
                        </Form>
                    </Formik>
                    <p>Lưu ý:</p>
                    <ul>
                        <li>
                            <span className="text-warning">Bước 1:</span> nhập email mới và nhấn nút <span className="text-warning">lấy mã</span> , Sẽ có 1 mã OTP được gửi vào <span className='text-warning'>email cũ</span> của bạn
                        </li>
                        <li>
                            <span className="text-warning">Bước 2:</span> <span className='text-primary'>Mở hộp thư email cũ</span> để lấy mã OTP, sau đó nhập mã OTP và nhấn nút <span className="text-warning">Cập nhật</span>
                        </li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Đóng</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalUpdateEmail;