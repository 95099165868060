import React from 'react';
import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import { useSelector } from 'react-redux';

function Contact(props) {

    const sytemSettings = useSelector((state) => state.system_setting);
    
    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Liên hệ" />
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Liên Hệ</h4>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-12">
                                            <div className="widget-stat card">
                                                <div className="card-body p-4">
                                                    <div className="media ai-icon">
                                                        <span className="me-3 bgl-primary text-primary">
                                                            {/* <i className="ti-user"></i> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-building-add" viewBox="0 0 16 16">
                                                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0" />
                                                                <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6.5a.5.5 0 0 1-1 0V1H3v14h3v-2.5a.5.5 0 0 1 .5-.5H8v4H3a1 1 0 0 1-1-1z" />
                                                                <path d="M4.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z" />
                                                            </svg>
                                                        </span>
                                                        <div className="media-body">
                                                            <p className="mb-1">ĐỊA CHỈ</p>
                                                            <strong className="mb-0 text-white">{ sytemSettings?.address ?? ''}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-12">
                                            <div className="widget-stat card">
                                                <div className="card-body p-4">
                                                    <div className="media ai-icon">
                                                        <span className="me-3 bgl-primary text-primary">
                                                            {/* <i className="ti-user"></i> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-forward-fill" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zm10.761.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708" />
                                                            </svg>
                                                        </span>
                                                        <div className="media-body">
                                                            <p className="mb-1">SỐ ĐIỆN THOẠI</p>
                                                            <strong className="mb-0 text-white">{ sytemSettings?.phone ?? ''}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-12">
                                            <div className="widget-stat card">
                                                <div className="card-body p-4">
                                                    <div className="media ai-icon">
                                                        <span className="me-3 bgl-primary text-primary">
                                                            {/* <i className="ti-user"></i> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                                            </svg>
                                                        </span>
                                                        <div className="media-body">
                                                            <p className="mb-1">Email</p>
                                                            <strong className="mb-0 text-white">{ sytemSettings?.email ?? ''}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-12">
                                            <div className="widget-stat card">
                                                <div className="card-body p-4">
                                                    <div className="media ai-icon">
                                                        <span className="me-3 bgl-primary text-primary">
                                                            {/* <i className="ti-user"></i> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telegram" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                                                            </svg>
                                                        </span>
                                                        <div className="media-body">
                                                            <p className="mb-1">Telegram</p>
                                                            <a href={ sytemSettings?.pathTele_lienhe ?? ''} target="_blank"><strong className="mb-0 text-warning">Nhấn vào : { sytemSettings?.nameTele_lienhe ?? ''}</strong></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MasterLayout>
    );
}

export default Contact;