import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import AppRoutes from "./route";
import { useEffect } from "react";
import AppNotification from "./helpers/AppNotification";
import DeviceModel from "./models/DeviceModel";
import AppCookie from "./helpers/AppCookie";
import AuthMoldel from "./models/AuthMoldel";
import Swal from 'sweetalert2';

function App() {
  // lấy thông tin máy và trình duyệt
  const browserInfo = navigator.userAgent;
  console.log("Thông tin trình duyệt:", browserInfo);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
        var isLoggedin = AuthMoldel.checkAuth();
          if (isLoggedin) {
          // lấy thông tin máy và trình duyệt
          const browserInfo = navigator.userAgent;
          DeviceModel.getDevice({
            browserInfo: browserInfo
          })
            .then(res => {
              if (res.success === false) {
                AuthMoldel.logout({
                  browserInfo: browserInfo
                }).then((res) => {
                  AppCookie.deleteCookie('token');
                  AppCookie.deleteCookie('user');
                  window.location.href = '/login';
                }).catch((err) => {
                  console.log(err);
                });
    
                Swal.fire({
                  icon: "error",
                  title: "Tài Khoản đã đăng nhập trên thiết bị khác",
                  showConfirmButton: false,
                  timer: 2000,
                });
                clearInterval(intervalId);
              }
            }).catch(err => {
              console.log(err);
              AppNotification.showError('Lấy dữ liệu thất bại!');
            })
          }
        }, 5000); // Gọi hàm mỗi 5 giây
    
        return () => clearInterval(intervalId); // Xóa interval khi component unmount 
    });
  
  return (
    <AppRoutes />
  );
}

export default App;
