import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import BankItemBill from '../components/bill/BankItemBill';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../redux/action';
import BankInfoBill from '../models/BankInfoBill';
import AppNotification from '../helpers/AppNotification';
import QuestionAcordition from '../components/bill/QuestionAcordition';
import FormCreateBill from '../components/bill/FormCreateBill';
import { Tooltip } from 'react-tooltip';
import BankItemFackNoti from '../components/fakeNotications/BankItemFackNoti';



function FakeNotication(props) {
    const [bankInfoBill, setBankInfoBill] = useState([]);
    const [packageBill, setPackageBill] = useState({});
    const [userPackageBill, setUserPackageBill] = useState({});
    const dispatch = useDispatch();
    const [showFormCreateBill, setShowFormCreateBill] = useState();
    const [bankName, setBankName] = useState();
    const [imageBillIOS, setImageBillIOS] = useState();
    const [imageBillAndroid, setImageBillAndroid] = useState();
    const [typeBank, setTypeBank] = useState();
    useEffect(() => {
        setShowFormCreateBill(0); // Set lại giá trị của showFormCreateBill thành 0 mỗi khi component được tải lại
        setBankName('');
        setImageBillIOS('');
        setImageBillAndroid('');
        setTypeBank('');
    }, []);
    const handleShowForm = (bankName, imageBillIOS, imageBillAndroid, typeBank) => {
        setBankName(bankName);
        setImageBillIOS(imageBillIOS);
        setImageBillAndroid(imageBillAndroid);
        setTypeBank(typeBank);
        setShowFormCreateBill(1);
    }
    const handleCloseForm = () => {
        setShowFormCreateBill(0);
    }
    
    return (
        <MasterLayout>
            <>
                <ThemeBreadcrumb title="Fake thông báo" />
                <div className='row'>
                    <div className='col-xl-12'>
                        <div className='row'>
                            <div className="col-xl-12 col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Fake thông báo Ngân hàng và Tin Nhắn( Messenger, Telegram, SMS)</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-xl-4 col-xxl-4 col-lg-4 col-sm-12'>
                                                {/* <BankItemFackNoti handleShowForm={() => handleShowForm(item.name, item.image_bill_fake_ios, item.image_bill_fake_android, item.type)} item={item} /> */}
                                                <BankItemFackNoti />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </MasterLayout>
    );
}

export default FakeNotication;