import React from 'react';
import ButtonSuppot from './ButtonSuppot';

function Footer(props) {
    return (
        <div>
            <ButtonSuppot/>
        </div>
    );
}

export default Footer;