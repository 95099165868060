import React, { useEffect, useState } from 'react';
import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import HomeCardMoney from '../components/home/HomeCardMoney';
import HomeModel from '../models/HomeModel';
import AppNotification from '../helpers/AppNotification';
import HomeCardInfo from '../components/home/HomeCardInfo';
import NewModel from '../models/NewsModel';
import CardNews from '../components/home/CardNews';
import VietQrModel from '../models/VietQrModel';

function HomePage(props) {
    const [data,setData] = useState([]);
    const [news,setNews] = useState([]);

    useEffect( () => {
        HomeModel.getDashboard().then( (res) => {
            setData(res.data);
        } ).catch( (err) => {
            console.log(err);
            AppNotification.showError('Lấy dữ liệu thất bại!');
        })
    },[])
    
    useEffect( () => {
        NewModel.getNews().then( (res) => {
            setNews(res.data);
        } ).catch( (err) => {
            console.log(err);
            AppNotification.showError('Lấy tin tức thất bại!');
        })
    },[])

    useEffect(() => {
        HomeModel.getInfoHeader().then((res) => {
            VietQrModel.getQRdeposit({username:res.data.user_name}).then((res) => {
                localStorage.setItem("imageQR", res.data.qrDataURL);
            }).catch((err) => {
            })
        }).catch((err) => {
        })
    }, [])

    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Bảng điều khiển" />
            <div className='row'>
                <div className='col-xl-12'>
                    {/* <div className='row'>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <HomeCardMoney title={'Số tiền đã nạp vào hệ thống'} value={data.count_deposit} />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <HomeCardMoney title={'Số tiền nạp vào TK N.Hàng'} value={data.count_paymoney} />
                        </div>
                    </div> */}
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className="card">
                                <div className="card-header border-0 pb-0">
                                    <h4 className="card-title">Tin tức mới nhất</h4>
                                </div>
                                <div className="card-body">
                                    <div id="DZ_W_TimeLine" className="widget-timeline dz-scroll">
                                        <ul className="timeline">
                                        {
                                            news.map((item, key) => (
                                                <li key={key}>
                                                    <CardNews title={item.title} content={item.content} />
                                                </li>
                                            ))
                                        }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </MasterLayout>
    );
}

export default HomePage;