import React, { useEffect, useState } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Link, useParams } from 'react-router-dom';
import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import { setShowPreloader } from '../redux/action';
import VietQrModel from '../models/VietQrModel';
import AppNotification from '../helpers/AppNotification';
import UserModel from '../models/UserModel';
import ImageBill from '../components/bill/ImageBill';
import ImageBillDowload from '../components/bill/ImageBillDowload';

const validationSchema = Yup.object().shape({

    // Thêm các quy tắc xác thực khác cho các trường khác nếu cần thiết
});


function DowloadBill(props) {
    const { type } = useParams();
    const dispatch = useDispatch();
    const isFirstLoadRef = React.useRef(true);
    const storedInitialValues = localStorage.getItem('initialValues');
    const parsedInitialValues = JSON.parse(storedInitialValues);
    const [initialValues,setinitialValues] = useState(parsedInitialValues);
    
    
    const [flagShowImage, setFlagShowImage] = useState(true);
    const [exportBill, setExportBill] = useState(false);

    const [reloadCanvas, setReloadCanvas] = useState(false);
    const [counter, setCounter] = useState(0);
    
    useEffect(() => {
        let reloaded = localStorage.getItem('reloaded');
        if(!reloaded){
            localStorage.setItem('reloaded',1);
            window.location.reload();
        }
        if (counter === 5) {
          return;
        }
        const interval = setInterval(() => {
          setReloadCanvas(new Date().getTime());
          setCounter((prevCounter) => prevCounter + 1);
        }, 1000);
        return () => {
          clearInterval(interval);
        };
      }, [counter]);

    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Tạo bill/ Tải bill" />
            <div className='row card d-flex justify-content-center'>
                <div className="card-header">
                    <h4 className="card-title">Bill</h4>
                </div>
                <div className='col-xl-12 d-flex justify-content-center'>
                    <ImageBillDowload initialValues={initialValues} flagShowImage={flagShowImage} reloadCanvas={reloadCanvas}  />
                </div>
            </div>
        </MasterLayout>
    );
}

export default DowloadBill;