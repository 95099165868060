import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import InformationAndroidBank from './InformationAndroidBank';

function InformationAndroidBankComponent(props) {
    const {
        logo1, titleMessGlobal1, contentMessGlobal1, date1, account1, transaction1, balance1, note1, amount1, timeWord1, accountGuest1,
        logo2, titleMessGlobal2, contentMessGlobal2, date2, minute2, second2, hour2, account2, transaction2, balance2, note2, amount2, timeWord2, accountGuest2,
        logo3, titleMessGlobal3, contentMessGlobal3, date3, minute3, second3, hour3, account3, transaction3, balance3, note3, amount3, timeWord3, accountGuest3,
        showFormNotiTwo, showFormNotiThree, driverNetwork, background, hideMark,
        network, date, hour, minute,
    } = props;



    const [newAccountMB, setNewAccountMB] = useState('');
    const [newAccountVCB, setNewAccountVCB] = useState('');
    const [newAccount, setNewAccount] = useState('');

    useEffect(() => {
        setNewAccount("xxxx" + account1.slice(4));
    }, [logo1 == 'TPB'])
    useEffect(() => {
        setNewAccountMB(account1.slice(0, 2) + "xxx" + account1.slice(5));
    }, [logo1 == 'MB'])
    useEffect(() => {
        if (account1.length <= 7) {
            setNewAccountVCB(account1);
          } else {
            setNewAccountVCB(account1.slice(0, 7) + "...");
          }
    }, [logo1 == 'VCB'])



    let { hour1, minute1, second1 } = props;
    hour1 = hour1 < 10 ? '0' + hour1 : hour1;
    minute1 = minute1 < 10 ? '0' + minute1 : minute1;
    second1 = second1 < 10 ? '0' + second1 : second1;
    hour1 = hour1 + ":" + minute1;

    let formattedDate = '';
    if (date.indexOf('/') !== -1) {
        const [day, month, year] = date.split('/');
        if (logo1 === "MB") {
            formattedDate = format(new Date(year, month - 1, day), 'dd/MM/yy');
        } else if (logo1 === 'MSB') {
            formattedDate = format(new Date(year, month - 1, day), 'dd/MM');
        } else {
            formattedDate = format(new Date(year, month - 1, day), 'dd/MM/yyyy');
        }
    } else {
        if (logo1 === "MB") {
            formattedDate = format(new Date(date), 'dd/MM/yy');
        } else if (logo1 === 'MSB') {
            formattedDate = format(new Date(date), 'dd/MM');
        } else {
            formattedDate = format(new Date(date), 'dd/MM/yyyy');
        }
    }


    return (
        <div className="components" >
            <div className="componentContent d-flex justify-content-between">
                <div className='d-flex'>
                    {
                        (logo1 != 'ANDROID_SMS' && logo1 != 'ANDROID_MESS' && logo1 != 'ZALO') ? (
                            <img
                                className="logoVietin1"
                                src={`/asset-noti-custom/image/smallLogo/${logo1}.png`}
                                alt="vietin-logo"
                            />
                        ):
                        (
                            <img
                                className="logoVietin1"
                                src={`/asset-noti-custom/image/${logo1}.png`}
                                alt="vietin-logo"
                            />
                        )
                    }
                    <p className="content1 d-flex">
                        {logo1 === 'ACB' && 'ACB ONE •'}
                        {logo1 === 'VCB' && (`VCB Digibank  • Số dư TK ${newAccountVCB} `)}
                        {logo1 === 'TCB' && (`Techcombank  •`)}
                        {logo1 === 'BIDV' && (`SmartBangking • ${hour1} ${formattedDate}...`)}
                        {logo1 === 'ICB' && (`VietinBank iP... • Thời gian ${formattedDate.substring(0, 5)}... •`)}
                        {logo1 === 'VBA' && (`Agribank Plus •`)}
                        {logo1 === 'TPB' && (`TPBank Mobile •`)}
                        {logo1 === 'VPB' && (`VPBank NEO •`)}
                        {logo1 === 'MSB' && (`MSB mbank •`)}
                        {logo1 === 'MB' && (`MB Bank •`)}
                        {logo1 === 'SCB' && (`Sacombank Pay •`)}
                        {logo1 === 'ANDROID_SMS' && (`Tin nhắn •`)}
                        {logo1 === 'ANDROID_MESS' && (`Messenger •`)}
                        {logo1 === 'ZALO' && (`Zalo •`)}
                    </p>
                    {
                        (logo1 != 'ZALO' && logo1 != 'ANDROID_MESS' && logo1 != 'ANDROID_SMS' && logo1 != 'SCB' && logo1 != 'MB' && logo1 != 'TCB' && logo1 != 'MSB' && logo1 != 'VPB' && logo1 != 'ACB'  && logo1 != 'ICB' && logo1 != 'VBA' && logo1 != 'TPB') && (
                            <>
                                <img
                                    className="ellipse"
                                    src="/asset-noti-custom/image/android/ellipse.png"
                                    alt="ellipse-icon"
                                />
                                <img
                                    className="ellipse"
                                    src="/asset-noti-custom/image/android/ellipse.png"
                                    alt="ellipse-icon"
                                />
                            </>
                        )
                    }
                    <p className="content1">{timeWord1}</p>
                </div>
                <img
                    className="chevron"
                    src="/asset-noti-custom/image/android/chevron.png"
                    alt="chevron-icon"
                />
            </div>
            <div className="componentContent3">
                <div className="componentContent2">
                    {/* title */}
                    <p className="balance">
                    {logo1 === 'VCB' && 'Thông báo VCB'}
                        {logo1 === 'TCB' && (<>
                            <p className='title-tcb'><span id='title-tcb'>+</span> VND {amount1}</p>
                        </>)}
                        {logo1 === 'VBA' && `Thông báo Agribank`}
                        {logo1 === 'ACB' && (
                            <>
                            <p className='title-acb'>Thong bao thay doi so du tai khoan</p>
                            </>
                        )}
                        {logo1 === 'BIDV' && `Thông báo BIDV`}
                        {logo1 === 'MB' && `Thông báo biến động số dư`}
                        {logo1 === 'MSB' && `Thông báo biến động số dư`}
                        {logo1 === 'SCB' && (
                            <p className='title-scb'>Sacombank Pay: Thông báo gi...</p>
                        )}
                        {logo1 === 'VPB' && `Thông báo VPBank`}
                        {logo1 === 'TPB' && `TPBank Mobile`}
                        {logo1 === 'ICB' && `Biến động số dư`}
                        {logo1 === 'ANDROID_SMS' && `${titleMessGlobal1}`}
                        {logo1 === 'ANDROID_MESS' && `${titleMessGlobal1}`}
                        {logo1 === 'ZALO' && `${titleMessGlobal1}`}
                    </p>
                      {/* content */}
                      {
                        logo1 == 'ACB' && (
                            <div>
                                <p id="account" className="contentName" >
                                    {logo1}: TK {account1}(VND) +  {amount1} luc {hour1} {formattedDate}. So du {balance1}. GD {note1} {transaction1} GD {accountGuest1}-{account1} {hour1}:{second1}
                                </p>
                            </div>
                        )
                     }
                     {/* VCB */}
                {
                    logo1 == 'VCB' && (
                        <>
                            <p id="account" className="contentName" > Số dư TK VCB {account1} +{amount1} VND lúc {date1.split("/").join("-")} {hour1}:{second1}. Số dư {balance1} VND. Ref {transaction1}. {note1}</p>
                        </>
                    )
                }
                {/* TCB */}
                {
                    logo1 == 'TCB' && (
                        <>
                            <p id="account" className="contentName" > Tài khoản: {account1} <br />Số dư: {balance1} VND <br />{note1}</p>
                        </>
                    )
                }
                {/* TPB */}
                {
                    logo1 == 'TPB' && (
                        <>
                            <p id="account" className="contentName" > (TPBank): {date1};{hour1}<br />TK: {newAccount}<br />PS: +{amount1}VND<br />SD: {balance1}VND...</p>
                        </>
                    )
                }
                {/* ICB */}
                {
                    logo1 == 'ICB' && (
                        <>
                            <p id="account" className="contentName" >Thời gian: {date1} {hour}<br />Tài khoản: {account1}<br />Giao dịch: +{amount1} VND<br />Số dư hiện tại: {balance1} VND ...</p>
                        </>
                    )
                }
                {/* VPB */}
                {
                    logo1 == 'VPB' && (
                        <>
                            <p id="account" className="contentName" >Quý khách có thông báo biến động số dư mới</p>
                        </>
                    )
                }
                {/* SCB */}
                {
                    logo1 == 'SCB' && (
                        <>
                            <p id="account" className="contentName" >Ngày {date1} {hour} TK: {account1}. PS: +{amount1} VND. Số dư khả dụng: {balance1} VND. {note1} {transaction1}</p>
                        </>
                    )
                }
                {/* {
                    logo == 'ACB' && (
                        <>
                            <p id="account" className="contentName" > ACB: TK {account + "(VND)"} + {amount} <br/> luc {hour} {formattedDate}. So du {balance}. GD </p>
                            <p id="account" className="contentName" >{note} {transaction} </p>
                        </>
                    )
                } */}
                {
                    logo1 == 'VBA' && (
                        <>
                            <p id="account" className="contentName" > Agribank: {hour} {formattedDate} Tài khoản {account1}: +{amount1} VND. Nội dung giao dịch: {note1} {transaction1} </p>
                        </>
                    )
                }
                {
                    logo1 == 'BIDV' && (
                        <>
                            <p id="account" className="contentName" >{hour1} {formattedDate}  Tài khoản thanh toán <br/> {account1}. Số tiền: +{amount1} VND. Số dư cuối {balance1} VND Nội dung giao dịch: TKThe: {accountGuest1} tại... </p>
                        </>
                    )
                }
                {
                    logo1 == 'MB' && (
                        <>
                            <p id="account" className="contentName" >TK: {newAccountMB}|GD:+{amount1}VND {formattedDate} {hour} |SD: {balance1}VND|TU:{note1} - {transaction1}</p>
                        </>
                    )
                }
                {
                    logo1 == 'MSB' && (
                        <>
                            <p id="account" className="contentName" >TK: {formattedDate} {hour} TK: {account1} VND (+) {amount1} (GD:10,000,Thue/Phi:0) ND: {accountGuest1} - {transaction1} - {note1} </p>
                        </>
                    )
                }
                {
                    logo1 == 'ANDROID_MESS' && (
                        <>
                            <p id="account" className="contentName" >{contentMessGlobal1}</p>
                        </>
                    )
                }
                {
                    logo1 == 'ANDROID_SMS' && (
                        <>
                            <p id="account" className="contentName" >{contentMessGlobal1}</p>
                        </>
                    )
                }
                {
                    logo1 == 'ZALO' && (
                        <>
                            <p id="account" className="contentName" >{contentMessGlobal1}</p>
                        </>
                    )
                }
                </div>
                <div className="containerVietin">
                    {/* logo image right */}
                    <img className="imgVietin" src={`/asset-noti-custom/image/${logo1}.png`} />
                </div>
            </div>
            {showFormNotiTwo ? <InformationAndroidBank contentMessGlobal={contentMessGlobal2} titleMessGlobal={titleMessGlobal2} accountGuest={accountGuest2} logo={logo2} date={date2} minute={minute2} second={second2} hour={hour2} account={account2} transaction={transaction2} balance={balance2} note={note2} amount={amount2} timeWord={timeWord2} /> : ''}
            {showFormNotiThree ? <InformationAndroidBank contentMessGlobal={contentMessGlobal3} titleMessGlobal={titleMessGlobal3} accountGuest={accountGuest3} logo={logo3} date={date3} minute={minute3} second={second3} hour={hour3} account={account3} transaction={transaction3} balance={balance3} note={note3} amount={amount3} timeWord={timeWord3} /> : ''}
        </div>
    );
}

export default InformationAndroidBankComponent;