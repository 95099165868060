import React, { useState } from 'react';
import { useEffect } from 'react';
import BillGenerate from '../../models/BillGenerate';
import Swal from 'sweetalert2';


function ImageBill(props) {
    return (
        <div className="col-xl-4 col-lg-4 ">
            <div className='row'>
                <div className='col-12'>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Bill</h4>
                        </div>
                        <div className="card-body p-0">
                            {/* <div id="renderBill">
                                <img src={props.imagePreview} style={{ width: '100%', height: '100%' }} />
                            </div> */}
                            <div id="renderBill" style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {
                                props.imageBill ? (
                                    <div className="text-center">
                                        <img alt='Đang tải ảnh' src={props.imageBill} style={{ width: '100%', height: '100%', zIndex: 0 }} />
                                    </div>
                                ) : <div className="text-center">
                                        <img alt='Đang tải ảnh' src={props.imageTemplate} style={{ width: '100%', height: '100%', zIndex: 0 }} />
                                    </div>
                            }
                            {/* <img id="billLogo" src={props.initialValues.logo} style={{ display:'none', zIndex: -1 }} /> */}
                            <img src="/bills/mark.png" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }} />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageBill;