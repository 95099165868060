import React, { useState } from 'react';
import AuthLayout from "../layouts/AuthLayout";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setShowPreloader} from '../redux/action';
import AuthMoldel from '../models/AuthMoldel';
import Swal from 'sweetalert2';

const regiterSchema = Yup.object().shape({
    name: Yup.string()
        .max(255, "Tối đa 255 kí tự ")
        .required("Trường bắt buộc"),
    password: Yup.string()
        .min(6, "Tối thiểu 6 kí tự")
        .max(255, "Tối đa 255 kí tự ")
        .required("Trường bắt buộc"),
    user_name: Yup.string()
        .max(255, "Tối đa 255 kí tự ")
        .required("Trường bắt buộc"),
    email: Yup.string()
        .required("Trường bắt buộc")
});
function RegisterPage(props) {
    // get params url
    const queryParameters = new URLSearchParams(window.location.search);
    // const ref = queryParameters.get("ref") ? queryParameters.get("ref") : '';
    const [ref, setRef] = useState(queryParameters.get("ref") ? queryParameters.get("ref") : '');
    const handleChange = (event) => {
        // const { value } = event.target;
        setRef(event.target.value);
      };
    const dispatch = useDispatch();
    const [form] = useState({
        password: "",
        user_name: "",
        name: "",
        email: "",
        referral_code: ref,
    })
    const [app_errors,setAppErrors] = useState([]);
    let navigate = useNavigate();
    const  handleSubmit = async (values, { setFieldError }) => {
        console.log(values);
        dispatch(setShowPreloader(true));
        AuthMoldel.register(values).then((res) => {
            dispatch(setShowPreloader(false));
            if(res.success){
                Swal.fire({
                    icon: "success",
                    title: "Đăng ký thành công!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                navigate('/login')
            }else{
                if( res.has_errors ){
                    Object.keys(res.errors).forEach((fieldName) => {
                        setFieldError(fieldName, res.errors[fieldName][0]);
                    });
                }
            }
            // Redirect to dashboard
        }).catch((err) => {
            dispatch(setShowPreloader(false));
            Swal.fire({
                icon: "error",
                title: "Đăng ký thất bại!",
                showConfirmButton: false,
                timer: 2000,
            });
        })
    }
    return (
        <AuthLayout>
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-body ">
                        <div className="row">
                            <div className="col-xl-6 col-md-6 sign text-center">
                                <div>
                                    <img src="/images/log.png" className="education-img" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="sign-in-your">
                                    <h1>Đăng Ký</h1>
                                    <Formik
                                        initialValues={form}
                                        validationSchema={regiterSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ errors, touched }) => (
                                            <Form className="mt-5">
                                                <div className="mb-3">
                                                    <label className="mb-1" htmlFor="name">Họ và tên</label>
                                                    <Field type="text" name="name" className="form-control" />
                                                    {errors.name && touched.name ? (
                                                        <div style={{ color: 'red' }}>{errors.name}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="mb-1" htmlFor="user_name">Tài khoản (Username)</label>
                                                    <Field type="text" name="user_name" className="form-control" />
                                                    {errors.user_name && touched.user_name ? (
                                                        <div style={{ color: 'red' }}>{errors.user_name}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="mb-1" htmlFor="email">Email</label>
                                                    <Field type="email" name="email" className="form-control" />
                                                    {errors.email && touched.email ? (
                                                        <div style={{ color: 'red' }}>{errors.email}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="mb-1" htmlFor="password">Mật khẩu</label>
                                                    <Field type="password" name="password" className="form-control" />
                                                    {errors.password && touched.password ? (
                                                        <div style={{ color: 'red' }}>{errors.password}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="mb-1" htmlFor="referral_code">Mã giới thiệu <span className='text-warning pt-2'>*không bắt buộc</span></label>
                                                    <Field type="text" onChange={handleChange} value={ref} name="referral_code" className="form-control" />
                                                    {errors.referral_code && touched.referral_code ? (
                                                        <div style={{ color: 'red' }}>{errors.referral_code}</div>
                                                    ) : null}
                                                </div>
                                                <div className="text-center pt-3">
                                                    <button type="submit" className="btn btn-primary btn-block">
                                                        Đăng Ký
                                                    </button>
                                                </div>
                                                <div className="row mt-4 mb-2">
                                                    <div className="mb-3 d-flex justify-content-center">
                                                        <a className='text-success' href="/login">Quay lại đăng nhập</a>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
}

export default RegisterPage;