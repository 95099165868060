import axiosClient from './axiosClient';

class TransactionModel {
  constructor() {
    this.api_url = "/api/transactions/";
  }
  async history(credentials) {
    const res = await axiosClient.get(this.api_url + "history", {
      params: {
        search: credentials.search,
        page: credentials.page,
        perPage: credentials.perPage
      },
    });
    return res;
  }

  async historyAdmin(credentials) {
    const res = await axiosClient.get(this.api_url + "history-admin", {
      params: {
        search: credentials.search,
        page: credentials.page,
        perPage: credentials.perPage
      },
    });
    return res;
  }

  async changeStatus(credentials) {
    const res = await axiosClient.post(this.api_url + "updateStatus/" + credentials.id, credentials);
    return res;
  }
  async find(id) {
    const res = await axiosClient.get(this.api_url + id);
    return res;
  }
}

export default new TransactionModel();