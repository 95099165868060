import React, { useEffect, useRef, useState } from 'react';
import ThemeBreadcrumb from '../themes/ThemeBreadcrumb';
import MasterLayout from '../../layouts/MasterLayout';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MaGiaoDich from '../../models/MaGiaoDich';
import FormCreateBillSchema from '../../models/FormCreateBillSchema';
import AppNotification from '../../helpers/AppNotification';
import VietQrModel from '../../models/VietQrModel';
import ImageHTMLIOS from './ImageHTMLIOS';
import ImageHTMLAndroid from './ImageHTMLAndroid';
import { toPng } from 'html-to-image';
import download from 'downloadjs';
import { useDispatch } from 'react-redux';
import UserModel from '../../models/UserModel';
import { setShowPreloader } from '../../redux/action';
import CurrencyInput from '../global/CurrencyInput';
import BankInfoBill from '../../models/BankInfoBill';
import { Tooltip } from 'react-tooltip';
import html2canvas from 'html2canvas';


function FormCreateFakeNoti(props) {



    const [bankInfoBill, setBankInfoBill] = useState([]);
    const [packageBill, setPackageBill] = useState({});
    const [userPackageBill, setUserPackageBill] = useState({});



    useEffect(() => {
        localStorage.setItem('reloaded', 0);
        dispatch(setShowPreloader(true));
        BankInfoBill.all({})
            .then(res => {
                dispatch(setShowPreloader(false));
                setBankInfoBill(res.data.data);
                setPackageBill(res.data.package_bill);
                setUserPackageBill(res.data.is_package_bill);
            }).catch(err => {
                dispatch(setShowPreloader(false));
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, []);


    const navigate = useNavigate();
    const { type } = useParams();
    const [dataValidate, setDataValidate] = useState({}); // Initialize with an empty object
    const hours = Array.from({ length: 24 }, (_, index) => index);
    const minutes = Array.from({ length: 59 }, (_, index) => index);
    const [hideMark, setHideMark] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const [operaSystem, setOperaSystem] = useState('ios');
    const [network, setNetwork] = useState('Viettel');
    const [pin, setPin] = useState('50');
    const [date, setDate] = useState('11/06/2024');
    const [hour, setHour] = useState('1');
    const [minute, setMinute] = useState('2');
    const [driverNetwork, setDriverNetwork] = useState('/asset-noti-custom/image/wifi.png');
    const [background, setBackground] = useState('url("/asset-noti-custom/image/ios_background.png")');
    const [networkDark, setNetworkDark] = useState('3');





    // time, account, transaction, balance
    const [hour1, setHour1] = useState('22');
    const [minute1, setMinute1] = useState('1');
    const [date1, setDate1] = useState('20/05/2024');
    const [account1, setAccount1] = useState('123456789012');
    const [transaction1, setTransaction1] = useState('411251312');
    const [amount1, setAmount1] = useState('10,000');
    const [balance1, setBalance1] = useState('50,000');
    const [note1, setNote1] = useState('thanh cong');
    const [timeWord1, setTimeWord1] = useState('1 phút trước');
    const [logo1, setLogo1] = useState('VCB');
    const [accountGuest1, setAccountGuest1] = useState('12345678');
    const [second1, setSecond1] = useState('8');
    const [contentMessGlobal1, setContentMessGlobal1] = useState('Nội dung');
    const [titleMessGlobal1, setTitleMessGlobal1] = useState('Tiêu đề');

    //
    const [hour2, setHour2] = useState('22');
    const [minute2, setMinute2] = useState('1');
    const [date2, setDate2] = useState('20/05/2024');
    const [account2, setAccount2] = useState('123456789012');
    const [transaction2, setTransaction2] = useState('411251312');
    const [amount2, setAmount2] = useState('10,000');
    const [balance2, setBalance2] = useState('50,000');
    const [note2, setNote2] = useState('thanh cong');
    const [timeWord2, setTimeWord2] = useState('1 phút trước');
    const [logo2, setLogo2] = useState('VCB');
    const [accountGuest2, setAccountGuest2] = useState('12345678');
    const [second2, setSecond2] = useState('12');
    const [contentMessGlobal2, setContentMessGlobal2] = useState('Nội dung');
    const [titleMessGlobal2, setTitleMessGlobal2] = useState('Tiêu đề');



    const [hour3, setHour3] = useState('22');
    const [minute3, setMinute3] = useState('1');
    const [date3, setDate3] = useState('20/05/2024');
    const [account3, setAccount3] = useState('123456789012');
    const [amount3, setAmount3] = useState('10,000');
    const [transaction3, setTransaction3] = useState('454546');
    const [balance3, setBalance3] = useState('50,000');
    const [note3, setNote3] = useState('thanh cong');
    const [timeWord3, setTimeWord3] = useState('1 phút trước');
    const [logo3, setLogo3] = useState('VCB');
    const [accountGuest3, setAccountGuest3] = useState('12345678');
    const [second3, setSecond3] = useState('12');
    const [contentMessGlobal3, setContentMessGlobal3] = useState('Nội dung');
    const [titleMessGlobal3, setTitleMessGlobal3] = useState('Tiêu đề');




    useEffect(() => {
        if (operaSystem == 'android') {
            setBackground('url("/asset-noti-custom/image/android/android_bacground.png")')
            setLogo1('VCB');
        } else {
            setBackground('url("/asset-noti-custom/image/ios_background.png")')
            setLogo1('VCB');
        }
    }, [operaSystem])


    const [initialValues, setInitialValues] = useState({
        type: type,
        amount: '',
        hour: '',
        minute: '',
        day: '',
        receiver_name: '',
        receiver_number: '',
        receiver_bank_name: '',
        operatingSystem: 'ios',
        decreption: '',
        sender_name: '',
        sender_number: '',
        logo: '',
        shortname: '',
        fullname: '',
        fullname_fm: '',
        power: 10,
    });



    const onChangeOperaSystem = (e) => {
        setOperaSystem(e.target.value)
    }
    const onChangeNetwork = (e) => {
        setNetwork(e.target.value)
    }
    const onChangePin = (e) => {
        setPin(e.target.value)
    }
    const onChangeDate = (e) => {
        setDate(e.target.value)
    }
    const onChangeHour = (e) => {
        setHour(e.target.value)
    }
    const onChangeMinute = (e) => {
        setMinute(e.target.value)
    }
    const onChangeDriverNetwork = (e) => {
        setDriverNetwork(e.target.value)
    }
    const onChangeNetworkDark = (e) => {
        setNetworkDark(e.target.value)
    }
    const onChangeBackground = (e) => {
        if (e.target.files && e.target.files[0]) {
            setBackground(URL.createObjectURL(e.target.files[0]));
            const backgroundDiv = document.getElementById('background_image_div');
            backgroundDiv.style.backgroundImage = `url(${URL.createObjectURL(e.target.files[0])})`;
        }
    }

    const onChangeTitleMessGlobal1 = (e) => {
        setTitleMessGlobal1(e.target.value)
    }
    const onChangeContentMessGlobal1 = (e) => {
        setContentMessGlobal1(e.target.value)
    }
    const onChangeLogo1 = (e) => {
        setLogo1(e.target.value)
    }
    const onChangeAccount1 = (e) => {
        setAccount1(e.target.value)
    }
    const onChangeTransaction1 = (e) => {
        setTransaction1(e.target.value)
    }
    const onChangeBalance1 = (e) => {
        setBalance1(e.target.value)
    }
    const onChangeNote1 = (e) => {
        setNote1(e.target.value)
    }
    const onChangeAmount1 = (e) => {
        setAmount1(e.target.value)
    }
    const onChangeTimeWord1 = (e) => {
        setTimeWord1(e.target.value)
    }
    const onChangeHour1 = (e) => {
        setHour1(e.target.value)
    }
    const onChangeDate1 = (e) => {
        setDate1(e.target.value)
    }
    const onChangeAccountGuest1 = (e) => {
        setAccountGuest1(e.target.value)
    }
    const onChangeMinute1 = (e) => {
        setMinute1(e.target.value)
    }
    const onChangeSecond1 = (e) => {
        setSecond1(e.target.value)
    }


    const onChangeTitleMessGlobal2 = (e) => {
        setTitleMessGlobal2(e.target.value)
    }
    const onChangeContentMessGlobal2 = (e) => {
        setContentMessGlobal2(e.target.value)
    }
    const onChangeLogo2 = (e) => {
        setLogo2(e.target.value)
    }
    const onChangeAccount2 = (e) => {
        setAccount2(e.target.value)
    }
    const onChangeTransaction2 = (e) => {
        setTransaction2(e.target.value)
    }
    const onChangeBalance2 = (e) => {
        setBalance2(e.target.value)
    }
    const onChangeNote2 = (e) => {
        setNote2(e.target.value)
    }
    const onChangeAmount2 = (e) => {
        setAmount2(e.target.value)
    }
    const onChangeTimeWord2 = (e) => {
        setTimeWord2(e.target.value)
    }
    const onChangeHour2 = (e) => {
        setHour2(e.target.value)
    }
    const onChangeDate2 = (e) => {
        setDate2(e.target.value)
    }
    const onChangeAccountGuest2 = (e) => {
        setAccountGuest2(e.target.value)
    }
    const onChangeMinute2 = (e) => {
        setMinute2(e.target.value)
    }
    const onChangeSecond2 = (e) => {
        setSecond2(e.target.value)
    }


    const onChangeTitleMessGlobal3 = (e) => {
        setTitleMessGlobal3(e.target.value)
    }
    const onChangeContentMessGlobal3 = (e) => {
        setContentMessGlobal3(e.target.value)
    }
    const onChangeLogo3 = (e) => {
        setLogo3(e.target.value)
    }
    const onChangeAccount3 = (e) => {
        setAccount3(e.target.value)
    }
    const onChangeTransaction3 = (e) => {
        setTransaction3(e.target.value)
    }
    const onChangeBalance3 = (e) => {
        setBalance3(e.target.value)
    }
    const onChangeNote3 = (e) => {
        setNote3(e.target.value)
    }
    const onChangeAmount3 = (e) => {
        setAmount3(e.target.value)
    }
    const onChangeTimeWord3 = (e) => {
        setTimeWord3(e.target.value)
    }
    const onChangeHour3 = (e) => {
        setHour3(e.target.value)
    }
    const onChangeDate3 = (e) => {
        setDate3(e.target.value)
    }
    const onChangeAccountGuest3 = (e) => {
        setAccountGuest3(e.target.value)
    }
    const onChangeMinute3 = (e) => {
        setMinute3(e.target.value)
    }
    const onChangeSecond3 = (e) => {
        setSecond3(e.target.value)
    }



    const [showFormNotiTwo, setShowFormNotiTwo] = useState(false);
    const handleShowFormNotiTwo = () => {
        setShowFormNotiTwo(true);
    }
    const handleCloseFormNotiTwo = () => {
        setShowFormNotiTwo(false);
    }

    const [showFormNotiThree, setShowFormNotiThree] = useState(false);
    const handleShowFormNotiThree = () => {
        setShowFormNotiThree(true);
    }
    const handleCloseFormNotiThree = () => {
        setShowFormNotiThree(false);
    }


    useEffect(() => {
        const currentDate = new Date();
        const currentHour = currentDate.getHours().toString().padStart(2, "0"); // Lấy giờ hiện tại và định dạng thành chuỗi 2 chữ số
        const currentDay = currentDate.toISOString().split("T")[0]; // Lấy ngày hiện tại và định dạng thành chuỗi YYYY-MM-DD
        const currentMinute = currentDate.getMinutes().toString().padStart(2, "0");
        setInitialValues({
            ...initialValues,
            hour: currentHour,
            minute: currentMinute,
            day: currentDay,
            operatingSystem: "ios",
        });
    }, []);

    // const { typeBank, bankName } = location.state;
    // //console.log(typeBank);
    const [flagShowImage, setFlagShowImage] = useState(false);


    const [operating, setOperating] = useState();
    const [bankList, setBankList] = useState([]);
    useEffect(() => {
        setOperating(0); // Set lại giá trị của showFormCreateBill thành 0 mỗi khi component được tải lại
    }, []);
    const handleChangeOperating = (event) => {
        setOperating(event.target.value);
    };

    const [vietQrBanks, setVietQrBanks] = useState([]);

    useEffect(() => {
        VietQrModel.getAllVietQR({
            type: type
        })
            .then(res => {
                setVietQrBanks(res.data);

                let newBankList = []
                for (var i in res.data) {
                    let bankObj = res.data[i];
                    let bill_logo = bankObj.logo;
                    bill_logo = bill_logo.replace('http://127.0.0.1:8000/images', '');
                    bill_logo = bill_logo.replace('https://okbill.net/images', '');
                    newBankList[bankObj.shortName] = {
                        type: bankObj.type,
                        logo: bill_logo,
                        name: bankObj.name,
                        name_fm: bankObj.name_fm,
                        shortName: bankObj.shortName,
                    };
                }
                setBankList(newBankList)
            }).catch(err => {
                //console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, []);

    const options = vietQrBanks.map(item => ({
        value: item.name_fm,
        label: item.shortName
    }));
    const handleSubmit = (values) => {
        //console.log(values);


    };

    const [infoBankBill, setInfoBankBill] = useState({});
    useEffect(() => {
        let foundElement = null;
        vietQrBanks.forEach(element => {
            if (element.code === type) {
                foundElement = element;
            }
        });

        if (foundElement) {
            setInfoBankBill(foundElement);
        }
    }, [type, vietQrBanks]);

    const divRef = useRef(null);
    const dispatch = useDispatch();
    useEffect(() => {
        if (hideMark) {
            handleDownloadImage();
        }
    }, [hideMark])

    const handleClickDowNoti = () => {
        setHideMark(true);
    }

    const handleDownloadImage = async () => {
        try {
            dispatch(setShowPreloader(true));
            const userAgent = navigator.userAgent;
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
            if (!divRef.current) {
                dispatch(setShowPreloader(false));
                return;
            }

            if (isMobile) {
                const scale = 4; // Tăng giá trị này để tăng độ phân giải
                const width = 400;
                const height = 812;

                const canvas = await html2canvas(divRef.current, {
                    width,
                    height,
                    scale,
                    useCORS: true,
                    allowTaint: true,
                    backgroundColor: null,
                    imageTimeout: 15000,
                });
                const dataUrl = canvas.toDataURL('image/png');
                const res = await UserModel.feeDowloadBill(dataUrl);
                if (res.success) {
                    dispatch(setShowPreloader(false));
                    setHideMark(false);

                    if (isMobile) {
                        window.location.href = res.imageUrl;
                    }
                } else {
                    dispatch(setShowPreloader(false));
                    setHideMark(false);
                    Swal.fire({
                        icon: 'error',
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            } else {

                const scale = 2; // Tăng giá trị này để tăng độ phân giải
                const width = divRef.current.offsetWidth * scale;
                const height = divRef.current.offsetHeight * scale;

                const dataUrlPng = await toPng(divRef.current, {
                    width,
                    height,
                    style: {
                        transform: `scale(${scale})`,
                        transformOrigin: 'top left',
                    },
                });
                const res = await UserModel.feeDowloadBill(dataUrlPng);
                if (res.success) {
                    dispatch(setShowPreloader(false));
                    setHideMark(false);

                    if (!isMobile) {
                        const link = document.createElement('a');
                        link.href = res.imageUrl;
                        link.download = 'bill.png';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                } else {
                    dispatch(setShowPreloader(false));
                    setHideMark(false);
                    Swal.fire({
                        icon: 'error',
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }


        } catch (err) {
            dispatch(setShowPreloader(false));
            AppNotification.showError('Lấy dữ liệu thất bại!');
        }
    };

    // const handleDownloadImage = async () => {
    //     try {
    //         dispatch(setShowPreloader(true));
    //         const userAgent = navigator.userAgent;
    //         const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    //         if (divRef.current === null) {
    //             return;
    //         }
    //         const scale = 2; // Tăng giá trị này để tăng độ phân giải
    //         const width = divRef.current.offsetWidth * scale;
    //         const height = divRef.current.offsetHeight * scale;

    //         const dataUrl = await toPng(divRef.current, {
    //             width,
    //             height,
    //             style: {
    //                 transform: `scale(${scale})`,
    //                 transformOrigin: 'top left',
    //             },
    //         });

    //         const res = await UserModel.feeDowloadBill(dataUrl);
    //         if (res.success === true) {
    //             dispatch(setShowPreloader(false));
    //             if (isMobile) {
    //                 console.log(res.imageUrl);
    //                 setHideMark(false);
    //                 var segments = res.imageUrl.split('/');
    //                 var lastSegment = segments.pop();
    //                 var desiredString = lastSegment.split('.')[0];
    //                 navigate('/result-img/'+desiredString);
    //                 // window.location.href = res.imageUrl;
    //             } else {
    //                 setHideMark(false);
    //                 var link = document.createElement('a');
    //                 link.href = res.imageUrl;
    //                 link.download = 'bill.png';
    //                 document.body.appendChild(link);
    //                 link.click();
    //                 document.body.removeChild(link);
    //             }
    //         } else {
    //             dispatch(setShowPreloader(false));
    //             setHideMark(false);
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: res.message,
    //                 showConfirmButton: false,
    //                 timer: 2000,
    //             });
    //         }
    //     } catch (err) {
    //         dispatch(setShowPreloader(false));
    //         AppNotification.showError('Lấy dữ liệu thất bại!');
    //     }
    // };
    const reloadPage = () => {
        window.location.reload();
    };
    return (
        <MasterLayout>
            <ThemeBreadcrumb title={`Tạo bill / Thông báo ngân hàng và tin nhắn`} />
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className="col-xl-8 col-lg-8">
                            <div className="row">
                                <div className="col-xl-12 col-xxl-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div>
                                                {userPackageBill && (
                                                    <>
                                                        <a data-tooltip-id={'detail-vip-bill'} className="badge badge-xl badge-warning">
                                                            {packageBill.name}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye ms-2" viewBox="0 0 16 16">
                                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                            </svg>
                                                        </a>
                                                        <Tooltip

                                                            id={'detail-vip-bill'}
                                                            html={`Tải bill miễn phí tối đa: ${userPackageBill.type == 'vip3' ? 'Không giới hạn' : userPackageBill.max_create_bill + ' / ' + packageBill.max_download_bill + ' bill / ngày'} <br /><br />
                                                        Đăng nhập tối đa: ${userPackageBill.max_login_device} / ${packageBill.max_device_login} Thiết bị<br /><br />
                                                        Thời hạn vip:  ${userPackageBill.type == 'vip3' ? 'Vĩnh viễn' : 'còn ' + userPackageBill.duration_vip_bill + ' ngày'} <br /><br />`}
                                                        />
                                                        {userPackageBill.type === 'vip1' && <img src="/images/vip1.png" width={40} height={40} />}
                                                        {userPackageBill.type === 'vip2' && <img src="/images/vip 2.png" width={40} height={40} />}
                                                        {userPackageBill.type === 'vip3' && <img src="/images/vip3.png" width={40} height={40} />}
                                                    </>
                                                )}
                                            </div>
                                            <h4 className="card-title"> Bill ngân hàng và tin nhắn</h4>
                                            {
                                                (showForm === false) ?
                                                    <button className='btn btn-sm btn-primary' onClick={() => setShowForm(true)}>Tạo ảnh</button>
                                                    :
                                                    <button className='btn btn-sm btn-primary' onClick={() => setShowForm(false)}>Thu gọn</button>
                                            }
                                        </div>
                                        <div className="card-body">
                                            <Formik
                                                initialValues={initialValues}
                                                // validationSchema={validationSchema}
                                                onSubmit={handleSubmit}
                                                enableReinitialize={true}
                                            >
                                                {({ errors, touched, setFieldValue }) => (
                                                    <Form>
                                                        {
                                                            showForm && (
                                                                <>
                                                                    <div id="smartwizard" className="form-wizard order-create sw sw-theme-default sw-justified">
                                                                        <div className="tab-content" style={{ height: 'auto' }}>
                                                                            <div
                                                                                id="wizard_Service"
                                                                                className="tab-pane"
                                                                                role="tabpanel"
                                                                                style={{ display: 'block' }}
                                                                            >
                                                                                <div className="row">
                                                                                    <div className="col-lg-4 col-6 mb-2">
                                                                                        <div className="mb-3">
                                                                                            <label className="text-label form-label">Hệ điều hành*</label>
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="operatingSystem"
                                                                                                className="form-control form-control-new"
                                                                                                required=""
                                                                                                fdprocessedid="4aq0kr"
                                                                                                value={operaSystem}
                                                                                                onChange={onChangeOperaSystem}
                                                                                            >
                                                                                                <option value="ios">IOS</option>
                                                                                                <option value="android">Android</option>
                                                                                            </Field>
                                                                                            <ErrorMessage name="operatingSystem" component="div" className="error-message text-danger" />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-lg-4 col-6 mb-2">
                                                                                        <div className="mb-3">
                                                                                            <label className="text-label form-label">Chọn Mức sóng*</label>
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="type"
                                                                                                className="form-control form-control-new"
                                                                                                required=""
                                                                                                value={networkDark}
                                                                                                onChange={onChangeNetworkDark}
                                                                                                fdprocessedid="4aq0kr"
                                                                                            >
                                                                                                <option value="1">1 vạch sóng</option>
                                                                                                <option value="2">2 vạch sóng</option>
                                                                                                <option value="3">3 vạch sóng</option>
                                                                                                <option value="4">4 vạch sóng</option>
                                                                                            </Field>

                                                                                            <ErrorMessage name="type" component="div" className="error-message text-danger" />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-lg-4 col-6 mb-2">
                                                                                        <div className="mb-3">
                                                                                            <label className="text-label form-label">Nhà mạng*</label>
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="network"
                                                                                                className="form-control form-control-new"
                                                                                                required=""
                                                                                                value={network}
                                                                                                onChange={onChangeNetwork}
                                                                                                fdprocessedid="4aq0kr"
                                                                                            >
                                                                                                <option value="Viettel">Viettel</option>
                                                                                                <option value="Vinaphone">Vinaphone</option>
                                                                                                <option value="mobifone">Mobifone</option>
                                                                                            </Field>
                                                                                            <ErrorMessage name="network" component="div" className="error-message text-danger" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-6 mb-2">
                                                                                        <div className="mb-3">
                                                                                            <label className="text-label form-label">Wifi*</label>
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="operatingSystem"
                                                                                                className="form-control form-control-new"
                                                                                                required=""
                                                                                                fdprocessedid="4aq0kr"
                                                                                                value={driverNetwork}
                                                                                                onChange={onChangeDriverNetwork}
                                                                                            >
                                                                                                <option value="/asset-noti-custom/image/wifi.png">Wifi</option>
                                                                                                <option value="/asset-noti-custom/image/4G.png">4G</option>
                                                                                            </Field>
                                                                                            <ErrorMessage name="operatingSystem" component="div" className="error-message text-danger" />
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        operaSystem == 'ios' && (
                                                                                            <div className="col-lg-4 col-6 mb-2">
                                                                                                <div className="mb-3">
                                                                                                    <label className="text-label form-label">Màn Hình*</label>
                                                                                                    <input
                                                                                                        type="file"
                                                                                                        name="operatingSystem"
                                                                                                        className="form-control form-control-new"
                                                                                                        required=""
                                                                                                        onChange={onChangeBackground}
                                                                                                    />
                                                                                                    <ErrorMessage name="operatingSystem" component="div" className="error-message text-danger" />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        operaSystem == 'android' && (
                                                                                            <div className="col-lg-4 col-6 mb-2">
                                                                                                <div className="mb-3">
                                                                                                    <label className="text-label form-label">Màn Hình*</label>
                                                                                                    <input
                                                                                                        type="file"
                                                                                                        name="operatingSystem"
                                                                                                        className="form-control form-control-new"
                                                                                                        required=""
                                                                                                        onChange={onChangeBackground}
                                                                                                    />
                                                                                                    <ErrorMessage name="operatingSystem" component="div" className="error-message text-danger" />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    <div className="col-lg-4 col-6 mb-2">
                                                                                        <div className="mb-3">
                                                                                            <label className="text-label form-label">Pin*</label>
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="power"
                                                                                                className="form-control form-control-new"
                                                                                                required=""
                                                                                                value={pin}
                                                                                                onChange={onChangePin}
                                                                                                fdprocessedid="4aq0kr"
                                                                                            >
                                                                                                {Array.from({ length: 100 }, (_, i) => (
                                                                                                    <option key={i + 1} value={i + 1}>
                                                                                                        {i + 1}%
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                            <ErrorMessage name="power" component="div" className="error-message text-danger" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-6 mb-2">
                                                                                        <div className="mb-3">
                                                                                            <label className="text-label form-label">Ngày Tháng*</label>
                                                                                            <Field
                                                                                                type="date"
                                                                                                name="day"
                                                                                                className="form-control"
                                                                                                placeholder=""
                                                                                                value={date}
                                                                                                onChange={onChangeDate}
                                                                                                required=""
                                                                                                fdprocessedid="4aq0kr"
                                                                                            />
                                                                                            <ErrorMessage name="day" component="div" className="error-message text-danger" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-2 col-3 mb-2">
                                                                                        <div className="mb-3">
                                                                                            <label className="text-label form-label">giờ*</label>
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="hour"
                                                                                                className="form-control form-control-new"
                                                                                                required=""
                                                                                                value={hour}
                                                                                                onChange={onChangeHour}
                                                                                                fdprocessedid="4aq0kr"
                                                                                            >
                                                                                                {[...Array(24)].map((_, index) => (
                                                                                                    <option key={index + 1} value={index + 1}>
                                                                                                        {index + 1}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                            <ErrorMessage name="hour" component="div" className="error-message text-danger" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-2 col-3 mb-2">
                                                                                        <div className="mb-3">
                                                                                            <label className="text-label form-label">Phút*</label>
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="minute"
                                                                                                className="form-control form-control-new"
                                                                                                required=""
                                                                                                value={minute}
                                                                                                onChange={onChangeMinute}
                                                                                                fdprocessedid="4aq0kr"
                                                                                            >
                                                                                                {[...Array(60)].map((_, index) => (
                                                                                                    <option key={index + 1} value={index + 1}>
                                                                                                        {index + 1}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                            <ErrorMessage name="minute" component="div" className="error-message text-danger" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='d-flex justify-content-center text-warning m-3'>
                                                                                        -- Thông báo thứ nhất --
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-6 mb-2">
                                                                                        <div className="mb-3">
                                                                                            <label className="text-label form-label">Logo <span className='text-warning'>Bank</span> hoặc <span className='text-warning'>Tin nhắn</span>*</label>
                                                                                            {
                                                                                                operaSystem == 'ios' && (
                                                                                                    <Field
                                                                                                        as="select"
                                                                                                        name="logo1"
                                                                                                        className="form-control form-control-new"
                                                                                                        required=""
                                                                                                        value={logo1}
                                                                                                        onChange={onChangeLogo1}
                                                                                                        fdprocessedid="4aq0kr"
                                                                                                    >
                                                                                                        <option value="VCB">VCB</option>
                                                                                                        <option value="TCB">TCB</option>
                                                                                                        <option value="ACB">ACB</option>
                                                                                                        <option value="BIDV">BIDV</option>
                                                                                                        <option value="ICB">Viettin</option>
                                                                                                        <option value="VBA">Agribank</option>
                                                                                                        <option value="TPB">TPB</option>
                                                                                                        <option value="VPB">VPB</option>
                                                                                                        <option value="MSB">MSB</option>
                                                                                                        <option value="MB">MB</option>
                                                                                                        <option value="SCB">SCB</option>
                                                                                                        <option value="IOS_MESS">Mesenger</option>
                                                                                                        <option value="IOS_SMS">Tin nhắn</option>
                                                                                                        <option value="ZALO">Zalo</option>
                                                                                                    </Field>
                                                                                                )
                                                                                            }
                                                                                            {
                                                                                                operaSystem == 'android' && (
                                                                                                    <Field
                                                                                                        as="select"
                                                                                                        name="logo1"
                                                                                                        className="form-control form-control-new"
                                                                                                        required=""
                                                                                                        value={logo1}
                                                                                                        onChange={onChangeLogo1}
                                                                                                        fdprocessedid="4aq0kr"
                                                                                                    >
                                                                                                        <option value="VCB">VCB</option>
                                                                                                        <option value="TCB">TCB</option>
                                                                                                        <option value="ACB">ACB</option>
                                                                                                        <option value="BIDV">BIDV</option>
                                                                                                        <option value="ICB">Viettin</option>
                                                                                                        <option value="VBA">Agribank</option>
                                                                                                        <option value="TPB">TPB</option>
                                                                                                        <option value="VPB">VPB</option>
                                                                                                        <option value="MSB">MSB</option>
                                                                                                        <option value="MB">MB</option>
                                                                                                        <option value="SCB">SCB</option>
                                                                                                        <option value="ANDROID_MESS">Mesenger</option>
                                                                                                        <option value="ANDROID_SMS">Tin nhắn</option>
                                                                                                        <option value="ZALO">Zalo</option>
                                                                                                    </Field>
                                                                                                )
                                                                                            }
                                                                                            <ErrorMessage name="logo1" component="div" className="error-message text-danger" />
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        (logo1 != 'IOS_MESS' && logo1 != 'IOS_SMS' && logo1 != 'ANDROID_MESS' && logo1 != 'ANDROID_SMS' && logo1 != 'ZALO') ? (
                                                                                            <>
                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">Số tài khoản*</label>
                                                                                                        <Field
                                                                                                            type="text"
                                                                                                            name="bank_number"
                                                                                                            className="form-control"
                                                                                                            placeholder=""
                                                                                                            required=""
                                                                                                            value={account1}
                                                                                                            onChange={onChangeAccount1}
                                                                                                            fdprocessedid="4aq0kr"
                                                                                                        />
                                                                                                        <ErrorMessage name="bank_number" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">thời gian chữ*</label>
                                                                                                        <Field
                                                                                                            type="text"
                                                                                                            name="bank_number"
                                                                                                            className="form-control"
                                                                                                            placeholder=""
                                                                                                            required=""
                                                                                                            value={timeWord1}
                                                                                                            onChange={onChangeTimeWord1}
                                                                                                            fdprocessedid="4aq0kr"
                                                                                                        />
                                                                                                        <ErrorMessage name="bank_number" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">Ngày Tháng Năm*</label>
                                                                                                        <Field
                                                                                                            type="date"
                                                                                                            name="bank_number"
                                                                                                            className="form-control"
                                                                                                            placeholder=""
                                                                                                            required=""
                                                                                                            value={date1}
                                                                                                            onChange={onChangeDate1}
                                                                                                            fdprocessedid="4aq0kr"
                                                                                                        />
                                                                                                        <ErrorMessage name="bank_number" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-2 col-6 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">giờ*</label>
                                                                                                        <Field
                                                                                                            as="select"
                                                                                                            name="hour"
                                                                                                            className="form-control form-control-new"
                                                                                                            required=""
                                                                                                            value={hour1}
                                                                                                            onChange={onChangeHour1}
                                                                                                            fdprocessedid="4aq0kr"
                                                                                                        >
                                                                                                            {[...Array(24)].map((_, index) => (
                                                                                                                <option key={index + 1} value={index + 1}>
                                                                                                                    {index + 1}
                                                                                                                </option>
                                                                                                            ))}
                                                                                                        </Field>
                                                                                                        <ErrorMessage name="hour" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-2 col-3 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">Phút*</label>
                                                                                                        <Field
                                                                                                            as="select"
                                                                                                            name="minute"
                                                                                                            className="form-control form-control-new"
                                                                                                            required=""
                                                                                                            value={minute1}
                                                                                                            onChange={onChangeMinute1}
                                                                                                            fdprocessedid="4aq0kr"
                                                                                                        >
                                                                                                            {[...Array(60)].map((_, index) => (
                                                                                                                <option key={index + 1} value={index + 1}>
                                                                                                                    {index + 1}
                                                                                                                </option>
                                                                                                            ))}
                                                                                                        </Field>
                                                                                                        <ErrorMessage name="minute" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-lg-2 col-3 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">Giây*</label>
                                                                                                        <Field
                                                                                                            as="select"
                                                                                                            name="second1"
                                                                                                            className="form-control form-control-new"
                                                                                                            required=""
                                                                                                            value={second1}
                                                                                                            onChange={onChangeSecond1}
                                                                                                            fdprocessedid="4aq0kr"
                                                                                                        >
                                                                                                            {[...Array(60)].map((_, index) => (
                                                                                                                <option key={index + 1} value={index + 1}>
                                                                                                                    {index + 1}
                                                                                                                </option>
                                                                                                            ))}
                                                                                                        </Field>
                                                                                                        <ErrorMessage name="minute" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">Số tiền*</label>
                                                                                                        <CurrencyInput
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                            value={amount1}
                                                                                                            onChange={e => {
                                                                                                                let value = e.target.value || "";
                                                                                                                value = value.replaceAll(',', '');
                                                                                                                onChangeAmount1(e)
                                                                                                            }}
                                                                                                        />
                                                                                                        <ErrorMessage name="amount" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">Số dư*</label>
                                                                                                        <CurrencyInput
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                            value={balance1}
                                                                                                            onChange={e => {
                                                                                                                let value = e.target.value || "";
                                                                                                                value = value.replaceAll(',', '');
                                                                                                                onChangeBalance1(e)
                                                                                                            }}
                                                                                                        />
                                                                                                        <ErrorMessage name="amount_blance" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">Mã giao dịch*</label>
                                                                                                        <Field
                                                                                                            type="text"
                                                                                                            name="transaction_code"
                                                                                                            className="form-control"
                                                                                                            placeholder=""
                                                                                                            required=""
                                                                                                            value={transaction1}
                                                                                                            onChange={onChangeTransaction1}

                                                                                                            fdprocessedid="4aq0kr"
                                                                                                        />
                                                                                                        <ErrorMessage name="transaction_code" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">Người chuyển*</label>
                                                                                                        <Field
                                                                                                            type="text"
                                                                                                            name="sender"
                                                                                                            className="form-control"
                                                                                                            placeholder=""
                                                                                                            required=""
                                                                                                            value={accountGuest1}
                                                                                                            onChange={onChangeAccountGuest1}
                                                                                                            fdprocessedid="4aq0kr"
                                                                                                        />
                                                                                                        <ErrorMessage name="sender" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-4 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">Ghi chú*</label>
                                                                                                        <Field
                                                                                                            type="text"
                                                                                                            name="sender"
                                                                                                            className="form-control"
                                                                                                            placeholder=""
                                                                                                            required=""
                                                                                                            value={note1}
                                                                                                            onChange={onChangeNote1}
                                                                                                            fdprocessedid="4aq0kr"
                                                                                                        />
                                                                                                        <ErrorMessage name="sender" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                            :
                                                                                            (
                                                                                                <>
                                                                                                    <div className="col-lg-4 mb-2">
                                                                                                        <div className="mb-3">
                                                                                                            <label className="text-label form-label">Tiêu đề*</label>
                                                                                                            <Field
                                                                                                                type="text"
                                                                                                                name="sender"
                                                                                                                className="form-control"
                                                                                                                placeholder=""
                                                                                                                required=""
                                                                                                                value={titleMessGlobal1}
                                                                                                                onChange={onChangeTitleMessGlobal1}
                                                                                                                fdprocessedid="4aq0kr"
                                                                                                            />
                                                                                                            <ErrorMessage name="sender" component="div" className="error-message text-danger" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-lg-4 mb-2">
                                                                                                        <div className="mb-3">
                                                                                                            <label className="text-label form-label">Nội dung*</label>
                                                                                                            <Field
                                                                                                                type="text"
                                                                                                                name="sender"
                                                                                                                className="form-control"
                                                                                                                placeholder=""
                                                                                                                required=""
                                                                                                                value={contentMessGlobal1}
                                                                                                                onChange={onChangeContentMessGlobal1}
                                                                                                                fdprocessedid="4aq0kr"
                                                                                                            />
                                                                                                            <ErrorMessage name="sender" component="div" className="error-message text-danger" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-lg-4 mb-2">
                                                                                                        <div className="mb-3">
                                                                                                            <label className="text-label form-label">thời gian chữ*</label>
                                                                                                            <Field
                                                                                                                type="text"
                                                                                                                name="bank_number"
                                                                                                                className="form-control"
                                                                                                                placeholder=""
                                                                                                                required=""
                                                                                                                value={timeWord1}
                                                                                                                onChange={onChangeTimeWord1}
                                                                                                                fdprocessedid="4aq0kr"
                                                                                                            />
                                                                                                            <ErrorMessage name="bank_number" component="div" className="error-message text-danger" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                    }
                                                                                    {
                                                                                        (showFormNotiTwo === false) && (
                                                                                            <>
                                                                                                <div className='d-flex justify-content-end'>
                                                                                                    <button className='btn btn-primary btn-sm' type='button' onClick={handleShowFormNotiTwo}>+ Thêm Thông báo</button>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        (showFormNotiTwo === true) && (
                                                                                            <>
                                                                                                <div className='d-flex justify-content-center text-warning m-3'>
                                                                                                    -- Thông báo thứ hai --
                                                                                                </div>
                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">Logo <span className='text-warning'>Bank</span> hoặc <span className='text-warning'>Tin nhắn</span>*</label>
                                                                                                        {
                                                                                                            operaSystem == 'ios' && (
                                                                                                                <Field
                                                                                                                    as="select"
                                                                                                                    name="logo1"
                                                                                                                    className="form-control form-control-new"
                                                                                                                    required=""
                                                                                                                    value={logo2}
                                                                                                                    onChange={onChangeLogo2}
                                                                                                                    fdprocessedid="4aq0kr"
                                                                                                                >
                                                                                                                    <option value="VCB">VCB</option>
                                                                                                                    <option value="TCB">TCB</option>
                                                                                                                    <option value="ACB">ACB</option>
                                                                                                                    <option value="BIDV">BIDV</option>
                                                                                                                    <option value="ICB">Viettin</option>
                                                                                                                    <option value="VBA">Agribank</option>
                                                                                                                    <option value="TPB">TPB</option>
                                                                                                                    <option value="VPB">VPB</option>
                                                                                                                    <option value="MSB">MSB</option>
                                                                                                                    <option value="MB">MB</option>
                                                                                                                    <option value="SCB">SCB</option>
                                                                                                                    <option value="IOS_MESS">Messenger</option>
                                                                                                                    <option value="IOS_SMS">Tin nhắn</option>
                                                                                                                    <option value="ZALO">Zalo</option>
                                                                                                                </Field>
                                                                                                            )
                                                                                                        }
                                                                                                        {
                                                                                                            operaSystem == 'android' && (
                                                                                                                <Field
                                                                                                                    as="select"
                                                                                                                    name="logo1"
                                                                                                                    className="form-control form-control-new"
                                                                                                                    required=""
                                                                                                                    value={logo2}
                                                                                                                    onChange={onChangeLogo2}
                                                                                                                    fdprocessedid="4aq0kr"
                                                                                                                >
                                                                                                                    <option value="VCB">VCB</option>
                                                                                                                    <option value="TCB">TCB</option>
                                                                                                                    <option value="ACB">ACB</option>
                                                                                                                    <option value="BIDV">BIDV</option>
                                                                                                                    <option value="ICB">Viettin</option>
                                                                                                                    <option value="VBA">Agribank</option>
                                                                                                                    <option value="TPB">TPB</option>
                                                                                                                    <option value="VPB">VPB</option>
                                                                                                                    <option value="MSB">MSB</option>
                                                                                                                    <option value="MB">MB</option>
                                                                                                                    <option value="SCB">SCB</option>
                                                                                                                    <option value="ANDROID_MESS">Messenger</option>
                                                                                                                    <option value="ANDROID_SMS">Tin nhắn</option>
                                                                                                                    <option value="ZALO">Zalo</option>
                                                                                                                </Field>
                                                                                                            )
                                                                                                        }
                                                                                                        <ErrorMessage name="logo1" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    (logo2 != 'IOS_MESS' && logo2 != 'IOS_SMS' && logo2 != 'ANDROID_MESS' && logo2 != 'ANDROID_SMS' && logo2 != 'ZALO') ? (
                                                                                                        <>
                                                                                                            <div className="col-lg-4 col-6 mb-2">
                                                                                                                <div className="mb-3">
                                                                                                                    <label className="text-label form-label">Số tài khoản*</label>
                                                                                                                    <Field
                                                                                                                        type="text"
                                                                                                                        name="bank_number2"
                                                                                                                        className="form-control"
                                                                                                                        placeholder=""
                                                                                                                        required=""
                                                                                                                        value={account2}
                                                                                                                        onChange={onChangeAccount2}
                                                                                                                        fdprocessedid="4aq0kr"
                                                                                                                    />
                                                                                                                    <ErrorMessage name="bank_number2" component="div" className="error-message text-danger" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-lg-4 col-6 mb-2">
                                                                                                                <div className="mb-3">
                                                                                                                    <label className="text-label form-label">thời gian chữ*</label>
                                                                                                                    <Field
                                                                                                                        type="text"
                                                                                                                        name="bank_number"
                                                                                                                        className="form-control"
                                                                                                                        placeholder=""
                                                                                                                        required=""
                                                                                                                        value={timeWord2}
                                                                                                                        onChange={onChangeTimeWord2}
                                                                                                                        fdprocessedid="4aq0kr"
                                                                                                                    />
                                                                                                                    <ErrorMessage name="bank_number" component="div" className="error-message text-danger" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-lg-4 col-6 mb-2">
                                                                                                                <div className="mb-3">
                                                                                                                    <label className="text-label form-label">Ngày Tháng Năm*</label>
                                                                                                                    <Field
                                                                                                                        type="date"
                                                                                                                        name="bank_number"
                                                                                                                        className="form-control"
                                                                                                                        placeholder=""
                                                                                                                        required=""
                                                                                                                        value={date2}
                                                                                                                        onChange={onChangeDate2}
                                                                                                                        fdprocessedid="4aq0kr"
                                                                                                                    />
                                                                                                                    <ErrorMessage name="bank_number" component="div" className="error-message text-danger" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-lg-2 col-6 mb-2">
                                                                                                                <div className="mb-3">
                                                                                                                    <label className="text-label form-label">giờ*</label>
                                                                                                                    <Field
                                                                                                                        as="select"
                                                                                                                        name="hour"
                                                                                                                        className="form-control form-control-new"
                                                                                                                        required=""
                                                                                                                        value={hour2}
                                                                                                                        onChange={onChangeHour2}
                                                                                                                        fdprocessedid="4aq0kr"
                                                                                                                    >
                                                                                                                        {[...Array(24)].map((_, index) => (
                                                                                                                            <option key={index + 1} value={index + 1}>
                                                                                                                                {index + 1}
                                                                                                                            </option>
                                                                                                                        ))}
                                                                                                                    </Field>
                                                                                                                    <ErrorMessage name="hour" component="div" className="error-message text-danger" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-lg-2 col-3 mb-2">
                                                                                                                <div className="mb-3">
                                                                                                                    <label className="text-label form-label">Phút*</label>
                                                                                                                    <Field
                                                                                                                        as="select"
                                                                                                                        name="minute"
                                                                                                                        className="form-control form-control-new"
                                                                                                                        required=""
                                                                                                                        value={minute2}
                                                                                                                        onChange={onChangeMinute2}
                                                                                                                        fdprocessedid="4aq0kr"
                                                                                                                    >
                                                                                                                        {[...Array(60)].map((_, index) => (
                                                                                                                            <option key={index + 1} value={index + 1}>
                                                                                                                                {index + 1}
                                                                                                                            </option>
                                                                                                                        ))}
                                                                                                                    </Field>
                                                                                                                    <ErrorMessage name="minute" component="div" className="error-message text-danger" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-lg-2 col-3 mb-2">
                                                                                                                <div className="mb-3">
                                                                                                                    <label className="text-label form-label">Giây*</label>
                                                                                                                    <Field
                                                                                                                        as="select"
                                                                                                                        name="minute"
                                                                                                                        className="form-control form-control-new"
                                                                                                                        required=""
                                                                                                                        value={second2}
                                                                                                                        onChange={onChangeSecond2}
                                                                                                                        fdprocessedid="4aq0kr"
                                                                                                                    >
                                                                                                                        {[...Array(60)].map((_, index) => (
                                                                                                                            <option key={index + 1} value={index + 1}>
                                                                                                                                {index + 1}
                                                                                                                            </option>
                                                                                                                        ))}
                                                                                                                    </Field>
                                                                                                                    <ErrorMessage name="minute" component="div" className="error-message text-danger" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-lg-4 col-6 mb-2">
                                                                                                                <div className="mb-3">
                                                                                                                    <label className="text-label form-label">Số tiền*</label>
                                                                                                                    <CurrencyInput
                                                                                                                        type="text"
                                                                                                                        className="form-control"
                                                                                                                        value={amount2}
                                                                                                                        onChange={e => {
                                                                                                                            let value = e.target.value || "";
                                                                                                                            value = value.replaceAll(',', '');
                                                                                                                            onChangeAmount2(e)
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <ErrorMessage name="amount2" component="div" className="error-message text-danger" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-lg-4 col-6 mb-2">
                                                                                                                <div className="mb-3">
                                                                                                                    <label className="text-label form-label">Số dư*</label>
                                                                                                                    <CurrencyInput
                                                                                                                        type="text"
                                                                                                                        className="form-control"
                                                                                                                        value={balance2}
                                                                                                                        onChange={e => {
                                                                                                                            let value = e.target.value || "";
                                                                                                                            value = value.replaceAll(',', '');
                                                                                                                            onChangeBalance2(e)
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <ErrorMessage name="amount_blance2" component="div" className="error-message text-danger" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-lg-4 col-6 mb-2">
                                                                                                                <div className="mb-3">
                                                                                                                    <label className="text-label form-label">Mã giao dịch*</label>
                                                                                                                    <Field
                                                                                                                        type="text"
                                                                                                                        name="transaction_code2"
                                                                                                                        className="form-control"
                                                                                                                        placeholder=""
                                                                                                                        required=""
                                                                                                                        value={transaction2}
                                                                                                                        onChange={onChangeTransaction2}
                                                                                                                        fdprocessedid="4aq0kr"
                                                                                                                    />
                                                                                                                    <ErrorMessage name="transaction_code2" component="div" className="error-message text-danger" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-lg-4 col-6 mb-2">
                                                                                                                <div className="mb-3">
                                                                                                                    <label className="text-label form-label">Người chuyển*</label>
                                                                                                                    <Field
                                                                                                                        type="text"
                                                                                                                        name="sender2"
                                                                                                                        className="form-control"
                                                                                                                        placeholder=""
                                                                                                                        required=""
                                                                                                                        value={accountGuest2}
                                                                                                                        onChange={onChangeAccountGuest2}
                                                                                                                        fdprocessedid="4aq0kr"
                                                                                                                    />
                                                                                                                    <ErrorMessage name="sender2" component="div" className="error-message text-danger" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-lg-4 mb-2">
                                                                                                                <div className="mb-3">
                                                                                                                    <label className="text-label form-label">Ghi chú*</label>
                                                                                                                    <Field
                                                                                                                        type="text"
                                                                                                                        name="sender"
                                                                                                                        className="form-control"
                                                                                                                        placeholder=""
                                                                                                                        required=""
                                                                                                                        value={note2}
                                                                                                                        onChange={onChangeNote2}
                                                                                                                        fdprocessedid="4aq0kr"
                                                                                                                    />
                                                                                                                    <ErrorMessage name="sender" component="div" className="error-message text-danger" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )
                                                                                                        :
                                                                                                        (

                                                                                                            <>
                                                                                                                <div className="col-lg-4 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Tiêu đề*</label>
                                                                                                                        <Field
                                                                                                                            type="text"
                                                                                                                            name="sender"
                                                                                                                            className="form-control"
                                                                                                                            placeholder=""
                                                                                                                            required=""
                                                                                                                            value={titleMessGlobal2}
                                                                                                                            onChange={onChangeTitleMessGlobal2}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        />
                                                                                                                        <ErrorMessage name="sender" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-4 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Nội dung*</label>
                                                                                                                        <Field
                                                                                                                            type="text"
                                                                                                                            name="sender"
                                                                                                                            className="form-control"
                                                                                                                            placeholder=""
                                                                                                                            required=""
                                                                                                                            value={contentMessGlobal2}
                                                                                                                            onChange={onChangeContentMessGlobal2}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        />
                                                                                                                        <ErrorMessage name="sender" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">thời gian chữ*</label>
                                                                                                                        <Field
                                                                                                                            type="text"
                                                                                                                            name="bank_number"
                                                                                                                            className="form-control"
                                                                                                                            placeholder=""
                                                                                                                            required=""
                                                                                                                            value={timeWord2}
                                                                                                                            onChange={onChangeTimeWord2}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        />
                                                                                                                        <ErrorMessage name="bank_number" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                }
                                                                                                {
                                                                                                    (showFormNotiThree === false) && (
                                                                                                        <div className='d-flex justify-content-end'>
                                                                                                            <button className='btn btn-primary btn-sm' type='button' onClick={handleShowFormNotiThree} >+ Thêm Thông báo</button>
                                                                                                            <button className='btn btn-danger btn-sm ms-2' type='button' onClick={handleCloseFormNotiTwo}>Xóa Thông báo thứ 2</button>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    }

                                                                                    {
                                                                                        (showFormNotiThree === true) && (
                                                                                            <>
                                                                                                <div className='d-flex justify-content-center text-warning m-3'>
                                                                                                    -- Thông báo thứ ba --
                                                                                                </div>
                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="text-label form-label">Logo <span className='text-warning'>Bank</span> hoặc <span className='text-warning'>Tin nhắn</span>*</label>
                                                                                                        {
                                                                                                            operaSystem == 'ios' && (
                                                                                                                <Field
                                                                                                                    as="select"
                                                                                                                    name="logo1"
                                                                                                                    className="form-control form-control-new"
                                                                                                                    required=""
                                                                                                                    value={logo3}
                                                                                                                    onChange={onChangeLogo3}
                                                                                                                    fdprocessedid="4aq0kr"
                                                                                                                >
                                                                                                                    <option value="VCB">VCB</option>
                                                                                                                    <option value="TCB">TCB</option>
                                                                                                                    <option value="ACB">ACB</option>
                                                                                                                    <option value="BIDV">BIDV</option>
                                                                                                                    <option value="ICB">Viettin</option>
                                                                                                                    <option value="VBA">Agribank</option>
                                                                                                                    <option value="TPB">TPB</option>
                                                                                                                    <option value="VPB">VPB</option>
                                                                                                                    <option value="MSB">MSB</option>
                                                                                                                    <option value="MB">MB</option>
                                                                                                                    <option value="SCB">SCB</option>
                                                                                                                    <option value="IOS_MESS">Mesenger</option>
                                                                                                                    <option value="IOS_SMS">Tin nhắn</option>
                                                                                                                    <option value="ZALO">Zalo</option>
                                                                                                                </Field>
                                                                                                            )
                                                                                                        }
                                                                                                        {
                                                                                                            operaSystem == 'android' && (
                                                                                                                <Field
                                                                                                                    as="select"
                                                                                                                    name="logo1"
                                                                                                                    className="form-control form-control-new"
                                                                                                                    required=""
                                                                                                                    value={logo3}
                                                                                                                    onChange={onChangeLogo3}
                                                                                                                    fdprocessedid="4aq0kr"
                                                                                                                >
                                                                                                                    <option value="VCB">VCB</option>
                                                                                                                    <option value="TCB">TCB</option>
                                                                                                                    <option value="ACB">ACB</option>
                                                                                                                    <option value="BIDV">BIDV</option>
                                                                                                                    <option value="ICB">Viettin</option>
                                                                                                                    <option value="VBA">Agribank</option>
                                                                                                                    <option value="TPB">TPB</option>
                                                                                                                    <option value="VPB">VPB</option>
                                                                                                                    <option value="MSB">MSB</option>
                                                                                                                    <option value="MB">MB</option>
                                                                                                                    <option value="SCB">SCB</option>
                                                                                                                    <option value="ANDROID_MESS">Messenger</option>
                                                                                                                    <option value="ANDROID_SMS">Tin nhắn</option>
                                                                                                                    <option value="ZALO">Zalo</option>
                                                                                                                </Field>
                                                                                                            )
                                                                                                        }

                                                                                                        <ErrorMessage name="logo1" component="div" className="error-message text-danger" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    (logo3 != 'IOS_MESS' && logo3 != 'IOS_SMS' && logo3 != 'ANDROID_MESS' && logo3 != 'ANDROID_SMS' && logo3 != 'ZALO') ?
                                                                                                        (
                                                                                                            <>
                                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Số tài khoản*</label>
                                                                                                                        <Field
                                                                                                                            type="text"
                                                                                                                            name="bank_number3"
                                                                                                                            className="form-control"
                                                                                                                            placeholder=""
                                                                                                                            required=""
                                                                                                                            value={account3}
                                                                                                                            onChange={onChangeAccount3}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        />
                                                                                                                        <ErrorMessage name="bank_number3" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">thời gian chữ*</label>
                                                                                                                        <Field
                                                                                                                            type="text"
                                                                                                                            name="bank_number"
                                                                                                                            className="form-control"
                                                                                                                            placeholder=""
                                                                                                                            required=""
                                                                                                                            value={timeWord3}
                                                                                                                            onChange={onChangeTimeWord3}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        />
                                                                                                                        <ErrorMessage name="bank_number" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Ngày Tháng Năm*</label>
                                                                                                                        <Field
                                                                                                                            type="date"
                                                                                                                            name="bank_number"
                                                                                                                            className="form-control"
                                                                                                                            placeholder=""
                                                                                                                            required=""
                                                                                                                            value={date3}
                                                                                                                            onChange={onChangeDate3}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        />
                                                                                                                        <ErrorMessage name="bank_number" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-2 col-6 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">giờ*</label>
                                                                                                                        <Field
                                                                                                                            as="select"
                                                                                                                            name="hour"
                                                                                                                            className="form-control form-control-new"
                                                                                                                            required=""
                                                                                                                            value={hour3}
                                                                                                                            onChange={onChangeHour3}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        >
                                                                                                                            {[...Array(24)].map((_, index) => (
                                                                                                                                <option key={index + 1} value={index + 1}>
                                                                                                                                    {index + 1}
                                                                                                                                </option>
                                                                                                                            ))}
                                                                                                                        </Field>
                                                                                                                        <ErrorMessage name="hour" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-2 col-3 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Phút*</label>
                                                                                                                        <Field
                                                                                                                            as="select"
                                                                                                                            name="minute"
                                                                                                                            className="form-control form-control-new"
                                                                                                                            required=""
                                                                                                                            value={minute3}
                                                                                                                            onChange={onChangeMinute3}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        >
                                                                                                                            {[...Array(60)].map((_, index) => (
                                                                                                                                <option key={index + 1} value={index + 1}>
                                                                                                                                    {index + 1}
                                                                                                                                </option>
                                                                                                                            ))}
                                                                                                                        </Field>
                                                                                                                        <ErrorMessage name="minute" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-2 col-3 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Giây*</label>
                                                                                                                        <Field
                                                                                                                            as="select"
                                                                                                                            name="minute"
                                                                                                                            className="form-control form-control-new"
                                                                                                                            required=""
                                                                                                                            value={second3}
                                                                                                                            onChange={onChangeSecond3}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        >
                                                                                                                            {[...Array(60)].map((_, index) => (
                                                                                                                                <option key={index + 1} value={index + 1}>
                                                                                                                                    {index + 1}
                                                                                                                                </option>
                                                                                                                            ))}
                                                                                                                        </Field>
                                                                                                                        <ErrorMessage name="minute" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Số tiền*</label>
                                                                                                                        <CurrencyInput
                                                                                                                            type="text"
                                                                                                                            className="form-control"
                                                                                                                            value={amount3}
                                                                                                                            onChange={e => {
                                                                                                                                let value = e.target.value || "";
                                                                                                                                value = value.replaceAll(',', '');
                                                                                                                                onChangeAmount3(e)
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        <ErrorMessage name="amount3" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Số dư*</label>
                                                                                                                        <CurrencyInput
                                                                                                                            type="text"
                                                                                                                            className="form-control"
                                                                                                                            value={balance3}
                                                                                                                            onChange={e => {
                                                                                                                                let value = e.target.value || "";
                                                                                                                                value = value.replaceAll(',', '');
                                                                                                                                onChangeBalance3(e)
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        <ErrorMessage name="amount_blance3" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Mã giao dịch*</label>
                                                                                                                        <Field
                                                                                                                            type="text"
                                                                                                                            name="transaction_code3"
                                                                                                                            className="form-control"
                                                                                                                            placeholder=""
                                                                                                                            required=""
                                                                                                                            value={transaction3}
                                                                                                                            onChange={onChangeTransaction3}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        />
                                                                                                                        <ErrorMessage name="transaction_code3" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-4 col-6 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Người chuyển*</label>
                                                                                                                        <Field
                                                                                                                            type="text"
                                                                                                                            name="sender3"
                                                                                                                            className="form-control"
                                                                                                                            placeholder=""
                                                                                                                            required=""
                                                                                                                            value={accountGuest3}
                                                                                                                            onChange={onChangeAccountGuest3}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        />
                                                                                                                        <ErrorMessage name="sender3" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-4 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Ghi chú*</label>
                                                                                                                        <Field
                                                                                                                            type="text"
                                                                                                                            name="sender"
                                                                                                                            className="form-control"
                                                                                                                            placeholder=""
                                                                                                                            required=""
                                                                                                                            value={note3}
                                                                                                                            onChange={onChangeNote3}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        />
                                                                                                                        <ErrorMessage name="sender" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                        :
                                                                                                        (
                                                                                                            <>
                                                                                                                <div className="col-lg-4 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Tiêu đề*</label>
                                                                                                                        <Field
                                                                                                                            type="text"
                                                                                                                            name="sender"
                                                                                                                            className="form-control"
                                                                                                                            placeholder=""
                                                                                                                            required=""
                                                                                                                            value={titleMessGlobal3}
                                                                                                                            onChange={onChangeTitleMessGlobal3}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        />
                                                                                                                        <ErrorMessage name="sender" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-4 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">Nội dung*</label>
                                                                                                                        <Field
                                                                                                                            type="text"
                                                                                                                            name="sender"
                                                                                                                            className="form-control"
                                                                                                                            placeholder=""
                                                                                                                            required=""
                                                                                                                            value={contentMessGlobal3}
                                                                                                                            onChange={onChangeContentMessGlobal3}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        />
                                                                                                                        <ErrorMessage name="sender" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-4 mb-2">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label className="text-label form-label">thời gian chữ*</label>
                                                                                                                        <Field
                                                                                                                            type="text"
                                                                                                                            name="bank_number"
                                                                                                                            className="form-control"
                                                                                                                            placeholder=""
                                                                                                                            required=""
                                                                                                                            value={timeWord3}
                                                                                                                            onChange={onChangeTimeWord3}
                                                                                                                            fdprocessedid="4aq0kr"
                                                                                                                        />
                                                                                                                        <ErrorMessage name="bank_number" component="div" className="error-message text-danger" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                }

                                                                                                <div className='d-flex justify-content-end'>
                                                                                                    <button className='btn btn-danger btn-sm ms-2' type='button' onClick={handleCloseFormNotiThree}>Xóa Thông báo thứ 3</button>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }


                                                                                    {/* Thêm các trường khác tương tự */}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="toolbar toolbar-bottom d-flex justify-content-between mt-5"
                                                                            role="toolbar"
                                                                            style={{ textAlign: 'right' }}
                                                                        >
                                                                            <Link
                                                                                className="btn btn-primary sw-btn-prev"
                                                                                to="/fake-notication"
                                                                            >
                                                                                Quay lại
                                                                            </Link>
                                                                            <button type="button" onClick={handleClickDowNoti} className="btn btn-primary ms-2 sw-btn-prev">
                                                                                Tải ảnh
                                                                                <svg className='ms-2 bi bi-cloud-download' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                                                    <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                                                                                    <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </Form>
                                                )}

                                            </Formik>
                                            <div className='row pt-3'>
                                                <div className='col-12 d-flex justify-content-center'>
                                                    <strong className='text-warning'>*Lưu ý :</strong>
                                                    <p className='text-primary'>- Phí tạo bill là : 19.000 VNĐ</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-bill-wrapper custom-center-bill-ver2">

                            {
                                operaSystem == 'ios' ?
                                    <ImageHTMLIOS
                                        network={network} pin={pin} date={date} hour={hour} minute={minute} networkDark={networkDark}
                                        logo1={logo1} contentMessGlobal1={contentMessGlobal1} titleMessGlobal1={titleMessGlobal1} minute1={minute1} second1={second1} accountGuest1={accountGuest1} date1={date1} hour1={hour1} account1={account1} transaction1={transaction1} balance1={balance1} note1={note1} amount1={amount1} timeWord1={timeWord1}
                                        logo2={logo2} contentMessGlobal2={contentMessGlobal2} titleMessGlobal2={titleMessGlobal2} minute2={minute2} second2={second2} accountGuest2={accountGuest2} date2={date2} hour2={hour2} account2={account2} transaction2={transaction2} balance2={balance2} note2={note2} amount2={amount2} timeWord2={timeWord2}
                                        logo3={logo3} contentMessGlobal3={contentMessGlobal3} titleMessGlobal3={titleMessGlobal3} minute3={minute3} second3={second3} accountGuest3={accountGuest3} date3={date3} hour3={hour3} account3={account3} transaction3={transaction3} balance3={balance3} note3={note3} amount3={amount3} timeWord3={timeWord3}
                                        showFormNotiTwo={showFormNotiTwo}
                                        showFormNotiThree={showFormNotiThree}
                                        driverNetwork={driverNetwork}
                                        background={background}
                                        divRef={divRef}
                                        hideMark={hideMark}
                                    />
                                    :
                                    <ImageHTMLAndroid
                                        network={network} pin={pin} date={date} hour={hour} minute={minute} networkDark={networkDark}
                                        logo1={logo1} contentMessGlobal1={contentMessGlobal1} titleMessGlobal1={titleMessGlobal1} minute1={minute1} second1={second1} accountGuest1={accountGuest1} date1={date1} hour1={hour1} account1={account1} transaction1={transaction1} balance1={balance1} note1={note1} amount1={amount1} timeWord1={timeWord1}
                                        logo2={logo2} contentMessGlobal2={contentMessGlobal2} titleMessGlobal2={titleMessGlobal2} minute2={minute2} second2={second2} accountGuest2={accountGuest2} date2={date2} hour2={hour2} account2={account2} transaction2={transaction2} balance2={balance2} note2={note2} amount2={amount2} timeWord2={timeWord2}
                                        logo3={logo3} contentMessGlobal3={contentMessGlobal3} titleMessGlobal3={titleMessGlobal3} minute3={minute3} second3={second3} accountGuest3={accountGuest3} date3={date3} hour3={hour3} account3={account3} transaction3={transaction3} balance3={balance3} note3={note3} amount3={amount3} timeWord3={timeWord3}
                                        showFormNotiTwo={showFormNotiTwo}
                                        showFormNotiThree={showFormNotiThree}
                                        driverNetwork={driverNetwork}
                                        background={background}
                                        divRef={divRef}
                                        hideMark={hideMark}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </MasterLayout >
    );
}

export default FormCreateFakeNoti;