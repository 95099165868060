import React, { useState } from 'react';
import InstructBank from '../instructs/InstructBank';
function BankVideo(props) {
    return (
        <div className="card">
            <div className="card-body p-3">
                <strong className='text-success d-flex justify-content-center pb-2'>HƯỚNG DẪN SỬ DỤNG</strong>
                <InstructBank/>
            </div>
        </div>
    );
}

export default BankVideo;