import React from 'react';
import { Link } from 'react-router-dom';

function BankItemFackNoti(props) {
    const handleButtonClick = () => {
        window.location.href = '/notication/create'; // Thay đổi URL theo nhu cầu của bạn
    };
    return (
        <div className="card overflow-hidden">
            <div className="card-body">
                <div className="text-center">
                    <div className="profile-photo">
                        <img
                            src='/asset-noti-custom/image/bank.png'
                            style={{
                                width: '200px',
                                height: '100px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                            }}
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <p className="mt-2 text-white">Fake thông báo <span className='text-warning'>Ngân hàng</span>  và Tin Nhắn</p>
                    <p className='mb-1 text-warning'>Messenger, Telegram, SMS</p>
                </div>
            </div>
            <div className="card-footer pt-0 pb-0 text-center">
                <div className="row">
                    <div className="col-12 pt-2 pb-2">
                        <button
                            onClick={handleButtonClick}
                            className='btn btn-primary'
                        >
                            Tạo thông báo
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BankItemFackNoti;