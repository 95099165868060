import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {  useSelector } from 'react-redux';
import Swal from "sweetalert2";

function DemoModalDeposit(props) {
    const sytemSettings = useSelector((state) => state.system_setting);
    const [modalShow, setModalShow] = React.useState(false);
    const handleCloseModal = () => {
        setModalShow(false);
    };

    const copyToClipboard = async (e) => {
        await navigator.clipboard.writeText(e);
        Swal.fire({
            icon: "success",
            title: "Đã copy!",
            showConfirmButton: false,
            timer: 1000,
        });
    };

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                       Nạp tiền vào tài khoản
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-center'>
                            <img src={sytemSettings?.imageTCB} width={300} height={300} />
                        </div>
                    </div>
                    <div className='row pt-5'>
                        <div className='col-12 d-flex justify-content-center'>
                        <div>
                            <div className='pb-2'><span className='text-warning'>Ngân hàng:</span> Ngân hàng Thương mại cổ phần Quốc Tế Việt Nam</div>
                            <div className='row pb-2 d-flex justify-content-between'>
                                <div className='col-12'>
                                    <span className='text-warning'>Số tài khoản:</span> {sytemSettings?.bankCodeVIB} <button className='' onTouchStart={() => copyToClipboard(sytemSettings?.bankCodeVIB)} onClick={() => copyToClipboard(sytemSettings?.bankCodeVIB)} style={{ padding: '5px 15px', borderRadius: '8px', backgroundColor: '#16dee5', color: 'white' }}>copy</button>
                                </div>
                            </div>
                            <div className='pb-2'><span className='text-warning'>Tên tài khoản:</span> {sytemSettings?.bankNameVIB} <button className='' onTouchStart={() => copyToClipboard(sytemSettings?.bankNameVIB)} onClick={() => copyToClipboard(sytemSettings?.bankNameVIB)} style={{ padding: '5px 15px', borderRadius: '8px', backgroundColor: '#16dee5', color: 'white' }}>copy</button></div>
                            <p className='text-warning'>*lưu ý: Nạp tiền thành công, hãy liên hệ admin.</p>
                        </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>


    );
}

export default DemoModalDeposit;