import React from 'react';
import ModalBuyPackage from './ModalBuyPackage';
import ModalBuyPackageBill from './ModalBuyPackageBill';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../../redux/action';
import PackageBillModel from '../../models/PackageBillModel';
import Swal from "sweetalert2";


function BillPackageItem(props) {
    const dispatch = useDispatch();

    const [modalShow, setModalShow] = React.useState(false);
    
    const handleDeletePackage = () => {
        const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa gói này?');
        if (confirmDelete){
            dispatch(setShowPreloader(true));
            PackageBillModel.deletePackageBill().then(function (res) {
                dispatch(setShowPreloader(false));
                    Swal.fire({
                        icon: "success",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    setTimeout(() => {
                        reloadPage();
                    }, 2000);
            }).catch(function (err) {
                dispatch(setShowPreloader(false));
                Swal.fire({
                    icon: "error",
                    title: 'Hủy gói thất bại!',
                    showConfirmButton: false,
                    timer: 2000,
                });
                console.log(err);
            })
        }
      }
      const reloadPage = () => {
        window.location.reload();
      };
    return (
        <>
            <div className="col-xl-4 col-xxl-6 col-lg-6">
                <div className="card">
                    <div className="card-header  border-0 pb-0">
                        <div className='row d-flex justify-content-center'>
                            <div className='col-12 d-flex justify-content-center'>
                                <h4 className={props.package.type == 'vip3' ? "card-title text-success" : "card-title text-warning"}>
                                    {props.package.name}
                                </h4>
                            </div>
                            <div className='col-12 d-flex justify-content-center'>
                                {props.package.type == 'vip1' && <img src="/images/vip1.png" width={100} height={100} />}
                                {props.package.type == 'vip2' && <img src="/images/vip 2.png" width={100} height={100} />}
                                {props.package.type == 'vip3' && <img src="/images/vip3.png" width={100} height={100} />}

                            </div>
                            <div className='col-12 d-flex justify-content-center'>
                                <h1 className="card-title text-primary">
                                    {props.package.price_fm} VNĐ /
                                    {props.package.type === 'vip1' ? ' tháng' :
                                        props.package.type === 'vip2' ? ' Năm' :
                                            props.package.type === 'vip3' ? ' Vĩnh viễn' : ''}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div
                            id="DZ_W_Todo1"
                            className="widget-media dz-scroll ps ps--active-y"
                        >
                            <div className='row'>
                                <div className='col-12 pb-4'><strong className='text-warning'>Quyền lợi:<span className='text-primary'> Áp dụng cho 3 dịch vụ gồm: bill chuyển khoản, bill thông báo và bill số dư</span></strong></div>
                            </div>
                            <div className='row pb-3'>
                                <div className='col-12'>
                                    <strong className=''>{props.package.max_download_bill == -1 ? 'Tải bill không giới hạn' : `- Tải tối đa ${props.package.max_download_bill} bill / ngày`}<span className='text-primary'> (Tổng bill tải của 3 dịch vụ)</span></strong>
                                </div>
                            </div>
                            <div className='row pb-3'>
                                <div className='col-12'>
                                    <strong className=''>- Có thể đăng nhập trên {props.package.max_device_login} thiết bị</strong>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                {
                                    props.package.is_package_bill == true ?
                                        (
                                            <button className="btn btn-danger sw-btn-next ms-2" onClick={handleDeletePackage} type="button">
                                                Hủy gói
                                            </button>
                                        )
                                        :
                                        (
                                            <button className="btn btn-primary sw-btn-next" onClick={() => setModalShow(true)} type="button">
                                                Đăng ký gói
                                            </button>
                                        )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalBuyPackageBill
                show={modalShow}
                onHide={() => setModalShow(false)}
                packageName={props.package.name}
                packagePrice={props.package.price_fm}
                packagePriceNoFm={props.package.price}
                typePackage={props.package.type}
            />
        </>
    );
}

export default BillPackageItem;