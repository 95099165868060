import axiosClient from './axiosClient';
import AppCookie from "../helpers/AppCookie";

class FakeNotiModel {
    constructor() {
        this.date = '';
    }
    formatDate (date) {
        const dateObj = new Date(date);

        const daysOfWeek = ['CN', 'Th 2', 'Th 3', 'Th 4', 'Th 5', 'Th 6', 'Th 7'];
        const monthsOfYear = ['Th 1', 'Th 2', 'Th 3', 'Th 4', 'Th 5', 'Th 6', 'Th 7', 'Th 8', 'Th 9', 'Th 10', 'Th 11', 'Th 12'];

        const dayOfWeek = daysOfWeek[dateObj.getDay()];
        const month = monthsOfYear[dateObj.getMonth()];
        const day = dateObj.getDate();

        let formattedDate;
        if (dayOfWeek === 'CN') {
            formattedDate = `${dayOfWeek}, ${day} ${month}`;
        } else {
            formattedDate = `${dayOfWeek}, ${day} ${month}`;
        }
        return formattedDate;
    } 
}

export default new FakeNotiModel();