import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';



function CurrencyPage(props) {
    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Giao dịch ngoại tệ" />
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Chức năng sắp mắt</h4>
                                </div>
                                <div className="card-body">
                                   Đang phát triển...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MasterLayout>
    );
}

export default CurrencyPage;