import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import VerifyCode from '../../models/VerifyCode';
import AppNotification from '../../helpers/AppNotification';
import UserModel from '../../models/UserModel';
import PaymentModel from '../../models/PaymentModel';
import ModalOwnerBank from './ModalOwnerBank';
import Swal from "sweetalert2";
import CurrencyInput from '../global/CurrencyInput';
const validationSchema = Yup.object().shape({
    amount: Yup.number()
        .required('Vui lòng nhập số tiền')
        .min(10000, 'Số tiền phải lớn hơn hoặc bằng 10.000đ'),
});

function ModalDepositHandmade(props) {

    const ownerBank = props.bank;
    const [amount, setAmount] = useState();
    const [depositIdcheck, setDepositIdcheck] = useState(null);
    const [dataQR, setDataQR] = useState();

    const [modalShow, setModalShow] = React.useState(false);

    const handleCloseModal = () => {
        setModalShow(false);
    };

    const initialValues = {
        amount: '',
        user_id: props.dataRecord.user_id,
    };

    const handleSubmit = (values) => {
        UserModel.depositHandmade(values)
            .then(res => {
                Swal.fire({
                    icon: "success",
                    title: "Nạp " + res.amount + " VNĐ thành công!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                setTimeout(() => {
                    props.reloadPage();
                }, 2000);
            }).catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Nạp tiền thất bại!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    };


    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Nạp tiền thủ công cho tài khoản {props.dataRecord.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched, setFieldValue }) => (
                            <Form>
                                <div className="mb-3">
                                    <label htmlFor="amount" className="form-label">
                                        Số tiền nạp (*)
                                    </label>
                                    <CurrencyInput
                                        type="text"
                                        className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                            }`}
                                        autoFocus
                                        onChange={e => {
                                            let value = e.target.value || "";
                                            value = value.replaceAll(',', '');
                                            setFieldValue("amount", value);
                                        }}
                                    />
                                    <Field
                                        type="hidden"
                                        id="amount"
                                        name="amount"
                                        className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''
                                            }`}
                                        autoFocus
                                    />
                                    {errors.amount && touched.amount && (
                                        <div className="invalid-feedback">{errors.amount}</div>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={props.onHide}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-success">
                                        Xác nhận
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>


    );
}

export default ModalDepositHandmade;