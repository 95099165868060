import React, { useEffect, useState } from 'react';
import MasterLayout from '../../layouts/MasterLayout';
import ThemeBreadcrumb from '../../components/themes/ThemeBreadcrumb';
import HomeModel from '../../models/HomeModel';
import AppNotification from '../../helpers/AppNotification';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Paginate from '../../components/paginate/Paginate';
import UserModel from '../../models/UserModel';
import ModalDepositHandmade from '../../components/paymentdeposit/ModalDepositHandmade';
import ModalDetailUser from '../../components/users/ModalDetailUser';
import HomeAdminCardMoney from '../../components/homeAdmin/HomeAdminCardMoney';
import Swal from "sweetalert2";
import ModalAcountApp from '../../components/homeAdmin/ModalAcountApp';


function AdminHomePage(props) {

    // show modal account app
    const [showAcountApp,setShowAcountApp] = useState(false);
    const [userID,setUserID] = useState('');
    const [flagUserID,setFlagUserID] = useState(false);
    useEffect(() => {
        if (flagUserID == true) {
            setShowAcountApp(true);
        }
    }, [userID,flagUserID])
    const handleShowModalAppAcount = (id) => {
        setUserID(id);
        setFlagUserID(true);
    }

    const handleCloseModalAcountApp = () => {
        setUserID('');
        setShowAcountApp(false);
        setFlagUserID(false);
    }

    // xử lí tổng quan
    const [total, setTotal] = useState({});

    useEffect(() => {
        HomeModel.getDashboardAdmin().then((res) => {
            setTotal(res.data);
        }).catch((err) => {
            AppNotification.showError('Lấy dữ liệu thất bại!');
        })
    }, [])

    // paginate
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const [meta, setMeta] = useState({});
    const changePage = (page) => {
        setPage(page);
        setFilter({ search: keyword });
    }
    const handleSetPage = (number) => {
        setPage(page + number);
        setFilter({ search: keyword });
    }

    // search
    const [keyword, setKeyword] = useState({
        user_name : null
    });
    const [filter, setFilter] = useState({
        search: '',
        perPage: perPage,
        page: page,
    });
    const handleChange = (event) => {
        setKeyword({
            ...keyword,
            [event.target.name]: event.target.value
        });
        setPage(1);
        // Xử lý tìm kiếm dữ liệu dựa trên keyword
        setFilter({ search: keyword });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setPage(1);
        // Xử lý tìm kiếm dữ liệu dựa trên keyword
        setFilter({ search: keyword });
        // Reset trường input sau khi submit
    }


    // xử lý
    const [data, setData] = useState([]);


    useEffect(() => {
        UserModel.getAllUser(
            {
                search: filter.search,
                page: page,
                perPage: perPage
            }
        ).then((res) => {
            setData(res.data);
            setMeta(res.meta);
        }).catch((err) => {
            console.log(err);
            AppNotification.showError('Lấy dữ liệu thất bại!');
        })
    }, [page, filter])

    const handleChangeperPage = (e) => {
        setPerPage(e.target.value);
        setPage(1);
        setFilter({ search: keyword });
    }

    // show modal napj tiền thủ công
    const [modalShowTransfer, setModalShowTransfer] = React.useState(false);
    const handleCloseModalTransfer = () => {
        setModalShowTransfer(false);
    };
    const [flag, setFlag] = useState(false);
    const [dataRecord, setDataRecord] = useState({
        user_id: '',
        name: '',
    });

    useEffect(() => {
        if (flag == true) {
            setModalShowTransfer(true);
        }
    }, [dataRecord, flag])

    const handleShowModalTransfer = (user_id, name) => {
        setDataRecord({
            user_id: user_id,
            name: name,
        });
        setFlag(true);

    };

    // xử lí showModal cấp quyền 
    const [modalShow, setModalShow] = React.useState(false);
    const [flagRole, setFlagRole] = useState(false);
    const [user, setUser] = useState({});
    useEffect(() => {
        if (flagRole === true) {
            setModalShow(true);
        }
    }, [user, flagRole])

    const handleShowModalRole = (item) => {
        setUser(item);
        setFlagRole(true);

    };

    const handleCloseModalRole = () => {
        setUser({});
        setFlagRole(false);
        setModalShow(false);
    }

    const reloadPage = () => {
        window.location.reload();
    };

    // xóa user
    const handleDelete = (id) => {
        const shouldDelete = window.confirm('Bạn có chắc chắn muốn xóa không?');
        if (shouldDelete) {
            UserModel.delete(id)
                .then(res => {
                    Swal.fire({
                        icon: "success",
                        title: "Xóa thành công!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    reloadPage();
                }).catch(err => {
                    Swal.fire({
                        icon: "error",
                        title: "Xóa thất bại!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    console.log(err);
                    AppNotification.showError('Xóa thất bại!');
                })
        }
    }

    // update Status
    const handleUpdateStatus = (user_id, status) => {
        UserModel.updateStatus({
            user_id: user_id,
            status: status,
        })
            .then(res => {
                Swal.fire({
                    icon: "success",
                    title: "Cập nhật thành công!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                setTimeout(() => {
                    reloadPage();
                }, 2000);
            }).catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Cập nhật thất bại!",
                    showConfirmButton: false,
                    timer: 5000,
                });
                console.log(err);
                AppNotification.showError('Cập nhật thất bại!');
            })
    }


    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Danh sách account" />
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                            <HomeAdminCardMoney title={'Tổng người dùng :'} value={total.total_user} />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                            <HomeAdminCardMoney title={'Người dùng hoạt động :'} value={total.total_user_active} />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                            <HomeAdminCardMoney title={'Người dùng vô hiệu hóa :'} value={total.total_user_NoActive} />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                            <HomeAdminCardMoney title={'Đăng ký hôm nay :'} value={total.usersToday} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Danh sách account</h4>
                        </div>
                        <div className="card-body">
                            <div className='table-responsive'>
                                <div id="example3_wrapper" className="dataTables_wrapper no-footer">
                                    <div className='row mb-3'>
                                        <div className='col-3 d-flex align-items-end'>
                                            <div className="dataTables_length" id="example3_length">
                                                <label>
                                                    Hiển thị{" "}
                                                    <select
                                                        name="example3_length"
                                                        aria-controls="example3"
                                                        className=""
                                                        value={perPage}
                                                        onChange={(e) => handleChangeperPage(e)}
                                                    >
                                                        <option value={1}>1</option>
                                                        <option value={25}>25</option>
                                                        <option value={100}>100</option>
                                                    </select>{" "}
                                                    dữ liệu.
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-9 d-flex justify-content-end'>
                                            <form className='w-100' onSubmit={handleSubmit}>
                                                <div className='row'>
                                                        <div className='col-2 offset-4'>
                                                            <label className=''>Tìm kiếm : </label>
                                                            <input
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Tên, email, số tài khoản'
                                                                name='user_name'
                                                                value={keyword.search_text}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className='col-2'>
                                                            <label className=''>Từ ngày : </label>
                                                            <input
                                                                type='date'
                                                                name='start_date'
                                                                className='form-control ms-2'
                                                                value={keyword.start_date}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className='col-2'>
                                                            <label className='ms-2'>Đến ngày : </label>
                                                            <input
                                                                type='date'
                                                                name='end_date'
                                                                className='form-control ms-2'
                                                                value={keyword.end_date}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div
                                                            className='col-2 d-flex flex-column justify-content-end'>
                                                            <div className='input-group-append'>
                                                                <button className='btn light btn-light ms-2'
                                                                        type='submit'>
                                                                    Tìm kiếm
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </form>
                                        </div>
                                    </div>
                                    <table
                                        id="example3"
                                        className="display dataTable no-footer"
                                        style={{ width: '100%' }}
                                        role="grid"
                                        aria-describedby="example3_info"
                                    >
                                        <thead>
                                            <tr role="row">
                                                <th
                                                    className="sorting_asc"
                                                    tabIndex={0}
                                                    aria-controls="example3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-sort="ascending"
                                                    aria-label=": activate to sort column descending"
                                                    style={{ width: "58.9531px" }}
                                                />
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="example3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Name: activate to sort column ascending"
                                                    style={{ width: "172.5px" }}
                                                >
                                                    ID
                                                </th>
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="example3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Name: activate to sort column ascending"
                                                    style={{ width: "172.5px" }}
                                                >
                                                    NGƯỜI DÙNG
                                                </th>
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="example3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Department: activate to sort column ascending"
                                                    style={{ width: "205.312px" }}
                                                >
                                                    TÊN TÀI KHOẢN
                                                </th>
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="example3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Gender: activate to sort column ascending"
                                                    style={{ width: "81.7031px" }}
                                                >
                                                    SỐ DƯ
                                                </th>
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="example3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Education: activate to sort column ascending"
                                                    style={{ width: "156.734px" }}
                                                >
                                                    TỔNG NẠP
                                                </th>
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="example3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Mobile: activate to sort column ascending"
                                                    style={{ width: "130.516px" }}
                                                >
                                                    TÌNH TRẠNG
                                                </th>
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="example3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Mobile: activate to sort column ascending"
                                                    style={{ width: "130.516px" }}
                                                >
                                                    TRẠNG THÁI
                                                </th>
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="example3"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Email: activate to sort column ascending"
                                                    style={{ width: "177.891px" }}
                                                >
                                                    NGÀY TẠO
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.map((item, key) => (
                                                    <tr key={key} role="row" className="odd">
                                                        <td>
                                                            {
                                                                item.role === 1 ? 
                                                                    <div className="d-flex">
                                                                        <DropdownButton  id="dropdown-basic-button" title="+">
                                                                            <Dropdown.Item onClick={() => handleShowModalTransfer(item.id, item.name)} className='text-primary'>Nạp tiền thủ công</Dropdown.Item>
                                                                            <Dropdown.Item onClick={() => handleShowModalRole(item)} className='text-success'>Chỉnh Sửa</Dropdown.Item>
                                                                            <Dropdown.Item onClick={() => handleShowModalAppAcount(item.id)} className='text-while'>Tài khoản App</Dropdown.Item>
                                                                            {
                                                                                item.status == 1 ?
                                                                                    <Dropdown.Item onClick={(e) => handleUpdateStatus(item.id, 0)} className='text-warning'>Vô hiệu hóa</Dropdown.Item>
                                                                                    :
                                                                                    <Dropdown.Item onClick={(e) => handleUpdateStatus(item.id, 1)} className='text-warning'>Kích hoạt</Dropdown.Item>
                                                                            }
                                                                            <Dropdown.Item onClick={(e) => handleDelete(item.id)} className='text-danger'>Xóa tài khoản</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </div>
                                                                : 
                                                                <div className="d-flex">
                                                                    <span className="badge badge-success">Admin</span>
                                                                </div>
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="d-flex text-warning">
                                                                {item.id}
                                                            </div>
                                                        </td>
                                                        <td className='text-warning'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <span className='text-primary'>{item.name}</span> <br />
                                                                    <span>{item.email}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='text-warning'>{item.user_name}</td>
                                                        <td className='text-warning'>{item.account_balance_fm} đ</td>
                                                        <td className='text-warning'>{item.total_deposit} đ</td>
                                                        {
                                                            item.isOnline === true ?
                                                                <td><span className="badge badge-success">online</span></td>
                                                                :
                                                                <td><span className="badge badge-danger">offline</span></td>
                                                        }
                                                        {
                                                            item.status == 1 ?
                                                                <td ><span className="badge light badge-success">Đã kích hoạt</span>
                                                                </td>
                                                                :
                                                                <td><span className="badge light badge-danger">Vô hiệu hóa</span>
                                                                </td>
                                                        }
                                                        <td className='text-warning'>{item.create_at_fm}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    <Paginate meta={meta} changePage={changePage} page={page} handleSetPage={handleSetPage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDepositHandmade
                show={modalShowTransfer}
                onHide={handleCloseModalTransfer}
                dataRecord={dataRecord}
                reloadPage={reloadPage}
            />
            <ModalDetailUser
                show={modalShow}
                onHide={handleCloseModalRole}
                user={user}
                reloadPage={reloadPage}
            />
            <ModalAcountApp
                show={showAcountApp}
                onHide={handleCloseModalAcountApp}
                userID={userID}
            />
        </MasterLayout>
    );
}

export default AdminHomePage;