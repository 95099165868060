import React from 'react';
import HomePage from '../pages/HomePage';
import BankPage from '../pages/BankPage';
import PaymentDepositPage from '../pages/PaymentDepositPage';
import BankDownloadPage from '../pages/BankDownloadPage';
import PaymentEarnMoneyPage from '../pages/PaymentEarnMoneyPage';
import UserProfilePage from '../pages/UserProfilePage';
import TransactionHistoryPage from '../pages/TransactionHistoryPage';
import UserChangePasswordPage from '../pages/UserChangePasswordPage';
import AppCookie from '../helpers/AppCookie';
import { useNavigate } from 'react-router-dom';
import AuthMoldel from "../models/AuthMoldel";
import BillPage from '../pages/BillPage';
import CurrencyPage from '../pages/CurrencyPage';
import Contact from '../pages/Contact';
import Package from '../pages/Package';
import FormCreateBill from '../components/bill/FormCreateBill';
import DowloadBill from '../pages/DowloadBill';
import LoadingDeposit from '../components/paymentdeposit/LoadingDeposit';
import PackageBill from '../pages/PackageBill';
import FakeNotication from '../pages/FakeNotication';
import FormCreateFakeNoti from '../components/fakeNotications/FormCreateFakeNoti';
import FakeBillBlance from '../pages/FakeBillBlance';
import FormCreateBillBlance from '../components/bill-blance/FormCreateBillBlance';
import FormCreateBillNew from '../components/bill/FormCreateBillNew';
import BillResult from '../pages/BillResult';

const LogOut = () => {
	let navigate = useNavigate();
	// lấy thông tin máy và trình duyệt
	const browserInfo = navigator.userAgent;
	AuthMoldel.logout({
		browserInfo:browserInfo
	}).then((res) => {
		AppCookie.deleteCookie('token');
		AppCookie.deleteCookie('user');
		navigate('/login');
	}).catch((err) => {
		console.log(err);
	});
}

const users = [
	{ path: '/', element: <HomePage /> },
	{ path: '/logout', element: <LogOut /> },
	{ path: '/bank/download', element: <BankDownloadPage /> },
	{ path: '/bill', element: <BillPage /> },
	{ path: '/bill-new', element: <FormCreateBillNew /> },
	{ path: '/result-img/:img', element: <BillResult /> },
	{ path: '/fake-notication', element: <FakeNotication /> },
	{ path: '/fake-blance', element: <FormCreateBillBlance /> },
	{ path: '/currency', element: <CurrencyPage /> },
	{ path: '/bank', element: <BankPage /> },
	{ path: '/package/:type', element: <Package /> },
	{ path: '/bill/create/:type', element: <FormCreateBill /> },
	{ path: '/notication/create', element: <FormCreateFakeNoti /> },
	{ path: '/dowload-bill', element: <DowloadBill/> },
	{ path: '/package-bill', element: <PackageBill/> },
	{ path: '/deposit/app', element: <LoadingDeposit/> },
	{ path: '/payment/deposit', element: <PaymentDepositPage /> },
	{ path: '/payment/earn-money', element: <PaymentEarnMoneyPage /> },
	{ path: '/user/profile', element: <UserProfilePage /> },
	{ path: '/user/change-password', element: <UserChangePasswordPage /> },
	{ path: '/transaction/history', element: <TransactionHistoryPage /> },
	{ path: '/contact', element: <Contact /> },
];

export default users;