class MaGiaoDich {
    constructor() {
        this.type = '';
    }
    tranansactionCodeRandom(type){
        let tranansaction_code_random = '';
        switch (type) {
            case 'STB':
            case 'MSB':
            case 'ACB':
                tranansaction_code_random = this.generateRandomStringSTB();
                break;
            case 'BIDV':
            case 'VBA':
            case 'VPB':
                tranansaction_code_random = this.generateRandomDigitsBIDV();
                break;
            case 'TCB':
            case 'MB':
                tranansaction_code_random = this.generateRandomStringTCB();
                break;
            case 'ICB':
                tranansaction_code_random = this.generateRandomStringICB();
                break;
            case 'VCB':
                tranansaction_code_random = this.generateRandomNumber();
                break;
            case 'TPB':
                tranansaction_code_random = this.generateRandomString();
                break;
            default:
                break;
        }
        return tranansaction_code_random;
    }
    generateRandomNumber(){
        const fixedDigits = "555";
        const randomDigits = Math.floor(Math.random() * 10000000).toString().padStart(7, "0");
        const result = fixedDigits + randomDigits;
        return result;
    }
    generateRandomString(){
        const fixedString = '661V00923282';
        const randomLetters = this.generateRandomLetters(4); // Tạo 4 chữ cái tiếng Anh ngẫu nhiên
        return fixedString + randomLetters;
    }
    generateRandomLetters(length){
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let randomString = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * letters.length);
            randomString += letters[randomIndex];
        }
        return randomString;
    }
    generateRandomStringTCB = () => {
        const fixedString = 'FT2408';
        const randomDigits = this.generateRandomDigitsTCB(9); // Tạo 9 chữ số ngẫu nhiên
        return fixedString + randomDigits;
    }

    generateRandomDigitsTCB = (length) => {
        let randomString = '';
        for (let i = 0; i < length; i++) {
            const randomDigit = Math.floor(Math.random() * 10);
            randomString += randomDigit;
        }

        return randomString;
    }

    generateRandomStringICB = () => {
        const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const length = 8;
        let randomString = '932S22A1';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            const randomChar = characters[randomIndex];
            randomString += randomChar;
        }
        return randomString;
    }
    generateRandomDigitsBIDV = () => {
        const length = 6;
        let randomString = '';
        for (let i = 0; i < length; i++) {
            const randomDigit = Math.floor(Math.random() * 10);
            randomString += randomDigit;
        }
        return randomString;
    }
    generateRandomStringSTB = () => {
        const prefix = "8000";
        const length = 5;
        let randomString = prefix;
        for (let i = 0; i < length; i++) {
            const randomDigit = Math.floor(Math.random() * 10);
            randomString += randomDigit;
        }
        return randomString;
    }
}
export default new MaGiaoDich;