import axiosClient from "./axiosClient";
import AppCookie from '../helpers/AppCookie';

class UserBankModel {
    constructor(){
        this.api_url = "/api/userbanks";
    }

    async updateStatus(credentials) {
        const res = await axiosClient.put(this.api_url + "/update", credentials);
        return res;
    }

}

export default new UserBankModel;