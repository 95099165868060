import React, { useEffect, useState } from 'react';
import AuthLayout from "../layouts/AuthLayout";
import AuthMoldel from '../models/AuthMoldel';
import AppCookie from '../helpers/AppCookie';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { setShowPreloader } from '../redux/action';
import { useDispatch } from 'react-redux';

const loginSchema = Yup.object().shape({
    name_login: Yup.string()
        .max(255, "Tối đa 255 kí tự")
        .required("Trường bắt buộc"),
    password: Yup.string()
        .min(6, "Tối thiểu 6 kí tự")
        .max(255, "Tối đa 255 kí tự ")
        .required("Trường bắt buộc")
});
function LoginPage(props) {
    // lấy thông tin máy và trình duyệt
    const browserInfo = navigator.userAgent;
    console.log("Thông tin trình duyệt:", browserInfo);
    // login
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let isLoggedin = AuthMoldel.checkAuth()
    useEffect(() => {
        if (isLoggedin) {
          navigate('/');
        }
      }, []);
    const [form] = useState({
        name_login: "",
        password: "",
        type_web: "web",
        browserInfo: browserInfo,
    })
    const handleSubmit = (values) => {
        dispatch(setShowPreloader(true));
        AuthMoldel.login(values).then((res) => {
            dispatch(setShowPreloader(false));
            // Notification
            if (res.success === false) {
                Swal.fire({
                    icon: "error",
                    title: res.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
            } else {
                Swal.fire({
                    icon: "success",
                    title: "Đăng nhập thành công!",
                    showConfirmButton: false,
                    timer: 2000,
                });
                //Set cookie
                AppCookie.setCookie('token', res.authorization.token);
                AppCookie.setCookie('user', JSON.stringify(res.data));

                // Redirect to dashboard
                if (res.data.role == 0) {
                    navigate('/admin')
                } else {
                    navigate('/')
                }
            }
        }).catch((err) => {
            dispatch(setShowPreloader(false));
            Swal.fire({
                icon: "error",
                title: "Đăng nhập Thất bại!",
                showConfirmButton: false,
                timer: 2000,
            });
        })
    }
    return (
        <AuthLayout>
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-body ">
                        <div className="row">
                            <div className="col-xl-6 col-md-6 sign text-center">
                                <div>
                                    <img src="/images/log.png" className="education-img" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="sign-in-your">
                                    <h1>Đăng nhập</h1>
                                    <span className="text-white mt-3">
                                        Chào mừng trở lại! Đăng nhập với dữ liệu bạn đã nhập
                                        <br /> trong quá trình đăng ký
                                    </span>

                                    <Formik
                                        initialValues={form}
                                        validationSchema={loginSchema}
                                        onSubmit={(values) => handleSubmit(values)}
                                    >
                                        {({ errors, touched }) => (
                                            <Form className="mt-5">
                                                <div className="mb-3">
                                                    <label className="mb-1" htmlFor="name_login">Tài khoản</label>
                                                    <Field type="text" name="name_login" placeholder="Nhập Email hoặc Username" className="form-control" />
                                                    {errors.name_login && touched.name_login ? (
                                                        <div style={{ color: 'red' }}>{errors.name_login}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="mb-1" htmlFor="password">Mật khẩu</label>
                                                    <Field type="password" name="password" className="form-control" />
                                                    {errors.password && touched.password ? (
                                                        <div style={{ color: 'red' }}>{errors.password}</div>
                                                    ) : null}
                                                </div>
                                                <div className="row d-flex justify-content-between mt-2 mb-2">
                                                    <div className="mb-3 d-flex justify-content-end">
                                                        <a href="/forgot-password">Quên mật khẩu?</a>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <button type="submit" className="btn btn-success btn-block">
                                                        Đăng nhập
                                                    </button>
                                                    <a href='/register' className="btn btn-primary btn-block mt-3">
                                                        Đăng ký
                                                    </a>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
}

export default LoginPage;