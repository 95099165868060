import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../../redux/action';
import Swal from "sweetalert2";
import PackageBillModel from '../../models/PackageBillModel';

function ModalBuyPackageBill(props) {
  const dispatch = useDispatch();

    const handleClick = (type,price) => {
      dispatch(setShowPreloader(true));
      PackageBillModel.buyPackageBill({
        type:type,
        price:price
      }).then(function (res) {
          dispatch(setShowPreloader(false));
          if (res.success === true) {
              Swal.fire({
                  icon: "success",
                  title: "Mua gói bill thành công!",
                  showConfirmButton: false,
                  timer: 2000,
              });
              setTimeout(() => {
                  reloadPage();
              }, 2000);
          } else {
              Swal.fire({
                  icon: "error",
                  title: res.message,
                  showConfirmButton: false,
                  timer: 2000,
              });
          }
      }).catch(function (err) {
          dispatch(setShowPreloader(false));
          Swal.fire({
              icon: "error",
              title: 'Mua gói bill thất bại!',
              showConfirmButton: false,
              timer: 2000,
          });
          console.log(err);
      })
    }
    const reloadPage = () => {
      window.location.reload();
    };
    return (
        <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className='text-success'>
            {props.packageName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-warning'>Bạn có chắc chắn mua gói này không?</h4>
        <p className='text-primary'>
          Số tiền bạn cần thanh toán là : {props.packagePrice} VNĐ
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-success' onClick={() => handleClick(props.typePackage,props.packagePriceNoFm)}>Xác Nhận</button>
        <Button onClick={props.onHide}>Đóng</Button>
      </Modal.Footer>
    </Modal>
    );
}

export default ModalBuyPackageBill;