const SHOW_PRELOADER = 'SHOW_PRELOADER';

export const setShowPreloader = (payload) => ({
    type: SHOW_PRELOADER,
    payload,
  });

  export const setDownloadBillData = (data) => ({
    type: 'SET_DOWNLOAD_BILL_DATA',
    payload: data,
  });

  export const setSystemSettings = (data) => ({
    type: 'SET_SYSTEM_SETTING',
    payload: data,
  });