import React from 'react';
import Battery from '../global/Battery';

function ImageHTMLBIDV(props) {
    const {
        network, date,
        showFormNotiTwo, showFormNotiThree, driverNetwork, hideMark, background, balance, account, networkDark
    } = props;


    let { hour, minute, second } = props;
    hour = hour < 10 ? '0' + hour : hour;
    minute = minute < 10 ? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;
    hour = hour + ":" + minute;
    let { pin } = props;
    let power = (20 / 100) * pin
    let color = pin < 21 ? 'red' : 'black';



    return (
        <>
            <>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>IOS</title>
                <link rel="stylesheet" href="/asste-bill-blance/BIDV/css/ios.css" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
                    rel="stylesheet"
                />
                <main ref={props.divRef} style={{ width: '375px', height: '812px', position: 'relative' }}>
                    {
                        !hideMark && (
                            <img src='/bills/mark.png' style={{ position: 'absolute', objectFit: 'cover', width: '100%', height: '100%', top: 0, left: 0, zIndex: 100 }} alt='mark' />
                        )
                    }
                    <img src="/asste-bill-blance\BIDV\image\bidv.png" className="container-bidv">
                    </img>
                        <div className="containerHeader">
                            <div>
                                <p className="time-ios">{hour}</p>
                            </div>
                            <div className="blockInfoNetwork">
                                <img
                                    className="network"
                                    src={`/image-electromagnetics/network-${networkDark}-dark.png`}
                                    alt="network"
                                />
                                {
                                    driverNetwork === 'wifi' ?
                                        <img id="wifi" className="wifi" src="/asste-bill-blance/BIDV/image/wifi.png" alt="wifi" />
                                        :
                                        <img id="wifi" className="wifi" src="/asste-bill-blance/BIDV/image/4g-dark.png" alt="wifi" />
                                }
                                <Battery pin ={pin} color = {color} isWhiteBorder = {false} />

                                {/* <div className="battery">
                                    <div className="icon-container">
                                        <div className="outer-rect" />
                                        <div className="inner-rect" id="innerRect" style={{ width: `${power}px`, backgroundColor: `${color}` }} />
                                    </div>
                                    <div className="semi-circle" id="semiCircle" />
                                </div> */}
                            </div>
                        </div>
                        <div className="components">
                            <div className="">
                                <p className="amount">{balance}</p>
                            </div>
                            <div className="account-number">
                                <div className="">{account}</div>
                                <div className="img-copy">
                                    <img src="/asste-bill-blance/BIDV/image/copy-icon.png" alt="" />
                                </div>
                            </div>

                            <div className="amount-available">
                                <div className="">{balance}</div>
                                <div className="currency">VND</div>
                            </div>
                        </div>
                </main>
            </>

        </>
    );
}

export default ImageHTMLBIDV;