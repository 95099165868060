import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import HomeModel from "../../models/HomeModel";
import Swal from "sweetalert2";
import VietQrModel from '../../models/VietQrModel';


function ModalACB(props) {

    const [modalShow, setModalShow] = React.useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        HomeModel.getInfoHeader().then((res) => {
            setData(res.data);
        }).catch((err) => {
        })
    }, [])
    const handleCloseModal = () => {
        setModalShow(false);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText("40910727");
        Swal.fire({
            icon: "success",
            title: "Đã copy số tài khoản!",
            showConfirmButton: false,
            timer: 1000,
        });
    };
    const copyToClipboardTransactionCode = () => {
        navigator.clipboard.writeText(`okbill ${data.user_name} ck`);
        Swal.fire({
            icon: "success",
            title: "Đã copy nội dung chuyển khoản!",
            showConfirmButton: false,
            timer: 1000,
        });
    };
    const imageQR = localStorage.getItem("imageQR");
    // const [dataCheck, setDataCheck] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            HomeModel.getInfoHeader()
                .then((res) => {
                    // setDataCheck(res.data);
                    if (res.data.account_balance > data.account_balance) {
                        Swal.fire({
                            icon: "success",
                            title: "Nạp tiền thành công!",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        setTimeout(() => {
                            reloadPage();
                        }, 2000);
                        return () => clearInterval(interval);
                    }

                })
                .catch((err) => {
                    console.error(err);
                });
        }, 5000);

        // Cleanup the interval when the component is unmounted
        return () => clearInterval(interval);
    }, [data]);

    const reloadPage = () => {
        window.location.reload();
    };
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            // style={{width:'500px', display:'flex' , justifyContent:'center'}}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Nạp tiền tự động qua ACB
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert alert-danger mt-1 alert-validation-msg" role="alert">
                        <div className="alert-body d-flex align-items-center">
                            <span>
                                *Lưu ý: Nhập đúng nội dung chuyển khoản.<br /> Tiền sẽ được tự động cộng vào tài khoản trong tối đa 5 phút
                            </span>
                        </div>
                    </div>
                    <div className='wraper-c-one'>
                        <div className='row justify-content-center text-primary custom-margin'>Cách 1: Chuyển khoản bằng mã QR<br />Mở app ngân hàng quét mã QRCode</div>
                        <div className='row pb-3'>
                            <div className='col-12 d-flex justify-content-center pt-3'>
                                <img src={imageQR} width={300} height={300} />
                            </div>
                        </div>
                    </div>
                    <div className='row pt-3 wraper-c-two'>
                        <div className='row justify-content-center mb-5 text-primary'>Cách 2: Chuyển khoản thủ công theo thông tin</div>
                        <div className='col-12'>
                            <div className='pb-2'><span className='text-warning'>Ngân hàng:</span> Ngân hàng Thương Mại Cổ Phần Á Châu</div>
                            <div className='row pb-2 d-flex justify-content-between'>
                                <div className='col-6'>
                                    <span className='text-warning'>Số tài khoản:</span> 40910727
                                </div>
                                <div className='col-6' style={{ height: '30px', width: '100px' }}>
                                    <button className='' onClick={copyToClipboard} style={{ height: '100%', width: '100%', borderRadius: '8px', backgroundColor: '#16dee5', color: 'white' }}>copy</button>
                                </div>
                            </div>
                            <div className='pb-2'><span className='text-warning'>Tên tài khoản:</span> NGUYEN TIEN TRIEU</div>
                            <div className='row pb-2 d-flex justify-content-between'>
                                <div className='col-6 text-warning'>Nội dung CK: <span className='text-primary'>okbill {data.user_name} ck</span></div>
                                <div className='col-6' style={{ height: '30px', width: '100px' }}>
                                    <button className='' onClick={copyToClipboardTransactionCode} style={{ height: '100%', width: '100%', borderRadius: '8px', backgroundColor: '#16dee5', color: 'white' }}>copy</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>


    );
}

export default ModalACB;