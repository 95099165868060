import axiosClient from './axiosClient';
import AppCookie from "../helpers/AppCookie";

class NewsModel {
    constructor(){
        this.api_url = "/api/news";
    }
    async getNews() {
        const res = await axiosClient.get(this.api_url);
        return res;
    }

    async getAllPaginate(credentials) {
        const res = await axiosClient.get(this.api_url + '/getall', {
            params: {
              search: credentials.search,
              page: credentials.page,
              perPage: credentials.perPage
            },
          });
        return res;
    }

    async show() {
        const res = await axiosClient.get(this.api_url + '/show');
        return res;
    }

    async create(credentials) {
        const res = await axiosClient.post(this.api_url + "/create", credentials);
        return res;
    }

    async update(credentials) {
        const res = await axiosClient.post(this.api_url, credentials);
        return res;
    }

    async delete(credentials) {
        console.log(credentials);
        const res = await axiosClient.delete(this.api_url + '/' + credentials);
        return res;
    }
}

export default new NewsModel;