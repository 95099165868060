import { useState, useEffect } from 'react';

const usePreloader = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulating an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return isLoading;
};

export default usePreloader;