import axiosClient from "./axiosClient";
import AppCookie from '../helpers/AppCookie';

class VietQrModel {
  constructor() {
    this.api_url = "/api/bank-name-bill";
  }

  async getAllVietQR(credentials) {
    const res = await axiosClient.get(this.api_url, {
      params: {
        type: credentials.type,
      },
    });
    return res;
  }

  async getQRdeposit(credentials) {
    const res = await axiosClient.get('/api/deposit/qr', {
      params: {
        accountNo: 40910727,
        accountName: 'NGUYEN TIEN TRIEU',
        acqId: 970416,
        format: 'text',
        template: 'compact',
        addInfo: 'okbill ' + credentials.username + ' ck',
      },
    });
    return res;
  }

}

export default new VietQrModel;