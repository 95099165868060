import React, { useState } from 'react';

function InstructEarnmonye(props) {
    const [isShow, setIsShow] = useState(0);
    const handleShow = () => {
        setIsShow(1);
    }
    const handleClose = () => {
        setIsShow(0);
    }
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <p><span className="text-warning">* Rút tiền :</span></p><br />
                    <p><span className='text-primary'>- Chỉ có thể rút tiền từ tài khoản giới thiệu :</span> số tiền này kiếm được khi các tài khoản giới thiệu nạp tiền vào web, hoa hồng 10%/ tổng số tiền nạp của tài khoản giới thiệu.</p>
                    <p><span className='text-primary'>- Bước 1 :</span> Nhấn vào rút tiền, sẽ xuất hiện hộp thoại rút tiền.</p>
                    <p><span className='text-primary'>- Bước 2 :</span> Nhập thông tin tài khoản nhận tiền gồm : chủ tài khoản và số tài khoản.</p>
                    <p><span className='text-primary'>- Bước 3 :</span> Nhập số tiền muốn rút.</p>
                    <p><span className='text-primary'>- Bước 4 :</span> Nhấn vào "Lấy mã" để lấy mã OTP rút tiền, mã OTP sẽ được gửi vào email của quý khách.</p>
                    <p><span className='text-primary'>- Bước 5 :</span> Nhập mã OTP và nhấn nút "xác nhận" để rút tiền...</p>
                </div>
            </div>
            {
                isShow == 1 ?
                    <div>
                        <div className='row'>
                            <div className='col-12'>
                                <p><span className='text-primary'>- Bước 6 :</span> chờ quản trị viên thực hiện xác nhận và gửi tiền vào tài khoản quý khách vừa nhập trong ít phút!.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p><span className="text-warning">* Chuyển tiền :</span></p><br />
                                <p><span className='text-primary'>- Chỉ có thể chuyển tiền từ tài khoản giới thiệu vào tài khoản chính :</span> số tiền này kiếm được khi các tài khoản giới thiệu nạp tiền vào web, hoa hồng 10%/ tổng số tiền nạp của tài khoản giới thiệu.</p>
                                <p><span className='text-primary'>- Bước 1 :</span> Nhấn vào chuyển tiền, sẽ xuất hiện hộp thoại chuyển tiền.</p>
                                <p><span className='text-primary'>- Bước 2 :</span> Nhập số tiền muốn chuyển.</p>
                                <p><span className='text-primary'>- Bước 3 :</span> Nhấn vào "Lấy mã" để lấy mã OTP chuyển tiền, mã OTP sẽ được gửi vào email của quý khách.</p>
                                <p><span className='text-primary'>- Bước 4 :</span> Nhập mã OTP và nhấn nút "xác nhận" để chuyển tiền...</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <button onClick={handleClose} className='btn btn-primary btn-sm'>Đóng</button>
                            </div>
                        </div>
                    </div>
                    :
                    ''
            }
            {
                isShow == 0 ?
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <button onClick={handleShow} className='btn btn-primary btn-sm'>Xem thêm</button>
                        </div>
                    </div>
                    : ''
            }
        </>

    );
}

export default InstructEarnmonye;