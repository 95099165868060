import React, { useEffect, useState } from 'react';
import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import BankItem from '../components/bank/BankItem';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import AppNotification from '../helpers/AppNotification';
import OwnerBankModel from '../models/OwnerBankModel';
import OwnerBankItem from '../components/paymentdeposit/OwnerBankItem';
import PaymentModel from '../models/PaymentModel';
import ModalDeposit from '../components/paymentdeposit/ModalDeposit';
import Paginate from '../components/paginate/Paginate';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../redux/action';
import InstructDeposut from '../components/instructs/InstructDeposut';
import LoadingDeposit from '../components/paymentdeposit/LoadingDeposit';
import DemoModalDeposit from '../components/paymentdeposit/DemoModalDeposit';
import ModalACB from '../components/paymentdeposit/ModalACB';
import VietQrModel from '../models/VietQrModel';
import HomeModel from '../models/HomeModel';
import CodeMoneyModel from '../models/CodeMoneyModel';
import Swal from 'sweetalert2';

function PaymentDepositPage(props) {

    const [code, setCode] = useState('');
    const onChangeCode = (e) => {
        setCode(e.target.value)
    }
    const handleDepositCode = () => {
        dispatch(setShowPreloader(true));
        CodeMoneyModel.depositCode({
            code: code
        })
            .then(res => {
                dispatch(setShowPreloader(false));
                if (res.success === true) {
                    dispatch(setShowPreloader(false));
                    Swal.fire({
                        icon: "success",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    setTimeout(() => {
                        reloadPage();
                    }, 2000);
                } else {
                    dispatch(setShowPreloader(false));
                    Swal.fire({
                        icon: "error",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }).catch(err => {
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }

    const dispatch = useDispatch();
    const [showPageLoadingDeposit, setShowPageLoadingDeposit] = useState(false);
    // paginate
    const [perPage, setPerPage] = useState(5);
    const [page, setPage] = useState(1);
    const [meta, setMeta] = useState({});
    const changePage = (page) => {
        setPage(page);
        setFilter({ search: keyword });
    }
    const handleSetPage = (number) => {
        setPage(page + number);
        setFilter({ search: keyword });
    }

    // search
    const [keyword, setKeyword] = useState({
        start_date: '',
        end_date: '',
    });
    const [filter, setFilter] = useState({
        search: '',
        perPage: perPage,
        page: page,
    });
    const handleChange = (event) => {
        setKeyword({
            ...keyword,
            [event.target.name]: event.target.value
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setPage(1);
        // Xử lý tìm kiếm dữ liệu dựa trên keyword
        setFilter({ search: keyword });
        // Reset trường input sau khi submit
    }

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
    };

    const [modalShowTransfer, setModalShowTransfer] = React.useState(false);
    const [modalShowTransferDemo, setModalShowTransferDemo] = React.useState(false);
    const [modalShowTransferACB, setModalShowTransferACB] = React.useState(false);
    const [infoOwner, setInfoOwner] = useState();
    const [ownerid, setOwnerid] = useState();
    const [infoOwnerChanged, setInfoOwnerChanged] = useState();
    useEffect(() => {
        if (infoOwnerChanged) {
            setModalShowTransfer(true);
            setInfoOwnerChanged(false);
        }
    }, [infoOwner, infoOwnerChanged, ownerid]);

    const handleShowModalTransfer = (bank) => {
        setOwnerid(bank.id);
        setInfoOwner(bank);
        setInfoOwnerChanged(true);
    };
    const handleCloseModalTransfer = () => {
        setModalShowTransfer(false);
    };

    const handleShowModalTransferDemo = () => {
        setModalShowTransferDemo(true);
    };
    const handleShowModalTransferACB = () => {
        setModalShowTransferACB(true);
    };
    const handleCloseModalTransferDemo = () => {
        setModalShowTransferDemo(false);
    };
    const handleCloseModalTransferACB = () => {
        setModalShowTransferACB(false);
    };

    const [banks, setBanks] = useState([]);
    useEffect(() => {
        dispatch(setShowPreloader(true));
        OwnerBankModel.getOwnerBank({})
            .then(res => {
                dispatch(setShowPreloader(false));
                setBanks(res.data);
            }).catch(err => {
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, []);

    const [deposit, setDeposit] = useState([]);
    useEffect(() => {
        PaymentModel.getDeposit({
            search: filter.search,
            page: page,
            perPage: perPage
        })
            .then(res => {
                setDeposit(res.data);
                setMeta(res.meta);
            }).catch(err => {
                AppNotification.showError('Lấy lịch sử nạp tiền thất bại!');
            })
    }, [page, filter]);
    const [data, setData] = useState([]);
    const [imageQR, setImageQR] = useState('');
    // useEffect(() => {
    //     HomeModel.getInfoHeader().then((res) => {
    //         setData(res.data);
    //         VietQrModel.getQRdeposit({username:res.data.user_name}).then((res) => {
    //             setImageQR(res.data.qrDataURL);
    //         }).catch((err) => {
    //         })
    //     }).catch((err) => {
    //     })
    // }, [])

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Nạp tiền" />
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className='col-xl-6'>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Nạp Tiền Tự Động</h4>
                                </div>
                                <div className="card-body p-3">
                                    <div className='row'>
                                        <div className="alert alert-dark solid alert-dismissible fade show custom-alert"
                                            style={{ backgroundColor: '#f7edd7' }}>
                                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="#9F6D22"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" className="me-2">
                                                <path
                                                    d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                                <line x1="12" y1="9" x2="12" y2="13"></line>
                                                <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                            </svg>
                                            <span style={{ color: '#9F6D22' }}>Vui lòng chuyển khoản đến 1 trong các cổng thanh toán phía dưới bằng cách click vào ngân hàng đó để hiển thị thông tin chuyển khoản.</span>
                                            <button type="button" className="btn-close" data-bs-dismiss="alert"
                                                aria-label="btn-close"></button>
                                        </div>
                                        <div className="alert alert-dark solid alert-dismissible fade show custom-alert"
                                            style={{ backgroundColor: '#f7edd7' }}>
                                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="#9F6D22"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" className="me-2">
                                                <path
                                                    d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                                <line x1="12" y1="9" x2="12" y2="13"></line>
                                                <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                            </svg>
                                            <span style={{ color: '#9F6D22' }}>Khi chuyển khoản thành công, vui lòng chờ nhận tiền vào tài khoản trước khi nạp đơn tiếp theo.</span>
                                            <button type="button" className="btn-close" data-bs-dismiss="alert"
                                                aria-label="btn-close"></button>
                                        </div>
                                        {/* {
                                            banks.map((bank, key) => (
                                                <div className='col-xl-6' key={key}>
                                                    <a onClick={() => handleShowModalTransfer(bank)} >
                                                        <OwnerBankItem bank={bank} />
                                                    </a>
                                                </div>
                                            ))
                                        } */}
                                        <div className='col-xl-6'>
                                            <div className="card overflow-hidden">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <div className="profile-photo">
                                                            <img
                                                                src="https://api.vietqr.io/img/VIB.png"
                                                                style={{
                                                                    width: '200px',
                                                                    height: '100px',
                                                                    objectFit: 'cover',
                                                                    borderRadius: '8px',
                                                                }}
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <button className='btn btn-sm btn-primary'
                                                                onClick={handleShowModalTransferDemo}>Nạp tiền
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='col-xl-6'>
                                            <div className="card overflow-hidden">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <div className="profile-photo">
                                                            <img
                                                                src="https://api.vietqr.io/img/ACB.png"
                                                                style={{
                                                                    width: '200px',
                                                                    height: '100px',
                                                                    objectFit: 'cover',
                                                                    borderRadius: '8px',
                                                                }}
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <button className='btn btn-sm btn-primary'
                                                                onClick={handleShowModalTransferACB}>Nạp tiền
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className='col-xl-6'>
                                            <div className="card overflow-hidden">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <div className="profile-photo">
                                                            <img
                                                                src="https://api.vietqr.io/img/TCB.png"
                                                                style={{
                                                                    width: '200px',
                                                                    height: '100px',
                                                                    objectFit: 'cover',
                                                                    borderRadius: '8px',
                                                                }}
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-2 mb-1 text-warning">Đang cập nhật</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col-xl-12 col-lg-12 col-sm-12'>
                                                    <label className='text-warning'>Nhập code nạp tiền: </label>
                                                    <div className='d-flex '>
                                                        <input
                                                            type='text'
                                                            name='denominations'
                                                            className='form-control ms-2'
                                                            placeholder='Nhập code...'
                                                            value={code}
                                                            onChange={onChangeCode}
                                                        />
                                                        <button className='btn-sm light btn-primary ms-2' type='button' onClick={handleDepositCode}>
                                                            Nạp Code
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Hướng dẫn sử dụng</h4>
                                </div>
                                <div className="card-body p-3">
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <InstructDeposut />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Lịch sử nạp tiền</h4>
                        </div>
                        <div className="card-body">
                            <div className='row d-flex justify-content-end'>
                                <div className='col-lg-6 d-flex justify-content-end'>
                                    <form onSubmit={handleSubmit}>
                                        <div className='input-group mb-3'>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <label className=''>Từ ngày : </label>
                                                    <input
                                                        type='date'
                                                        name='start_date'
                                                        className='form-control ms-2'
                                                        placeholder='Nhập từ khóa...'
                                                        value={keyword.start_date}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-4'>
                                                    <label className='ms-2'>Đến ngày : </label>
                                                    <input
                                                        type='date'
                                                        name='end_date'
                                                        className='form-control ms-2'
                                                        placeholder='Nhập từ khóa...'
                                                        value={keyword.end_date}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-4 d-flex flex-column justify-content-end'>
                                                    <div className='input-group-append'>
                                                        <button className='btn light btn-light ms-2' type='submit'>
                                                            Tìm kiếm
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <table className="table header-border table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Số tiền</th>
                                            <th>Thực nhận</th>
                                            <th>Tình trạng</th>
                                            <th>Ghi chú</th>
                                            <th>Thời gian</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            deposit.map((item, key) => (
                                                <tr>
                                                    <td>{item.id}</td>
                                                    <td>{item.amount_fm} VNĐ</td>
                                                    <td>{item.received_fm} VNĐ</td>
                                                    {item.status == 1 ? <td><span className="badge badge-success">Đã duyệt</span></td> : <td><span className="badge badge-danger">Chờ duyệt</span></td>}
                                                    <td>{item.note}</td>
                                                    <td><span className="text-muted">{item.create_at_fm}</span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <Paginate meta={meta} changePage={changePage} page={page} handleSetPage={handleSetPage} />
                            </div>
                        </div>
                    </div>
                </div>
                <ModalDeposit
                    show={modalShowTransfer}
                    onHide={handleCloseModalTransfer}
                    bank={infoOwner}
                    ownerid={ownerid}
                />
                <DemoModalDeposit
                    show={modalShowTransferDemo}
                    onHide={handleCloseModalTransferDemo}
                />

                <ModalACB
                    show={modalShowTransferACB}
                    onHide={handleCloseModalTransferACB}
                    imageQR={imageQR}
                />

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nạp tiền vào tài khoản</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Số tiền muốn nạp:
                        <Form.Control
                            type="email"
                            placeholder="500.000"
                            autoFocus
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-danger" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="btn btn-success" onClick={handleClose}>
                            Xác nhận
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </MasterLayout>

    );
}

export default PaymentDepositPage;
