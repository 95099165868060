import React from 'react';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import NotFoundPage from '../pages/NotFoundPage';

const publics = [
	{ path: '/login', element: <LoginPage/> },
	{ path: '/register', element: <RegisterPage/> },
	{ path: '/forgot-password', element: <ForgotPasswordPage/> },
	{ path: '*', element: <NotFoundPage /> },
];

export default publics;