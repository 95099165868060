import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalNoticationPass(props) {
    const [modalShowNoti, setModalShowNoti] = React.useState(false);
    const handleCloseModalNoti = () => {
        setModalShowNoti(false);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='row d-flex justify-content-center'>
                        <strong className='text-success'>Đổi mật khẩu thành công!</strong>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3">
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-center'>
                            <div><span className='text-success'>{props.notication}</span></div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-center'>
                            <div><span className='text-warning'></span></div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Đóng</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalNoticationPass;