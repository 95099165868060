import React from 'react';

const PayosIframe = ({ src }) => {
  return (
    <iframe
      src={src}
      // style={{ overflow: 'hidden', position: 'absolute', zIndex: '1000' }}
      allowFullScreen
      allow="clipboard-write"
      height={window.innerHeight.toString()}
      width={'100%'}
    />
  );
};

export default PayosIframe;