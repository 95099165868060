import React, { useEffect, useState } from 'react';
import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import BankItem from '../components/bank/BankItem';
import BankVideo from '../components/bank/BankVideo';
import BankNotication from '../components/bank/BankNotication';
import BankInfo from '../components/bank/BankInfo';
import BankModel from '../models/BankModel';
import AppNotification from '../helpers/AppNotification';
import UserBankAcountModel from '../models/UserBankAcountModel';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../redux/action';
import InfoBankDowload from '../components/dowload/InfoBankDowload';
import {useRef} from 'react';

function BankPage(props) {
    //state không load lại trang
    // const [loaddata, setLoaddata] = useState(0);
    // const changloadaData = (bank_info) => {
    //     setBank(bank_info);
    //     setLoaddata(loaddata+1);
    // }
    
    const ref = useRef(null);

    //
    const [banks, setBanks] = useState([]);
    const [bank, setBank] = useState(null);
    const [type, setType] = useState(0);
    const [flag, setFlag] = useState(false);
    const [listUserBankAcount, setListUserBankAcount] = useState([]);
    const [countAccount, setCountAccount] = useState();
    const dispatch = useDispatch();
    const [urlVideo, setUrlVieo] = useState('');

    useEffect(() => {
        dispatch(setShowPreloader(true));
        BankModel.all({})
            .then(res => {
                dispatch(setShowPreloader(false));
                setBanks(res.data);
                setUrlVieo(res.data[0]);
            }).catch(err => {
                dispatch(setShowPreloader(false));
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
        
    }, []);

    const handleClick = (id,type) => {
        console.log(id);
        ref.current?.scrollIntoView({behavior: 'smooth'});
        dispatch(setShowPreloader(true));
        setType(type);
        BankModel.find(id)
            .then(res => {
                dispatch(setShowPreloader(false));
                setBank(res.data);
                console.log(res.data);
                UserBankAcountModel.getUserBankAcount({
                    type: res.data.type
                })
                    .then((respon) => {
                        setListUserBankAcount(respon.data);
                        setCountAccount(respon.countAccount);
                        console.log(respon.data);
                        // Notification
                        AppNotification.showSuccess('lấy user bank acount thành công!');
                    })
                    .catch((err) => {
                        setListUserBankAcount(null);
                        console.log(err);
                        AppNotification.showError('lấy user bank acount thất bại!');

                    });
                setFlag(true);
            }).catch(err => {
                dispatch(setShowPreloader(false));
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            });
    }
    const [loaddata, setLoaddata] = useState(false);
    const reloadPage = (id,type) => {
        handleClick(id,type);
        setLoaddata(true);
    };
    return (
        <MasterLayout>
            <ThemeBreadcrumb title="Ngân hàng" />
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className='col-xl-6'>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Ngân Hàng</h4>
                                </div>
                                <div className="card-body p-3">
                                    <div className='row'>
                                        {
                                            banks.map((item, key) => (
                                                <div className='col-xl-6' key={key}>
                                                    <BankItem handleClick={handleClick}  id={item.id} image={item.image} name={item.name} status={item.status} user_status={item.user_status} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div ref={ref} className='col-xl-12'>
                                {!bank && (
                                    <div className='row'>
                                        <BankNotication />
                                    </div>
                                )}
                                { ((bank != null && type ==2) || (loaddata ==true)) && (
                                    <BankInfo ref={ref} bankInfo={bank} reloadPage={reloadPage} countAccount={!countAccount ? 0 : countAccount} listUserBankAcount={!listUserBankAcount ? null : listUserBankAcount} />
                                )}
                                { (bank != null && type ==1) && (
                                    <InfoBankDowload bankInfo={bank}/>
                                )}
                            </div>
                            <div className='row'>
                                <div className='col-xl-12'>
                                    <BankVideo urlVideo={urlVideo.url_video_setting_bank} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MasterLayout>
    );
}

export default BankPage;