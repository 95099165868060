import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import NewsModel from '../../models/NewsModel';
import UserBankAcountModel from '../../models/UserBankAcountModel';
import UserModel from '../../models/UserModel';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Không được để trống!'),
    email: Yup.string().required('Không được để trống!').email('Email phải đúng định dạng!'),
    user_name: Yup.string().required('Không được để trống!').matches(/^\S*$/, 'Không được chứa khoảng trắng!'),
    account_balance: Yup.string().required('Không được để trống!').matches(/^[0-9]+$/, 'Chỉ nhập số!'),
    password: Yup.string()
        .min(6, 'Độ dài tối thiểu 6 ký tự')
        .max(32, 'Độ dài tối đa 32 ký tự')
});

function ModalDetailUser(props) {
    const [initialValues, setInitialValues] = useState({
        user_id: '',
        name: '',
        email: '',
        user_name: '',
        account_balance: '',
        password_admin_reset: '',
        password: '',
    });

    useEffect(() => {
        if (props.user) {
            setInitialValues({
                user_id: props.user.id,
                name: props.user.name,
                email: props.user.email,
                user_name: props.user.user_name,
                account_balance: props.user.account_balance,
                password_admin_reset: props.user.password_admin_reset,
                password: '',
            });
        }
    }, [props.user]);

    const onSubmit = (values) => {
        UserModel.adminUpdate(values).then((res) => {
            // Notification
            Swal.fire({
                icon: "success",
                title: "Cập nhật thành công!",
                showConfirmButton: false,
                timer: 2000,
            });
            setTimeout(() => {
                reloadPage();
            }, 2000);
        }).catch((err) => {
            Swal.fire({
                icon: "error",
                title: "Cập nhật thất bại!",
                showConfirmButton: false,
                timer: 2000,
            });
        })
    };

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cập nhật Tài khoản
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='pt-2'>
                                        <label htmlFor="name" className='text-warning'>Tên:</label>
                                        <Field className="form-control" type="text" id="name" name="name" />
                                        {errors.name && touched.name ? (
                                            <div style={{ color: 'red' }}>{errors.name}</div>
                                        ) : null}
                                    </div>
                                    <div className='pt-2'>
                                        <label htmlFor="email" className='text-warning'>Email:</label>
                                        <Field className="form-control" type="text" id="email" name="email" />
                                        {errors.email && touched.email ? (
                                            <div style={{ color: 'red' }}>{errors.email}</div>
                                        ) : null}
                                    </div>
                                    <div className='pt-2'>
                                        <label htmlFor="user_name" className='text-warning'>Tài khoản(User_name):</label>
                                        <Field className="form-control" type="text" id="user_name" name="user_name" />
                                        {errors.user_name && touched.user_name ? (
                                            <div style={{ color: 'red' }}>{errors.user_name}</div>
                                        ) : null}
                                    </div>
                                    <div className='pt-2'>
                                        <label htmlFor="account_balance" className='text-warning'>Số dư:</label>
                                        <Field className="form-control" type="text" id="account_balance" name="account_balance" />
                                        {errors.account_balance && touched.account_balance ? (
                                            <div style={{ color: 'red' }}>{errors.account_balance}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='pt-2'>
                                        <label htmlFor="password_admin_reset" className='text-warning'>Mật Khẩu hiện tại:</label>
                                        <Field className="form-control" type="text" id="password_admin_reset" name="password_admin_reset" readOnly/>
                                        {errors.password_admin_reset && touched.password_admin_reset ? (
                                            <div style={{ color: 'red' }}>{errors.password_admin_reset}</div>
                                        ) : null}
                                    </div>
                                    <div className='pt-2'>
                                        <label htmlFor="password" className='text-warning'>Đặt lại mật khẩu:</label>
                                        <Field className="form-control" type="text" id="password" name="password" />
                                        {errors.password && touched.password ? (
                                            <div style={{ color: 'red' }}>{errors.password}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={props.onHide}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-success">
                                    Xác nhận
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default ModalDetailUser;