import React, { useState } from 'react';
import { useEffect } from 'react';
import BillGenerate from '../../models/BillGenerate';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import UserModel from '../../models/UserModel';
import { setShowPreloader } from '../../redux/action';
import AppNotification from '../../helpers/AppNotification';
import { useNavigate } from 'react-router-dom';
// import './../../fonts/BeVietnamPro/BeVietnamPro.css';

function ImageBillDowload(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [exportBill, setExportBill] = useState(false);
    const [downloadBill, setDownloadBill] = useState('');

    const [imagePreview, setImagePreview] = useState('');
    useEffect(() => {
        console.log('props',props);
        // Load fonts
        const fonts = [
            { name: 'SFProDisplay', url: 'url(/fonts/SF-Pro-Display/SFProDisplay-Regular.woff2)',weight:'normal' },
            { name: 'SFProDisplay', url: 'url(/fonts/SF-Pro-Display/SFProDisplay-Medium.woff2)',weight:'500' },
            { name: 'SFProDisplay', url: 'url(/fonts/SF-Pro-Display/SFProDisplay-Bold.woff2)',weight:'bold' },
            { name: 'Inter', url: 'url(/fonts/Inter/Inter-Regular.woff2)',weight:'normal' },
            { name: 'Inter', url: 'url(/fonts/Inter/Inter-Medium.woff2)',weight:'500' },
            { name: 'Inter', url: 'url(/fonts/Inter/Inter-Bold.woff2)',weight:'bold' },
            { name: 'Be Vietnam Pro', url: 'url(/fonts/BeVietnamPro/BeVietnamPro-Regular.woff2)',weight:'normal' },
            { name: 'Be Vietnam Pro', url: 'url(/fonts/BeVietnamPro/BeVietnamPro-Medium.woff2)',weight:'500' },
            { name: 'Be Vietnam Pro', url: 'url(/fonts/BeVietnamPro/BeVietnamPro-Bold.woff2)',weight:'bold' },
        ];

        const fontPromises = fonts.map(({ name, url,weight }) => {
            const font = new FontFace(name, url,{ weight: weight });
            return font.load().then((loadedFont) => {
                document.fonts.add(loadedFont);
            });
        });

        Promise.all(fontPromises)
        .then((data) => {
            if (props.initialValues.amount && props.flagShowImage) {
                var platform = props.initialValues.operatingSystem;
                if (!platform) {
                    platform = 'android';
                }
                var type = props.initialValues.type;
                var canvas = document.createElement('canvas');
                canvas.width = 636;
                canvas.height = 1377;
                var ctx = canvas.getContext('2d');
                var image = new Image();
                // image.src = './bills/'+ type + '/' + platform + '.png';
                image.src = '/bills/' + type + '/' + platform + '.png';
                image.onload = () => {
                    var imageWidth = image.width;
                    var imageHeight = image.height;
                
                    // Tính tỷ lệ giữa kích thước hình ảnh và canvas
                    var aspectRatio = imageWidth / imageHeight;
                    // Tính toán kích thước mới cho hình ảnh
                    var newWidth, newHeight;
                    if (aspectRatio > 1) {
                        newWidth = canvas.width;
                        newHeight = canvas.width / aspectRatio;
                    } else {
                        newWidth = canvas.height * aspectRatio;
                        newHeight = canvas.height;
                    }
                    // Set lại canvas
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    // Tính toán vị trí chèn hình ảnh vào canvas
                    var x = (canvas.width - newWidth) / 2;
                    var y = (canvas.height - newHeight) / 2;
                    ctx.drawImage(image, x, y, newWidth, newHeight);
                    ctx = BillGenerate.generateBill(type, props.initialValues, ctx, canvas);

                    // Render image
                    var imageURL = canvas.toDataURL('image/png',1);
                    setImagePreview(imageURL);

                    if (exportBill) {
                        var link = document.createElement("a");
                        link.href = canvas.toDataURL('image/png',1);
                        link.download = type + ' - ' + Math.floor(Date.now() / 1000) + ".png";
                        link.click();
                        Swal.fire({
                            icon: "success",
                            title: "Tải bill thành công!",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    }
                }
            }
        });

    }, [props.initialValues, props.exportBill, props.reloadCanvas])

    useEffect(() => {
        if(downloadBill != ''){
            var newWindow = window.open(downloadBill);
            // newWindow.location = downloadBill;
        }
    },[downloadBill])

    const handleExportBill = (imagePreview) => {
        const userAgent = navigator.userAgent;
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

        dispatch(setShowPreloader(true));
        UserModel.feeDowloadBill(imagePreview)
            .then(res => {
                dispatch(setShowPreloader(false));
                if (res.success === true) {
                    if( isMobile ){
                        window.location.href = res.imageUrl;
                    }else{
                        var link = document.createElement('a');
                        link.href = res.imageUrl;
                        link.download = 'bill.png';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                    // setDownloadBill(res.imageUrl)
                    
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.messange,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }).catch(err => {
                dispatch(setShowPreloader(false));
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    };
    function goBack() {
        window.history.back();
    }
    return (
        <div className="col-xl-4 col-lg-4">
            <div className='row'>
                <div className='col-12'>
                    <div className="card-body">
                        <div className='row d-flex justify-content-center p-3'>
                            <div className='col-6 d-flex justify-content-center'>
                                <button onClick={goBack} type="button" className="btn btn-warning ms-2 sw-btn-prev">
                                    Quay lại
                                </button>
                            </div>
                            <div className='col-6 d-flex justify-content-center'>
                                <button onClick={() => handleExportBill(imagePreview)} type="button" className="btn btn-primary ms-2 sw-btn-prev">
                                    Tải bill
                                    <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-download" viewBox="0 0 16 16">
                                        <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                                        <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div id="renderBill" style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {
                                imagePreview ? (
                                    <div className="text-center">
                                        <img alt='Đang tải ảnh' src={imagePreview} style={{ width: '100%', height: '100%', zIndex: 0 }} />
                                    </div>
                                ) : 'Đang tải dữ liệu'
                            }
                            <img id="billLogo" src={props.initialValues.logo} style={{ display:'none', zIndex: -1 }} />
                            <img id="billSign" src={'/bills/'+props.initialValues.type + '/vnd.png'} style={{ display:'none', zIndex: -2 }} />
                            <img src="/bills/mark.png" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageBillDowload;