import axiosClient from './axiosClient';
import AppCookie from "../helpers/AppCookie";

class BankInfoBill {
    constructor(){
        this.api_url = "/api/bankinfobills";
    }
    async all() {
        const res = await axiosClient.get(this.api_url);
        return res;
    }

}

export default new BankInfoBill;