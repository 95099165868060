import axiosClient from "./axiosClient";
import AppCookie from '../helpers/AppCookie';

class PaymentModel {
    constructor(){
        this.api_url = "/api/payments";
    }

    async getInfo() {
        const res = await axiosClient.get(this.api_url + "/earn-money");
        return res;
    }

    async getDeposit(credentials) {
        const res = await axiosClient.get(this.api_url + "/deposits" , {
            params: {
              search: credentials.search,
              page: credentials.page,
              perPage: credentials.perPage
            },
          });
        return res;
    }

    async deposit(credentials) {
        const res = await axiosClient.post(this.api_url + "/deposits", credentials );
        return res;
    }

    async depositDetail(credentials) {
        const res = await axiosClient.get(this.api_url + "/deposits/" + credentials );
        return res;
    }

    async getWithdrawHistory(credentials) {
        const res = await axiosClient.get(this.api_url + "/withdraws" , {
            params: {
              search: credentials.search,
              page: credentials.page,
              perPage: credentials.perPage
            },
          });
        return res;
    }

    async withdraw(credentials) {
        const res = await axiosClient.post(this.api_url + "/withdraw" , credentials );
        return res;
    }

    async transfer(credentials) {
        const res = await axiosClient.post(this.api_url + "/transfer" , credentials );
        return res;
    }
    
}

export default new PaymentModel;