import React, { useEffect, useState } from 'react';
import MasterLayout from '../layouts/MasterLayout';
import ThemeBreadcrumb from '../components/themes/ThemeBreadcrumb';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PackageItem from '../components/package/PackageItem';
import { useDispatch } from 'react-redux';
import { setShowPreloader } from '../redux/action';
import AppNotification from '../helpers/AppNotification';
import PackageModel from '../models/PackageModel';
import BankModel from '../models/BankModel';
import { useRef } from 'react';
import Swal from 'sweetalert2';
import ModalDeletePackage from '../components/package/ModalDeletePackage';


function Package(props) {
    const ref = useRef(null);
    const navigate = useNavigate();
    const { type } = useParams();
    const [typeBank, setTypeBank] = useState('');
    const [packAges, setPackAges] = useState([]);
    const [curentPackage, setCurentPackage] = useState([]);
    const [banks, setBanks] = useState([]);
    const [typeNavigate, setTypeNavigate] = useState(type);
    useEffect(() => {
        dispatch(setShowPreloader(true));
        PackageModel.getCurrentPackage()
            .then(res => {
                dispatch(setShowPreloader(false));
                setCurentPackage(res.data);
            }).catch(err => {
                dispatch(setShowPreloader(false));
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, []);
    const handleChangeTypeNavigate = (e) => {
        setTypeNavigate(e.target.value);
    }
    useEffect(() => {
        navigate("/package/" + typeNavigate);
    }, [typeNavigate]);
    useEffect(() => {
        dispatch(setShowPreloader(true));
        BankModel.all({})
            .then(res => {
                dispatch(setShowPreloader(false));
                setBanks(res.data);
            }).catch(err => {
                dispatch(setShowPreloader(false));
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, []);
    useEffect(() => {
        setPackAges([]);
        setTypeBank(type);
    }, [type]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setShowPreloader(true));
        PackageModel.all({
            bank_code: type
        })
            .then(res => {
                dispatch(setShowPreloader(false));
                setPackAges(res.data);
            }).catch(err => {
                dispatch(setShowPreloader(false));
                console.log(err);
                AppNotification.showError('Lấy dữ liệu thất bại!');
            })
    }, [typeBank]);

    const handleNavigate = (type_package) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        navigate('/package/' + type_package);
    }

    const handleDeletePackage = (type) => {
        const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa gói này?');
        if (confirmDelete) {
            dispatch(setShowPreloader(true));
            PackageModel.deletePackageApp({
                bank_code: type
            })
                .then(res => {
                    dispatch(setShowPreloader(false));
                    Swal.fire({
                        icon: "success",
                        title: "Hủy Gói thành công!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    setTimeout(() => {
                        reloadPage();
                    }, 2000);
                }).catch(err => {
                    dispatch(setShowPreloader(false));
                    console.log(err);
                    Swal.fire({
                        icon: "error",
                        title: "Hủy Gói thất bại!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    AppNotification.showError('Hủy gói thất bại!');
                })
        }
    }

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <MasterLayout>
            <ThemeBreadcrumb title={(type == 'VCB' && 'Gói Vietcombank') || (type == 'TCB' && 'Gói Techcombank') || (type == 'MB' && 'Gói MB-bank') || (type == 'BIDV' && 'Gói BIDV') || (type == 'ICB' && 'Gói Viettinbank')} />
            <div className='row'>
                <div className='col-12'>
                    <div className='row d-flex justify-content-center'>
                        <div className={`col-12 card`}>
                            {/*column*/}
                            {/* <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6"> */}
                            {/* <div className={`card mt-1 mt-md-3 ${item.type === type ? 'bg-primary' : ''}`}> */}
                            <div className={`card-body `}>
                                <div className="align-items-center h-100">
                                    <div className='row p-3'>
                                        <div className={`col-12 p-2 d-flex justify-content-center`}>
                                            <img src="/images/combo5app.png" width={150} height={150} />
                                        </div>
                                        <div className={`col-12 d-flex justify-content-center`}>
                                            <button className={`pt-2 btn btn-primary`} onClick={() => handleNavigate('ALL')} type='button'>
                                                <strong className='text-success'>CLICK MUA Gói ĐẶC BIỆT</strong>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='row d-flex justify-content-center'>
                                        {
                                            banks.map((item, key) => (
                                                <div className='col-lg-2 col-sm-6 p-2  d-flex justify-content-center'>
                                                    <button className='btn btn-primary btn-sm' onClick={() => handleNavigate(item.type)} type='button'>
                                                        <strong className='pt-2'>
                                                            <span className='text-white'>Gói {item.type == 'VCB' ? 'Vietcombank'
                                                                : item.type == 'TCB' ? 'Techcombank'
                                                                    : item.type == 'ICB' ? 'Viettinbank'
                                                                        : item.type == 'BIDV' ? 'BIDV'
                                                                            : 'MB-bank'}</span>
                                                        </strong>
                                                    </button>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                            {/*/column*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row' ref={ref}>
                <div className='col-xl-12'>
                    <div className='row'>
                        {
                            packAges.map((item, key) => (
                                <PackageItem package={item} type={type} />
                            ))
                        }
                    </div >
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <h4>
                                Gói đang sử dụng
                            </h4>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th>TÊN GÓI</th>
                                            <th>HÀNH ĐỘNG</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            curentPackage.map((item, key) => (
                                                <tr>
                                                    {
                                                        item.type_package == 'week' ?
                                                            <td className='col-4'>Gói Tuần của gói {item.bank_code}</td>
                                                            :
                                                            <td className='col-4'>{item.type_package} của gói {item.bank_code}</td>
                                                    }
                                                    <td className='col-4'>
                                                        <button type='button' onClick={() => handleDeletePackage(item.bank_code)} className="badge badge-danger">HỦY GÓI</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </MasterLayout>
    );
}

export default Package;